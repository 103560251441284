import React from 'react';
import "./App.css";
import Footer from './Footer.js';
import { RaceInfoCreateForm } from './ui-components';
import { Amplify } from 'aws-amplify';
import { Paper, Typography, Box } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports.js';
import { withAuthenticator } from '@aws-amplify/ui-react';

Amplify.configure(awsExports);

const styles = {
    mainContent: {
        backgroundColor: '#f4f4f4',
        padding: '20px',
        marginTop: '100px',
        display: 'flex',
        justifyContent: 'center'
    },
    paperContent: {
        width: '100%',
        maxWidth: '800px',
        padding: '20px',
        backgroundColor: '#ffffff',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        margin: '20px 0'
    },
    formContainer: {
        backgroundColor: '#f9f9f9',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        marginBottom: '40px'
    },
    h3: {
        color: '#333',
        fontSize: '2rem',
        marginBottom: '20px',
        textAlign: 'center'
    },
    p: {
        fontSize: '1.2rem',
        color: '#555',
        textAlign: 'center',
        marginBottom: '20px'
    },
    hr: {
        border: '1px solid #ddd',
        margin: '20px 0'
    },
    button: {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '1rem',
        margin: '10px 0',
        display: 'block',
        width: '100%'
    },
    buttonHover: {
        backgroundColor: '#45a049'
    },
    input: {
        width: '100%',
        padding: '12px 20px',
        margin: '8px 0',
        display: 'inline-block',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box'
    },
    label: {
        fontSize: '1rem',
        color: '#333',
        marginBottom: '8px',
        display: 'block'
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.9rem',
        marginBottom: '20px'
    }
};

function RaceInfo({ user }) {
    var staticData = {
        id: user.username,
    };

    return (
        <>
        <div style={styles.mainContent}>
            <Paper elevation={3} style={styles.paperContent}>
                <Typography variant="h3" gutterBottom style={styles.h3}>
                    Information for Government Monitoring Purposes
                </Typography>
                <hr style={styles.hr} />
                <Typography variant="body1" gutterBottom style={styles.p}>
                    The following information is requested by the Federal Government for certain types of loans related to a dwelling in order to monitor the lender's compliance with equal credit opportunity, fair housing
                    and home mortgage disclosure laws.
                </Typography>
                <Typography variant="body1" gutterBottom style={styles.p}>
                    You are not required to furnish this information, but are encouraged to do so. The law provides that a lender may not discriminate either on the basis of this
                    information, or on whether you choose to furnish it.
                </Typography>
                <Typography variant="body1" gutterBottom style={styles.p}>
                    If you furnish the information, please provide both ethnicity and race. For race, you may check more than one designation. If you do not furnish
                    ethnicity, race, or sex, under Federal regulations, this lender is required to note the information on the basis of
                    visual observation and surname if you have made this application in person.
                </Typography>
                <Typography variant="body1" gutterBottom style={styles.p}>
                    If you do not wish to furnish the information, please check the box below. (Lender must review the above material to assure that the disclosures satisfy all requirements to which the lender is subject under applicable
                    state law for the particular type of loan applied for.)
                </Typography>
                <Box style={styles.formContainer}>
                    <RaceInfoCreateForm
                        onSubmit={(fields) => {
                            const payload = {
                                ...fields,
                                ...staticData,
                            };
                            return payload;
                        }}
                    />
                </Box>
            </Paper>          
        </div>
        <Footer />
        </>
    );
}

export default withAuthenticator(RaceInfo);
