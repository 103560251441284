import './App.css';
import React, { useEffect, useState, useCallback } from 'react';
import { Amplify } from 'aws-amplify';
import { getUrl, list } from '@aws-amplify/storage';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports.js';
import Footer from './Footer.js';
import { usePlaidLink } from 'react-plaid-link';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import './S3LinkUI.css';
import { loadConfig } from './ConfigLoader';


Amplify.configure(awsExports);

const sendPublicTokenToServer = async (publicToken, userId, actionType) => {
  console.log("Sending public token to server with action:", actionType);
  try {
        // Load configuration to get the API URL
        const config = await loadConfig();
        const plaidApiUrl = config.REACT_APP_PLAID_LINK_URL;
        
        const response = await fetch(`${plaidApiUrl}/exchangeToken`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ publicToken, userId, action: actionType }),
      });
    console.log("Public token:", publicToken);
    const data = await response.json();
    console.log('Data:', data);
    console.log('Access Token:', data.accessToken);
    // Handle the access token securely
  } catch (error) {
    console.error('Error exchanging public token:', error);
  }
};

const S3Link = ({ user }) => {
  const userId = user ? user.username : 'defaultUserId';  // Handling potential undefined user
  const [s3Url, setS3Url] = useState('');

  const [linkToken2, setLinkToken2] = useState('');
  const [linkToken3, setLinkToken3] = useState('');
  const [licenseId, setLicenseId] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fileList, setFileList] = useState([]); // State for bank statements file list
  const [paystubList, setPaystubList] = useState([]); // State for paystub file list
  const [previewUrl, setPreviewUrl] = useState(''); // State for preview URL

  const fetchLicenseId = async () => {
    try {
      const config = await loadConfig();
      const plaidFetchLicenseApiUrl = config.REACT_APP_PLAID_FETCH_LICENSE_URL;
      const response = await fetch(`${plaidFetchLicenseApiUrl}/users/${user.username}/license`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();
      if (result && result.licenseId) {
        console.log("License ID:", result.licenseId);
        // Display or use licenseId as needed
        setLicenseId(result.licenseId); // Set the licenseId state
        
      } else {
        console.error('License not found');
      }
    } catch (error) {
      console.error('Error fetching license:', error);
    }
  };


  useEffect(() => {
    const fetchUrl = async () => {
      // S3 key for the file
      console.log('User:', userId);
      const filename = user.username + '/modified_example1003.pdf';
      try {
        const result = await getUrl({
          key: filename,
        });
        const signedUrlString = result.url.toString(); // Convert URL object to string
        console.log('Result:', result); // Check the result
        console.log('Signed URL:', signedUrlString); // Check the structure of signedUrl
        console.log('Requesting S3 key:', filename);

        setS3Url(signedUrlString);
      } catch (error) {
        console.error('Error getting signed URL: ', error);
      }
    };

    const fetchSecondLinkToken = async (userId) => {
      setLoading(true); // Reuse the existing loading state or create a new one for this process
      const config = await loadConfig();
      const plaidApiUrl = config.REACT_APP_PLAID_LINK_URL;
      const apiUrl = `${plaidApiUrl}/createLinkToken`;
        console.log("API URL for fetching link token:", apiUrl);
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId }),
        });
        if (!response.ok) throw new Error('Failed to fetch second link token');
        const data = await response.json();
        setLinkToken2(data.link_token2); // Assuming the response structure is similar
      } catch (error) {
        console.error('Error fetching second link token:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchThirdLinkToken = async (userId) => {
      setLoading(true); // Reuse the existing loading state or create a new one for this process
      try {
        const config = await loadConfig();
        const plaidApiUrl = config.REACT_APP_PLAID_LINK_URL;
        const response = await fetch(`${plaidApiUrl}/createLinkToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId }),
        });
        if (!response.ok) throw new Error('Failed to fetch third link token');
        const data = await response.json();
        setLinkToken3(data.link_token3); // Assuming the response structure is similar
      } catch (error) {
        console.error('Error fetching third link token:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchFileList = async (userId) => {
      try {
        const path = `${userId}/documents/bankStatements/`;
        const response = await list(path, { level: 'public' });
        console.log('User ID:', userId);
        console.log('Path:', path);
        console.log('Response from S3 list:', response); // Check the structure of the response

        if (response && response.items) {
          // Log all items to verify the response
          console.log('All items:', response.items);

          // Filter files to include only those in the specified directory
          const filteredFiles = response.items.filter(file => file.key.startsWith(path));
          console.log('Filtered files:', filteredFiles); // Log the filtered files

          setFileList(filteredFiles);
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error('Error listing files:', error);
        setError(error);
      }
    };

    const fetchPaystubList = async (userId) => {
      try {
        const path = `${userId}/documents/paystubs/`;
        const response = await list(path, { level: 'public' });
        console.log('User ID:', userId);
        console.log('Path:', path);
        console.log('Response from S3 list:', response); // Check the structure of the response

        if (response && response.items) {
          // Log all items to verify the response
          console.log('All items:', response.items);

          // Filter files to include only those in the specified directory
          const filteredFiles = response.items.filter(file => file.key.startsWith(path));
          console.log('Filtered files:', filteredFiles); // Log the filtered files

          setPaystubList(filteredFiles);
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error('Error listing files:', error);
        setError(error);
      }
    };

    fetchUrl();
    fetchSecondLinkToken(userId);
    fetchThirdLinkToken(userId);
    fetchFileList(userId);
    fetchPaystubList(userId);
  }, [user]); // Dependency array: re-run the effect if the user changes

  console.log('User ID:', user.username);
  const onSuccess2 = useCallback((publicToken) => {
    console.log('Public Token 2: ', publicToken);
    sendPublicTokenToServer(publicToken, userId, "fetchStatements");
  }, [userId]);

  const onSuccess3 = useCallback((publicToken) => {
    console.log('Public Token 3: ', publicToken);
    sendPublicTokenToServer(publicToken, userId, "verifyIncome");
  }, [userId]);

  const config2 = {
    token: linkToken2,
    onSuccess: onSuccess2,
  };

  const config3 = {
    token: linkToken3,
    onSuccess: onSuccess3,
  };

  const { open: open2, ready: ready2 } = usePlaidLink(config2);
  const { open: open3, ready: ready3 } = usePlaidLink(config3);

  const handlePreview = async (key) => {
    try {
      const result = await getUrl({
        key,
      });
      setPreviewUrl(result.url);
    } catch (error) {
      console.error('Error getting preview URL: ', error);
    }
  };

  const extractFileName = (key) => {
    return key.split('/').pop();
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching link token or listing files!</p>;

  return (
    <div style={{ marginTop: '100px' }}>
      <h3 className='documents-header'>Supporting Documents for Application</h3>
      <div id="userIdSection">
        <p>Your user id is {userId}</p>
      </div>
      <div>
        <button onClick={fetchLicenseId}>
          Fetch License Info
        </button>
        {loading && <p>Loading...</p>}
      {error && <p>Error fetching license info.</p>}
      {!loading && !error && licenseId && (
        <p>Your license ID is: <strong>{licenseId}</strong></p>  // Display the licenseId here
      )}
      </div>

      <div className="light-gray-box">
        <div className="paystubsSection">
          <h3> Fetch paystubs from Plaid</h3>
          <div class="button-container">
            <button id="connectButton" onClick={() => open3()} disabled={!ready3}>
              Connect to verify income
            </button>
          </div>
        </div>

        <br></br>
        <div id="uploadSection">
          <h3>You can also manually upload your paystub here:</h3>
          <StorageManager
            acceptedFileTypes={['*']}
            path={`${userId}/documents/paystubs/`}
            accessLevel='public'
            maxFileCount={1}
            isResumable
          />
        </div>

        <div id="mainSection">
          <div id="uploadedPaystubsSection">
            <h3>View your uploaded paystubs here:</h3>
            <ul id='paystubList'>
              {paystubList && paystubList.map((file, index) => (
                <li key={file.key}>
                  <a href="#" onClick={() => handlePreview(file.key)}>
                    Paystub{index + 1}. {extractFileName(file.key)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        ____________________________________________________________________________________________________________________________________


        <div className="paystubsSection">
          <h3> Fetch W2 from Plaid</h3>
          <div class="button-container">
            <button id="connectButton" onClick={() => open3()} disabled={!ready3}>
              Fetch your W2
            </button>
          </div>
        </div>
        <br></br>
        <div className="paystubsSection">
          <h3>Upload your W2 document here</h3>
          <StorageManager
            acceptedFileTypes={['*']}
            path={`${userId}/documents/W2/`}
            accessLevel='public'
            maxFileCount={1}
            isResumable
          />
        </div>

        <div id="mainSection">
          <div id="uploadedPaystubsSection">
            <h3>View your uploaded W2 here:</h3>
            <ul id='paystubList'>
              {fileList && fileList.map((file, index) => (
                <li key={file.key}>
                  <a href="#" onClick={() => handlePreview(file.key)}>
                    W2-{index + 1}. {extractFileName(file.key)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <br></br>
        <div className="paystubsSection">
          <h3> Fetch bank statements from Plaid</h3>
          <div class="button-container">
            <button id="connectButton" onClick={() => open2()} disabled={!ready2}>
              Fetch your bank statements
            </button>
          </div>
        </div>

        <br></br>
        <div id="uploadSection">
          <h3>Upload your bank statements here:</h3>
          <StorageManager
            acceptedFileTypes={['*']}
            path={`${userId}/documents/bankStatements/`}
            accessLevel='public'
            maxFileCount={1}
            isResumable
          />
        </div>

        <div id="mainSection">
          <div id="uploadedPaystubsSection">
            <h3>View your uploaded bank statements here:</h3>
            <ul id='paystubList'>
              {fileList && fileList.map((file, index) => (
                <li key={file.key}>
                  <a href="#" onClick={() => handlePreview(file.key)}>
                    Statement{index + 1}. {extractFileName(file.key)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {previewUrl && (
          <div className="file-preview-frame">
            <iframe className="file-preview" src={previewUrl} title="File Preview" />
          </div>
        )}

        <div className="highlight-section">
          <p> Please click on the link below to access the pre-filled loan application form. </p>
          <p>Link to pre-filled document</p>
          {s3Url && (
            <a href={s3Url} target="_blank" rel="noopener noreferrer">
              Click here
            </a>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default withAuthenticator(S3Link);