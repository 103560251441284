/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Radio,
  RadioGroupField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createAssetsInfo } from "../graphql/mutations";
const client = generateClient();
export default function AssetsInfoCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    completedJointly: undefined,
    cashDeposit: "",
    cashOrMarketVal: "",
    nameAddressOfBank: "",
    accountNumber: "",
    amount: "",
    nameAddressOfBank2: "",
    accountNumber2: "",
    amount2: "",
    nameAddressOfBank3: "",
    accountNumber3: "",
    amount3: "",
    nameAddressOfBank4: "",
    accountNumber4: "",
    amount4: "",
    stockandBondsCompany: "",
    stockAmount: "",
    lifeInsuranceFaceAmount: "",
    insuranceAmount: "",
    subtotalAssets: "",
    realEstateAmount: "",
    retirementFund: "",
    netWorthOfBusiness: "",
    automobilesMakeAndYear: "",
    automobilesAmount: "",
    otherAssets: "",
    otherAssetsAmount: "",
    totalAssets: "",
  };
  const [completedJointly, setCompletedJointly] = React.useState(
    initialValues.completedJointly
  );
  const [cashDeposit, setCashDeposit] = React.useState(
    initialValues.cashDeposit
  );
  const [cashOrMarketVal, setCashOrMarketVal] = React.useState(
    initialValues.cashOrMarketVal
  );
  const [nameAddressOfBank, setNameAddressOfBank] = React.useState(
    initialValues.nameAddressOfBank
  );
  const [accountNumber, setAccountNumber] = React.useState(
    initialValues.accountNumber
  );
  const [amount, setAmount] = React.useState(initialValues.amount);
  const [nameAddressOfBank2, setNameAddressOfBank2] = React.useState(
    initialValues.nameAddressOfBank2
  );
  const [accountNumber2, setAccountNumber2] = React.useState(
    initialValues.accountNumber2
  );
  const [amount2, setAmount2] = React.useState(initialValues.amount2);
  const [nameAddressOfBank3, setNameAddressOfBank3] = React.useState(
    initialValues.nameAddressOfBank3
  );
  const [accountNumber3, setAccountNumber3] = React.useState(
    initialValues.accountNumber3
  );
  const [amount3, setAmount3] = React.useState(initialValues.amount3);
  const [nameAddressOfBank4, setNameAddressOfBank4] = React.useState(
    initialValues.nameAddressOfBank4
  );
  const [accountNumber4, setAccountNumber4] = React.useState(
    initialValues.accountNumber4
  );
  const [amount4, setAmount4] = React.useState(initialValues.amount4);
  const [stockandBondsCompany, setStockandBondsCompany] = React.useState(
    initialValues.stockandBondsCompany
  );
  const [stockAmount, setStockAmount] = React.useState(
    initialValues.stockAmount
  );
  const [lifeInsuranceFaceAmount, setLifeInsuranceFaceAmount] = React.useState(
    initialValues.lifeInsuranceFaceAmount
  );
  const [insuranceAmount, setInsuranceAmount] = React.useState(
    initialValues.insuranceAmount
  );
  const [subtotalAssets, setSubtotalAssets] = React.useState(
    initialValues.subtotalAssets
  );
  const [realEstateAmount, setRealEstateAmount] = React.useState(
    initialValues.realEstateAmount
  );
  const [retirementFund, setRetirementFund] = React.useState(
    initialValues.retirementFund
  );
  const [netWorthOfBusiness, setNetWorthOfBusiness] = React.useState(
    initialValues.netWorthOfBusiness
  );
  const [automobilesMakeAndYear, setAutomobilesMakeAndYear] = React.useState(
    initialValues.automobilesMakeAndYear
  );
  const [automobilesAmount, setAutomobilesAmount] = React.useState(
    initialValues.automobilesAmount
  );
  const [otherAssets, setOtherAssets] = React.useState(
    initialValues.otherAssets
  );
  const [otherAssetsAmount, setOtherAssetsAmount] = React.useState(
    initialValues.otherAssetsAmount
  );
  const [totalAssets, setTotalAssets] = React.useState(
    initialValues.totalAssets
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setCompletedJointly(initialValues.completedJointly);
    setCashDeposit(initialValues.cashDeposit);
    setCashOrMarketVal(initialValues.cashOrMarketVal);
    setNameAddressOfBank(initialValues.nameAddressOfBank);
    setAccountNumber(initialValues.accountNumber);
    setAmount(initialValues.amount);
    setNameAddressOfBank2(initialValues.nameAddressOfBank2);
    setAccountNumber2(initialValues.accountNumber2);
    setAmount2(initialValues.amount2);
    setNameAddressOfBank3(initialValues.nameAddressOfBank3);
    setAccountNumber3(initialValues.accountNumber3);
    setAmount3(initialValues.amount3);
    setNameAddressOfBank4(initialValues.nameAddressOfBank4);
    setAccountNumber4(initialValues.accountNumber4);
    setAmount4(initialValues.amount4);
    setStockandBondsCompany(initialValues.stockandBondsCompany);
    setStockAmount(initialValues.stockAmount);
    setLifeInsuranceFaceAmount(initialValues.lifeInsuranceFaceAmount);
    setInsuranceAmount(initialValues.insuranceAmount);
    setSubtotalAssets(initialValues.subtotalAssets);
    setRealEstateAmount(initialValues.realEstateAmount);
    setRetirementFund(initialValues.retirementFund);
    setNetWorthOfBusiness(initialValues.netWorthOfBusiness);
    setAutomobilesMakeAndYear(initialValues.automobilesMakeAndYear);
    setAutomobilesAmount(initialValues.automobilesAmount);
    setOtherAssets(initialValues.otherAssets);
    setOtherAssetsAmount(initialValues.otherAssetsAmount);
    setTotalAssets(initialValues.totalAssets);
    setErrors({});
  };
  const validations = {
    completedJointly: [],
    cashDeposit: [],
    cashOrMarketVal: [],
    nameAddressOfBank: [],
    accountNumber: [],
    amount: [],
    nameAddressOfBank2: [],
    accountNumber2: [],
    amount2: [],
    nameAddressOfBank3: [],
    accountNumber3: [],
    amount3: [],
    nameAddressOfBank4: [],
    accountNumber4: [],
    amount4: [],
    stockandBondsCompany: [],
    stockAmount: [],
    lifeInsuranceFaceAmount: [],
    insuranceAmount: [],
    subtotalAssets: [],
    realEstateAmount: [],
    retirementFund: [],
    netWorthOfBusiness: [],
    automobilesMakeAndYear: [],
    automobilesAmount: [],
    otherAssets: [],
    otherAssetsAmount: [],
    totalAssets: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          completedJointly,
          cashDeposit,
          cashOrMarketVal,
          nameAddressOfBank,
          accountNumber,
          amount,
          nameAddressOfBank2,
          accountNumber2,
          amount2,
          nameAddressOfBank3,
          accountNumber3,
          amount3,
          nameAddressOfBank4,
          accountNumber4,
          amount4,
          stockandBondsCompany,
          stockAmount,
          lifeInsuranceFaceAmount,
          insuranceAmount,
          subtotalAssets,
          realEstateAmount,
          retirementFund,
          netWorthOfBusiness,
          automobilesMakeAndYear,
          automobilesAmount,
          otherAssets,
          otherAssetsAmount,
          totalAssets,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createAssetsInfo.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "AssetsInfoCreateForm")}
      {...rest}
    >
      <RadioGroupField
        label="Completed jointly:"
        name="completedJointly"
        isReadOnly={false}
        isRequired={false}
        onChange={(e) => {
          let value = e.target.value === "true";
          if (onChange) {
            const modelFields = {
              completedJointly: value,
              cashDeposit,
              cashOrMarketVal,
              nameAddressOfBank,
              accountNumber,
              amount,
              nameAddressOfBank2,
              accountNumber2,
              amount2,
              nameAddressOfBank3,
              accountNumber3,
              amount3,
              nameAddressOfBank4,
              accountNumber4,
              amount4,
              stockandBondsCompany,
              stockAmount,
              lifeInsuranceFaceAmount,
              insuranceAmount,
              subtotalAssets,
              realEstateAmount,
              retirementFund,
              netWorthOfBusiness,
              automobilesMakeAndYear,
              automobilesAmount,
              otherAssets,
              otherAssetsAmount,
              totalAssets,
            };
            const result = onChange(modelFields);
            value = result?.completedJointly ?? value;
          }
          if (errors.completedJointly?.hasError) {
            runValidationTasks("completedJointly", value);
          }
          setCompletedJointly(value);
        }}
        onBlur={() => runValidationTasks("completedJointly", completedJointly)}
        errorMessage={errors.completedJointly?.errorMessage}
        hasError={errors.completedJointly?.hasError}
        {...getOverrideProps(overrides, "completedJointly")}
      >
        <Radio
          children="Yes"
          value="true"
          {...getOverrideProps(overrides, "completedJointlyRadio0")}
        ></Radio>
        <Radio
          children="No"
          value="false"
          {...getOverrideProps(overrides, "completedJointlyRadio1")}
        ></Radio>
      </RadioGroupField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid1")}
      >
        <TextField
          label="Cash deposit towards purchase held by:"
          isRequired={false}
          isReadOnly={false}
          value={cashDeposit}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit: value,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.cashDeposit ?? value;
            }
            if (errors.cashDeposit?.hasError) {
              runValidationTasks("cashDeposit", value);
            }
            setCashDeposit(value);
          }}
          onBlur={() => runValidationTasks("cashDeposit", cashDeposit)}
          errorMessage={errors.cashDeposit?.errorMessage}
          hasError={errors.cashDeposit?.hasError}
          {...getOverrideProps(overrides, "cashDeposit")}
        ></TextField>
        <TextField
          label="Cash or market value"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={cashOrMarketVal}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal: value,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.cashOrMarketVal ?? value;
            }
            if (errors.cashOrMarketVal?.hasError) {
              runValidationTasks("cashOrMarketVal", value);
            }
            setCashOrMarketVal(value);
          }}
          onBlur={() => runValidationTasks("cashOrMarketVal", cashOrMarketVal)}
          errorMessage={errors.cashOrMarketVal?.errorMessage}
          hasError={errors.cashOrMarketVal?.hasError}
          {...getOverrideProps(overrides, "cashOrMarketVal")}
        ></TextField>
      </Grid>
      <TextField
        label="Name address of bank, S&L, or Credit Union"
        isRequired={false}
        isReadOnly={false}
        value={nameAddressOfBank}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              completedJointly,
              cashDeposit,
              cashOrMarketVal,
              nameAddressOfBank: value,
              accountNumber,
              amount,
              nameAddressOfBank2,
              accountNumber2,
              amount2,
              nameAddressOfBank3,
              accountNumber3,
              amount3,
              nameAddressOfBank4,
              accountNumber4,
              amount4,
              stockandBondsCompany,
              stockAmount,
              lifeInsuranceFaceAmount,
              insuranceAmount,
              subtotalAssets,
              realEstateAmount,
              retirementFund,
              netWorthOfBusiness,
              automobilesMakeAndYear,
              automobilesAmount,
              otherAssets,
              otherAssetsAmount,
              totalAssets,
            };
            const result = onChange(modelFields);
            value = result?.nameAddressOfBank ?? value;
          }
          if (errors.nameAddressOfBank?.hasError) {
            runValidationTasks("nameAddressOfBank", value);
          }
          setNameAddressOfBank(value);
        }}
        onBlur={() =>
          runValidationTasks("nameAddressOfBank", nameAddressOfBank)
        }
        errorMessage={errors.nameAddressOfBank?.errorMessage}
        hasError={errors.nameAddressOfBank?.hasError}
        {...getOverrideProps(overrides, "nameAddressOfBank")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid3")}
      >
        <TextField
          label="Account number"
          isRequired={false}
          isReadOnly={false}
          value={accountNumber}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber: value,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.accountNumber ?? value;
            }
            if (errors.accountNumber?.hasError) {
              runValidationTasks("accountNumber", value);
            }
            setAccountNumber(value);
          }}
          onBlur={() => runValidationTasks("accountNumber", accountNumber)}
          errorMessage={errors.accountNumber?.errorMessage}
          hasError={errors.accountNumber?.hasError}
          {...getOverrideProps(overrides, "accountNumber")}
        ></TextField>
        <TextField
          label="Amount"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={amount}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount: value,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.amount ?? value;
            }
            if (errors.amount?.hasError) {
              runValidationTasks("amount", value);
            }
            setAmount(value);
          }}
          onBlur={() => runValidationTasks("amount", amount)}
          errorMessage={errors.amount?.errorMessage}
          hasError={errors.amount?.hasError}
          {...getOverrideProps(overrides, "amount")}
        ></TextField>
      </Grid>
      <TextField
        label="Name address of bank, S&L, or Credit Union"
        isRequired={false}
        isReadOnly={false}
        value={nameAddressOfBank2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              completedJointly,
              cashDeposit,
              cashOrMarketVal,
              nameAddressOfBank,
              accountNumber,
              amount,
              nameAddressOfBank2: value,
              accountNumber2,
              amount2,
              nameAddressOfBank3,
              accountNumber3,
              amount3,
              nameAddressOfBank4,
              accountNumber4,
              amount4,
              stockandBondsCompany,
              stockAmount,
              lifeInsuranceFaceAmount,
              insuranceAmount,
              subtotalAssets,
              realEstateAmount,
              retirementFund,
              netWorthOfBusiness,
              automobilesMakeAndYear,
              automobilesAmount,
              otherAssets,
              otherAssetsAmount,
              totalAssets,
            };
            const result = onChange(modelFields);
            value = result?.nameAddressOfBank2 ?? value;
          }
          if (errors.nameAddressOfBank2?.hasError) {
            runValidationTasks("nameAddressOfBank2", value);
          }
          setNameAddressOfBank2(value);
        }}
        onBlur={() =>
          runValidationTasks("nameAddressOfBank2", nameAddressOfBank2)
        }
        errorMessage={errors.nameAddressOfBank2?.errorMessage}
        hasError={errors.nameAddressOfBank2?.hasError}
        {...getOverrideProps(overrides, "nameAddressOfBank2")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid5")}
      >
        <TextField
          label="Account number"
          isRequired={false}
          isReadOnly={false}
          value={accountNumber2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2: value,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.accountNumber2 ?? value;
            }
            if (errors.accountNumber2?.hasError) {
              runValidationTasks("accountNumber2", value);
            }
            setAccountNumber2(value);
          }}
          onBlur={() => runValidationTasks("accountNumber2", accountNumber2)}
          errorMessage={errors.accountNumber2?.errorMessage}
          hasError={errors.accountNumber2?.hasError}
          {...getOverrideProps(overrides, "accountNumber2")}
        ></TextField>
        <TextField
          label="Amount"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={amount2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2: value,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.amount2 ?? value;
            }
            if (errors.amount2?.hasError) {
              runValidationTasks("amount2", value);
            }
            setAmount2(value);
          }}
          onBlur={() => runValidationTasks("amount2", amount2)}
          errorMessage={errors.amount2?.errorMessage}
          hasError={errors.amount2?.hasError}
          {...getOverrideProps(overrides, "amount2")}
        ></TextField>
      </Grid>
      <TextField
        label="Name address of bank, S&L, or Credit Union"
        isRequired={false}
        isReadOnly={false}
        value={nameAddressOfBank3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              completedJointly,
              cashDeposit,
              cashOrMarketVal,
              nameAddressOfBank,
              accountNumber,
              amount,
              nameAddressOfBank2,
              accountNumber2,
              amount2,
              nameAddressOfBank3: value,
              accountNumber3,
              amount3,
              nameAddressOfBank4,
              accountNumber4,
              amount4,
              stockandBondsCompany,
              stockAmount,
              lifeInsuranceFaceAmount,
              insuranceAmount,
              subtotalAssets,
              realEstateAmount,
              retirementFund,
              netWorthOfBusiness,
              automobilesMakeAndYear,
              automobilesAmount,
              otherAssets,
              otherAssetsAmount,
              totalAssets,
            };
            const result = onChange(modelFields);
            value = result?.nameAddressOfBank3 ?? value;
          }
          if (errors.nameAddressOfBank3?.hasError) {
            runValidationTasks("nameAddressOfBank3", value);
          }
          setNameAddressOfBank3(value);
        }}
        onBlur={() =>
          runValidationTasks("nameAddressOfBank3", nameAddressOfBank3)
        }
        errorMessage={errors.nameAddressOfBank3?.errorMessage}
        hasError={errors.nameAddressOfBank3?.hasError}
        {...getOverrideProps(overrides, "nameAddressOfBank3")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <TextField
          label="Account number"
          isRequired={false}
          isReadOnly={false}
          value={accountNumber3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3: value,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.accountNumber3 ?? value;
            }
            if (errors.accountNumber3?.hasError) {
              runValidationTasks("accountNumber3", value);
            }
            setAccountNumber3(value);
          }}
          onBlur={() => runValidationTasks("accountNumber3", accountNumber3)}
          errorMessage={errors.accountNumber3?.errorMessage}
          hasError={errors.accountNumber3?.hasError}
          {...getOverrideProps(overrides, "accountNumber3")}
        ></TextField>
        <TextField
          label="Amount"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={amount3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3: value,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.amount3 ?? value;
            }
            if (errors.amount3?.hasError) {
              runValidationTasks("amount3", value);
            }
            setAmount3(value);
          }}
          onBlur={() => runValidationTasks("amount3", amount3)}
          errorMessage={errors.amount3?.errorMessage}
          hasError={errors.amount3?.hasError}
          {...getOverrideProps(overrides, "amount3")}
        ></TextField>
      </Grid>
      <TextField
        label="Name address of bank, S&L, or Credit Union"
        isRequired={false}
        isReadOnly={false}
        value={nameAddressOfBank4}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              completedJointly,
              cashDeposit,
              cashOrMarketVal,
              nameAddressOfBank,
              accountNumber,
              amount,
              nameAddressOfBank2,
              accountNumber2,
              amount2,
              nameAddressOfBank3,
              accountNumber3,
              amount3,
              nameAddressOfBank4: value,
              accountNumber4,
              amount4,
              stockandBondsCompany,
              stockAmount,
              lifeInsuranceFaceAmount,
              insuranceAmount,
              subtotalAssets,
              realEstateAmount,
              retirementFund,
              netWorthOfBusiness,
              automobilesMakeAndYear,
              automobilesAmount,
              otherAssets,
              otherAssetsAmount,
              totalAssets,
            };
            const result = onChange(modelFields);
            value = result?.nameAddressOfBank4 ?? value;
          }
          if (errors.nameAddressOfBank4?.hasError) {
            runValidationTasks("nameAddressOfBank4", value);
          }
          setNameAddressOfBank4(value);
        }}
        onBlur={() =>
          runValidationTasks("nameAddressOfBank4", nameAddressOfBank4)
        }
        errorMessage={errors.nameAddressOfBank4?.errorMessage}
        hasError={errors.nameAddressOfBank4?.hasError}
        {...getOverrideProps(overrides, "nameAddressOfBank4")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <TextField
          label="Account number"
          isRequired={false}
          isReadOnly={false}
          value={accountNumber4}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4: value,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.accountNumber4 ?? value;
            }
            if (errors.accountNumber4?.hasError) {
              runValidationTasks("accountNumber4", value);
            }
            setAccountNumber4(value);
          }}
          onBlur={() => runValidationTasks("accountNumber4", accountNumber4)}
          errorMessage={errors.accountNumber4?.errorMessage}
          hasError={errors.accountNumber4?.hasError}
          {...getOverrideProps(overrides, "accountNumber4")}
        ></TextField>
        <TextField
          label="Amount"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={amount4}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4: value,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.amount4 ?? value;
            }
            if (errors.amount4?.hasError) {
              runValidationTasks("amount4", value);
            }
            setAmount4(value);
          }}
          onBlur={() => runValidationTasks("amount4", amount4)}
          errorMessage={errors.amount4?.errorMessage}
          hasError={errors.amount4?.hasError}
          {...getOverrideProps(overrides, "amount4")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid10")}
      >
        <TextField
          label="Stock and bonds "
          descriptiveText="(Company name/ number and description)"
          isRequired={false}
          isReadOnly={false}
          value={stockandBondsCompany}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany: value,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.stockandBondsCompany ?? value;
            }
            if (errors.stockandBondsCompany?.hasError) {
              runValidationTasks("stockandBondsCompany", value);
            }
            setStockandBondsCompany(value);
          }}
          onBlur={() =>
            runValidationTasks("stockandBondsCompany", stockandBondsCompany)
          }
          errorMessage={errors.stockandBondsCompany?.errorMessage}
          hasError={errors.stockandBondsCompany?.hasError}
          {...getOverrideProps(overrides, "stockandBondsCompany")}
        ></TextField>
        <TextField
          label="Stock amount"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={stockAmount}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount: value,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.stockAmount ?? value;
            }
            if (errors.stockAmount?.hasError) {
              runValidationTasks("stockAmount", value);
            }
            setStockAmount(value);
          }}
          onBlur={() => runValidationTasks("stockAmount", stockAmount)}
          errorMessage={errors.stockAmount?.errorMessage}
          hasError={errors.stockAmount?.hasError}
          {...getOverrideProps(overrides, "stockAmount")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid11")}
      >
        <TextField
          label="Life insurance net cash value"
          descriptiveText="Face amount"
          isRequired={false}
          isReadOnly={false}
          value={lifeInsuranceFaceAmount}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount: value,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.lifeInsuranceFaceAmount ?? value;
            }
            if (errors.lifeInsuranceFaceAmount?.hasError) {
              runValidationTasks("lifeInsuranceFaceAmount", value);
            }
            setLifeInsuranceFaceAmount(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "lifeInsuranceFaceAmount",
              lifeInsuranceFaceAmount
            )
          }
          errorMessage={errors.lifeInsuranceFaceAmount?.errorMessage}
          hasError={errors.lifeInsuranceFaceAmount?.hasError}
          {...getOverrideProps(overrides, "lifeInsuranceFaceAmount")}
        ></TextField>
        <TextField
          label="Insurance amount"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={insuranceAmount}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount: value,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.insuranceAmount ?? value;
            }
            if (errors.insuranceAmount?.hasError) {
              runValidationTasks("insuranceAmount", value);
            }
            setInsuranceAmount(value);
          }}
          onBlur={() => runValidationTasks("insuranceAmount", insuranceAmount)}
          errorMessage={errors.insuranceAmount?.errorMessage}
          hasError={errors.insuranceAmount?.hasError}
          {...getOverrideProps(overrides, "insuranceAmount")}
        ></TextField>
      </Grid>
      <TextField
        label="Subtotal Liquid Assets"
        isRequired={false}
        isReadOnly={false}
        value={subtotalAssets}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              completedJointly,
              cashDeposit,
              cashOrMarketVal,
              nameAddressOfBank,
              accountNumber,
              amount,
              nameAddressOfBank2,
              accountNumber2,
              amount2,
              nameAddressOfBank3,
              accountNumber3,
              amount3,
              nameAddressOfBank4,
              accountNumber4,
              amount4,
              stockandBondsCompany,
              stockAmount,
              lifeInsuranceFaceAmount,
              insuranceAmount,
              subtotalAssets: value,
              realEstateAmount,
              retirementFund,
              netWorthOfBusiness,
              automobilesMakeAndYear,
              automobilesAmount,
              otherAssets,
              otherAssetsAmount,
              totalAssets,
            };
            const result = onChange(modelFields);
            value = result?.subtotalAssets ?? value;
          }
          if (errors.subtotalAssets?.hasError) {
            runValidationTasks("subtotalAssets", value);
          }
          setSubtotalAssets(value);
        }}
        onBlur={() => runValidationTasks("subtotalAssets", subtotalAssets)}
        errorMessage={errors.subtotalAssets?.errorMessage}
        hasError={errors.subtotalAssets?.hasError}
        {...getOverrideProps(overrides, "subtotalAssets")}
      ></TextField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Divider>
      <TextField
        label="Real estate owned "
        descriptiveText="(Enter market value from schedule of real estate owned)"
        isRequired={false}
        isReadOnly={false}
        placeholder="$"
        value={realEstateAmount}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              completedJointly,
              cashDeposit,
              cashOrMarketVal,
              nameAddressOfBank,
              accountNumber,
              amount,
              nameAddressOfBank2,
              accountNumber2,
              amount2,
              nameAddressOfBank3,
              accountNumber3,
              amount3,
              nameAddressOfBank4,
              accountNumber4,
              amount4,
              stockandBondsCompany,
              stockAmount,
              lifeInsuranceFaceAmount,
              insuranceAmount,
              subtotalAssets,
              realEstateAmount: value,
              retirementFund,
              netWorthOfBusiness,
              automobilesMakeAndYear,
              automobilesAmount,
              otherAssets,
              otherAssetsAmount,
              totalAssets,
            };
            const result = onChange(modelFields);
            value = result?.realEstateAmount ?? value;
          }
          if (errors.realEstateAmount?.hasError) {
            runValidationTasks("realEstateAmount", value);
          }
          setRealEstateAmount(value);
        }}
        onBlur={() => runValidationTasks("realEstateAmount", realEstateAmount)}
        errorMessage={errors.realEstateAmount?.errorMessage}
        hasError={errors.realEstateAmount?.hasError}
        {...getOverrideProps(overrides, "realEstateAmount")}
      ></TextField>
      <TextField
        label="Vested interest in retirement fund"
        isRequired={false}
        isReadOnly={false}
        placeholder="$"
        value={retirementFund}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              completedJointly,
              cashDeposit,
              cashOrMarketVal,
              nameAddressOfBank,
              accountNumber,
              amount,
              nameAddressOfBank2,
              accountNumber2,
              amount2,
              nameAddressOfBank3,
              accountNumber3,
              amount3,
              nameAddressOfBank4,
              accountNumber4,
              amount4,
              stockandBondsCompany,
              stockAmount,
              lifeInsuranceFaceAmount,
              insuranceAmount,
              subtotalAssets,
              realEstateAmount,
              retirementFund: value,
              netWorthOfBusiness,
              automobilesMakeAndYear,
              automobilesAmount,
              otherAssets,
              otherAssetsAmount,
              totalAssets,
            };
            const result = onChange(modelFields);
            value = result?.retirementFund ?? value;
          }
          if (errors.retirementFund?.hasError) {
            runValidationTasks("retirementFund", value);
          }
          setRetirementFund(value);
        }}
        onBlur={() => runValidationTasks("retirementFund", retirementFund)}
        errorMessage={errors.retirementFund?.errorMessage}
        hasError={errors.retirementFund?.hasError}
        {...getOverrideProps(overrides, "retirementFund")}
      ></TextField>
      <TextField
        label="Net worth of business(es) owned"
        descriptiveText="(attach financial statement)"
        isRequired={false}
        isReadOnly={false}
        placeholder="$"
        value={netWorthOfBusiness}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              completedJointly,
              cashDeposit,
              cashOrMarketVal,
              nameAddressOfBank,
              accountNumber,
              amount,
              nameAddressOfBank2,
              accountNumber2,
              amount2,
              nameAddressOfBank3,
              accountNumber3,
              amount3,
              nameAddressOfBank4,
              accountNumber4,
              amount4,
              stockandBondsCompany,
              stockAmount,
              lifeInsuranceFaceAmount,
              insuranceAmount,
              subtotalAssets,
              realEstateAmount,
              retirementFund,
              netWorthOfBusiness: value,
              automobilesMakeAndYear,
              automobilesAmount,
              otherAssets,
              otherAssetsAmount,
              totalAssets,
            };
            const result = onChange(modelFields);
            value = result?.netWorthOfBusiness ?? value;
          }
          if (errors.netWorthOfBusiness?.hasError) {
            runValidationTasks("netWorthOfBusiness", value);
          }
          setNetWorthOfBusiness(value);
        }}
        onBlur={() =>
          runValidationTasks("netWorthOfBusiness", netWorthOfBusiness)
        }
        errorMessage={errors.netWorthOfBusiness?.errorMessage}
        hasError={errors.netWorthOfBusiness?.hasError}
        {...getOverrideProps(overrides, "netWorthOfBusiness")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid17")}
      >
        <TextField
          label="Automobiles owned "
          descriptiveText="make and year"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={automobilesMakeAndYear}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear: value,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.automobilesMakeAndYear ?? value;
            }
            if (errors.automobilesMakeAndYear?.hasError) {
              runValidationTasks("automobilesMakeAndYear", value);
            }
            setAutomobilesMakeAndYear(value);
          }}
          onBlur={() =>
            runValidationTasks("automobilesMakeAndYear", automobilesMakeAndYear)
          }
          errorMessage={errors.automobilesMakeAndYear?.errorMessage}
          hasError={errors.automobilesMakeAndYear?.hasError}
          {...getOverrideProps(overrides, "automobilesMakeAndYear")}
        ></TextField>
        <TextField
          label="Automobiles amount"
          isRequired={false}
          isReadOnly={false}
          value={automobilesAmount}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount: value,
                otherAssets,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.automobilesAmount ?? value;
            }
            if (errors.automobilesAmount?.hasError) {
              runValidationTasks("automobilesAmount", value);
            }
            setAutomobilesAmount(value);
          }}
          onBlur={() =>
            runValidationTasks("automobilesAmount", automobilesAmount)
          }
          errorMessage={errors.automobilesAmount?.errorMessage}
          hasError={errors.automobilesAmount?.hasError}
          {...getOverrideProps(overrides, "automobilesAmount")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid18")}
      >
        <TextField
          label="Other assets"
          descriptiveText="(itemize)"
          isRequired={false}
          isReadOnly={false}
          value={otherAssets}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets: value,
                otherAssetsAmount,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.otherAssets ?? value;
            }
            if (errors.otherAssets?.hasError) {
              runValidationTasks("otherAssets", value);
            }
            setOtherAssets(value);
          }}
          onBlur={() => runValidationTasks("otherAssets", otherAssets)}
          errorMessage={errors.otherAssets?.errorMessage}
          hasError={errors.otherAssets?.hasError}
          {...getOverrideProps(overrides, "otherAssets")}
        ></TextField>
        <TextField
          label="Other assets amount"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={otherAssetsAmount}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                completedJointly,
                cashDeposit,
                cashOrMarketVal,
                nameAddressOfBank,
                accountNumber,
                amount,
                nameAddressOfBank2,
                accountNumber2,
                amount2,
                nameAddressOfBank3,
                accountNumber3,
                amount3,
                nameAddressOfBank4,
                accountNumber4,
                amount4,
                stockandBondsCompany,
                stockAmount,
                lifeInsuranceFaceAmount,
                insuranceAmount,
                subtotalAssets,
                realEstateAmount,
                retirementFund,
                netWorthOfBusiness,
                automobilesMakeAndYear,
                automobilesAmount,
                otherAssets,
                otherAssetsAmount: value,
                totalAssets,
              };
              const result = onChange(modelFields);
              value = result?.otherAssetsAmount ?? value;
            }
            if (errors.otherAssetsAmount?.hasError) {
              runValidationTasks("otherAssetsAmount", value);
            }
            setOtherAssetsAmount(value);
          }}
          onBlur={() =>
            runValidationTasks("otherAssetsAmount", otherAssetsAmount)
          }
          errorMessage={errors.otherAssetsAmount?.errorMessage}
          hasError={errors.otherAssetsAmount?.hasError}
          {...getOverrideProps(overrides, "otherAssetsAmount")}
        ></TextField>
      </Grid>
      <TextField
        label="Total assets (a)"
        isRequired={false}
        isReadOnly={false}
        placeholder="$"
        value={totalAssets}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              completedJointly,
              cashDeposit,
              cashOrMarketVal,
              nameAddressOfBank,
              accountNumber,
              amount,
              nameAddressOfBank2,
              accountNumber2,
              amount2,
              nameAddressOfBank3,
              accountNumber3,
              amount3,
              nameAddressOfBank4,
              accountNumber4,
              amount4,
              stockandBondsCompany,
              stockAmount,
              lifeInsuranceFaceAmount,
              insuranceAmount,
              subtotalAssets,
              realEstateAmount,
              retirementFund,
              netWorthOfBusiness,
              automobilesMakeAndYear,
              automobilesAmount,
              otherAssets,
              otherAssetsAmount,
              totalAssets: value,
            };
            const result = onChange(modelFields);
            value = result?.totalAssets ?? value;
          }
          if (errors.totalAssets?.hasError) {
            runValidationTasks("totalAssets", value);
          }
          setTotalAssets(value);
        }}
        onBlur={() => runValidationTasks("totalAssets", totalAssets)}
        errorMessage={errors.totalAssets?.errorMessage}
        hasError={errors.totalAssets?.hasError}
        {...getOverrideProps(overrides, "totalAssets")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Save"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
