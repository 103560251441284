/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createLoanApplication } from "../graphql/mutations";
const client = generateClient();
export default function LoanForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    borrowerName: "",
    coborrowerName: "",
    mortgageType: "",
    amount: "",
    interestRate: "",
    numberOfMonths: "",
    amortizationType: "",
  };
  const [borrowerName, setBorrowerName] = React.useState(
    initialValues.borrowerName
  );
  const [coborrowerName, setCoborrowerName] = React.useState(
    initialValues.coborrowerName
  );
  const [mortgageType, setMortgageType] = React.useState(
    initialValues.mortgageType
  );
  const [amount, setAmount] = React.useState(initialValues.amount);
  const [interestRate, setInterestRate] = React.useState(
    initialValues.interestRate
  );
  const [numberOfMonths, setNumberOfMonths] = React.useState(
    initialValues.numberOfMonths
  );
  const [amortizationType, setAmortizationType] = React.useState(
    initialValues.amortizationType
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setBorrowerName(initialValues.borrowerName);
    setCoborrowerName(initialValues.coborrowerName);
    setMortgageType(initialValues.mortgageType);
    setAmount(initialValues.amount);
    setInterestRate(initialValues.interestRate);
    setNumberOfMonths(initialValues.numberOfMonths);
    setAmortizationType(initialValues.amortizationType);
    setErrors({});
  };
  const validations = {
    borrowerName: [],
    coborrowerName: [],
    mortgageType: [],
    amount: [],
    interestRate: [],
    numberOfMonths: [],
    amortizationType: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          borrowerName,
          coborrowerName,
          mortgageType,
          amount,
          interestRate,
          numberOfMonths,
          amortizationType,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createLoanApplication.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "LoanForm")}
      {...rest}
    >
      <TextField
        label="Borrower name"
        isRequired={false}
        isReadOnly={false}
        value={borrowerName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName: value,
              coborrowerName,
              mortgageType,
              amount,
              interestRate,
              numberOfMonths,
              amortizationType,
            };
            const result = onChange(modelFields);
            value = result?.borrowerName ?? value;
          }
          if (errors.borrowerName?.hasError) {
            runValidationTasks("borrowerName", value);
          }
          setBorrowerName(value);
        }}
        onBlur={() => runValidationTasks("borrowerName", borrowerName)}
        errorMessage={errors.borrowerName?.errorMessage}
        hasError={errors.borrowerName?.hasError}
        {...getOverrideProps(overrides, "borrowerName")}
      ></TextField>
      <TextField
        label="Coborrower name"
        isRequired={false}
        isReadOnly={false}
        value={coborrowerName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName,
              coborrowerName: value,
              mortgageType,
              amount,
              interestRate,
              numberOfMonths,
              amortizationType,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerName ?? value;
          }
          if (errors.coborrowerName?.hasError) {
            runValidationTasks("coborrowerName", value);
          }
          setCoborrowerName(value);
        }}
        onBlur={() => runValidationTasks("coborrowerName", coborrowerName)}
        errorMessage={errors.coborrowerName?.errorMessage}
        hasError={errors.coborrowerName?.hasError}
        {...getOverrideProps(overrides, "coborrowerName")}
      ></TextField>
      <SelectField
        label="Mortgage type"
        placeholder="Please select an option"
        isDisabled={false}
        value={mortgageType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName,
              coborrowerName,
              mortgageType: value,
              amount,
              interestRate,
              numberOfMonths,
              amortizationType,
            };
            const result = onChange(modelFields);
            value = result?.mortgageType ?? value;
          }
          if (errors.mortgageType?.hasError) {
            runValidationTasks("mortgageType", value);
          }
          setMortgageType(value);
        }}
        onBlur={() => runValidationTasks("mortgageType", mortgageType)}
        errorMessage={errors.mortgageType?.errorMessage}
        hasError={errors.mortgageType?.hasError}
        {...getOverrideProps(overrides, "mortgageType")}
      >
        <option
          children="VA"
          value="VA"
          {...getOverrideProps(overrides, "mortgageTypeoption0")}
        ></option>
        <option
          children="FHA"
          value="FHA"
          {...getOverrideProps(overrides, "mortgageTypeoption1")}
        ></option>
        <option
          children="Conventional"
          value="Conventional"
          {...getOverrideProps(overrides, "mortgageTypeoption2")}
        ></option>
        <option
          children="Rural-USDA"
          value="Rural-USDA"
          {...getOverrideProps(overrides, "mortgageTypeoption3")}
        ></option>
        <option
          children="Others"
          value="Others"
          {...getOverrideProps(overrides, "mortgageTypeoption4")}
        ></option>
      </SelectField>
      <TextField
        label="Amount"
        isRequired={false}
        isReadOnly={false}
        value={amount}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName,
              coborrowerName,
              mortgageType,
              amount: value,
              interestRate,
              numberOfMonths,
              amortizationType,
            };
            const result = onChange(modelFields);
            value = result?.amount ?? value;
          }
          if (errors.amount?.hasError) {
            runValidationTasks("amount", value);
          }
          setAmount(value);
        }}
        onBlur={() => runValidationTasks("amount", amount)}
        errorMessage={errors.amount?.errorMessage}
        hasError={errors.amount?.hasError}
        {...getOverrideProps(overrides, "amount")}
      ></TextField>
      <SelectField
        label="Interest rate"
        placeholder="Please select an option"
        isDisabled={false}
        value={interestRate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName,
              coborrowerName,
              mortgageType,
              amount,
              interestRate: value,
              numberOfMonths,
              amortizationType,
            };
            const result = onChange(modelFields);
            value = result?.interestRate ?? value;
          }
          if (errors.interestRate?.hasError) {
            runValidationTasks("interestRate", value);
          }
          setInterestRate(value);
        }}
        onBlur={() => runValidationTasks("interestRate", interestRate)}
        errorMessage={errors.interestRate?.errorMessage}
        hasError={errors.interestRate?.hasError}
        {...getOverrideProps(overrides, "interestRate")}
      >
        <option
          children="6.0%"
          value="6.0%"
          {...getOverrideProps(overrides, "interestRateoption0")}
        ></option>
        <option
          children="6.25%"
          value="6.25%"
          {...getOverrideProps(overrides, "interestRateoption1")}
        ></option>
        <option
          children="6.5%"
          value="6.5%"
          {...getOverrideProps(overrides, "interestRateoption2")}
        ></option>
        <option
          children="6.75%"
          value="6.75%"
          {...getOverrideProps(overrides, "interestRateoption3")}
        ></option>
        <option
          children="7.0%"
          value="7.0%"
          {...getOverrideProps(overrides, "interestRateoption4")}
        ></option>
        <option
          children="7.25%"
          value="7.25%"
          {...getOverrideProps(overrides, "interestRateoption5")}
        ></option>
        <option
          children="7.5%"
          value="7.5%"
          {...getOverrideProps(overrides, "interestRateoption6")}
        ></option>
        <option
          children="7.75%"
          value="7.75%"
          {...getOverrideProps(overrides, "interestRateoption7")}
        ></option>
        <option
          children="8.0%"
          value="8.0%"
          {...getOverrideProps(overrides, "interestRateoption8")}
        ></option>
      </SelectField>
      <SelectField
        label="Number of years"
        placeholder="Please select an option"
        isDisabled={false}
        value={numberOfMonths}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName,
              coborrowerName,
              mortgageType,
              amount,
              interestRate,
              numberOfMonths: value,
              amortizationType,
            };
            const result = onChange(modelFields);
            value = result?.numberOfMonths ?? value;
          }
          if (errors.numberOfMonths?.hasError) {
            runValidationTasks("numberOfMonths", value);
          }
          setNumberOfMonths(value);
        }}
        onBlur={() => runValidationTasks("numberOfMonths", numberOfMonths)}
        errorMessage={errors.numberOfMonths?.errorMessage}
        hasError={errors.numberOfMonths?.hasError}
        {...getOverrideProps(overrides, "numberOfMonths")}
      >
        <option
          children="15"
          value="15"
          {...getOverrideProps(overrides, "numberOfMonthsoption0")}
        ></option>
        <option
          children="30"
          value="30"
          {...getOverrideProps(overrides, "numberOfMonthsoption1")}
        ></option>
      </SelectField>
      <SelectField
        label="Amortization type"
        placeholder="Please select an option"
        isDisabled={false}
        value={amortizationType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName,
              coborrowerName,
              mortgageType,
              amount,
              interestRate,
              numberOfMonths,
              amortizationType: value,
            };
            const result = onChange(modelFields);
            value = result?.amortizationType ?? value;
          }
          if (errors.amortizationType?.hasError) {
            runValidationTasks("amortizationType", value);
          }
          setAmortizationType(value);
        }}
        onBlur={() => runValidationTasks("amortizationType", amortizationType)}
        errorMessage={errors.amortizationType?.errorMessage}
        hasError={errors.amortizationType?.hasError}
        {...getOverrideProps(overrides, "amortizationType")}
      >
        <option
          children="Fixed Rate"
          value="Fixed Rate"
          {...getOverrideProps(overrides, "amortizationTypeoption0")}
        ></option>
        <option
          children="GPM"
          value="GPM"
          {...getOverrideProps(overrides, "amortizationTypeoption1")}
        ></option>
        <option
          children="ARM"
          value="ARM"
          {...getOverrideProps(overrides, "amortizationTypeoption2")}
        ></option>
        <option
          children="Other"
          value="Other"
          {...getOverrideProps(overrides, "amortizationTypeoption3")}
        ></option>
      </SelectField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Save"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
