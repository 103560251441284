import React from 'react';
import { Container, Typography } from '@mui/material';
import Footer from './Footer';
import BasicHeader from './Header';
import './Terms.css'; // Import the CSS module

const Terms = () => {
    return (
        <>
            <BasicHeader />
            <div className='containerWithMargin'>
                <Container>
                <Typography variant="h2" gutterBottom>
                    Terms of Use
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Acceptance of Terms
                </Typography>
                <Typography variant="body1" paragraph>
                    By accessing and using the Gobdu website (the “Site”), you accept and agree to be bound by the terms and provisions of this agreement. Additionally, when using Gobdu's services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use this service.
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Services Provided
                </Typography>
                <Typography variant="body1" paragraph>
                    Gobdu provides various services and resources related to business and technology solutions. The specific features and functionalities of the services may change from time to time.
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Intellectual Property Rights
                </Typography>
                <Typography variant="body1" paragraph>
                    The Site and its original content, features, and functionality are owned by Gobdu and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. You agree not to use, reproduce, or distribute any of the content of the Site without our express written permission.
                </Typography>
                <Typography variant="h4" gutterBottom>
                    User Responsibilities
                </Typography>
                <Typography variant="body1" paragraph>
                    As a user of the Site, you agree to:
                </Typography>
                <Typography variant="body1" component="div">
                    <ul>
                        <li>Provide accurate, current, and complete information about yourself as prompted by the registration forms.</li>
                        <li>Maintain the security of your password and identification.</li>
                        <li>Maintain and promptly update the registration data and any other information you provide to Gobdu, to keep it accurate, current, and complete.</li>
                        <li>Accept all risks of unauthorized access to the information and data you provide to Gobdu.</li>
                    </ul>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Prohibited Activities
                </Typography>
                <Typography variant="body1" paragraph>
                    You are prohibited from:
                </Typography>
                <Typography variant="body1" component="div">
                    <ul>
                        <li>Using the Site for any illegal purpose.</li>
                        <li>Attempting to gain unauthorized access to the Site, other user accounts, or computer systems or networks connected to the Site through hacking, password mining, or any other means.</li>
                        <li>Using the Site in any manner that could damage, disable, overburden, or impair Gobdu's servers or networks, or interfere with any other party’s use and enjoyment of the Site.</li>
                    </ul>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Termination
                </Typography>
                <Typography variant="body1" paragraph>
                    We may terminate or suspend access to our Site immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Disclaimer of Warranties
                </Typography>
                <Typography variant="body1" paragraph>
                    The Site is provided on an "as is" and "as available" basis. Gobdu makes no representations or warranties of any kind.
                </Typography>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default Terms;
