/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getCoborrower } from "../graphql/queries";
import { updateCoborrower } from "../graphql/mutations";
const client = generateClient();
export default function CoborrowerUpdateForm(props) {
  const {
    id: idProp,
    coborrower: coborrowerModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    firstname: "",
    lastname: "",
    address1: "",
    address2: "",
    city: "",
    resState: "",
    zip: "",
    maritalStatus: "",
    numberOfDependents: "",
    birthdate: "",
    ssn: "",
    formerAddress1: "",
    formerAddress2: "",
    formerCity: "",
    formerState: "",
    formerZipcode: "",
    presentAddressOwnership: false,
    formerAddressOwnership: false,
    mailingAddress1: "",
    mailingAddress2: "",
    mailingAddressCity: "",
    mailingAddressState: "",
    mailingAddressZip: "",
    agesOfDependents: "",
    yrsInSchool: "",
    phone: "",
    noOfYrsAtPresentResidence: "",
    noOfYrsAtFormerResidence: "",
  };
  const [firstname, setFirstname] = React.useState(initialValues.firstname);
  const [lastname, setLastname] = React.useState(initialValues.lastname);
  const [address1, setAddress1] = React.useState(initialValues.address1);
  const [address2, setAddress2] = React.useState(initialValues.address2);
  const [city, setCity] = React.useState(initialValues.city);
  const [resState, setResState] = React.useState(initialValues.resState);
  const [zip, setZip] = React.useState(initialValues.zip);
  const [maritalStatus, setMaritalStatus] = React.useState(
    initialValues.maritalStatus
  );
  const [numberOfDependents, setNumberOfDependents] = React.useState(
    initialValues.numberOfDependents
  );
  const [birthdate, setBirthdate] = React.useState(initialValues.birthdate);
  const [ssn, setSsn] = React.useState(initialValues.ssn);
  const [formerAddress1, setFormerAddress1] = React.useState(
    initialValues.formerAddress1
  );
  const [formerAddress2, setFormerAddress2] = React.useState(
    initialValues.formerAddress2
  );
  const [formerCity, setFormerCity] = React.useState(initialValues.formerCity);
  const [formerState, setFormerState] = React.useState(
    initialValues.formerState
  );
  const [formerZipcode, setFormerZipcode] = React.useState(
    initialValues.formerZipcode
  );
  const [presentAddressOwnership, setPresentAddressOwnership] = React.useState(
    initialValues.presentAddressOwnership
  );
  const [formerAddressOwnership, setFormerAddressOwnership] = React.useState(
    initialValues.formerAddressOwnership
  );
  const [mailingAddress1, setMailingAddress1] = React.useState(
    initialValues.mailingAddress1
  );
  const [mailingAddress2, setMailingAddress2] = React.useState(
    initialValues.mailingAddress2
  );
  const [mailingAddressCity, setMailingAddressCity] = React.useState(
    initialValues.mailingAddressCity
  );
  const [mailingAddressState, setMailingAddressState] = React.useState(
    initialValues.mailingAddressState
  );
  const [mailingAddressZip, setMailingAddressZip] = React.useState(
    initialValues.mailingAddressZip
  );
  const [agesOfDependents, setAgesOfDependents] = React.useState(
    initialValues.agesOfDependents
  );
  const [yrsInSchool, setYrsInSchool] = React.useState(
    initialValues.yrsInSchool
  );
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [noOfYrsAtPresentResidence, setNoOfYrsAtPresentResidence] =
    React.useState(initialValues.noOfYrsAtPresentResidence);
  const [noOfYrsAtFormerResidence, setNoOfYrsAtFormerResidence] =
    React.useState(initialValues.noOfYrsAtFormerResidence);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = coborrowerRecord
      ? { ...initialValues, ...coborrowerRecord }
      : initialValues;
    setFirstname(cleanValues.firstname);
    setLastname(cleanValues.lastname);
    setAddress1(cleanValues.address1);
    setAddress2(cleanValues.address2);
    setCity(cleanValues.city);
    setResState(cleanValues.resState);
    setZip(cleanValues.zip);
    setMaritalStatus(cleanValues.maritalStatus);
    setNumberOfDependents(cleanValues.numberOfDependents);
    setBirthdate(cleanValues.birthdate);
    setSsn(cleanValues.ssn);
    setFormerAddress1(cleanValues.formerAddress1);
    setFormerAddress2(cleanValues.formerAddress2);
    setFormerCity(cleanValues.formerCity);
    setFormerState(cleanValues.formerState);
    setFormerZipcode(cleanValues.formerZipcode);
    setPresentAddressOwnership(cleanValues.presentAddressOwnership);
    setFormerAddressOwnership(cleanValues.formerAddressOwnership);
    setMailingAddress1(cleanValues.mailingAddress1);
    setMailingAddress2(cleanValues.mailingAddress2);
    setMailingAddressCity(cleanValues.mailingAddressCity);
    setMailingAddressState(cleanValues.mailingAddressState);
    setMailingAddressZip(cleanValues.mailingAddressZip);
    setAgesOfDependents(cleanValues.agesOfDependents);
    setYrsInSchool(cleanValues.yrsInSchool);
    setPhone(cleanValues.phone);
    setNoOfYrsAtPresentResidence(cleanValues.noOfYrsAtPresentResidence);
    setNoOfYrsAtFormerResidence(cleanValues.noOfYrsAtFormerResidence);
    setErrors({});
  };
  const [coborrowerRecord, setCoborrowerRecord] =
    React.useState(coborrowerModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getCoborrower.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getCoborrower
        : coborrowerModelProp;
      setCoborrowerRecord(record);
    };
    queryData();
  }, [idProp, coborrowerModelProp]);
  React.useEffect(resetStateValues, [coborrowerRecord]);
  const validations = {
    firstname: [],
    lastname: [],
    address1: [],
    address2: [],
    city: [],
    resState: [],
    zip: [],
    maritalStatus: [],
    numberOfDependents: [],
    birthdate: [],
    ssn: [],
    formerAddress1: [],
    formerAddress2: [],
    formerCity: [],
    formerState: [],
    formerZipcode: [],
    presentAddressOwnership: [],
    formerAddressOwnership: [],
    mailingAddress1: [],
    mailingAddress2: [],
    mailingAddressCity: [],
    mailingAddressState: [],
    mailingAddressZip: [],
    agesOfDependents: [],
    yrsInSchool: [],
    phone: [],
    noOfYrsAtPresentResidence: [],
    noOfYrsAtFormerResidence: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          firstname: firstname ?? null,
          lastname: lastname ?? null,
          address1: address1 ?? null,
          address2: address2 ?? null,
          city: city ?? null,
          resState: resState ?? null,
          zip: zip ?? null,
          maritalStatus: maritalStatus ?? null,
          numberOfDependents: numberOfDependents ?? null,
          birthdate: birthdate ?? null,
          ssn: ssn ?? null,
          formerAddress1: formerAddress1 ?? null,
          formerAddress2: formerAddress2 ?? null,
          formerCity: formerCity ?? null,
          formerState: formerState ?? null,
          formerZipcode: formerZipcode ?? null,
          presentAddressOwnership: presentAddressOwnership ?? null,
          formerAddressOwnership: formerAddressOwnership ?? null,
          mailingAddress1: mailingAddress1 ?? null,
          mailingAddress2: mailingAddress2 ?? null,
          mailingAddressCity: mailingAddressCity ?? null,
          mailingAddressState: mailingAddressState ?? null,
          mailingAddressZip: mailingAddressZip ?? null,
          agesOfDependents: agesOfDependents ?? null,
          yrsInSchool: yrsInSchool ?? null,
          phone: phone ?? null,
          noOfYrsAtPresentResidence: noOfYrsAtPresentResidence ?? null,
          noOfYrsAtFormerResidence: noOfYrsAtFormerResidence ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateCoborrower.replaceAll("__typename", ""),
            variables: {
              input: {
                id: coborrowerRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "CoborrowerUpdateForm")}
      {...rest}
    >
      <TextField
        label="Firstname"
        isRequired={false}
        isReadOnly={false}
        value={firstname}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname: value,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.firstname ?? value;
          }
          if (errors.firstname?.hasError) {
            runValidationTasks("firstname", value);
          }
          setFirstname(value);
        }}
        onBlur={() => runValidationTasks("firstname", firstname)}
        errorMessage={errors.firstname?.errorMessage}
        hasError={errors.firstname?.hasError}
        {...getOverrideProps(overrides, "firstname")}
      ></TextField>
      <TextField
        label="Lastname"
        isRequired={false}
        isReadOnly={false}
        value={lastname}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname: value,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.lastname ?? value;
          }
          if (errors.lastname?.hasError) {
            runValidationTasks("lastname", value);
          }
          setLastname(value);
        }}
        onBlur={() => runValidationTasks("lastname", lastname)}
        errorMessage={errors.lastname?.errorMessage}
        hasError={errors.lastname?.hasError}
        {...getOverrideProps(overrides, "lastname")}
      ></TextField>
      <TextField
        label="Address1"
        isRequired={false}
        isReadOnly={false}
        value={address1}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1: value,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.address1 ?? value;
          }
          if (errors.address1?.hasError) {
            runValidationTasks("address1", value);
          }
          setAddress1(value);
        }}
        onBlur={() => runValidationTasks("address1", address1)}
        errorMessage={errors.address1?.errorMessage}
        hasError={errors.address1?.hasError}
        {...getOverrideProps(overrides, "address1")}
      ></TextField>
      <TextField
        label="Address2"
        isRequired={false}
        isReadOnly={false}
        value={address2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2: value,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.address2 ?? value;
          }
          if (errors.address2?.hasError) {
            runValidationTasks("address2", value);
          }
          setAddress2(value);
        }}
        onBlur={() => runValidationTasks("address2", address2)}
        errorMessage={errors.address2?.errorMessage}
        hasError={errors.address2?.hasError}
        {...getOverrideProps(overrides, "address2")}
      ></TextField>
      <TextField
        label="City"
        isRequired={false}
        isReadOnly={false}
        value={city}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city: value,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.city ?? value;
          }
          if (errors.city?.hasError) {
            runValidationTasks("city", value);
          }
          setCity(value);
        }}
        onBlur={() => runValidationTasks("city", city)}
        errorMessage={errors.city?.errorMessage}
        hasError={errors.city?.hasError}
        {...getOverrideProps(overrides, "city")}
      ></TextField>
      <TextField
        label="Res state"
        isRequired={false}
        isReadOnly={false}
        value={resState}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState: value,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.resState ?? value;
          }
          if (errors.resState?.hasError) {
            runValidationTasks("resState", value);
          }
          setResState(value);
        }}
        onBlur={() => runValidationTasks("resState", resState)}
        errorMessage={errors.resState?.errorMessage}
        hasError={errors.resState?.hasError}
        {...getOverrideProps(overrides, "resState")}
      ></TextField>
      <TextField
        label="Zip"
        isRequired={false}
        isReadOnly={false}
        value={zip}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip: value,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.zip ?? value;
          }
          if (errors.zip?.hasError) {
            runValidationTasks("zip", value);
          }
          setZip(value);
        }}
        onBlur={() => runValidationTasks("zip", zip)}
        errorMessage={errors.zip?.errorMessage}
        hasError={errors.zip?.hasError}
        {...getOverrideProps(overrides, "zip")}
      ></TextField>
      <TextField
        label="Marital status"
        isRequired={false}
        isReadOnly={false}
        value={maritalStatus}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus: value,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.maritalStatus ?? value;
          }
          if (errors.maritalStatus?.hasError) {
            runValidationTasks("maritalStatus", value);
          }
          setMaritalStatus(value);
        }}
        onBlur={() => runValidationTasks("maritalStatus", maritalStatus)}
        errorMessage={errors.maritalStatus?.errorMessage}
        hasError={errors.maritalStatus?.hasError}
        {...getOverrideProps(overrides, "maritalStatus")}
      ></TextField>
      <TextField
        label="Number of dependents"
        isRequired={false}
        isReadOnly={false}
        value={numberOfDependents}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents: value,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.numberOfDependents ?? value;
          }
          if (errors.numberOfDependents?.hasError) {
            runValidationTasks("numberOfDependents", value);
          }
          setNumberOfDependents(value);
        }}
        onBlur={() =>
          runValidationTasks("numberOfDependents", numberOfDependents)
        }
        errorMessage={errors.numberOfDependents?.errorMessage}
        hasError={errors.numberOfDependents?.hasError}
        {...getOverrideProps(overrides, "numberOfDependents")}
      ></TextField>
      <TextField
        label="Birthdate"
        isRequired={false}
        isReadOnly={false}
        value={birthdate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate: value,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.birthdate ?? value;
          }
          if (errors.birthdate?.hasError) {
            runValidationTasks("birthdate", value);
          }
          setBirthdate(value);
        }}
        onBlur={() => runValidationTasks("birthdate", birthdate)}
        errorMessage={errors.birthdate?.errorMessage}
        hasError={errors.birthdate?.hasError}
        {...getOverrideProps(overrides, "birthdate")}
      ></TextField>
      <TextField
        label="Ssn"
        isRequired={false}
        isReadOnly={false}
        value={ssn}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn: value,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.ssn ?? value;
          }
          if (errors.ssn?.hasError) {
            runValidationTasks("ssn", value);
          }
          setSsn(value);
        }}
        onBlur={() => runValidationTasks("ssn", ssn)}
        errorMessage={errors.ssn?.errorMessage}
        hasError={errors.ssn?.hasError}
        {...getOverrideProps(overrides, "ssn")}
      ></TextField>
      <TextField
        label="Former address1"
        isRequired={false}
        isReadOnly={false}
        value={formerAddress1}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1: value,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.formerAddress1 ?? value;
          }
          if (errors.formerAddress1?.hasError) {
            runValidationTasks("formerAddress1", value);
          }
          setFormerAddress1(value);
        }}
        onBlur={() => runValidationTasks("formerAddress1", formerAddress1)}
        errorMessage={errors.formerAddress1?.errorMessage}
        hasError={errors.formerAddress1?.hasError}
        {...getOverrideProps(overrides, "formerAddress1")}
      ></TextField>
      <TextField
        label="Former address2"
        isRequired={false}
        isReadOnly={false}
        value={formerAddress2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2: value,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.formerAddress2 ?? value;
          }
          if (errors.formerAddress2?.hasError) {
            runValidationTasks("formerAddress2", value);
          }
          setFormerAddress2(value);
        }}
        onBlur={() => runValidationTasks("formerAddress2", formerAddress2)}
        errorMessage={errors.formerAddress2?.errorMessage}
        hasError={errors.formerAddress2?.hasError}
        {...getOverrideProps(overrides, "formerAddress2")}
      ></TextField>
      <TextField
        label="Former city"
        isRequired={false}
        isReadOnly={false}
        value={formerCity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity: value,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.formerCity ?? value;
          }
          if (errors.formerCity?.hasError) {
            runValidationTasks("formerCity", value);
          }
          setFormerCity(value);
        }}
        onBlur={() => runValidationTasks("formerCity", formerCity)}
        errorMessage={errors.formerCity?.errorMessage}
        hasError={errors.formerCity?.hasError}
        {...getOverrideProps(overrides, "formerCity")}
      ></TextField>
      <TextField
        label="Former state"
        isRequired={false}
        isReadOnly={false}
        value={formerState}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState: value,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.formerState ?? value;
          }
          if (errors.formerState?.hasError) {
            runValidationTasks("formerState", value);
          }
          setFormerState(value);
        }}
        onBlur={() => runValidationTasks("formerState", formerState)}
        errorMessage={errors.formerState?.errorMessage}
        hasError={errors.formerState?.hasError}
        {...getOverrideProps(overrides, "formerState")}
      ></TextField>
      <TextField
        label="Former zipcode"
        isRequired={false}
        isReadOnly={false}
        value={formerZipcode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode: value,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.formerZipcode ?? value;
          }
          if (errors.formerZipcode?.hasError) {
            runValidationTasks("formerZipcode", value);
          }
          setFormerZipcode(value);
        }}
        onBlur={() => runValidationTasks("formerZipcode", formerZipcode)}
        errorMessage={errors.formerZipcode?.errorMessage}
        hasError={errors.formerZipcode?.hasError}
        {...getOverrideProps(overrides, "formerZipcode")}
      ></TextField>
      <SwitchField
        label="Present address ownership"
        defaultChecked={false}
        isDisabled={false}
        isChecked={presentAddressOwnership}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership: value,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.presentAddressOwnership ?? value;
          }
          if (errors.presentAddressOwnership?.hasError) {
            runValidationTasks("presentAddressOwnership", value);
          }
          setPresentAddressOwnership(value);
        }}
        onBlur={() =>
          runValidationTasks("presentAddressOwnership", presentAddressOwnership)
        }
        errorMessage={errors.presentAddressOwnership?.errorMessage}
        hasError={errors.presentAddressOwnership?.hasError}
        {...getOverrideProps(overrides, "presentAddressOwnership")}
      ></SwitchField>
      <SwitchField
        label="Former address ownership"
        defaultChecked={false}
        isDisabled={false}
        isChecked={formerAddressOwnership}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership: value,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.formerAddressOwnership ?? value;
          }
          if (errors.formerAddressOwnership?.hasError) {
            runValidationTasks("formerAddressOwnership", value);
          }
          setFormerAddressOwnership(value);
        }}
        onBlur={() =>
          runValidationTasks("formerAddressOwnership", formerAddressOwnership)
        }
        errorMessage={errors.formerAddressOwnership?.errorMessage}
        hasError={errors.formerAddressOwnership?.hasError}
        {...getOverrideProps(overrides, "formerAddressOwnership")}
      ></SwitchField>
      <TextField
        label="Mailing address1"
        isRequired={false}
        isReadOnly={false}
        value={mailingAddress1}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1: value,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.mailingAddress1 ?? value;
          }
          if (errors.mailingAddress1?.hasError) {
            runValidationTasks("mailingAddress1", value);
          }
          setMailingAddress1(value);
        }}
        onBlur={() => runValidationTasks("mailingAddress1", mailingAddress1)}
        errorMessage={errors.mailingAddress1?.errorMessage}
        hasError={errors.mailingAddress1?.hasError}
        {...getOverrideProps(overrides, "mailingAddress1")}
      ></TextField>
      <TextField
        label="Mailing address2"
        isRequired={false}
        isReadOnly={false}
        value={mailingAddress2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2: value,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.mailingAddress2 ?? value;
          }
          if (errors.mailingAddress2?.hasError) {
            runValidationTasks("mailingAddress2", value);
          }
          setMailingAddress2(value);
        }}
        onBlur={() => runValidationTasks("mailingAddress2", mailingAddress2)}
        errorMessage={errors.mailingAddress2?.errorMessage}
        hasError={errors.mailingAddress2?.hasError}
        {...getOverrideProps(overrides, "mailingAddress2")}
      ></TextField>
      <TextField
        label="Mailing address city"
        isRequired={false}
        isReadOnly={false}
        value={mailingAddressCity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity: value,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.mailingAddressCity ?? value;
          }
          if (errors.mailingAddressCity?.hasError) {
            runValidationTasks("mailingAddressCity", value);
          }
          setMailingAddressCity(value);
        }}
        onBlur={() =>
          runValidationTasks("mailingAddressCity", mailingAddressCity)
        }
        errorMessage={errors.mailingAddressCity?.errorMessage}
        hasError={errors.mailingAddressCity?.hasError}
        {...getOverrideProps(overrides, "mailingAddressCity")}
      ></TextField>
      <TextField
        label="Mailing address state"
        isRequired={false}
        isReadOnly={false}
        value={mailingAddressState}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState: value,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.mailingAddressState ?? value;
          }
          if (errors.mailingAddressState?.hasError) {
            runValidationTasks("mailingAddressState", value);
          }
          setMailingAddressState(value);
        }}
        onBlur={() =>
          runValidationTasks("mailingAddressState", mailingAddressState)
        }
        errorMessage={errors.mailingAddressState?.errorMessage}
        hasError={errors.mailingAddressState?.hasError}
        {...getOverrideProps(overrides, "mailingAddressState")}
      ></TextField>
      <TextField
        label="Mailing address zip"
        isRequired={false}
        isReadOnly={false}
        value={mailingAddressZip}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip: value,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.mailingAddressZip ?? value;
          }
          if (errors.mailingAddressZip?.hasError) {
            runValidationTasks("mailingAddressZip", value);
          }
          setMailingAddressZip(value);
        }}
        onBlur={() =>
          runValidationTasks("mailingAddressZip", mailingAddressZip)
        }
        errorMessage={errors.mailingAddressZip?.errorMessage}
        hasError={errors.mailingAddressZip?.hasError}
        {...getOverrideProps(overrides, "mailingAddressZip")}
      ></TextField>
      <TextField
        label="Ages of dependents"
        isRequired={false}
        isReadOnly={false}
        value={agesOfDependents}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents: value,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.agesOfDependents ?? value;
          }
          if (errors.agesOfDependents?.hasError) {
            runValidationTasks("agesOfDependents", value);
          }
          setAgesOfDependents(value);
        }}
        onBlur={() => runValidationTasks("agesOfDependents", agesOfDependents)}
        errorMessage={errors.agesOfDependents?.errorMessage}
        hasError={errors.agesOfDependents?.hasError}
        {...getOverrideProps(overrides, "agesOfDependents")}
      ></TextField>
      <TextField
        label="Yrs in school"
        isRequired={false}
        isReadOnly={false}
        value={yrsInSchool}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool: value,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.yrsInSchool ?? value;
          }
          if (errors.yrsInSchool?.hasError) {
            runValidationTasks("yrsInSchool", value);
          }
          setYrsInSchool(value);
        }}
        onBlur={() => runValidationTasks("yrsInSchool", yrsInSchool)}
        errorMessage={errors.yrsInSchool?.errorMessage}
        hasError={errors.yrsInSchool?.hasError}
        {...getOverrideProps(overrides, "yrsInSchool")}
      ></TextField>
      <TextField
        label="Phone"
        isRequired={false}
        isReadOnly={false}
        value={phone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone: value,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></TextField>
      <TextField
        label="No of yrs at present residence"
        isRequired={false}
        isReadOnly={false}
        value={noOfYrsAtPresentResidence}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence: value,
              noOfYrsAtFormerResidence,
            };
            const result = onChange(modelFields);
            value = result?.noOfYrsAtPresentResidence ?? value;
          }
          if (errors.noOfYrsAtPresentResidence?.hasError) {
            runValidationTasks("noOfYrsAtPresentResidence", value);
          }
          setNoOfYrsAtPresentResidence(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "noOfYrsAtPresentResidence",
            noOfYrsAtPresentResidence
          )
        }
        errorMessage={errors.noOfYrsAtPresentResidence?.errorMessage}
        hasError={errors.noOfYrsAtPresentResidence?.hasError}
        {...getOverrideProps(overrides, "noOfYrsAtPresentResidence")}
      ></TextField>
      <TextField
        label="No of yrs at former residence"
        isRequired={false}
        isReadOnly={false}
        value={noOfYrsAtFormerResidence}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              address1,
              address2,
              city,
              resState,
              zip,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress1,
              formerAddress2,
              formerCity,
              formerState,
              formerZipcode,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress1,
              mailingAddress2,
              mailingAddressCity,
              mailingAddressState,
              mailingAddressZip,
              agesOfDependents,
              yrsInSchool,
              phone,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence: value,
            };
            const result = onChange(modelFields);
            value = result?.noOfYrsAtFormerResidence ?? value;
          }
          if (errors.noOfYrsAtFormerResidence?.hasError) {
            runValidationTasks("noOfYrsAtFormerResidence", value);
          }
          setNoOfYrsAtFormerResidence(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "noOfYrsAtFormerResidence",
            noOfYrsAtFormerResidence
          )
        }
        errorMessage={errors.noOfYrsAtFormerResidence?.errorMessage}
        hasError={errors.noOfYrsAtFormerResidence?.hasError}
        {...getOverrideProps(overrides, "noOfYrsAtFormerResidence")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || coborrowerModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || coborrowerModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
