/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getRaceInfo } from "../graphql/queries";
import { updateRaceInfo } from "../graphql/mutations";
const client = generateClient();
export default function RaceInfoUpdateForm(props) {
  const {
    id: idProp,
    raceInfo: raceInfoModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    borrowerDoNotWish: false,
    borrowerHispanic: false,
    borrowerEthnicity: "",
    borrowerSex: "",
    coborrowerDoNotWish: false,
    coborrowerHispanic: false,
    coborrowerEthnicity: "",
    coborrowerSex: "",
  };
  const [borrowerDoNotWish, setBorrowerDoNotWish] = React.useState(
    initialValues.borrowerDoNotWish
  );
  const [borrowerHispanic, setBorrowerHispanic] = React.useState(
    initialValues.borrowerHispanic
  );
  const [borrowerEthnicity, setBorrowerEthnicity] = React.useState(
    initialValues.borrowerEthnicity
  );
  const [borrowerSex, setBorrowerSex] = React.useState(
    initialValues.borrowerSex
  );
  const [coborrowerDoNotWish, setCoborrowerDoNotWish] = React.useState(
    initialValues.coborrowerDoNotWish
  );
  const [coborrowerHispanic, setCoborrowerHispanic] = React.useState(
    initialValues.coborrowerHispanic
  );
  const [coborrowerEthnicity, setCoborrowerEthnicity] = React.useState(
    initialValues.coborrowerEthnicity
  );
  const [coborrowerSex, setCoborrowerSex] = React.useState(
    initialValues.coborrowerSex
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = raceInfoRecord
      ? { ...initialValues, ...raceInfoRecord }
      : initialValues;
    setBorrowerDoNotWish(cleanValues.borrowerDoNotWish);
    setBorrowerHispanic(cleanValues.borrowerHispanic);
    setBorrowerEthnicity(cleanValues.borrowerEthnicity);
    setBorrowerSex(cleanValues.borrowerSex);
    setCoborrowerDoNotWish(cleanValues.coborrowerDoNotWish);
    setCoborrowerHispanic(cleanValues.coborrowerHispanic);
    setCoborrowerEthnicity(cleanValues.coborrowerEthnicity);
    setCoborrowerSex(cleanValues.coborrowerSex);
    setErrors({});
  };
  const [raceInfoRecord, setRaceInfoRecord] = React.useState(raceInfoModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getRaceInfo.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getRaceInfo
        : raceInfoModelProp;
      setRaceInfoRecord(record);
    };
    queryData();
  }, [idProp, raceInfoModelProp]);
  React.useEffect(resetStateValues, [raceInfoRecord]);
  const validations = {
    borrowerDoNotWish: [],
    borrowerHispanic: [],
    borrowerEthnicity: [],
    borrowerSex: [],
    coborrowerDoNotWish: [],
    coborrowerHispanic: [],
    coborrowerEthnicity: [],
    coborrowerSex: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          borrowerDoNotWish: borrowerDoNotWish ?? null,
          borrowerHispanic: borrowerHispanic ?? null,
          borrowerEthnicity: borrowerEthnicity ?? null,
          borrowerSex: borrowerSex ?? null,
          coborrowerDoNotWish: coborrowerDoNotWish ?? null,
          coborrowerHispanic: coborrowerHispanic ?? null,
          coborrowerEthnicity: coborrowerEthnicity ?? null,
          coborrowerSex: coborrowerSex ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateRaceInfo.replaceAll("__typename", ""),
            variables: {
              input: {
                id: raceInfoRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "RaceInfoUpdateForm")}
      {...rest}
    >
      <SwitchField
        label="Borrower do not wish"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerDoNotWish}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish: value,
              borrowerHispanic,
              borrowerEthnicity,
              borrowerSex,
              coborrowerDoNotWish,
              coborrowerHispanic,
              coborrowerEthnicity,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.borrowerDoNotWish ?? value;
          }
          if (errors.borrowerDoNotWish?.hasError) {
            runValidationTasks("borrowerDoNotWish", value);
          }
          setBorrowerDoNotWish(value);
        }}
        onBlur={() =>
          runValidationTasks("borrowerDoNotWish", borrowerDoNotWish)
        }
        errorMessage={errors.borrowerDoNotWish?.errorMessage}
        hasError={errors.borrowerDoNotWish?.hasError}
        {...getOverrideProps(overrides, "borrowerDoNotWish")}
      ></SwitchField>
      <SwitchField
        label="Borrower hispanic"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerHispanic}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic: value,
              borrowerEthnicity,
              borrowerSex,
              coborrowerDoNotWish,
              coborrowerHispanic,
              coborrowerEthnicity,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.borrowerHispanic ?? value;
          }
          if (errors.borrowerHispanic?.hasError) {
            runValidationTasks("borrowerHispanic", value);
          }
          setBorrowerHispanic(value);
        }}
        onBlur={() => runValidationTasks("borrowerHispanic", borrowerHispanic)}
        errorMessage={errors.borrowerHispanic?.errorMessage}
        hasError={errors.borrowerHispanic?.hasError}
        {...getOverrideProps(overrides, "borrowerHispanic")}
      ></SwitchField>
      <TextField
        label="Borrower ethnicity"
        isRequired={false}
        isReadOnly={false}
        value={borrowerEthnicity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic,
              borrowerEthnicity: value,
              borrowerSex,
              coborrowerDoNotWish,
              coborrowerHispanic,
              coborrowerEthnicity,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.borrowerEthnicity ?? value;
          }
          if (errors.borrowerEthnicity?.hasError) {
            runValidationTasks("borrowerEthnicity", value);
          }
          setBorrowerEthnicity(value);
        }}
        onBlur={() =>
          runValidationTasks("borrowerEthnicity", borrowerEthnicity)
        }
        errorMessage={errors.borrowerEthnicity?.errorMessage}
        hasError={errors.borrowerEthnicity?.hasError}
        {...getOverrideProps(overrides, "borrowerEthnicity")}
      ></TextField>
      <TextField
        label="Borrower sex"
        isRequired={false}
        isReadOnly={false}
        value={borrowerSex}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic,
              borrowerEthnicity,
              borrowerSex: value,
              coborrowerDoNotWish,
              coborrowerHispanic,
              coborrowerEthnicity,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.borrowerSex ?? value;
          }
          if (errors.borrowerSex?.hasError) {
            runValidationTasks("borrowerSex", value);
          }
          setBorrowerSex(value);
        }}
        onBlur={() => runValidationTasks("borrowerSex", borrowerSex)}
        errorMessage={errors.borrowerSex?.errorMessage}
        hasError={errors.borrowerSex?.hasError}
        {...getOverrideProps(overrides, "borrowerSex")}
      ></TextField>
      <SwitchField
        label="Coborrower do not wish"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerDoNotWish}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic,
              borrowerEthnicity,
              borrowerSex,
              coborrowerDoNotWish: value,
              coborrowerHispanic,
              coborrowerEthnicity,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerDoNotWish ?? value;
          }
          if (errors.coborrowerDoNotWish?.hasError) {
            runValidationTasks("coborrowerDoNotWish", value);
          }
          setCoborrowerDoNotWish(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerDoNotWish", coborrowerDoNotWish)
        }
        errorMessage={errors.coborrowerDoNotWish?.errorMessage}
        hasError={errors.coborrowerDoNotWish?.hasError}
        {...getOverrideProps(overrides, "coborrowerDoNotWish")}
      ></SwitchField>
      <SwitchField
        label="Coborrower hispanic"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerHispanic}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic,
              borrowerEthnicity,
              borrowerSex,
              coborrowerDoNotWish,
              coborrowerHispanic: value,
              coborrowerEthnicity,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerHispanic ?? value;
          }
          if (errors.coborrowerHispanic?.hasError) {
            runValidationTasks("coborrowerHispanic", value);
          }
          setCoborrowerHispanic(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerHispanic", coborrowerHispanic)
        }
        errorMessage={errors.coborrowerHispanic?.errorMessage}
        hasError={errors.coborrowerHispanic?.hasError}
        {...getOverrideProps(overrides, "coborrowerHispanic")}
      ></SwitchField>
      <TextField
        label="Coborrower ethnicity"
        isRequired={false}
        isReadOnly={false}
        value={coborrowerEthnicity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic,
              borrowerEthnicity,
              borrowerSex,
              coborrowerDoNotWish,
              coborrowerHispanic,
              coborrowerEthnicity: value,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerEthnicity ?? value;
          }
          if (errors.coborrowerEthnicity?.hasError) {
            runValidationTasks("coborrowerEthnicity", value);
          }
          setCoborrowerEthnicity(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerEthnicity", coborrowerEthnicity)
        }
        errorMessage={errors.coborrowerEthnicity?.errorMessage}
        hasError={errors.coborrowerEthnicity?.hasError}
        {...getOverrideProps(overrides, "coborrowerEthnicity")}
      ></TextField>
      <TextField
        label="Coborrower sex"
        isRequired={false}
        isReadOnly={false}
        value={coborrowerSex}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic,
              borrowerEthnicity,
              borrowerSex,
              coborrowerDoNotWish,
              coborrowerHispanic,
              coborrowerEthnicity,
              coborrowerSex: value,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerSex ?? value;
          }
          if (errors.coborrowerSex?.hasError) {
            runValidationTasks("coborrowerSex", value);
          }
          setCoborrowerSex(value);
        }}
        onBlur={() => runValidationTasks("coborrowerSex", coborrowerSex)}
        errorMessage={errors.coborrowerSex?.errorMessage}
        hasError={errors.coborrowerSex?.hasError}
        {...getOverrideProps(overrides, "coborrowerSex")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || raceInfoModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || raceInfoModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
