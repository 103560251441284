import React from 'react';
import { Container, Typography } from '@mui/material';
import BasicHeader from './Header'; // Assuming you have a header component
import Footer from './Footer';
import './Privacy.css'; // Import the CSS module

const Privacy = () => {
    return (
        <>
            <BasicHeader />
            <div className='privacy-container'>
                <Container className='content'>
                    <Typography variant="h2" className='privacy-title' gutterBottom>
                        Privacy Policy
                    </Typography>
                    <Typography variant="body1" paragraph className='privacy-section'>
                        Gobdu ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
                    </Typography>
                    <Typography variant="h4" className='privacy-title' gutterBottom>
                        Information We Collect
                    </Typography>
                    <Typography variant="body1" paragraph className='privacy-section'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec venenatis leo, ac auctor tortor. Vivamus fermentum nisi in massa tempus, id venenatis nisl scelerisque. Proin vehicula neque at lectus bibendum, nec facilisis magna lacinia. Curabitur vulputate elit at libero consectetur, ut dignissim nisl commodo. Etiam at lectus ut odio finibus dignissim. Cras egestas felis in urna tincidunt, ac ullamcorper libero gravida. Nam facilisis orci sed metus scelerisque, non faucibus erat fermentum. Duis vitae convallis arcu, vel laoreet magna.
                    </Typography>
                    <Typography variant="h4" className='privacy-title' gutterBottom>
                        Use of Your Information
                    </Typography>
                    <Typography variant="body1" paragraph className='privacy-section'>
                        Donec quis velit eget eros suscipit mollis. Sed sodales diam id ornare gravida. Aenean a dui vel libero efficitur egestas. Ut vehicula felis sed turpis consequat, at consectetur lorem vehicula. Praesent efficitur lorem id ex viverra, in bibendum nisi fermentum. Suspendisse venenatis, magna nec feugiat ultrices, turpis lorem luctus lorem, eget ullamcorper felis risus id ligula. In sit amet risus sit amet augue ultrices vehicula a nec libero. Aenean volutpat, magna a bibendum elementum, sapien nisi suscipit sapien, ut malesuada libero leo sit amet elit.
                    </Typography>
                    <Typography variant="h4" className='privacy-title' gutterBottom>
                        How We Protect Your Information
                    </Typography>
                    <Typography variant="body1" paragraph className='privacy-section'>
                        Nunc pulvinar vehicula purus, sit amet congue risus tempus in. Suspendisse malesuada erat eu mauris varius, a cursus velit consectetur. Proin hendrerit lectus nec sapien finibus tincidunt. Nulla facilisi. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla at fringilla eros, eget pulvinar nunc. Nam imperdiet, lacus a auctor ultricies, justo quam dapibus nisi, nec porttitor sem lorem in felis. Nulla aliquet consectetur malesuada. Aenean scelerisque nulla ac dolor elementum, id auctor eros vulputate. Nullam feugiat bibendum sapien.
                    </Typography>
                    <Typography variant="h4" className='privacy-title' gutterBottom>
                        Sharing Your Personal Information
                    </Typography>
                    <Typography variant="body1" paragraph className='privacy-section'>
                        Maecenas ullamcorper, libero et vulputate varius, tortor eros pellentesque ex, at bibendum dui nulla quis velit. Phasellus mollis sem id risus convallis, eget tristique ligula faucibus. Integer convallis, velit et elementum aliquam, odio arcu iaculis dui, eget pretium enim elit at magna. Sed sollicitudin ullamcorper velit at sodales. Integer aliquet venenatis odio nec scelerisque. Quisque volutpat tincidunt nulla, sit amet blandit tortor viverra in. Vivamus posuere, eros ut elementum malesuada, dolor nisl cursus libero, eget ullamcorper erat velit sit amet quam. Morbi sit amet mauris in felis feugiat tincidunt.
                    </Typography>
                    <Typography variant="h4" className='privacy-title' gutterBottom>
                        Changes to This Privacy Policy
                    </Typography>
                    <Typography variant="body1" paragraph className='privacy-section'>
                        Vestibulum sit amet posuere erat, ac sodales mi. Duis ultricies libero vitae mi cursus, a tincidunt risus pharetra. Proin nec ultricies justo. Vestibulum viverra ante et nulla euismod, sed cursus ligula suscipit. Integer dictum ex vel urna aliquet, nec porttitor mi posuere. Integer ut scelerisque libero, vitae pulvinar purus. Suspendisse potenti. Integer ut magna id nulla blandit sagittis. Quisque egestas urna sed ex scelerisque, vel sodales mi accumsan. Curabitur auctor, velit nec suscipit venenatis, mi purus tempor lectus, id mollis mauris magna at dolor.
                    </Typography>
                </Container>
                <Footer />
            </div>
        </>
    );
};

export default Privacy;
