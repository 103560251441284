/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createIncomeAndExpenseInfo } from "../graphql/mutations";
const client = generateClient();
export default function IncomeAndExpenseInfoCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    borrower_baseIncome: "",
    coborrower_baseIncome: "",
    total_baseIncome: "",
    borrower_overtime: "",
    coborrower_overtime: "",
    total_overtime: "",
    borrower_bonus: "",
    coborrower_bonus: "",
    total_bonus: "",
    borrower_commissions: "",
    coborrower_commissions: "",
    total_commissions: "",
    borrower_dividends: "",
    coborrower_dividends: "",
    total_dividends: "",
    borrower_netRentalIncome: "",
    coborrower_netRentalIncome: "",
    total_rentalIncome: "",
    borrower_other: "",
    coborrower_other: "",
    total_otherIncome: "",
    borrower_totalIncome: "",
    coborrower_totalIncome: "",
    family_total: "",
    grand_total_income: "",
    present_rent: "",
    present_mortgage1: "",
    proposed_mortgage1: "",
    present_financing: "",
    proposed_financing: "",
    present_hazard_insurance: "",
    proposed_hazard_insurance: "",
    present_real_estate_taxes: "",
    proposed_real_estate_taxes: "",
    present_mortgage_insurance: "",
    proposed_mortgage_insurance: "",
    present_hoa_dues: "",
    proposed_hoa_dues: "",
    present_other_expenses: "",
    proposed_other_expenses: "",
    total_combined_expenses: "",
    proposed_total_combined_expenses: "",
  };
  const [borrower_baseIncome, setBorrower_baseIncome] = React.useState(
    initialValues.borrower_baseIncome
  );
  const [coborrower_baseIncome, setCoborrower_baseIncome] = React.useState(
    initialValues.coborrower_baseIncome
  );
  const [total_baseIncome, setTotal_baseIncome] = React.useState(
    initialValues.total_baseIncome
  );
  const [borrower_overtime, setBorrower_overtime] = React.useState(
    initialValues.borrower_overtime
  );
  const [coborrower_overtime, setCoborrower_overtime] = React.useState(
    initialValues.coborrower_overtime
  );
  const [total_overtime, setTotal_overtime] = React.useState(
    initialValues.total_overtime
  );
  const [borrower_bonus, setBorrower_bonus] = React.useState(
    initialValues.borrower_bonus
  );
  const [coborrower_bonus, setCoborrower_bonus] = React.useState(
    initialValues.coborrower_bonus
  );
  const [total_bonus, setTotal_bonus] = React.useState(
    initialValues.total_bonus
  );
  const [borrower_commissions, setBorrower_commissions] = React.useState(
    initialValues.borrower_commissions
  );
  const [coborrower_commissions, setCoborrower_commissions] = React.useState(
    initialValues.coborrower_commissions
  );
  const [total_commissions, setTotal_commissions] = React.useState(
    initialValues.total_commissions
  );
  const [borrower_dividends, setBorrower_dividends] = React.useState(
    initialValues.borrower_dividends
  );
  const [coborrower_dividends, setCoborrower_dividends] = React.useState(
    initialValues.coborrower_dividends
  );
  const [total_dividends, setTotal_dividends] = React.useState(
    initialValues.total_dividends
  );
  const [borrower_netRentalIncome, setBorrower_netRentalIncome] =
    React.useState(initialValues.borrower_netRentalIncome);
  const [coborrower_netRentalIncome, setCoborrower_netRentalIncome] =
    React.useState(initialValues.coborrower_netRentalIncome);
  const [total_rentalIncome, setTotal_rentalIncome] = React.useState(
    initialValues.total_rentalIncome
  );
  const [borrower_other, setBorrower_other] = React.useState(
    initialValues.borrower_other
  );
  const [coborrower_other, setCoborrower_other] = React.useState(
    initialValues.coborrower_other
  );
  const [total_otherIncome, setTotal_otherIncome] = React.useState(
    initialValues.total_otherIncome
  );
  const [borrower_totalIncome, setBorrower_totalIncome] = React.useState(
    initialValues.borrower_totalIncome
  );
  const [coborrower_totalIncome, setCoborrower_totalIncome] = React.useState(
    initialValues.coborrower_totalIncome
  );
  const [family_total, setFamily_total] = React.useState(
    initialValues.family_total
  );
  const [grand_total_income, setGrand_total_income] = React.useState(
    initialValues.grand_total_income
  );
  const [present_rent, setPresent_rent] = React.useState(
    initialValues.present_rent
  );
  const [present_mortgage1, setPresent_mortgage1] = React.useState(
    initialValues.present_mortgage1
  );
  const [proposed_mortgage1, setProposed_mortgage1] = React.useState(
    initialValues.proposed_mortgage1
  );
  const [present_financing, setPresent_financing] = React.useState(
    initialValues.present_financing
  );
  const [proposed_financing, setProposed_financing] = React.useState(
    initialValues.proposed_financing
  );
  const [present_hazard_insurance, setPresent_hazard_insurance] =
    React.useState(initialValues.present_hazard_insurance);
  const [proposed_hazard_insurance, setProposed_hazard_insurance] =
    React.useState(initialValues.proposed_hazard_insurance);
  const [present_real_estate_taxes, setPresent_real_estate_taxes] =
    React.useState(initialValues.present_real_estate_taxes);
  const [proposed_real_estate_taxes, setProposed_real_estate_taxes] =
    React.useState(initialValues.proposed_real_estate_taxes);
  const [present_mortgage_insurance, setPresent_mortgage_insurance] =
    React.useState(initialValues.present_mortgage_insurance);
  const [proposed_mortgage_insurance, setProposed_mortgage_insurance] =
    React.useState(initialValues.proposed_mortgage_insurance);
  const [present_hoa_dues, setPresent_hoa_dues] = React.useState(
    initialValues.present_hoa_dues
  );
  const [proposed_hoa_dues, setProposed_hoa_dues] = React.useState(
    initialValues.proposed_hoa_dues
  );
  const [present_other_expenses, setPresent_other_expenses] = React.useState(
    initialValues.present_other_expenses
  );
  const [proposed_other_expenses, setProposed_other_expenses] = React.useState(
    initialValues.proposed_other_expenses
  );
  const [total_combined_expenses, setTotal_combined_expenses] = React.useState(
    initialValues.total_combined_expenses
  );
  const [
    proposed_total_combined_expenses,
    setProposed_total_combined_expenses,
  ] = React.useState(initialValues.proposed_total_combined_expenses);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setBorrower_baseIncome(initialValues.borrower_baseIncome);
    setCoborrower_baseIncome(initialValues.coborrower_baseIncome);
    setTotal_baseIncome(initialValues.total_baseIncome);
    setBorrower_overtime(initialValues.borrower_overtime);
    setCoborrower_overtime(initialValues.coborrower_overtime);
    setTotal_overtime(initialValues.total_overtime);
    setBorrower_bonus(initialValues.borrower_bonus);
    setCoborrower_bonus(initialValues.coborrower_bonus);
    setTotal_bonus(initialValues.total_bonus);
    setBorrower_commissions(initialValues.borrower_commissions);
    setCoborrower_commissions(initialValues.coborrower_commissions);
    setTotal_commissions(initialValues.total_commissions);
    setBorrower_dividends(initialValues.borrower_dividends);
    setCoborrower_dividends(initialValues.coborrower_dividends);
    setTotal_dividends(initialValues.total_dividends);
    setBorrower_netRentalIncome(initialValues.borrower_netRentalIncome);
    setCoborrower_netRentalIncome(initialValues.coborrower_netRentalIncome);
    setTotal_rentalIncome(initialValues.total_rentalIncome);
    setBorrower_other(initialValues.borrower_other);
    setCoborrower_other(initialValues.coborrower_other);
    setTotal_otherIncome(initialValues.total_otherIncome);
    setBorrower_totalIncome(initialValues.borrower_totalIncome);
    setCoborrower_totalIncome(initialValues.coborrower_totalIncome);
    setFamily_total(initialValues.family_total);
    setGrand_total_income(initialValues.grand_total_income);
    setPresent_rent(initialValues.present_rent);
    setPresent_mortgage1(initialValues.present_mortgage1);
    setProposed_mortgage1(initialValues.proposed_mortgage1);
    setPresent_financing(initialValues.present_financing);
    setProposed_financing(initialValues.proposed_financing);
    setPresent_hazard_insurance(initialValues.present_hazard_insurance);
    setProposed_hazard_insurance(initialValues.proposed_hazard_insurance);
    setPresent_real_estate_taxes(initialValues.present_real_estate_taxes);
    setProposed_real_estate_taxes(initialValues.proposed_real_estate_taxes);
    setPresent_mortgage_insurance(initialValues.present_mortgage_insurance);
    setProposed_mortgage_insurance(initialValues.proposed_mortgage_insurance);
    setPresent_hoa_dues(initialValues.present_hoa_dues);
    setProposed_hoa_dues(initialValues.proposed_hoa_dues);
    setPresent_other_expenses(initialValues.present_other_expenses);
    setProposed_other_expenses(initialValues.proposed_other_expenses);
    setTotal_combined_expenses(initialValues.total_combined_expenses);
    setProposed_total_combined_expenses(
      initialValues.proposed_total_combined_expenses
    );
    setErrors({});
  };
  const validations = {
    borrower_baseIncome: [],
    coborrower_baseIncome: [],
    total_baseIncome: [],
    borrower_overtime: [],
    coborrower_overtime: [],
    total_overtime: [],
    borrower_bonus: [],
    coborrower_bonus: [],
    total_bonus: [],
    borrower_commissions: [],
    coborrower_commissions: [],
    total_commissions: [],
    borrower_dividends: [],
    coborrower_dividends: [],
    total_dividends: [],
    borrower_netRentalIncome: [],
    coborrower_netRentalIncome: [],
    total_rentalIncome: [],
    borrower_other: [],
    coborrower_other: [],
    total_otherIncome: [],
    borrower_totalIncome: [],
    coborrower_totalIncome: [],
    family_total: [],
    grand_total_income: [],
    present_rent: [],
    present_mortgage1: [],
    proposed_mortgage1: [],
    present_financing: [],
    proposed_financing: [],
    present_hazard_insurance: [],
    proposed_hazard_insurance: [],
    present_real_estate_taxes: [],
    proposed_real_estate_taxes: [],
    present_mortgage_insurance: [],
    proposed_mortgage_insurance: [],
    present_hoa_dues: [],
    proposed_hoa_dues: [],
    present_other_expenses: [],
    proposed_other_expenses: [],
    total_combined_expenses: [],
    proposed_total_combined_expenses: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          borrower_baseIncome,
          coborrower_baseIncome,
          total_baseIncome,
          borrower_overtime,
          coborrower_overtime,
          total_overtime,
          borrower_bonus,
          coborrower_bonus,
          total_bonus,
          borrower_commissions,
          coborrower_commissions,
          total_commissions,
          borrower_dividends,
          coborrower_dividends,
          total_dividends,
          borrower_netRentalIncome,
          coborrower_netRentalIncome,
          total_rentalIncome,
          borrower_other,
          coborrower_other,
          total_otherIncome,
          borrower_totalIncome,
          coborrower_totalIncome,
          family_total,
          grand_total_income,
          present_rent,
          present_mortgage1,
          proposed_mortgage1,
          present_financing,
          proposed_financing,
          present_hazard_insurance,
          proposed_hazard_insurance,
          present_real_estate_taxes,
          proposed_real_estate_taxes,
          present_mortgage_insurance,
          proposed_mortgage_insurance,
          present_hoa_dues,
          proposed_hoa_dues,
          present_other_expenses,
          proposed_other_expenses,
          total_combined_expenses,
          proposed_total_combined_expenses,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createIncomeAndExpenseInfo.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "IncomeAndExpenseInfoCreateForm")}
      {...rest}
    >
      <Heading
        children="Gross Monthly Income"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(3, auto)"
        {...getOverrideProps(overrides, "RowGrid1")}
      >
        <TextField
          label="Borrower base income"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={borrower_baseIncome}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome: value,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.borrower_baseIncome ?? value;
            }
            if (errors.borrower_baseIncome?.hasError) {
              runValidationTasks("borrower_baseIncome", value);
            }
            setBorrower_baseIncome(value);
          }}
          onBlur={() =>
            runValidationTasks("borrower_baseIncome", borrower_baseIncome)
          }
          errorMessage={errors.borrower_baseIncome?.errorMessage}
          hasError={errors.borrower_baseIncome?.hasError}
          {...getOverrideProps(overrides, "borrower_baseIncome")}
        ></TextField>
        <TextField
          label="Coborrower base income"
          descriptiveText=""
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={coborrower_baseIncome}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome: value,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.coborrower_baseIncome ?? value;
            }
            if (errors.coborrower_baseIncome?.hasError) {
              runValidationTasks("coborrower_baseIncome", value);
            }
            setCoborrower_baseIncome(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrower_baseIncome", coborrower_baseIncome)
          }
          errorMessage={errors.coborrower_baseIncome?.errorMessage}
          hasError={errors.coborrower_baseIncome?.hasError}
          {...getOverrideProps(overrides, "coborrower_baseIncome")}
        ></TextField>
        <TextField
          label="Total base income"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={total_baseIncome}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome: value,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.total_baseIncome ?? value;
            }
            if (errors.total_baseIncome?.hasError) {
              runValidationTasks("total_baseIncome", value);
            }
            setTotal_baseIncome(value);
          }}
          onBlur={() =>
            runValidationTasks("total_baseIncome", total_baseIncome)
          }
          errorMessage={errors.total_baseIncome?.errorMessage}
          hasError={errors.total_baseIncome?.hasError}
          {...getOverrideProps(overrides, "total_baseIncome")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(3, auto)"
        {...getOverrideProps(overrides, "RowGrid2")}
      >
        <TextField
          label="Borrower overtime"
          isRequired={false}
          isReadOnly={false}
          value={borrower_overtime}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime: value,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.borrower_overtime ?? value;
            }
            if (errors.borrower_overtime?.hasError) {
              runValidationTasks("borrower_overtime", value);
            }
            setBorrower_overtime(value);
          }}
          onBlur={() =>
            runValidationTasks("borrower_overtime", borrower_overtime)
          }
          errorMessage={errors.borrower_overtime?.errorMessage}
          hasError={errors.borrower_overtime?.hasError}
          {...getOverrideProps(overrides, "borrower_overtime")}
        ></TextField>
        <TextField
          label="Coborrower overtime"
          isRequired={false}
          isReadOnly={false}
          value={coborrower_overtime}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime: value,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.coborrower_overtime ?? value;
            }
            if (errors.coborrower_overtime?.hasError) {
              runValidationTasks("coborrower_overtime", value);
            }
            setCoborrower_overtime(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrower_overtime", coborrower_overtime)
          }
          errorMessage={errors.coborrower_overtime?.errorMessage}
          hasError={errors.coborrower_overtime?.hasError}
          {...getOverrideProps(overrides, "coborrower_overtime")}
        ></TextField>
        <TextField
          label="Total overtime"
          isRequired={false}
          isReadOnly={false}
          value={total_overtime}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime: value,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.total_overtime ?? value;
            }
            if (errors.total_overtime?.hasError) {
              runValidationTasks("total_overtime", value);
            }
            setTotal_overtime(value);
          }}
          onBlur={() => runValidationTasks("total_overtime", total_overtime)}
          errorMessage={errors.total_overtime?.errorMessage}
          hasError={errors.total_overtime?.hasError}
          {...getOverrideProps(overrides, "total_overtime")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(3, auto)"
        {...getOverrideProps(overrides, "RowGrid3")}
      >
        <TextField
          label="Borrower bonus"
          isRequired={false}
          isReadOnly={false}
          value={borrower_bonus}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus: value,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.borrower_bonus ?? value;
            }
            if (errors.borrower_bonus?.hasError) {
              runValidationTasks("borrower_bonus", value);
            }
            setBorrower_bonus(value);
          }}
          onBlur={() => runValidationTasks("borrower_bonus", borrower_bonus)}
          errorMessage={errors.borrower_bonus?.errorMessage}
          hasError={errors.borrower_bonus?.hasError}
          {...getOverrideProps(overrides, "borrower_bonus")}
        ></TextField>
        <TextField
          label="Coborrower bonus"
          isRequired={false}
          isReadOnly={false}
          value={coborrower_bonus}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus: value,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.coborrower_bonus ?? value;
            }
            if (errors.coborrower_bonus?.hasError) {
              runValidationTasks("coborrower_bonus", value);
            }
            setCoborrower_bonus(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrower_bonus", coborrower_bonus)
          }
          errorMessage={errors.coborrower_bonus?.errorMessage}
          hasError={errors.coborrower_bonus?.hasError}
          {...getOverrideProps(overrides, "coborrower_bonus")}
        ></TextField>
        <TextField
          label="Total bonus"
          isRequired={false}
          isReadOnly={false}
          value={total_bonus}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus: value,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.total_bonus ?? value;
            }
            if (errors.total_bonus?.hasError) {
              runValidationTasks("total_bonus", value);
            }
            setTotal_bonus(value);
          }}
          onBlur={() => runValidationTasks("total_bonus", total_bonus)}
          errorMessage={errors.total_bonus?.errorMessage}
          hasError={errors.total_bonus?.hasError}
          {...getOverrideProps(overrides, "total_bonus")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(3, auto)"
        {...getOverrideProps(overrides, "RowGrid4")}
      >
        <TextField
          label="Borrower commissions"
          isRequired={false}
          isReadOnly={false}
          value={borrower_commissions}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions: value,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.borrower_commissions ?? value;
            }
            if (errors.borrower_commissions?.hasError) {
              runValidationTasks("borrower_commissions", value);
            }
            setBorrower_commissions(value);
          }}
          onBlur={() =>
            runValidationTasks("borrower_commissions", borrower_commissions)
          }
          errorMessage={errors.borrower_commissions?.errorMessage}
          hasError={errors.borrower_commissions?.hasError}
          {...getOverrideProps(overrides, "borrower_commissions")}
        ></TextField>
        <TextField
          label="Coborrower commissions"
          isRequired={false}
          isReadOnly={false}
          value={coborrower_commissions}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions: value,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.coborrower_commissions ?? value;
            }
            if (errors.coborrower_commissions?.hasError) {
              runValidationTasks("coborrower_commissions", value);
            }
            setCoborrower_commissions(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrower_commissions", coborrower_commissions)
          }
          errorMessage={errors.coborrower_commissions?.errorMessage}
          hasError={errors.coborrower_commissions?.hasError}
          {...getOverrideProps(overrides, "coborrower_commissions")}
        ></TextField>
        <TextField
          label="Total commissions"
          isRequired={false}
          isReadOnly={false}
          value={total_commissions}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions: value,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.total_commissions ?? value;
            }
            if (errors.total_commissions?.hasError) {
              runValidationTasks("total_commissions", value);
            }
            setTotal_commissions(value);
          }}
          onBlur={() =>
            runValidationTasks("total_commissions", total_commissions)
          }
          errorMessage={errors.total_commissions?.errorMessage}
          hasError={errors.total_commissions?.hasError}
          {...getOverrideProps(overrides, "total_commissions")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(3, auto)"
        {...getOverrideProps(overrides, "RowGrid5")}
      >
        <TextField
          label="Borrower dividends"
          isRequired={false}
          isReadOnly={false}
          value={borrower_dividends}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends: value,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.borrower_dividends ?? value;
            }
            if (errors.borrower_dividends?.hasError) {
              runValidationTasks("borrower_dividends", value);
            }
            setBorrower_dividends(value);
          }}
          onBlur={() =>
            runValidationTasks("borrower_dividends", borrower_dividends)
          }
          errorMessage={errors.borrower_dividends?.errorMessage}
          hasError={errors.borrower_dividends?.hasError}
          {...getOverrideProps(overrides, "borrower_dividends")}
        ></TextField>
        <TextField
          label="Coborrower dividends"
          isRequired={false}
          isReadOnly={false}
          value={coborrower_dividends}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends: value,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.coborrower_dividends ?? value;
            }
            if (errors.coborrower_dividends?.hasError) {
              runValidationTasks("coborrower_dividends", value);
            }
            setCoborrower_dividends(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrower_dividends", coborrower_dividends)
          }
          errorMessage={errors.coborrower_dividends?.errorMessage}
          hasError={errors.coborrower_dividends?.hasError}
          {...getOverrideProps(overrides, "coborrower_dividends")}
        ></TextField>
        <TextField
          label="Total dividends"
          isRequired={false}
          isReadOnly={false}
          value={total_dividends}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends: value,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.total_dividends ?? value;
            }
            if (errors.total_dividends?.hasError) {
              runValidationTasks("total_dividends", value);
            }
            setTotal_dividends(value);
          }}
          onBlur={() => runValidationTasks("total_dividends", total_dividends)}
          errorMessage={errors.total_dividends?.errorMessage}
          hasError={errors.total_dividends?.hasError}
          {...getOverrideProps(overrides, "total_dividends")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(3, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Borrower net rental income"
          isRequired={false}
          isReadOnly={false}
          value={borrower_netRentalIncome}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome: value,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.borrower_netRentalIncome ?? value;
            }
            if (errors.borrower_netRentalIncome?.hasError) {
              runValidationTasks("borrower_netRentalIncome", value);
            }
            setBorrower_netRentalIncome(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "borrower_netRentalIncome",
              borrower_netRentalIncome
            )
          }
          errorMessage={errors.borrower_netRentalIncome?.errorMessage}
          hasError={errors.borrower_netRentalIncome?.hasError}
          {...getOverrideProps(overrides, "borrower_netRentalIncome")}
        ></TextField>
        <TextField
          label="Coborrower net rental income"
          isRequired={false}
          isReadOnly={false}
          value={coborrower_netRentalIncome}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome: value,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.coborrower_netRentalIncome ?? value;
            }
            if (errors.coborrower_netRentalIncome?.hasError) {
              runValidationTasks("coborrower_netRentalIncome", value);
            }
            setCoborrower_netRentalIncome(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "coborrower_netRentalIncome",
              coborrower_netRentalIncome
            )
          }
          errorMessage={errors.coborrower_netRentalIncome?.errorMessage}
          hasError={errors.coborrower_netRentalIncome?.hasError}
          {...getOverrideProps(overrides, "coborrower_netRentalIncome")}
        ></TextField>
        <TextField
          label="Total rental income"
          isRequired={false}
          isReadOnly={false}
          value={total_rentalIncome}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome: value,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.total_rentalIncome ?? value;
            }
            if (errors.total_rentalIncome?.hasError) {
              runValidationTasks("total_rentalIncome", value);
            }
            setTotal_rentalIncome(value);
          }}
          onBlur={() =>
            runValidationTasks("total_rentalIncome", total_rentalIncome)
          }
          errorMessage={errors.total_rentalIncome?.errorMessage}
          hasError={errors.total_rentalIncome?.hasError}
          {...getOverrideProps(overrides, "total_rentalIncome")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(3, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <TextField
          label="Borrower other"
          isRequired={false}
          isReadOnly={false}
          value={borrower_other}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other: value,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.borrower_other ?? value;
            }
            if (errors.borrower_other?.hasError) {
              runValidationTasks("borrower_other", value);
            }
            setBorrower_other(value);
          }}
          onBlur={() => runValidationTasks("borrower_other", borrower_other)}
          errorMessage={errors.borrower_other?.errorMessage}
          hasError={errors.borrower_other?.hasError}
          {...getOverrideProps(overrides, "borrower_other")}
        ></TextField>
        <TextField
          label="Coborrower other"
          isRequired={false}
          isReadOnly={false}
          value={coborrower_other}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other: value,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.coborrower_other ?? value;
            }
            if (errors.coborrower_other?.hasError) {
              runValidationTasks("coborrower_other", value);
            }
            setCoborrower_other(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrower_other", coborrower_other)
          }
          errorMessage={errors.coborrower_other?.errorMessage}
          hasError={errors.coborrower_other?.hasError}
          {...getOverrideProps(overrides, "coborrower_other")}
        ></TextField>
        <TextField
          label="Total other income"
          isRequired={false}
          isReadOnly={false}
          value={total_otherIncome}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome: value,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.total_otherIncome ?? value;
            }
            if (errors.total_otherIncome?.hasError) {
              runValidationTasks("total_otherIncome", value);
            }
            setTotal_otherIncome(value);
          }}
          onBlur={() =>
            runValidationTasks("total_otherIncome", total_otherIncome)
          }
          errorMessage={errors.total_otherIncome?.errorMessage}
          hasError={errors.total_otherIncome?.hasError}
          {...getOverrideProps(overrides, "total_otherIncome")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <TextField
          label="Borrower total income"
          isRequired={false}
          isReadOnly={false}
          value={borrower_totalIncome}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome: value,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.borrower_totalIncome ?? value;
            }
            if (errors.borrower_totalIncome?.hasError) {
              runValidationTasks("borrower_totalIncome", value);
            }
            setBorrower_totalIncome(value);
          }}
          onBlur={() =>
            runValidationTasks("borrower_totalIncome", borrower_totalIncome)
          }
          errorMessage={errors.borrower_totalIncome?.errorMessage}
          hasError={errors.borrower_totalIncome?.hasError}
          {...getOverrideProps(overrides, "borrower_totalIncome")}
        ></TextField>
        <TextField
          label="Coborrower total income"
          isRequired={false}
          isReadOnly={false}
          value={coborrower_totalIncome}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome: value,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.coborrower_totalIncome ?? value;
            }
            if (errors.coborrower_totalIncome?.hasError) {
              runValidationTasks("coborrower_totalIncome", value);
            }
            setCoborrower_totalIncome(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrower_totalIncome", coborrower_totalIncome)
          }
          errorMessage={errors.coborrower_totalIncome?.errorMessage}
          hasError={errors.coborrower_totalIncome?.hasError}
          {...getOverrideProps(overrides, "coborrower_totalIncome")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <TextField
          label="Family total income"
          isRequired={false}
          isReadOnly={false}
          value={family_total}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total: value,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.family_total ?? value;
            }
            if (errors.family_total?.hasError) {
              runValidationTasks("family_total", value);
            }
            setFamily_total(value);
          }}
          onBlur={() => runValidationTasks("family_total", family_total)}
          errorMessage={errors.family_total?.errorMessage}
          hasError={errors.family_total?.hasError}
          {...getOverrideProps(overrides, "family_total")}
        ></TextField>
        <TextField
          label="Grand total income"
          isRequired={false}
          isReadOnly={false}
          value={grand_total_income}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income: value,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.grand_total_income ?? value;
            }
            if (errors.grand_total_income?.hasError) {
              runValidationTasks("grand_total_income", value);
            }
            setGrand_total_income(value);
          }}
          onBlur={() =>
            runValidationTasks("grand_total_income", grand_total_income)
          }
          errorMessage={errors.grand_total_income?.errorMessage}
          hasError={errors.grand_total_income?.hasError}
          {...getOverrideProps(overrides, "grand_total_income")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Divider>
      <Heading
        children="Combined Monthly Housing Expense"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Heading>
      <TextField
        label="Present rent"
        isRequired={false}
        isReadOnly={false}
        value={present_rent}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              coborrower_baseIncome,
              total_baseIncome,
              borrower_overtime,
              coborrower_overtime,
              total_overtime,
              borrower_bonus,
              coborrower_bonus,
              total_bonus,
              borrower_commissions,
              coborrower_commissions,
              total_commissions,
              borrower_dividends,
              coborrower_dividends,
              total_dividends,
              borrower_netRentalIncome,
              coborrower_netRentalIncome,
              total_rentalIncome,
              borrower_other,
              coborrower_other,
              total_otherIncome,
              borrower_totalIncome,
              coborrower_totalIncome,
              family_total,
              grand_total_income,
              present_rent: value,
              present_mortgage1,
              proposed_mortgage1,
              present_financing,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.present_rent ?? value;
          }
          if (errors.present_rent?.hasError) {
            runValidationTasks("present_rent", value);
          }
          setPresent_rent(value);
        }}
        onBlur={() => runValidationTasks("present_rent", present_rent)}
        errorMessage={errors.present_rent?.errorMessage}
        hasError={errors.present_rent?.hasError}
        {...getOverrideProps(overrides, "present_rent")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid13")}
      >
        <TextField
          label="First Mortgage (P&I)"
          isRequired={false}
          isReadOnly={false}
          value={present_mortgage1}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1: value,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.present_mortgage1 ?? value;
            }
            if (errors.present_mortgage1?.hasError) {
              runValidationTasks("present_mortgage1", value);
            }
            setPresent_mortgage1(value);
          }}
          onBlur={() =>
            runValidationTasks("present_mortgage1", present_mortgage1)
          }
          errorMessage={errors.present_mortgage1?.errorMessage}
          hasError={errors.present_mortgage1?.hasError}
          {...getOverrideProps(overrides, "present_mortgage1")}
        ></TextField>
        <TextField
          label="Proposed First Mortgage"
          isRequired={false}
          isReadOnly={false}
          value={proposed_mortgage1}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1: value,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.proposed_mortgage1 ?? value;
            }
            if (errors.proposed_mortgage1?.hasError) {
              runValidationTasks("proposed_mortgage1", value);
            }
            setProposed_mortgage1(value);
          }}
          onBlur={() =>
            runValidationTasks("proposed_mortgage1", proposed_mortgage1)
          }
          errorMessage={errors.proposed_mortgage1?.errorMessage}
          hasError={errors.proposed_mortgage1?.hasError}
          {...getOverrideProps(overrides, "proposed_mortgage1")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid14")}
      >
        <TextField
          label="Other Financing (P&I)"
          isRequired={false}
          isReadOnly={false}
          value={present_financing}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing: value,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.present_financing ?? value;
            }
            if (errors.present_financing?.hasError) {
              runValidationTasks("present_financing", value);
            }
            setPresent_financing(value);
          }}
          onBlur={() =>
            runValidationTasks("present_financing", present_financing)
          }
          errorMessage={errors.present_financing?.errorMessage}
          hasError={errors.present_financing?.hasError}
          {...getOverrideProps(overrides, "present_financing")}
        ></TextField>
        <TextField
          label="Proposed Other Financing (P&I)"
          isRequired={false}
          isReadOnly={false}
          value={proposed_financing}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing: value,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.proposed_financing ?? value;
            }
            if (errors.proposed_financing?.hasError) {
              runValidationTasks("proposed_financing", value);
            }
            setProposed_financing(value);
          }}
          onBlur={() =>
            runValidationTasks("proposed_financing", proposed_financing)
          }
          errorMessage={errors.proposed_financing?.errorMessage}
          hasError={errors.proposed_financing?.hasError}
          {...getOverrideProps(overrides, "proposed_financing")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid15")}
      >
        <TextField
          label="Present Hazard Insurance"
          isRequired={false}
          isReadOnly={false}
          value={present_hazard_insurance}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance: value,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.present_hazard_insurance ?? value;
            }
            if (errors.present_hazard_insurance?.hasError) {
              runValidationTasks("present_hazard_insurance", value);
            }
            setPresent_hazard_insurance(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "present_hazard_insurance",
              present_hazard_insurance
            )
          }
          errorMessage={errors.present_hazard_insurance?.errorMessage}
          hasError={errors.present_hazard_insurance?.hasError}
          {...getOverrideProps(overrides, "present_hazard_insurance")}
        ></TextField>
        <TextField
          label="Proposed Hazard Insurance"
          isRequired={false}
          isReadOnly={false}
          value={proposed_hazard_insurance}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance: value,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.proposed_hazard_insurance ?? value;
            }
            if (errors.proposed_hazard_insurance?.hasError) {
              runValidationTasks("proposed_hazard_insurance", value);
            }
            setProposed_hazard_insurance(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "proposed_hazard_insurance",
              proposed_hazard_insurance
            )
          }
          errorMessage={errors.proposed_hazard_insurance?.errorMessage}
          hasError={errors.proposed_hazard_insurance?.hasError}
          {...getOverrideProps(overrides, "proposed_hazard_insurance")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid16")}
      >
        <TextField
          label="Present real estate taxes"
          isRequired={false}
          isReadOnly={false}
          value={present_real_estate_taxes}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes: value,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.present_real_estate_taxes ?? value;
            }
            if (errors.present_real_estate_taxes?.hasError) {
              runValidationTasks("present_real_estate_taxes", value);
            }
            setPresent_real_estate_taxes(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "present_real_estate_taxes",
              present_real_estate_taxes
            )
          }
          errorMessage={errors.present_real_estate_taxes?.errorMessage}
          hasError={errors.present_real_estate_taxes?.hasError}
          {...getOverrideProps(overrides, "present_real_estate_taxes")}
        ></TextField>
        <TextField
          label="Proposed real estate taxes"
          isRequired={false}
          isReadOnly={false}
          value={proposed_real_estate_taxes}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes: value,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.proposed_real_estate_taxes ?? value;
            }
            if (errors.proposed_real_estate_taxes?.hasError) {
              runValidationTasks("proposed_real_estate_taxes", value);
            }
            setProposed_real_estate_taxes(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "proposed_real_estate_taxes",
              proposed_real_estate_taxes
            )
          }
          errorMessage={errors.proposed_real_estate_taxes?.errorMessage}
          hasError={errors.proposed_real_estate_taxes?.hasError}
          {...getOverrideProps(overrides, "proposed_real_estate_taxes")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid17")}
      >
        <TextField
          label="Present mortgage insurance"
          isRequired={false}
          isReadOnly={false}
          value={present_mortgage_insurance}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance: value,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.present_mortgage_insurance ?? value;
            }
            if (errors.present_mortgage_insurance?.hasError) {
              runValidationTasks("present_mortgage_insurance", value);
            }
            setPresent_mortgage_insurance(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "present_mortgage_insurance",
              present_mortgage_insurance
            )
          }
          errorMessage={errors.present_mortgage_insurance?.errorMessage}
          hasError={errors.present_mortgage_insurance?.hasError}
          {...getOverrideProps(overrides, "present_mortgage_insurance")}
        ></TextField>
        <TextField
          label="Proposed mortgage insurance"
          isRequired={false}
          isReadOnly={false}
          value={proposed_mortgage_insurance}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance: value,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.proposed_mortgage_insurance ?? value;
            }
            if (errors.proposed_mortgage_insurance?.hasError) {
              runValidationTasks("proposed_mortgage_insurance", value);
            }
            setProposed_mortgage_insurance(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "proposed_mortgage_insurance",
              proposed_mortgage_insurance
            )
          }
          errorMessage={errors.proposed_mortgage_insurance?.errorMessage}
          hasError={errors.proposed_mortgage_insurance?.hasError}
          {...getOverrideProps(overrides, "proposed_mortgage_insurance")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid18")}
      >
        <TextField
          label="Present HOA dues"
          descriptiveText="Home Owners Association dues (if any)"
          isRequired={false}
          isReadOnly={false}
          value={present_hoa_dues}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues: value,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.present_hoa_dues ?? value;
            }
            if (errors.present_hoa_dues?.hasError) {
              runValidationTasks("present_hoa_dues", value);
            }
            setPresent_hoa_dues(value);
          }}
          onBlur={() =>
            runValidationTasks("present_hoa_dues", present_hoa_dues)
          }
          errorMessage={errors.present_hoa_dues?.errorMessage}
          hasError={errors.present_hoa_dues?.hasError}
          {...getOverrideProps(overrides, "present_hoa_dues")}
        ></TextField>
        <TextField
          label="Proposed hoa dues"
          descriptiveText="Home Owners Association dues (if any)"
          isRequired={false}
          isReadOnly={false}
          value={proposed_hoa_dues}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues: value,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.proposed_hoa_dues ?? value;
            }
            if (errors.proposed_hoa_dues?.hasError) {
              runValidationTasks("proposed_hoa_dues", value);
            }
            setProposed_hoa_dues(value);
          }}
          onBlur={() =>
            runValidationTasks("proposed_hoa_dues", proposed_hoa_dues)
          }
          errorMessage={errors.proposed_hoa_dues?.errorMessage}
          hasError={errors.proposed_hoa_dues?.hasError}
          {...getOverrideProps(overrides, "proposed_hoa_dues")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid19")}
      >
        <TextField
          label="Present other expenses"
          isRequired={false}
          isReadOnly={false}
          value={present_other_expenses}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses: value,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.present_other_expenses ?? value;
            }
            if (errors.present_other_expenses?.hasError) {
              runValidationTasks("present_other_expenses", value);
            }
            setPresent_other_expenses(value);
          }}
          onBlur={() =>
            runValidationTasks("present_other_expenses", present_other_expenses)
          }
          errorMessage={errors.present_other_expenses?.errorMessage}
          hasError={errors.present_other_expenses?.hasError}
          {...getOverrideProps(overrides, "present_other_expenses")}
        ></TextField>
        <TextField
          label="Proposed other expenses"
          isRequired={false}
          isReadOnly={false}
          value={proposed_other_expenses}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses: value,
                total_combined_expenses,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.proposed_other_expenses ?? value;
            }
            if (errors.proposed_other_expenses?.hasError) {
              runValidationTasks("proposed_other_expenses", value);
            }
            setProposed_other_expenses(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "proposed_other_expenses",
              proposed_other_expenses
            )
          }
          errorMessage={errors.proposed_other_expenses?.errorMessage}
          hasError={errors.proposed_other_expenses?.hasError}
          {...getOverrideProps(overrides, "proposed_other_expenses")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid20")}
      >
        <TextField
          label="Total combined expenses"
          isRequired={false}
          isReadOnly={false}
          value={total_combined_expenses}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses: value,
                proposed_total_combined_expenses,
              };
              const result = onChange(modelFields);
              value = result?.total_combined_expenses ?? value;
            }
            if (errors.total_combined_expenses?.hasError) {
              runValidationTasks("total_combined_expenses", value);
            }
            setTotal_combined_expenses(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "total_combined_expenses",
              total_combined_expenses
            )
          }
          errorMessage={errors.total_combined_expenses?.errorMessage}
          hasError={errors.total_combined_expenses?.hasError}
          {...getOverrideProps(overrides, "total_combined_expenses")}
        ></TextField>
        <TextField
          label="Proposed total combined expenses"
          isRequired={false}
          isReadOnly={false}
          value={proposed_total_combined_expenses}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrower_baseIncome,
                coborrower_baseIncome,
                total_baseIncome,
                borrower_overtime,
                coborrower_overtime,
                total_overtime,
                borrower_bonus,
                coborrower_bonus,
                total_bonus,
                borrower_commissions,
                coborrower_commissions,
                total_commissions,
                borrower_dividends,
                coborrower_dividends,
                total_dividends,
                borrower_netRentalIncome,
                coborrower_netRentalIncome,
                total_rentalIncome,
                borrower_other,
                coborrower_other,
                total_otherIncome,
                borrower_totalIncome,
                coborrower_totalIncome,
                family_total,
                grand_total_income,
                present_rent,
                present_mortgage1,
                proposed_mortgage1,
                present_financing,
                proposed_financing,
                present_hazard_insurance,
                proposed_hazard_insurance,
                present_real_estate_taxes,
                proposed_real_estate_taxes,
                present_mortgage_insurance,
                proposed_mortgage_insurance,
                present_hoa_dues,
                proposed_hoa_dues,
                present_other_expenses,
                proposed_other_expenses,
                total_combined_expenses,
                proposed_total_combined_expenses: value,
              };
              const result = onChange(modelFields);
              value = result?.proposed_total_combined_expenses ?? value;
            }
            if (errors.proposed_total_combined_expenses?.hasError) {
              runValidationTasks("proposed_total_combined_expenses", value);
            }
            setProposed_total_combined_expenses(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "proposed_total_combined_expenses",
              proposed_total_combined_expenses
            )
          }
          errorMessage={errors.proposed_total_combined_expenses?.errorMessage}
          hasError={errors.proposed_total_combined_expenses?.hasError}
          {...getOverrideProps(overrides, "proposed_total_combined_expenses")}
        ></TextField>
      </Grid>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Save"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
