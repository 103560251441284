/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getDeclarations } from "../graphql/queries";
import { updateDeclarations } from "../graphql/mutations";
const client = generateClient();
export default function DeclarationsUpdateForm(props) {
  const {
    id: idProp,
    declarations: declarationsModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    borrowerDelinquent: false,
    coborrowerDelinquent: false,
    borrowerObligatedMaintenance: false,
    coborrowerObligatedMaintenance: false,
    borrowerDownpaymentBorrowed: false,
    coborrowerDownpaymentBorrowed: false,
    borrowerNote: false,
    coborrowerNote: false,
    borrowerCitizen: false,
    coborrowerCitizen: false,
    borrowerResidentAlien: false,
    coborrowerResidentAlien: false,
    borrowerPrimaryResidence: false,
    coborrowerPrimaryResidence: false,
    borrowerOwnership: false,
    coborrowerOwnership: false,
    borrowerPropertyOwned: "",
    coborrowerPropertyOwned: "",
    borrowerTitleHeld: "",
    coborrowerTitleHeld: "",
    borrowerOutstandingJudgments: false,
    coborrowerOutstandingJudgments: false,
    borrowerBankrupt: false,
    coborrowerBankrupt: false,
    borrowerForeclosed: false,
    coborrowerForeclosed: false,
    borrowerLawsuit: false,
    coborrowerLawsuit: false,
    borrowerObligatedLoan: false,
    coborrowerObligatedLoan: false,
  };
  const [borrowerDelinquent, setBorrowerDelinquent] = React.useState(
    initialValues.borrowerDelinquent
  );
  const [coborrowerDelinquent, setCoborrowerDelinquent] = React.useState(
    initialValues.coborrowerDelinquent
  );
  const [borrowerObligatedMaintenance, setBorrowerObligatedMaintenance] =
    React.useState(initialValues.borrowerObligatedMaintenance);
  const [coborrowerObligatedMaintenance, setCoborrowerObligatedMaintenance] =
    React.useState(initialValues.coborrowerObligatedMaintenance);
  const [borrowerDownpaymentBorrowed, setBorrowerDownpaymentBorrowed] =
    React.useState(initialValues.borrowerDownpaymentBorrowed);
  const [coborrowerDownpaymentBorrowed, setCoborrowerDownpaymentBorrowed] =
    React.useState(initialValues.coborrowerDownpaymentBorrowed);
  const [borrowerNote, setBorrowerNote] = React.useState(
    initialValues.borrowerNote
  );
  const [coborrowerNote, setCoborrowerNote] = React.useState(
    initialValues.coborrowerNote
  );
  const [borrowerCitizen, setBorrowerCitizen] = React.useState(
    initialValues.borrowerCitizen
  );
  const [coborrowerCitizen, setCoborrowerCitizen] = React.useState(
    initialValues.coborrowerCitizen
  );
  const [borrowerResidentAlien, setBorrowerResidentAlien] = React.useState(
    initialValues.borrowerResidentAlien
  );
  const [coborrowerResidentAlien, setCoborrowerResidentAlien] = React.useState(
    initialValues.coborrowerResidentAlien
  );
  const [borrowerPrimaryResidence, setBorrowerPrimaryResidence] =
    React.useState(initialValues.borrowerPrimaryResidence);
  const [coborrowerPrimaryResidence, setCoborrowerPrimaryResidence] =
    React.useState(initialValues.coborrowerPrimaryResidence);
  const [borrowerOwnership, setBorrowerOwnership] = React.useState(
    initialValues.borrowerOwnership
  );
  const [coborrowerOwnership, setCoborrowerOwnership] = React.useState(
    initialValues.coborrowerOwnership
  );
  const [borrowerPropertyOwned, setBorrowerPropertyOwned] = React.useState(
    initialValues.borrowerPropertyOwned
  );
  const [coborrowerPropertyOwned, setCoborrowerPropertyOwned] = React.useState(
    initialValues.coborrowerPropertyOwned
  );
  const [borrowerTitleHeld, setBorrowerTitleHeld] = React.useState(
    initialValues.borrowerTitleHeld
  );
  const [coborrowerTitleHeld, setCoborrowerTitleHeld] = React.useState(
    initialValues.coborrowerTitleHeld
  );
  const [borrowerOutstandingJudgments, setBorrowerOutstandingJudgments] =
    React.useState(initialValues.borrowerOutstandingJudgments);
  const [coborrowerOutstandingJudgments, setCoborrowerOutstandingJudgments] =
    React.useState(initialValues.coborrowerOutstandingJudgments);
  const [borrowerBankrupt, setBorrowerBankrupt] = React.useState(
    initialValues.borrowerBankrupt
  );
  const [coborrowerBankrupt, setCoborrowerBankrupt] = React.useState(
    initialValues.coborrowerBankrupt
  );
  const [borrowerForeclosed, setBorrowerForeclosed] = React.useState(
    initialValues.borrowerForeclosed
  );
  const [coborrowerForeclosed, setCoborrowerForeclosed] = React.useState(
    initialValues.coborrowerForeclosed
  );
  const [borrowerLawsuit, setBorrowerLawsuit] = React.useState(
    initialValues.borrowerLawsuit
  );
  const [coborrowerLawsuit, setCoborrowerLawsuit] = React.useState(
    initialValues.coborrowerLawsuit
  );
  const [borrowerObligatedLoan, setBorrowerObligatedLoan] = React.useState(
    initialValues.borrowerObligatedLoan
  );
  const [coborrowerObligatedLoan, setCoborrowerObligatedLoan] = React.useState(
    initialValues.coborrowerObligatedLoan
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = declarationsRecord
      ? { ...initialValues, ...declarationsRecord }
      : initialValues;
    setBorrowerDelinquent(cleanValues.borrowerDelinquent);
    setCoborrowerDelinquent(cleanValues.coborrowerDelinquent);
    setBorrowerObligatedMaintenance(cleanValues.borrowerObligatedMaintenance);
    setCoborrowerObligatedMaintenance(
      cleanValues.coborrowerObligatedMaintenance
    );
    setBorrowerDownpaymentBorrowed(cleanValues.borrowerDownpaymentBorrowed);
    setCoborrowerDownpaymentBorrowed(cleanValues.coborrowerDownpaymentBorrowed);
    setBorrowerNote(cleanValues.borrowerNote);
    setCoborrowerNote(cleanValues.coborrowerNote);
    setBorrowerCitizen(cleanValues.borrowerCitizen);
    setCoborrowerCitizen(cleanValues.coborrowerCitizen);
    setBorrowerResidentAlien(cleanValues.borrowerResidentAlien);
    setCoborrowerResidentAlien(cleanValues.coborrowerResidentAlien);
    setBorrowerPrimaryResidence(cleanValues.borrowerPrimaryResidence);
    setCoborrowerPrimaryResidence(cleanValues.coborrowerPrimaryResidence);
    setBorrowerOwnership(cleanValues.borrowerOwnership);
    setCoborrowerOwnership(cleanValues.coborrowerOwnership);
    setBorrowerPropertyOwned(cleanValues.borrowerPropertyOwned);
    setCoborrowerPropertyOwned(cleanValues.coborrowerPropertyOwned);
    setBorrowerTitleHeld(cleanValues.borrowerTitleHeld);
    setCoborrowerTitleHeld(cleanValues.coborrowerTitleHeld);
    setBorrowerOutstandingJudgments(cleanValues.borrowerOutstandingJudgments);
    setCoborrowerOutstandingJudgments(
      cleanValues.coborrowerOutstandingJudgments
    );
    setBorrowerBankrupt(cleanValues.borrowerBankrupt);
    setCoborrowerBankrupt(cleanValues.coborrowerBankrupt);
    setBorrowerForeclosed(cleanValues.borrowerForeclosed);
    setCoborrowerForeclosed(cleanValues.coborrowerForeclosed);
    setBorrowerLawsuit(cleanValues.borrowerLawsuit);
    setCoborrowerLawsuit(cleanValues.coborrowerLawsuit);
    setBorrowerObligatedLoan(cleanValues.borrowerObligatedLoan);
    setCoborrowerObligatedLoan(cleanValues.coborrowerObligatedLoan);
    setErrors({});
  };
  const [declarationsRecord, setDeclarationsRecord] = React.useState(
    declarationsModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getDeclarations.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getDeclarations
        : declarationsModelProp;
      setDeclarationsRecord(record);
    };
    queryData();
  }, [idProp, declarationsModelProp]);
  React.useEffect(resetStateValues, [declarationsRecord]);
  const validations = {
    borrowerDelinquent: [],
    coborrowerDelinquent: [],
    borrowerObligatedMaintenance: [],
    coborrowerObligatedMaintenance: [],
    borrowerDownpaymentBorrowed: [],
    coborrowerDownpaymentBorrowed: [],
    borrowerNote: [],
    coborrowerNote: [],
    borrowerCitizen: [],
    coborrowerCitizen: [],
    borrowerResidentAlien: [],
    coborrowerResidentAlien: [],
    borrowerPrimaryResidence: [],
    coborrowerPrimaryResidence: [],
    borrowerOwnership: [],
    coborrowerOwnership: [],
    borrowerPropertyOwned: [],
    coborrowerPropertyOwned: [],
    borrowerTitleHeld: [],
    coborrowerTitleHeld: [],
    borrowerOutstandingJudgments: [],
    coborrowerOutstandingJudgments: [],
    borrowerBankrupt: [],
    coborrowerBankrupt: [],
    borrowerForeclosed: [],
    coborrowerForeclosed: [],
    borrowerLawsuit: [],
    coborrowerLawsuit: [],
    borrowerObligatedLoan: [],
    coborrowerObligatedLoan: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          borrowerDelinquent: borrowerDelinquent ?? null,
          coborrowerDelinquent: coborrowerDelinquent ?? null,
          borrowerObligatedMaintenance: borrowerObligatedMaintenance ?? null,
          coborrowerObligatedMaintenance:
            coborrowerObligatedMaintenance ?? null,
          borrowerDownpaymentBorrowed: borrowerDownpaymentBorrowed ?? null,
          coborrowerDownpaymentBorrowed: coborrowerDownpaymentBorrowed ?? null,
          borrowerNote: borrowerNote ?? null,
          coborrowerNote: coborrowerNote ?? null,
          borrowerCitizen: borrowerCitizen ?? null,
          coborrowerCitizen: coborrowerCitizen ?? null,
          borrowerResidentAlien: borrowerResidentAlien ?? null,
          coborrowerResidentAlien: coborrowerResidentAlien ?? null,
          borrowerPrimaryResidence: borrowerPrimaryResidence ?? null,
          coborrowerPrimaryResidence: coborrowerPrimaryResidence ?? null,
          borrowerOwnership: borrowerOwnership ?? null,
          coborrowerOwnership: coborrowerOwnership ?? null,
          borrowerPropertyOwned: borrowerPropertyOwned ?? null,
          coborrowerPropertyOwned: coborrowerPropertyOwned ?? null,
          borrowerTitleHeld: borrowerTitleHeld ?? null,
          coborrowerTitleHeld: coborrowerTitleHeld ?? null,
          borrowerOutstandingJudgments: borrowerOutstandingJudgments ?? null,
          coborrowerOutstandingJudgments:
            coborrowerOutstandingJudgments ?? null,
          borrowerBankrupt: borrowerBankrupt ?? null,
          coborrowerBankrupt: coborrowerBankrupt ?? null,
          borrowerForeclosed: borrowerForeclosed ?? null,
          coborrowerForeclosed: coborrowerForeclosed ?? null,
          borrowerLawsuit: borrowerLawsuit ?? null,
          coborrowerLawsuit: coborrowerLawsuit ?? null,
          borrowerObligatedLoan: borrowerObligatedLoan ?? null,
          coborrowerObligatedLoan: coborrowerObligatedLoan ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateDeclarations.replaceAll("__typename", ""),
            variables: {
              input: {
                id: declarationsRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "DeclarationsUpdateForm")}
      {...rest}
    >
      <SwitchField
        label="Borrower delinquent"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerDelinquent}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent: value,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerDelinquent ?? value;
          }
          if (errors.borrowerDelinquent?.hasError) {
            runValidationTasks("borrowerDelinquent", value);
          }
          setBorrowerDelinquent(value);
        }}
        onBlur={() =>
          runValidationTasks("borrowerDelinquent", borrowerDelinquent)
        }
        errorMessage={errors.borrowerDelinquent?.errorMessage}
        hasError={errors.borrowerDelinquent?.hasError}
        {...getOverrideProps(overrides, "borrowerDelinquent")}
      ></SwitchField>
      <SwitchField
        label="Coborrower delinquent"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerDelinquent}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent: value,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerDelinquent ?? value;
          }
          if (errors.coborrowerDelinquent?.hasError) {
            runValidationTasks("coborrowerDelinquent", value);
          }
          setCoborrowerDelinquent(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerDelinquent", coborrowerDelinquent)
        }
        errorMessage={errors.coborrowerDelinquent?.errorMessage}
        hasError={errors.coborrowerDelinquent?.hasError}
        {...getOverrideProps(overrides, "coborrowerDelinquent")}
      ></SwitchField>
      <SwitchField
        label="Borrower obligated maintenance"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerObligatedMaintenance}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance: value,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerObligatedMaintenance ?? value;
          }
          if (errors.borrowerObligatedMaintenance?.hasError) {
            runValidationTasks("borrowerObligatedMaintenance", value);
          }
          setBorrowerObligatedMaintenance(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "borrowerObligatedMaintenance",
            borrowerObligatedMaintenance
          )
        }
        errorMessage={errors.borrowerObligatedMaintenance?.errorMessage}
        hasError={errors.borrowerObligatedMaintenance?.hasError}
        {...getOverrideProps(overrides, "borrowerObligatedMaintenance")}
      ></SwitchField>
      <SwitchField
        label="Coborrower obligated maintenance"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerObligatedMaintenance}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance: value,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerObligatedMaintenance ?? value;
          }
          if (errors.coborrowerObligatedMaintenance?.hasError) {
            runValidationTasks("coborrowerObligatedMaintenance", value);
          }
          setCoborrowerObligatedMaintenance(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "coborrowerObligatedMaintenance",
            coborrowerObligatedMaintenance
          )
        }
        errorMessage={errors.coborrowerObligatedMaintenance?.errorMessage}
        hasError={errors.coborrowerObligatedMaintenance?.hasError}
        {...getOverrideProps(overrides, "coborrowerObligatedMaintenance")}
      ></SwitchField>
      <SwitchField
        label="Borrower downpayment borrowed"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerDownpaymentBorrowed}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed: value,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerDownpaymentBorrowed ?? value;
          }
          if (errors.borrowerDownpaymentBorrowed?.hasError) {
            runValidationTasks("borrowerDownpaymentBorrowed", value);
          }
          setBorrowerDownpaymentBorrowed(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "borrowerDownpaymentBorrowed",
            borrowerDownpaymentBorrowed
          )
        }
        errorMessage={errors.borrowerDownpaymentBorrowed?.errorMessage}
        hasError={errors.borrowerDownpaymentBorrowed?.hasError}
        {...getOverrideProps(overrides, "borrowerDownpaymentBorrowed")}
      ></SwitchField>
      <SwitchField
        label="Coborrower downpayment borrowed"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerDownpaymentBorrowed}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed: value,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerDownpaymentBorrowed ?? value;
          }
          if (errors.coborrowerDownpaymentBorrowed?.hasError) {
            runValidationTasks("coborrowerDownpaymentBorrowed", value);
          }
          setCoborrowerDownpaymentBorrowed(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "coborrowerDownpaymentBorrowed",
            coborrowerDownpaymentBorrowed
          )
        }
        errorMessage={errors.coborrowerDownpaymentBorrowed?.errorMessage}
        hasError={errors.coborrowerDownpaymentBorrowed?.hasError}
        {...getOverrideProps(overrides, "coborrowerDownpaymentBorrowed")}
      ></SwitchField>
      <SwitchField
        label="Borrower note"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerNote}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote: value,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerNote ?? value;
          }
          if (errors.borrowerNote?.hasError) {
            runValidationTasks("borrowerNote", value);
          }
          setBorrowerNote(value);
        }}
        onBlur={() => runValidationTasks("borrowerNote", borrowerNote)}
        errorMessage={errors.borrowerNote?.errorMessage}
        hasError={errors.borrowerNote?.hasError}
        {...getOverrideProps(overrides, "borrowerNote")}
      ></SwitchField>
      <SwitchField
        label="Coborrower note"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerNote}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote: value,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerNote ?? value;
          }
          if (errors.coborrowerNote?.hasError) {
            runValidationTasks("coborrowerNote", value);
          }
          setCoborrowerNote(value);
        }}
        onBlur={() => runValidationTasks("coborrowerNote", coborrowerNote)}
        errorMessage={errors.coborrowerNote?.errorMessage}
        hasError={errors.coborrowerNote?.hasError}
        {...getOverrideProps(overrides, "coborrowerNote")}
      ></SwitchField>
      <SwitchField
        label="Borrower citizen"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerCitizen}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen: value,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerCitizen ?? value;
          }
          if (errors.borrowerCitizen?.hasError) {
            runValidationTasks("borrowerCitizen", value);
          }
          setBorrowerCitizen(value);
        }}
        onBlur={() => runValidationTasks("borrowerCitizen", borrowerCitizen)}
        errorMessage={errors.borrowerCitizen?.errorMessage}
        hasError={errors.borrowerCitizen?.hasError}
        {...getOverrideProps(overrides, "borrowerCitizen")}
      ></SwitchField>
      <SwitchField
        label="Coborrower citizen"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerCitizen}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen: value,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerCitizen ?? value;
          }
          if (errors.coborrowerCitizen?.hasError) {
            runValidationTasks("coborrowerCitizen", value);
          }
          setCoborrowerCitizen(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerCitizen", coborrowerCitizen)
        }
        errorMessage={errors.coborrowerCitizen?.errorMessage}
        hasError={errors.coborrowerCitizen?.hasError}
        {...getOverrideProps(overrides, "coborrowerCitizen")}
      ></SwitchField>
      <SwitchField
        label="Borrower resident alien"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerResidentAlien}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien: value,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerResidentAlien ?? value;
          }
          if (errors.borrowerResidentAlien?.hasError) {
            runValidationTasks("borrowerResidentAlien", value);
          }
          setBorrowerResidentAlien(value);
        }}
        onBlur={() =>
          runValidationTasks("borrowerResidentAlien", borrowerResidentAlien)
        }
        errorMessage={errors.borrowerResidentAlien?.errorMessage}
        hasError={errors.borrowerResidentAlien?.hasError}
        {...getOverrideProps(overrides, "borrowerResidentAlien")}
      ></SwitchField>
      <SwitchField
        label="Coborrower resident alien"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerResidentAlien}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien: value,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerResidentAlien ?? value;
          }
          if (errors.coborrowerResidentAlien?.hasError) {
            runValidationTasks("coborrowerResidentAlien", value);
          }
          setCoborrowerResidentAlien(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerResidentAlien", coborrowerResidentAlien)
        }
        errorMessage={errors.coborrowerResidentAlien?.errorMessage}
        hasError={errors.coborrowerResidentAlien?.hasError}
        {...getOverrideProps(overrides, "coborrowerResidentAlien")}
      ></SwitchField>
      <SwitchField
        label="Borrower primary residence"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerPrimaryResidence}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence: value,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerPrimaryResidence ?? value;
          }
          if (errors.borrowerPrimaryResidence?.hasError) {
            runValidationTasks("borrowerPrimaryResidence", value);
          }
          setBorrowerPrimaryResidence(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "borrowerPrimaryResidence",
            borrowerPrimaryResidence
          )
        }
        errorMessage={errors.borrowerPrimaryResidence?.errorMessage}
        hasError={errors.borrowerPrimaryResidence?.hasError}
        {...getOverrideProps(overrides, "borrowerPrimaryResidence")}
      ></SwitchField>
      <SwitchField
        label="Coborrower primary residence"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerPrimaryResidence}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence: value,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerPrimaryResidence ?? value;
          }
          if (errors.coborrowerPrimaryResidence?.hasError) {
            runValidationTasks("coborrowerPrimaryResidence", value);
          }
          setCoborrowerPrimaryResidence(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "coborrowerPrimaryResidence",
            coborrowerPrimaryResidence
          )
        }
        errorMessage={errors.coborrowerPrimaryResidence?.errorMessage}
        hasError={errors.coborrowerPrimaryResidence?.hasError}
        {...getOverrideProps(overrides, "coborrowerPrimaryResidence")}
      ></SwitchField>
      <SwitchField
        label="Borrower ownership"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerOwnership}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership: value,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerOwnership ?? value;
          }
          if (errors.borrowerOwnership?.hasError) {
            runValidationTasks("borrowerOwnership", value);
          }
          setBorrowerOwnership(value);
        }}
        onBlur={() =>
          runValidationTasks("borrowerOwnership", borrowerOwnership)
        }
        errorMessage={errors.borrowerOwnership?.errorMessage}
        hasError={errors.borrowerOwnership?.hasError}
        {...getOverrideProps(overrides, "borrowerOwnership")}
      ></SwitchField>
      <SwitchField
        label="Coborrower ownership"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerOwnership}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership: value,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerOwnership ?? value;
          }
          if (errors.coborrowerOwnership?.hasError) {
            runValidationTasks("coborrowerOwnership", value);
          }
          setCoborrowerOwnership(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerOwnership", coborrowerOwnership)
        }
        errorMessage={errors.coborrowerOwnership?.errorMessage}
        hasError={errors.coborrowerOwnership?.hasError}
        {...getOverrideProps(overrides, "coborrowerOwnership")}
      ></SwitchField>
      <TextField
        label="Borrower property owned"
        isRequired={false}
        isReadOnly={false}
        value={borrowerPropertyOwned}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned: value,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerPropertyOwned ?? value;
          }
          if (errors.borrowerPropertyOwned?.hasError) {
            runValidationTasks("borrowerPropertyOwned", value);
          }
          setBorrowerPropertyOwned(value);
        }}
        onBlur={() =>
          runValidationTasks("borrowerPropertyOwned", borrowerPropertyOwned)
        }
        errorMessage={errors.borrowerPropertyOwned?.errorMessage}
        hasError={errors.borrowerPropertyOwned?.hasError}
        {...getOverrideProps(overrides, "borrowerPropertyOwned")}
      ></TextField>
      <TextField
        label="Coborrower property owned"
        isRequired={false}
        isReadOnly={false}
        value={coborrowerPropertyOwned}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned: value,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerPropertyOwned ?? value;
          }
          if (errors.coborrowerPropertyOwned?.hasError) {
            runValidationTasks("coborrowerPropertyOwned", value);
          }
          setCoborrowerPropertyOwned(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerPropertyOwned", coborrowerPropertyOwned)
        }
        errorMessage={errors.coborrowerPropertyOwned?.errorMessage}
        hasError={errors.coborrowerPropertyOwned?.hasError}
        {...getOverrideProps(overrides, "coborrowerPropertyOwned")}
      ></TextField>
      <TextField
        label="Borrower title held"
        isRequired={false}
        isReadOnly={false}
        value={borrowerTitleHeld}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld: value,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerTitleHeld ?? value;
          }
          if (errors.borrowerTitleHeld?.hasError) {
            runValidationTasks("borrowerTitleHeld", value);
          }
          setBorrowerTitleHeld(value);
        }}
        onBlur={() =>
          runValidationTasks("borrowerTitleHeld", borrowerTitleHeld)
        }
        errorMessage={errors.borrowerTitleHeld?.errorMessage}
        hasError={errors.borrowerTitleHeld?.hasError}
        {...getOverrideProps(overrides, "borrowerTitleHeld")}
      ></TextField>
      <TextField
        label="Coborrower title held"
        isRequired={false}
        isReadOnly={false}
        value={coborrowerTitleHeld}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld: value,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerTitleHeld ?? value;
          }
          if (errors.coborrowerTitleHeld?.hasError) {
            runValidationTasks("coborrowerTitleHeld", value);
          }
          setCoborrowerTitleHeld(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerTitleHeld", coborrowerTitleHeld)
        }
        errorMessage={errors.coborrowerTitleHeld?.errorMessage}
        hasError={errors.coborrowerTitleHeld?.hasError}
        {...getOverrideProps(overrides, "coborrowerTitleHeld")}
      ></TextField>
      <SwitchField
        label="Borrower outstanding judgments"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerOutstandingJudgments}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments: value,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerOutstandingJudgments ?? value;
          }
          if (errors.borrowerOutstandingJudgments?.hasError) {
            runValidationTasks("borrowerOutstandingJudgments", value);
          }
          setBorrowerOutstandingJudgments(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "borrowerOutstandingJudgments",
            borrowerOutstandingJudgments
          )
        }
        errorMessage={errors.borrowerOutstandingJudgments?.errorMessage}
        hasError={errors.borrowerOutstandingJudgments?.hasError}
        {...getOverrideProps(overrides, "borrowerOutstandingJudgments")}
      ></SwitchField>
      <SwitchField
        label="Coborrower outstanding judgments"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerOutstandingJudgments}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments: value,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerOutstandingJudgments ?? value;
          }
          if (errors.coborrowerOutstandingJudgments?.hasError) {
            runValidationTasks("coborrowerOutstandingJudgments", value);
          }
          setCoborrowerOutstandingJudgments(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "coborrowerOutstandingJudgments",
            coborrowerOutstandingJudgments
          )
        }
        errorMessage={errors.coborrowerOutstandingJudgments?.errorMessage}
        hasError={errors.coborrowerOutstandingJudgments?.hasError}
        {...getOverrideProps(overrides, "coborrowerOutstandingJudgments")}
      ></SwitchField>
      <SwitchField
        label="Borrower bankrupt"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerBankrupt}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt: value,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerBankrupt ?? value;
          }
          if (errors.borrowerBankrupt?.hasError) {
            runValidationTasks("borrowerBankrupt", value);
          }
          setBorrowerBankrupt(value);
        }}
        onBlur={() => runValidationTasks("borrowerBankrupt", borrowerBankrupt)}
        errorMessage={errors.borrowerBankrupt?.errorMessage}
        hasError={errors.borrowerBankrupt?.hasError}
        {...getOverrideProps(overrides, "borrowerBankrupt")}
      ></SwitchField>
      <SwitchField
        label="Coborrower bankrupt"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerBankrupt}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt: value,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerBankrupt ?? value;
          }
          if (errors.coborrowerBankrupt?.hasError) {
            runValidationTasks("coborrowerBankrupt", value);
          }
          setCoborrowerBankrupt(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerBankrupt", coborrowerBankrupt)
        }
        errorMessage={errors.coborrowerBankrupt?.errorMessage}
        hasError={errors.coborrowerBankrupt?.hasError}
        {...getOverrideProps(overrides, "coborrowerBankrupt")}
      ></SwitchField>
      <SwitchField
        label="Borrower foreclosed"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerForeclosed}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed: value,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerForeclosed ?? value;
          }
          if (errors.borrowerForeclosed?.hasError) {
            runValidationTasks("borrowerForeclosed", value);
          }
          setBorrowerForeclosed(value);
        }}
        onBlur={() =>
          runValidationTasks("borrowerForeclosed", borrowerForeclosed)
        }
        errorMessage={errors.borrowerForeclosed?.errorMessage}
        hasError={errors.borrowerForeclosed?.hasError}
        {...getOverrideProps(overrides, "borrowerForeclosed")}
      ></SwitchField>
      <SwitchField
        label="Coborrower foreclosed"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerForeclosed}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed: value,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerForeclosed ?? value;
          }
          if (errors.coborrowerForeclosed?.hasError) {
            runValidationTasks("coborrowerForeclosed", value);
          }
          setCoborrowerForeclosed(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerForeclosed", coborrowerForeclosed)
        }
        errorMessage={errors.coborrowerForeclosed?.errorMessage}
        hasError={errors.coborrowerForeclosed?.hasError}
        {...getOverrideProps(overrides, "coborrowerForeclosed")}
      ></SwitchField>
      <SwitchField
        label="Borrower lawsuit"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerLawsuit}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit: value,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerLawsuit ?? value;
          }
          if (errors.borrowerLawsuit?.hasError) {
            runValidationTasks("borrowerLawsuit", value);
          }
          setBorrowerLawsuit(value);
        }}
        onBlur={() => runValidationTasks("borrowerLawsuit", borrowerLawsuit)}
        errorMessage={errors.borrowerLawsuit?.errorMessage}
        hasError={errors.borrowerLawsuit?.hasError}
        {...getOverrideProps(overrides, "borrowerLawsuit")}
      ></SwitchField>
      <SwitchField
        label="Coborrower lawsuit"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerLawsuit}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit: value,
              borrowerObligatedLoan,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerLawsuit ?? value;
          }
          if (errors.coborrowerLawsuit?.hasError) {
            runValidationTasks("coborrowerLawsuit", value);
          }
          setCoborrowerLawsuit(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerLawsuit", coborrowerLawsuit)
        }
        errorMessage={errors.coborrowerLawsuit?.errorMessage}
        hasError={errors.coborrowerLawsuit?.hasError}
        {...getOverrideProps(overrides, "coborrowerLawsuit")}
      ></SwitchField>
      <SwitchField
        label="Borrower obligated loan"
        defaultChecked={false}
        isDisabled={false}
        isChecked={borrowerObligatedLoan}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan: value,
              coborrowerObligatedLoan,
            };
            const result = onChange(modelFields);
            value = result?.borrowerObligatedLoan ?? value;
          }
          if (errors.borrowerObligatedLoan?.hasError) {
            runValidationTasks("borrowerObligatedLoan", value);
          }
          setBorrowerObligatedLoan(value);
        }}
        onBlur={() =>
          runValidationTasks("borrowerObligatedLoan", borrowerObligatedLoan)
        }
        errorMessage={errors.borrowerObligatedLoan?.errorMessage}
        hasError={errors.borrowerObligatedLoan?.hasError}
        {...getOverrideProps(overrides, "borrowerObligatedLoan")}
      ></SwitchField>
      <SwitchField
        label="Coborrower obligated loan"
        defaultChecked={false}
        isDisabled={false}
        isChecked={coborrowerObligatedLoan}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDelinquent,
              coborrowerDelinquent,
              borrowerObligatedMaintenance,
              coborrowerObligatedMaintenance,
              borrowerDownpaymentBorrowed,
              coborrowerDownpaymentBorrowed,
              borrowerNote,
              coborrowerNote,
              borrowerCitizen,
              coborrowerCitizen,
              borrowerResidentAlien,
              coborrowerResidentAlien,
              borrowerPrimaryResidence,
              coborrowerPrimaryResidence,
              borrowerOwnership,
              coborrowerOwnership,
              borrowerPropertyOwned,
              coborrowerPropertyOwned,
              borrowerTitleHeld,
              coborrowerTitleHeld,
              borrowerOutstandingJudgments,
              coborrowerOutstandingJudgments,
              borrowerBankrupt,
              coborrowerBankrupt,
              borrowerForeclosed,
              coborrowerForeclosed,
              borrowerLawsuit,
              coborrowerLawsuit,
              borrowerObligatedLoan,
              coborrowerObligatedLoan: value,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerObligatedLoan ?? value;
          }
          if (errors.coborrowerObligatedLoan?.hasError) {
            runValidationTasks("coborrowerObligatedLoan", value);
          }
          setCoborrowerObligatedLoan(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerObligatedLoan", coborrowerObligatedLoan)
        }
        errorMessage={errors.coborrowerObligatedLoan?.errorMessage}
        hasError={errors.coborrowerObligatedLoan?.hasError}
        {...getOverrideProps(overrides, "coborrowerObligatedLoan")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || declarationsModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || declarationsModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
