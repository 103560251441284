import React from 'react';
import { Container, Typography } from '@mui/material';
import BasicHeader from './Header'; // Assuming you have a header component
import Footer from './Footer';
import './Disclaimer.css'; // Import the CSS module

const Disclaimer = () => {
    return (
        <>
            <BasicHeader />
            <div className='disclaimer-container'>
                <Container className='content'>
                    <Typography variant="h2" className='disclaimer-title' gutterBottom>
                        Disclaimer
                    </Typography>
                    <Typography variant="h4" className='disclaimer-title' gutterBottom>
                        General Information
                    </Typography>
                    <Typography variant="body1" paragraph className='disclaimer-section'>
                        The information provided by Gobdu ("we," "us," or "our") on our website and related platforms is for general informational purposes only. All information on the site is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.
                    </Typography>
                    <Typography variant="h4" className='disclaimer-title' gutterBottom>
                        Professional Advice
                    </Typography>
                    <Typography variant="body1" paragraph className='disclaimer-section'>
                        The content provided is not intended to be a substitute for professional advice. Always seek the advice of a qualified professional with any questions you may have regarding your business. Never disregard professional advice or delay in seeking it because of something you have read on our site.
                    </Typography>
                    <Typography variant="h4" className='disclaimer-title' gutterBottom>
                        External Links
                    </Typography>
                    <Typography variant="body1" paragraph className='disclaimer-section'>
                        The site may contain (or you may be sent through the site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising.
                    </Typography>
                    <Typography variant="h4" className='disclaimer-title' gutterBottom>
                        Affiliates
                    </Typography>
                    <Typography variant="body1" paragraph className='disclaimer-section'>
                        Our site may contain links to affiliate websites, and we may receive an affiliate commission for any purchases made by you on the affiliate website using such links.
                    </Typography>
                    <Typography variant="h4" className='disclaimer-title' gutterBottom>
                        Personal Responsibility
                    </Typography>
                    <Typography variant="body1" paragraph className='disclaimer-section'>
                        You acknowledge that you are using our site and services at your own risk. We are not responsible or liable for any business decisions you make based on the information provided on our site.
                    </Typography>
                    <Typography variant="h4" className='disclaimer-title' gutterBottom>
                        Limitation of Liability
                    </Typography>
                    <Typography variant="body1" paragraph className='disclaimer-section'>
                        In no event shall Gobdu be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of your access to or use of, or inability to access or use, the site or any materials on the site.
                    </Typography>
                    <Typography variant="h4" className='disclaimer-title' gutterBottom>
                        Changes to Disclaimer
                    </Typography>
                    <Typography variant="body1" paragraph className='disclaimer-section'>
                        We reserve the right to update this disclaimer from time to time. The updated version will be indicated by an updated “Revised” date, and the updated version will be effective as soon as it is accessible.
                    </Typography>
                </Container>
                <Footer />
            </div>
        </>
    );
};

export default Disclaimer;
