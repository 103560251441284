/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createRealEstateInfo } from "../graphql/mutations";
const client = generateClient();
export default function RealEstateInfoCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    propertyAddress: "",
    propertyStatus: "",
    typeOfProperty: "",
    presentMarketValue: "",
    amountOfMortgageAndLiens: "",
    grossRentalIncome: "",
    mortgagePayments: "",
    miscPayments: "",
    netRentalIncome: "",
    propertyAddress2: "",
    propertyStatus2: "",
    typeOfProperty2: "",
    presentMarketValue2: "",
    amountOfMortgageAndLiens2: "",
    grossRentalIncome2: "",
    mortgagePayments2: "",
    miscPayments2: "",
    netRentalIncome2: "",
    propertyAddress3: "",
    propertyStatus3: "",
    typeOfProperty3: "",
    presentMarketValue3: "",
    amountOfMortgageAndLiens3: "",
    grossRentalIncome3: "",
    mortgagePayments3: "",
    miscPayments3: "",
    netRentalIncome3: "",
    totalMarketValue: "",
    totalMortgages: "",
    totalGrossRentalIncome: "",
    totalMortgagePayments: "",
    totalMisc: "",
    totalNetRentalIncome: "",
    altName: "",
    creditorName: "",
    accNumber: "",
    altName2: "",
    creditorName2: "",
    accNumber2: "",
  };
  const [propertyAddress, setPropertyAddress] = React.useState(
    initialValues.propertyAddress
  );
  const [propertyStatus, setPropertyStatus] = React.useState(
    initialValues.propertyStatus
  );
  const [typeOfProperty, setTypeOfProperty] = React.useState(
    initialValues.typeOfProperty
  );
  const [presentMarketValue, setPresentMarketValue] = React.useState(
    initialValues.presentMarketValue
  );
  const [amountOfMortgageAndLiens, setAmountOfMortgageAndLiens] =
    React.useState(initialValues.amountOfMortgageAndLiens);
  const [grossRentalIncome, setGrossRentalIncome] = React.useState(
    initialValues.grossRentalIncome
  );
  const [mortgagePayments, setMortgagePayments] = React.useState(
    initialValues.mortgagePayments
  );
  const [miscPayments, setMiscPayments] = React.useState(
    initialValues.miscPayments
  );
  const [netRentalIncome, setNetRentalIncome] = React.useState(
    initialValues.netRentalIncome
  );
  const [propertyAddress2, setPropertyAddress2] = React.useState(
    initialValues.propertyAddress2
  );
  const [propertyStatus2, setPropertyStatus2] = React.useState(
    initialValues.propertyStatus2
  );
  const [typeOfProperty2, setTypeOfProperty2] = React.useState(
    initialValues.typeOfProperty2
  );
  const [presentMarketValue2, setPresentMarketValue2] = React.useState(
    initialValues.presentMarketValue2
  );
  const [amountOfMortgageAndLiens2, setAmountOfMortgageAndLiens2] =
    React.useState(initialValues.amountOfMortgageAndLiens2);
  const [grossRentalIncome2, setGrossRentalIncome2] = React.useState(
    initialValues.grossRentalIncome2
  );
  const [mortgagePayments2, setMortgagePayments2] = React.useState(
    initialValues.mortgagePayments2
  );
  const [miscPayments2, setMiscPayments2] = React.useState(
    initialValues.miscPayments2
  );
  const [netRentalIncome2, setNetRentalIncome2] = React.useState(
    initialValues.netRentalIncome2
  );
  const [propertyAddress3, setPropertyAddress3] = React.useState(
    initialValues.propertyAddress3
  );
  const [propertyStatus3, setPropertyStatus3] = React.useState(
    initialValues.propertyStatus3
  );
  const [typeOfProperty3, setTypeOfProperty3] = React.useState(
    initialValues.typeOfProperty3
  );
  const [presentMarketValue3, setPresentMarketValue3] = React.useState(
    initialValues.presentMarketValue3
  );
  const [amountOfMortgageAndLiens3, setAmountOfMortgageAndLiens3] =
    React.useState(initialValues.amountOfMortgageAndLiens3);
  const [grossRentalIncome3, setGrossRentalIncome3] = React.useState(
    initialValues.grossRentalIncome3
  );
  const [mortgagePayments3, setMortgagePayments3] = React.useState(
    initialValues.mortgagePayments3
  );
  const [miscPayments3, setMiscPayments3] = React.useState(
    initialValues.miscPayments3
  );
  const [netRentalIncome3, setNetRentalIncome3] = React.useState(
    initialValues.netRentalIncome3
  );
  const [totalMarketValue, setTotalMarketValue] = React.useState(
    initialValues.totalMarketValue
  );
  const [totalMortgages, setTotalMortgages] = React.useState(
    initialValues.totalMortgages
  );
  const [totalGrossRentalIncome, setTotalGrossRentalIncome] = React.useState(
    initialValues.totalGrossRentalIncome
  );
  const [totalMortgagePayments, setTotalMortgagePayments] = React.useState(
    initialValues.totalMortgagePayments
  );
  const [totalMisc, setTotalMisc] = React.useState(initialValues.totalMisc);
  const [totalNetRentalIncome, setTotalNetRentalIncome] = React.useState(
    initialValues.totalNetRentalIncome
  );
  const [altName, setAltName] = React.useState(initialValues.altName);
  const [creditorName, setCreditorName] = React.useState(
    initialValues.creditorName
  );
  const [accNumber, setAccNumber] = React.useState(initialValues.accNumber);
  const [altName2, setAltName2] = React.useState(initialValues.altName2);
  const [creditorName2, setCreditorName2] = React.useState(
    initialValues.creditorName2
  );
  const [accNumber2, setAccNumber2] = React.useState(initialValues.accNumber2);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setPropertyAddress(initialValues.propertyAddress);
    setPropertyStatus(initialValues.propertyStatus);
    setTypeOfProperty(initialValues.typeOfProperty);
    setPresentMarketValue(initialValues.presentMarketValue);
    setAmountOfMortgageAndLiens(initialValues.amountOfMortgageAndLiens);
    setGrossRentalIncome(initialValues.grossRentalIncome);
    setMortgagePayments(initialValues.mortgagePayments);
    setMiscPayments(initialValues.miscPayments);
    setNetRentalIncome(initialValues.netRentalIncome);
    setPropertyAddress2(initialValues.propertyAddress2);
    setPropertyStatus2(initialValues.propertyStatus2);
    setTypeOfProperty2(initialValues.typeOfProperty2);
    setPresentMarketValue2(initialValues.presentMarketValue2);
    setAmountOfMortgageAndLiens2(initialValues.amountOfMortgageAndLiens2);
    setGrossRentalIncome2(initialValues.grossRentalIncome2);
    setMortgagePayments2(initialValues.mortgagePayments2);
    setMiscPayments2(initialValues.miscPayments2);
    setNetRentalIncome2(initialValues.netRentalIncome2);
    setPropertyAddress3(initialValues.propertyAddress3);
    setPropertyStatus3(initialValues.propertyStatus3);
    setTypeOfProperty3(initialValues.typeOfProperty3);
    setPresentMarketValue3(initialValues.presentMarketValue3);
    setAmountOfMortgageAndLiens3(initialValues.amountOfMortgageAndLiens3);
    setGrossRentalIncome3(initialValues.grossRentalIncome3);
    setMortgagePayments3(initialValues.mortgagePayments3);
    setMiscPayments3(initialValues.miscPayments3);
    setNetRentalIncome3(initialValues.netRentalIncome3);
    setTotalMarketValue(initialValues.totalMarketValue);
    setTotalMortgages(initialValues.totalMortgages);
    setTotalGrossRentalIncome(initialValues.totalGrossRentalIncome);
    setTotalMortgagePayments(initialValues.totalMortgagePayments);
    setTotalMisc(initialValues.totalMisc);
    setTotalNetRentalIncome(initialValues.totalNetRentalIncome);
    setAltName(initialValues.altName);
    setCreditorName(initialValues.creditorName);
    setAccNumber(initialValues.accNumber);
    setAltName2(initialValues.altName2);
    setCreditorName2(initialValues.creditorName2);
    setAccNumber2(initialValues.accNumber2);
    setErrors({});
  };
  const validations = {
    propertyAddress: [],
    propertyStatus: [],
    typeOfProperty: [],
    presentMarketValue: [],
    amountOfMortgageAndLiens: [],
    grossRentalIncome: [],
    mortgagePayments: [],
    miscPayments: [],
    netRentalIncome: [],
    propertyAddress2: [],
    propertyStatus2: [],
    typeOfProperty2: [],
    presentMarketValue2: [],
    amountOfMortgageAndLiens2: [],
    grossRentalIncome2: [],
    mortgagePayments2: [],
    miscPayments2: [],
    netRentalIncome2: [],
    propertyAddress3: [],
    propertyStatus3: [],
    typeOfProperty3: [],
    presentMarketValue3: [],
    amountOfMortgageAndLiens3: [],
    grossRentalIncome3: [],
    mortgagePayments3: [],
    miscPayments3: [],
    netRentalIncome3: [],
    totalMarketValue: [],
    totalMortgages: [],
    totalGrossRentalIncome: [],
    totalMortgagePayments: [],
    totalMisc: [],
    totalNetRentalIncome: [],
    altName: [],
    creditorName: [],
    accNumber: [],
    altName2: [],
    creditorName2: [],
    accNumber2: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          propertyAddress,
          propertyStatus,
          typeOfProperty,
          presentMarketValue,
          amountOfMortgageAndLiens,
          grossRentalIncome,
          mortgagePayments,
          miscPayments,
          netRentalIncome,
          propertyAddress2,
          propertyStatus2,
          typeOfProperty2,
          presentMarketValue2,
          amountOfMortgageAndLiens2,
          grossRentalIncome2,
          mortgagePayments2,
          miscPayments2,
          netRentalIncome2,
          propertyAddress3,
          propertyStatus3,
          typeOfProperty3,
          presentMarketValue3,
          amountOfMortgageAndLiens3,
          grossRentalIncome3,
          mortgagePayments3,
          miscPayments3,
          netRentalIncome3,
          totalMarketValue,
          totalMortgages,
          totalGrossRentalIncome,
          totalMortgagePayments,
          totalMisc,
          totalNetRentalIncome,
          altName,
          creditorName,
          accNumber,
          altName2,
          creditorName2,
          accNumber2,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createRealEstateInfo.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "RealEstateInfoCreateForm")}
      {...rest}
    >
      <TextField
        label="Property address"
        isRequired={false}
        isReadOnly={false}
        value={propertyAddress}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress: value,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber,
              altName2,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.propertyAddress ?? value;
          }
          if (errors.propertyAddress?.hasError) {
            runValidationTasks("propertyAddress", value);
          }
          setPropertyAddress(value);
        }}
        onBlur={() => runValidationTasks("propertyAddress", propertyAddress)}
        errorMessage={errors.propertyAddress?.errorMessage}
        hasError={errors.propertyAddress?.hasError}
        {...getOverrideProps(overrides, "propertyAddress")}
      ></TextField>
      <TextField
        label="Property status"
        descriptiveText="(enter S if sold, PS if pending sale or R if rental being held for income)"
        isRequired={false}
        isReadOnly={false}
        value={propertyStatus}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus: value,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber,
              altName2,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.propertyStatus ?? value;
          }
          if (errors.propertyStatus?.hasError) {
            runValidationTasks("propertyStatus", value);
          }
          setPropertyStatus(value);
        }}
        onBlur={() => runValidationTasks("propertyStatus", propertyStatus)}
        errorMessage={errors.propertyStatus?.errorMessage}
        hasError={errors.propertyStatus?.hasError}
        {...getOverrideProps(overrides, "propertyStatus")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid2")}
      >
        <TextField
          label="Type of property"
          descriptiveText=""
          isRequired={false}
          isReadOnly={false}
          value={typeOfProperty}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty: value,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.typeOfProperty ?? value;
            }
            if (errors.typeOfProperty?.hasError) {
              runValidationTasks("typeOfProperty", value);
            }
            setTypeOfProperty(value);
          }}
          onBlur={() => runValidationTasks("typeOfProperty", typeOfProperty)}
          errorMessage={errors.typeOfProperty?.errorMessage}
          hasError={errors.typeOfProperty?.hasError}
          {...getOverrideProps(overrides, "typeOfProperty")}
        ></TextField>
        <TextField
          label="Present market value"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={presentMarketValue}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue: value,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.presentMarketValue ?? value;
            }
            if (errors.presentMarketValue?.hasError) {
              runValidationTasks("presentMarketValue", value);
            }
            setPresentMarketValue(value);
          }}
          onBlur={() =>
            runValidationTasks("presentMarketValue", presentMarketValue)
          }
          errorMessage={errors.presentMarketValue?.errorMessage}
          hasError={errors.presentMarketValue?.hasError}
          {...getOverrideProps(overrides, "presentMarketValue")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid3")}
      >
        <TextField
          label="Amount of mortgage and liens"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={amountOfMortgageAndLiens}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens: value,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.amountOfMortgageAndLiens ?? value;
            }
            if (errors.amountOfMortgageAndLiens?.hasError) {
              runValidationTasks("amountOfMortgageAndLiens", value);
            }
            setAmountOfMortgageAndLiens(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "amountOfMortgageAndLiens",
              amountOfMortgageAndLiens
            )
          }
          errorMessage={errors.amountOfMortgageAndLiens?.errorMessage}
          hasError={errors.amountOfMortgageAndLiens?.hasError}
          {...getOverrideProps(overrides, "amountOfMortgageAndLiens")}
        ></TextField>
        <TextField
          label="Gross rental income"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={grossRentalIncome}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome: value,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.grossRentalIncome ?? value;
            }
            if (errors.grossRentalIncome?.hasError) {
              runValidationTasks("grossRentalIncome", value);
            }
            setGrossRentalIncome(value);
          }}
          onBlur={() =>
            runValidationTasks("grossRentalIncome", grossRentalIncome)
          }
          errorMessage={errors.grossRentalIncome?.errorMessage}
          hasError={errors.grossRentalIncome?.hasError}
          {...getOverrideProps(overrides, "grossRentalIncome")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid4")}
      >
        <TextField
          label="Mortgage payments"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={mortgagePayments}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments: value,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.mortgagePayments ?? value;
            }
            if (errors.mortgagePayments?.hasError) {
              runValidationTasks("mortgagePayments", value);
            }
            setMortgagePayments(value);
          }}
          onBlur={() =>
            runValidationTasks("mortgagePayments", mortgagePayments)
          }
          errorMessage={errors.mortgagePayments?.errorMessage}
          hasError={errors.mortgagePayments?.hasError}
          {...getOverrideProps(overrides, "mortgagePayments")}
        ></TextField>
        <TextField
          label="Insurance, Maintenance, Taxes & Misc."
          descriptiveText=""
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={miscPayments}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments: value,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.miscPayments ?? value;
            }
            if (errors.miscPayments?.hasError) {
              runValidationTasks("miscPayments", value);
            }
            setMiscPayments(value);
          }}
          onBlur={() => runValidationTasks("miscPayments", miscPayments)}
          errorMessage={errors.miscPayments?.errorMessage}
          hasError={errors.miscPayments?.hasError}
          {...getOverrideProps(overrides, "miscPayments")}
        ></TextField>
      </Grid>
      <TextField
        label="Net rental income"
        isRequired={false}
        isReadOnly={false}
        placeholder="$"
        value={netRentalIncome}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome: value,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber,
              altName2,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.netRentalIncome ?? value;
          }
          if (errors.netRentalIncome?.hasError) {
            runValidationTasks("netRentalIncome", value);
          }
          setNetRentalIncome(value);
        }}
        onBlur={() => runValidationTasks("netRentalIncome", netRentalIncome)}
        errorMessage={errors.netRentalIncome?.errorMessage}
        hasError={errors.netRentalIncome?.hasError}
        {...getOverrideProps(overrides, "netRentalIncome")}
      ></TextField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Divider>
      <TextField
        label="Property address"
        isRequired={false}
        isReadOnly={false}
        value={propertyAddress2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2: value,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber,
              altName2,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.propertyAddress2 ?? value;
          }
          if (errors.propertyAddress2?.hasError) {
            runValidationTasks("propertyAddress2", value);
          }
          setPropertyAddress2(value);
        }}
        onBlur={() => runValidationTasks("propertyAddress2", propertyAddress2)}
        errorMessage={errors.propertyAddress2?.errorMessage}
        hasError={errors.propertyAddress2?.hasError}
        {...getOverrideProps(overrides, "propertyAddress2")}
      ></TextField>
      <TextField
        label="Property status"
        descriptiveText="(enter S if sold, PS if pending sale or R if rental being held for income)"
        isRequired={false}
        isReadOnly={false}
        value={propertyStatus2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2: value,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber,
              altName2,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.propertyStatus2 ?? value;
          }
          if (errors.propertyStatus2?.hasError) {
            runValidationTasks("propertyStatus2", value);
          }
          setPropertyStatus2(value);
        }}
        onBlur={() => runValidationTasks("propertyStatus2", propertyStatus2)}
        errorMessage={errors.propertyStatus2?.errorMessage}
        hasError={errors.propertyStatus2?.hasError}
        {...getOverrideProps(overrides, "propertyStatus2")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <TextField
          label="Type of property"
          isRequired={false}
          isReadOnly={false}
          value={typeOfProperty2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2: value,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.typeOfProperty2 ?? value;
            }
            if (errors.typeOfProperty2?.hasError) {
              runValidationTasks("typeOfProperty2", value);
            }
            setTypeOfProperty2(value);
          }}
          onBlur={() => runValidationTasks("typeOfProperty2", typeOfProperty2)}
          errorMessage={errors.typeOfProperty2?.errorMessage}
          hasError={errors.typeOfProperty2?.hasError}
          {...getOverrideProps(overrides, "typeOfProperty2")}
        ></TextField>
        <TextField
          label="Present market value"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={presentMarketValue2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2: value,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.presentMarketValue2 ?? value;
            }
            if (errors.presentMarketValue2?.hasError) {
              runValidationTasks("presentMarketValue2", value);
            }
            setPresentMarketValue2(value);
          }}
          onBlur={() =>
            runValidationTasks("presentMarketValue2", presentMarketValue2)
          }
          errorMessage={errors.presentMarketValue2?.errorMessage}
          hasError={errors.presentMarketValue2?.hasError}
          {...getOverrideProps(overrides, "presentMarketValue2")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid10")}
      >
        <TextField
          label="Amount of mortgage and liens"
          descriptiveText=""
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={amountOfMortgageAndLiens2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2: value,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.amountOfMortgageAndLiens2 ?? value;
            }
            if (errors.amountOfMortgageAndLiens2?.hasError) {
              runValidationTasks("amountOfMortgageAndLiens2", value);
            }
            setAmountOfMortgageAndLiens2(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "amountOfMortgageAndLiens2",
              amountOfMortgageAndLiens2
            )
          }
          errorMessage={errors.amountOfMortgageAndLiens2?.errorMessage}
          hasError={errors.amountOfMortgageAndLiens2?.hasError}
          {...getOverrideProps(overrides, "amountOfMortgageAndLiens2")}
        ></TextField>
        <TextField
          label="Gross rental income"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={grossRentalIncome2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2: value,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.grossRentalIncome2 ?? value;
            }
            if (errors.grossRentalIncome2?.hasError) {
              runValidationTasks("grossRentalIncome2", value);
            }
            setGrossRentalIncome2(value);
          }}
          onBlur={() =>
            runValidationTasks("grossRentalIncome2", grossRentalIncome2)
          }
          errorMessage={errors.grossRentalIncome2?.errorMessage}
          hasError={errors.grossRentalIncome2?.hasError}
          {...getOverrideProps(overrides, "grossRentalIncome2")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid11")}
      >
        <TextField
          label="Mortgage payments"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={mortgagePayments2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2: value,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.mortgagePayments2 ?? value;
            }
            if (errors.mortgagePayments2?.hasError) {
              runValidationTasks("mortgagePayments2", value);
            }
            setMortgagePayments2(value);
          }}
          onBlur={() =>
            runValidationTasks("mortgagePayments2", mortgagePayments2)
          }
          errorMessage={errors.mortgagePayments2?.errorMessage}
          hasError={errors.mortgagePayments2?.hasError}
          {...getOverrideProps(overrides, "mortgagePayments2")}
        ></TextField>
        <TextField
          label="Insurance, Maintenance, Taxes & Misc."
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={miscPayments2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2: value,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.miscPayments2 ?? value;
            }
            if (errors.miscPayments2?.hasError) {
              runValidationTasks("miscPayments2", value);
            }
            setMiscPayments2(value);
          }}
          onBlur={() => runValidationTasks("miscPayments2", miscPayments2)}
          errorMessage={errors.miscPayments2?.errorMessage}
          hasError={errors.miscPayments2?.hasError}
          {...getOverrideProps(overrides, "miscPayments2")}
        ></TextField>
      </Grid>
      <TextField
        label="Net rental income"
        isRequired={false}
        isReadOnly={false}
        placeholder="$"
        value={netRentalIncome2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2: value,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber,
              altName2,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.netRentalIncome2 ?? value;
          }
          if (errors.netRentalIncome2?.hasError) {
            runValidationTasks("netRentalIncome2", value);
          }
          setNetRentalIncome2(value);
        }}
        onBlur={() => runValidationTasks("netRentalIncome2", netRentalIncome2)}
        errorMessage={errors.netRentalIncome2?.errorMessage}
        hasError={errors.netRentalIncome2?.hasError}
        {...getOverrideProps(overrides, "netRentalIncome2")}
      ></TextField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Divider>
      <TextField
        label="Property address"
        isRequired={false}
        isReadOnly={false}
        value={propertyAddress3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3: value,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber,
              altName2,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.propertyAddress3 ?? value;
          }
          if (errors.propertyAddress3?.hasError) {
            runValidationTasks("propertyAddress3", value);
          }
          setPropertyAddress3(value);
        }}
        onBlur={() => runValidationTasks("propertyAddress3", propertyAddress3)}
        errorMessage={errors.propertyAddress3?.errorMessage}
        hasError={errors.propertyAddress3?.hasError}
        {...getOverrideProps(overrides, "propertyAddress3")}
      ></TextField>
      <TextField
        label="Property status"
        descriptiveText="(enter S if sold, PS if pending sale or R if rental being held for income)"
        isRequired={false}
        isReadOnly={false}
        value={propertyStatus3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3: value,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber,
              altName2,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.propertyStatus3 ?? value;
          }
          if (errors.propertyStatus3?.hasError) {
            runValidationTasks("propertyStatus3", value);
          }
          setPropertyStatus3(value);
        }}
        onBlur={() => runValidationTasks("propertyStatus3", propertyStatus3)}
        errorMessage={errors.propertyStatus3?.errorMessage}
        hasError={errors.propertyStatus3?.hasError}
        {...getOverrideProps(overrides, "propertyStatus3")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid16")}
      >
        <TextField
          label="Type of property"
          isRequired={false}
          isReadOnly={false}
          value={typeOfProperty3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3: value,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.typeOfProperty3 ?? value;
            }
            if (errors.typeOfProperty3?.hasError) {
              runValidationTasks("typeOfProperty3", value);
            }
            setTypeOfProperty3(value);
          }}
          onBlur={() => runValidationTasks("typeOfProperty3", typeOfProperty3)}
          errorMessage={errors.typeOfProperty3?.errorMessage}
          hasError={errors.typeOfProperty3?.hasError}
          {...getOverrideProps(overrides, "typeOfProperty3")}
        ></TextField>
        <TextField
          label="Present market value"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={presentMarketValue3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3: value,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.presentMarketValue3 ?? value;
            }
            if (errors.presentMarketValue3?.hasError) {
              runValidationTasks("presentMarketValue3", value);
            }
            setPresentMarketValue3(value);
          }}
          onBlur={() =>
            runValidationTasks("presentMarketValue3", presentMarketValue3)
          }
          errorMessage={errors.presentMarketValue3?.errorMessage}
          hasError={errors.presentMarketValue3?.hasError}
          {...getOverrideProps(overrides, "presentMarketValue3")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid17")}
      >
        <TextField
          label="Amount of mortgage and liens"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={amountOfMortgageAndLiens3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3: value,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.amountOfMortgageAndLiens3 ?? value;
            }
            if (errors.amountOfMortgageAndLiens3?.hasError) {
              runValidationTasks("amountOfMortgageAndLiens3", value);
            }
            setAmountOfMortgageAndLiens3(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "amountOfMortgageAndLiens3",
              amountOfMortgageAndLiens3
            )
          }
          errorMessage={errors.amountOfMortgageAndLiens3?.errorMessage}
          hasError={errors.amountOfMortgageAndLiens3?.hasError}
          {...getOverrideProps(overrides, "amountOfMortgageAndLiens3")}
        ></TextField>
        <TextField
          label="Gross rental income"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={grossRentalIncome3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3: value,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.grossRentalIncome3 ?? value;
            }
            if (errors.grossRentalIncome3?.hasError) {
              runValidationTasks("grossRentalIncome3", value);
            }
            setGrossRentalIncome3(value);
          }}
          onBlur={() =>
            runValidationTasks("grossRentalIncome3", grossRentalIncome3)
          }
          errorMessage={errors.grossRentalIncome3?.errorMessage}
          hasError={errors.grossRentalIncome3?.hasError}
          {...getOverrideProps(overrides, "grossRentalIncome3")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid18")}
      >
        <TextField
          label="Mortgage payments"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={mortgagePayments3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3: value,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.mortgagePayments3 ?? value;
            }
            if (errors.mortgagePayments3?.hasError) {
              runValidationTasks("mortgagePayments3", value);
            }
            setMortgagePayments3(value);
          }}
          onBlur={() =>
            runValidationTasks("mortgagePayments3", mortgagePayments3)
          }
          errorMessage={errors.mortgagePayments3?.errorMessage}
          hasError={errors.mortgagePayments3?.hasError}
          {...getOverrideProps(overrides, "mortgagePayments3")}
        ></TextField>
        <TextField
          label="Insurance, Maintenance, Taxes & Misc."
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={miscPayments3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3: value,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.miscPayments3 ?? value;
            }
            if (errors.miscPayments3?.hasError) {
              runValidationTasks("miscPayments3", value);
            }
            setMiscPayments3(value);
          }}
          onBlur={() => runValidationTasks("miscPayments3", miscPayments3)}
          errorMessage={errors.miscPayments3?.errorMessage}
          hasError={errors.miscPayments3?.hasError}
          {...getOverrideProps(overrides, "miscPayments3")}
        ></TextField>
      </Grid>
      <TextField
        label="Net rental income"
        isRequired={false}
        isReadOnly={false}
        placeholder="$"
        value={netRentalIncome3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3: value,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber,
              altName2,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.netRentalIncome3 ?? value;
          }
          if (errors.netRentalIncome3?.hasError) {
            runValidationTasks("netRentalIncome3", value);
          }
          setNetRentalIncome3(value);
        }}
        onBlur={() => runValidationTasks("netRentalIncome3", netRentalIncome3)}
        errorMessage={errors.netRentalIncome3?.errorMessage}
        hasError={errors.netRentalIncome3?.hasError}
        {...getOverrideProps(overrides, "netRentalIncome3")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid20")}
      >
        <TextField
          label="Total market value"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={totalMarketValue}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue: value,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.totalMarketValue ?? value;
            }
            if (errors.totalMarketValue?.hasError) {
              runValidationTasks("totalMarketValue", value);
            }
            setTotalMarketValue(value);
          }}
          onBlur={() =>
            runValidationTasks("totalMarketValue", totalMarketValue)
          }
          errorMessage={errors.totalMarketValue?.errorMessage}
          hasError={errors.totalMarketValue?.hasError}
          {...getOverrideProps(overrides, "totalMarketValue")}
        ></TextField>
        <TextField
          label="Total mortgages"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={totalMortgages}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages: value,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.totalMortgages ?? value;
            }
            if (errors.totalMortgages?.hasError) {
              runValidationTasks("totalMortgages", value);
            }
            setTotalMortgages(value);
          }}
          onBlur={() => runValidationTasks("totalMortgages", totalMortgages)}
          errorMessage={errors.totalMortgages?.errorMessage}
          hasError={errors.totalMortgages?.hasError}
          {...getOverrideProps(overrides, "totalMortgages")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid21")}
      >
        <TextField
          label="Total gross rental income"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={totalGrossRentalIncome}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome: value,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.totalGrossRentalIncome ?? value;
            }
            if (errors.totalGrossRentalIncome?.hasError) {
              runValidationTasks("totalGrossRentalIncome", value);
            }
            setTotalGrossRentalIncome(value);
          }}
          onBlur={() =>
            runValidationTasks("totalGrossRentalIncome", totalGrossRentalIncome)
          }
          errorMessage={errors.totalGrossRentalIncome?.errorMessage}
          hasError={errors.totalGrossRentalIncome?.hasError}
          {...getOverrideProps(overrides, "totalGrossRentalIncome")}
        ></TextField>
        <TextField
          label="Total mortgage payments"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={totalMortgagePayments}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments: value,
                totalMisc,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.totalMortgagePayments ?? value;
            }
            if (errors.totalMortgagePayments?.hasError) {
              runValidationTasks("totalMortgagePayments", value);
            }
            setTotalMortgagePayments(value);
          }}
          onBlur={() =>
            runValidationTasks("totalMortgagePayments", totalMortgagePayments)
          }
          errorMessage={errors.totalMortgagePayments?.errorMessage}
          hasError={errors.totalMortgagePayments?.hasError}
          {...getOverrideProps(overrides, "totalMortgagePayments")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid22")}
      >
        <TextField
          label="Total Insurance, Maintenance, Taxes & Misc."
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={totalMisc}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc: value,
                totalNetRentalIncome,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.totalMisc ?? value;
            }
            if (errors.totalMisc?.hasError) {
              runValidationTasks("totalMisc", value);
            }
            setTotalMisc(value);
          }}
          onBlur={() => runValidationTasks("totalMisc", totalMisc)}
          errorMessage={errors.totalMisc?.errorMessage}
          hasError={errors.totalMisc?.hasError}
          {...getOverrideProps(overrides, "totalMisc")}
        ></TextField>
        <TextField
          label="Total net rental income"
          isRequired={false}
          isReadOnly={false}
          placeholder="$"
          value={totalNetRentalIncome}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                propertyAddress,
                propertyStatus,
                typeOfProperty,
                presentMarketValue,
                amountOfMortgageAndLiens,
                grossRentalIncome,
                mortgagePayments,
                miscPayments,
                netRentalIncome,
                propertyAddress2,
                propertyStatus2,
                typeOfProperty2,
                presentMarketValue2,
                amountOfMortgageAndLiens2,
                grossRentalIncome2,
                mortgagePayments2,
                miscPayments2,
                netRentalIncome2,
                propertyAddress3,
                propertyStatus3,
                typeOfProperty3,
                presentMarketValue3,
                amountOfMortgageAndLiens3,
                grossRentalIncome3,
                mortgagePayments3,
                miscPayments3,
                netRentalIncome3,
                totalMarketValue,
                totalMortgages,
                totalGrossRentalIncome,
                totalMortgagePayments,
                totalMisc,
                totalNetRentalIncome: value,
                altName,
                creditorName,
                accNumber,
                altName2,
                creditorName2,
                accNumber2,
              };
              const result = onChange(modelFields);
              value = result?.totalNetRentalIncome ?? value;
            }
            if (errors.totalNetRentalIncome?.hasError) {
              runValidationTasks("totalNetRentalIncome", value);
            }
            setTotalNetRentalIncome(value);
          }}
          onBlur={() =>
            runValidationTasks("totalNetRentalIncome", totalNetRentalIncome)
          }
          errorMessage={errors.totalNetRentalIncome?.errorMessage}
          hasError={errors.totalNetRentalIncome?.hasError}
          {...getOverrideProps(overrides, "totalNetRentalIncome")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <Text
        children="List any additional names under which credit has previously been received and indicate appropriate creditor name(s) and account number(s):"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Text>
      <TextField
        label="Alternate Name"
        isRequired={false}
        isReadOnly={false}
        value={altName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName: value,
              creditorName,
              accNumber,
              altName2,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.altName ?? value;
          }
          if (errors.altName?.hasError) {
            runValidationTasks("altName", value);
          }
          setAltName(value);
        }}
        onBlur={() => runValidationTasks("altName", altName)}
        errorMessage={errors.altName?.errorMessage}
        hasError={errors.altName?.hasError}
        {...getOverrideProps(overrides, "altName")}
      ></TextField>
      <TextField
        label="Creditor Name"
        isRequired={false}
        isReadOnly={false}
        value={creditorName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName: value,
              accNumber,
              altName2,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.creditorName ?? value;
          }
          if (errors.creditorName?.hasError) {
            runValidationTasks("creditorName", value);
          }
          setCreditorName(value);
        }}
        onBlur={() => runValidationTasks("creditorName", creditorName)}
        errorMessage={errors.creditorName?.errorMessage}
        hasError={errors.creditorName?.hasError}
        {...getOverrideProps(overrides, "creditorName")}
      ></TextField>
      <TextField
        label="Account Number"
        isRequired={false}
        isReadOnly={false}
        value={accNumber}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber: value,
              altName2,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.accNumber ?? value;
          }
          if (errors.accNumber?.hasError) {
            runValidationTasks("accNumber", value);
          }
          setAccNumber(value);
        }}
        onBlur={() => runValidationTasks("accNumber", accNumber)}
        errorMessage={errors.accNumber?.errorMessage}
        hasError={errors.accNumber?.hasError}
        {...getOverrideProps(overrides, "accNumber")}
      ></TextField>
      <TextField
        label="Alternate Name"
        isRequired={false}
        isReadOnly={false}
        value={altName2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber,
              altName2: value,
              creditorName2,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.altName2 ?? value;
          }
          if (errors.altName2?.hasError) {
            runValidationTasks("altName2", value);
          }
          setAltName2(value);
        }}
        onBlur={() => runValidationTasks("altName2", altName2)}
        errorMessage={errors.altName2?.errorMessage}
        hasError={errors.altName2?.hasError}
        {...getOverrideProps(overrides, "altName2")}
      ></TextField>
      <TextField
        label="Creditor Name"
        isRequired={false}
        isReadOnly={false}
        value={creditorName2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber,
              altName2,
              creditorName2: value,
              accNumber2,
            };
            const result = onChange(modelFields);
            value = result?.creditorName2 ?? value;
          }
          if (errors.creditorName2?.hasError) {
            runValidationTasks("creditorName2", value);
          }
          setCreditorName2(value);
        }}
        onBlur={() => runValidationTasks("creditorName2", creditorName2)}
        errorMessage={errors.creditorName2?.errorMessage}
        hasError={errors.creditorName2?.hasError}
        {...getOverrideProps(overrides, "creditorName2")}
      ></TextField>
      <TextField
        label="Account Number"
        isRequired={false}
        isReadOnly={false}
        value={accNumber2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              propertyAddress,
              propertyStatus,
              typeOfProperty,
              presentMarketValue,
              amountOfMortgageAndLiens,
              grossRentalIncome,
              mortgagePayments,
              miscPayments,
              netRentalIncome,
              propertyAddress2,
              propertyStatus2,
              typeOfProperty2,
              presentMarketValue2,
              amountOfMortgageAndLiens2,
              grossRentalIncome2,
              mortgagePayments2,
              miscPayments2,
              netRentalIncome2,
              propertyAddress3,
              propertyStatus3,
              typeOfProperty3,
              presentMarketValue3,
              amountOfMortgageAndLiens3,
              grossRentalIncome3,
              mortgagePayments3,
              miscPayments3,
              netRentalIncome3,
              totalMarketValue,
              totalMortgages,
              totalGrossRentalIncome,
              totalMortgagePayments,
              totalMisc,
              totalNetRentalIncome,
              altName,
              creditorName,
              accNumber,
              altName2,
              creditorName2,
              accNumber2: value,
            };
            const result = onChange(modelFields);
            value = result?.accNumber2 ?? value;
          }
          if (errors.accNumber2?.hasError) {
            runValidationTasks("accNumber2", value);
          }
          setAccNumber2(value);
        }}
        onBlur={() => runValidationTasks("accNumber2", accNumber2)}
        errorMessage={errors.accNumber2?.errorMessage}
        hasError={errors.accNumber2?.hasError}
        {...getOverrideProps(overrides, "accNumber2")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Save"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
