import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Tabs, Tab, Box } from '@mui/material';
import { Authenticator } from '@aws-amplify/ui-react';
import BasicHeader from './Header';  // Import BasicHeader
import BorrowerInfo from './BorrowerInfo';
import CoborrowerInfo from './CoborrowerInfo';
import LoanApplication from './LoanApplication';
import PropertyInfo from './PropertyInfo';
import EmploymentInfo from './EmploymentInfo';
import IncomeExpense from './IncomeExpense';
import CoborrowerEmploymentInfo from './CoborrowerEmploymentInfo';
import AssetsInfo from './AssetsInfo';
import LiabilitiesInfo from './LiabilitiesInfo';
import RealEstateInfo from './RealEstate';
import TransactionsInfo from './Transactions';
import DeclarationsInfo from './Declarations';
import RaceInfo from './RaceInfo';
import FileUploads from './FileUploads';
// import ProfileHeader from './ProfileHeader';

const tabComponents = [
  { label: "Upload Supporting Documents", Component: FileUploads },
  { label: "Borrower Information", Component: BorrowerInfo },
  { label: "Co-Borrower Information", Component: CoborrowerInfo }, //isCoborrowerTab: true
  { label: "Loan Application", Component: LoanApplication },
  { label: "Property Information", Component: PropertyInfo },
  { label: "Employment Information", Component: EmploymentInfo },
  { label: "Income and Expense Information", Component: IncomeExpense },
  { label: "Co-Borrower Employment Information", Component: CoborrowerEmploymentInfo }, //isCoborrowerTab: true
  { label: "Assets Information", Component: AssetsInfo },
  { label: "Liabilities Information", Component: LiabilitiesInfo },
  { label: "Real Estate Information", Component: RealEstateInfo },
  { label: "Transactions Information", Component: TransactionsInfo },
  { label: "Declarations Information", Component: DeclarationsInfo },
  { label: "Race Information", Component: RaceInfo },
];

const theme = createTheme({
  palette: {
    primary: { main: '#5e35b1' },
    secondary: { main: '#d81b60' },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: '#21b6ae',
          },
        },
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function VerticalTabs() {
  const [value, setValue] = useState(0);
  const [hasCoborrower, setHasCoborrower] = useState(true); // State to control the visibility of the Co-Borrower Information tab

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Filter the tabComponents based on hasCoborrower state
  const filteredTabComponents = tabComponents.filter(tab => !tab.isCoborrowerTab || (tab.isCoborrowerTab && hasCoborrower));

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <>
          <BasicHeader signOut={signOut} isAuthenticated={!!user} />  {/* Ensure the header spans the entire width */}
          <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', flexGrow: 1, minHeight: 'calc(100vh - 64px)', bgcolor: 'background.default' }}>
                <Box sx={{ position: 'fixed', top: 100, left: 0, height: 'calc(100vh - 100px)', borderRight: 1, borderColor: 'divider', bgcolor: 'background.paper', width: '240px', overflowY: 'auto' }}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ height: '100%' }}
                  >
                    {filteredTabComponents.map((tab, index) => (
                      <Tab key={index} label={tab.label} id={`vertical-tab-${index}`} aria-controls={`vertical-tabpanel-${index}`} />
                    ))}
                  </Tabs>
                </Box>
                <Box sx={{ marginLeft: '240px', paddingTop: '100px', width: '100%', p: 3 }}>
                  {filteredTabComponents.map(({ Component }, index) => (
                    <TabPanel key={index} value={value} index={index}>
                      <Component />
                    </TabPanel>
                  ))}
                </Box>
              </Box>
            </Box>
          </ThemeProvider>
        </>
      )}
    </Authenticator>
  );
}
