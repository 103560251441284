import React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

// Custom BottomNavigationAction for overriding styles
const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
	color: theme.palette.common.white,
	"&.Mui-selected": {
		color: theme.palette.primary.main,
	},
}));

// Custom wrapper component for link actions to integrate with MUI and react-router-dom
const LinkBehavior = React.forwardRef((props, ref) => (
	<Link ref={ref} {...props} />
));

const Footer = () => {
	const [value, setValue] = React.useState(0);

	return (
		<BottomNavigation
			sx={{
				width: '100%',
				backgroundColor: "#4a4848",
				color: "white",
				"& .MuiBottomNavigationAction-root": {
					minWidth: "0",
					padding: "12px 16px",
				},
			}}
			value={value}
			onChange={(event, newValue) => {
				setValue(newValue);
			}}
			showLabels
		>
			<StyledBottomNavigationAction
				label="Email"
				icon={<AttachEmailOutlinedIcon />}
				component="a"
				href="mailto:gobducorp@gmail.com"
			/>
			<StyledBottomNavigationAction
				label="Phone"
				icon={<PhoneOutlinedIcon />}
				component="a"
				href="tel:+14256285854"
			/>
			<StyledBottomNavigationAction
				label="Terms & Conditions"
				component={LinkBehavior}
				to="/terms-and-conditions"
			/>
			<StyledBottomNavigationAction
				label="Privacy Policy"
				component={LinkBehavior}
				to="/privacy-policy"
			/>
			<StyledBottomNavigationAction
				label="Disclaimer"
				component={LinkBehavior}
				to="/disclaimer"
			/>
			<StyledBottomNavigationAction
				label="© 2024 Gobdu Corporation"
				component={LinkBehavior}
				to="/"
				disabled
			/>
		</BottomNavigation>
	);
};

export default Footer;
