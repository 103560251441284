/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getIncomeAndExpenseInfo } from "../graphql/queries";
import { updateIncomeAndExpenseInfo } from "../graphql/mutations";
const client = generateClient();
export default function IncomeAndExpenseInfoUpdateForm(props) {
  const {
    id: idProp,
    incomeAndExpenseInfo: incomeAndExpenseInfoModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    borrower_baseIncome: "",
    borrower_overtime: "",
    borrower_bonus: "",
    borrower_commissions: "",
    borrower_dividends: "",
    borrower_netRentalIncome: "",
    borrower_other: "",
    borrower_totalIncome: "",
    coborrower_baseIncome: "",
    coborrower_overtime: "",
    coborrower_bonus: "",
    coborrower_commissions: "",
    coborrower_dividends: "",
    coborrower_netRentalIncome: "",
    coborrower_other: "",
    coborrower_totalIncome: "",
    family_total: "",
    total_baseIncome: "",
    total_overtime: "",
    total_bonus: "",
    total_commissions: "",
    total_dividends: "",
    total_rentalIncome: "",
    total_otherIncome: "",
    grand_total_income: "",
    present_rent: "",
    present_mortgage1: "",
    present_financing: "",
    proposed_mortgage1: "",
    proposed_financing: "",
    present_hazard_insurance: "",
    proposed_hazard_insurance: "",
    present_real_estate_taxes: "",
    proposed_real_estate_taxes: "",
    present_mortgage_insurance: "",
    proposed_mortgage_insurance: "",
    present_hoa_dues: "",
    proposed_hoa_dues: "",
    present_other_expenses: "",
    proposed_other_expenses: "",
    total_combined_expenses: "",
    proposed_total_combined_expenses: "",
  };
  const [borrower_baseIncome, setBorrower_baseIncome] = React.useState(
    initialValues.borrower_baseIncome
  );
  const [borrower_overtime, setBorrower_overtime] = React.useState(
    initialValues.borrower_overtime
  );
  const [borrower_bonus, setBorrower_bonus] = React.useState(
    initialValues.borrower_bonus
  );
  const [borrower_commissions, setBorrower_commissions] = React.useState(
    initialValues.borrower_commissions
  );
  const [borrower_dividends, setBorrower_dividends] = React.useState(
    initialValues.borrower_dividends
  );
  const [borrower_netRentalIncome, setBorrower_netRentalIncome] =
    React.useState(initialValues.borrower_netRentalIncome);
  const [borrower_other, setBorrower_other] = React.useState(
    initialValues.borrower_other
  );
  const [borrower_totalIncome, setBorrower_totalIncome] = React.useState(
    initialValues.borrower_totalIncome
  );
  const [coborrower_baseIncome, setCoborrower_baseIncome] = React.useState(
    initialValues.coborrower_baseIncome
  );
  const [coborrower_overtime, setCoborrower_overtime] = React.useState(
    initialValues.coborrower_overtime
  );
  const [coborrower_bonus, setCoborrower_bonus] = React.useState(
    initialValues.coborrower_bonus
  );
  const [coborrower_commissions, setCoborrower_commissions] = React.useState(
    initialValues.coborrower_commissions
  );
  const [coborrower_dividends, setCoborrower_dividends] = React.useState(
    initialValues.coborrower_dividends
  );
  const [coborrower_netRentalIncome, setCoborrower_netRentalIncome] =
    React.useState(initialValues.coborrower_netRentalIncome);
  const [coborrower_other, setCoborrower_other] = React.useState(
    initialValues.coborrower_other
  );
  const [coborrower_totalIncome, setCoborrower_totalIncome] = React.useState(
    initialValues.coborrower_totalIncome
  );
  const [family_total, setFamily_total] = React.useState(
    initialValues.family_total
  );
  const [total_baseIncome, setTotal_baseIncome] = React.useState(
    initialValues.total_baseIncome
  );
  const [total_overtime, setTotal_overtime] = React.useState(
    initialValues.total_overtime
  );
  const [total_bonus, setTotal_bonus] = React.useState(
    initialValues.total_bonus
  );
  const [total_commissions, setTotal_commissions] = React.useState(
    initialValues.total_commissions
  );
  const [total_dividends, setTotal_dividends] = React.useState(
    initialValues.total_dividends
  );
  const [total_rentalIncome, setTotal_rentalIncome] = React.useState(
    initialValues.total_rentalIncome
  );
  const [total_otherIncome, setTotal_otherIncome] = React.useState(
    initialValues.total_otherIncome
  );
  const [grand_total_income, setGrand_total_income] = React.useState(
    initialValues.grand_total_income
  );
  const [present_rent, setPresent_rent] = React.useState(
    initialValues.present_rent
  );
  const [present_mortgage1, setPresent_mortgage1] = React.useState(
    initialValues.present_mortgage1
  );
  const [present_financing, setPresent_financing] = React.useState(
    initialValues.present_financing
  );
  const [proposed_mortgage1, setProposed_mortgage1] = React.useState(
    initialValues.proposed_mortgage1
  );
  const [proposed_financing, setProposed_financing] = React.useState(
    initialValues.proposed_financing
  );
  const [present_hazard_insurance, setPresent_hazard_insurance] =
    React.useState(initialValues.present_hazard_insurance);
  const [proposed_hazard_insurance, setProposed_hazard_insurance] =
    React.useState(initialValues.proposed_hazard_insurance);
  const [present_real_estate_taxes, setPresent_real_estate_taxes] =
    React.useState(initialValues.present_real_estate_taxes);
  const [proposed_real_estate_taxes, setProposed_real_estate_taxes] =
    React.useState(initialValues.proposed_real_estate_taxes);
  const [present_mortgage_insurance, setPresent_mortgage_insurance] =
    React.useState(initialValues.present_mortgage_insurance);
  const [proposed_mortgage_insurance, setProposed_mortgage_insurance] =
    React.useState(initialValues.proposed_mortgage_insurance);
  const [present_hoa_dues, setPresent_hoa_dues] = React.useState(
    initialValues.present_hoa_dues
  );
  const [proposed_hoa_dues, setProposed_hoa_dues] = React.useState(
    initialValues.proposed_hoa_dues
  );
  const [present_other_expenses, setPresent_other_expenses] = React.useState(
    initialValues.present_other_expenses
  );
  const [proposed_other_expenses, setProposed_other_expenses] = React.useState(
    initialValues.proposed_other_expenses
  );
  const [total_combined_expenses, setTotal_combined_expenses] = React.useState(
    initialValues.total_combined_expenses
  );
  const [
    proposed_total_combined_expenses,
    setProposed_total_combined_expenses,
  ] = React.useState(initialValues.proposed_total_combined_expenses);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = incomeAndExpenseInfoRecord
      ? { ...initialValues, ...incomeAndExpenseInfoRecord }
      : initialValues;
    setBorrower_baseIncome(cleanValues.borrower_baseIncome);
    setBorrower_overtime(cleanValues.borrower_overtime);
    setBorrower_bonus(cleanValues.borrower_bonus);
    setBorrower_commissions(cleanValues.borrower_commissions);
    setBorrower_dividends(cleanValues.borrower_dividends);
    setBorrower_netRentalIncome(cleanValues.borrower_netRentalIncome);
    setBorrower_other(cleanValues.borrower_other);
    setBorrower_totalIncome(cleanValues.borrower_totalIncome);
    setCoborrower_baseIncome(cleanValues.coborrower_baseIncome);
    setCoborrower_overtime(cleanValues.coborrower_overtime);
    setCoborrower_bonus(cleanValues.coborrower_bonus);
    setCoborrower_commissions(cleanValues.coborrower_commissions);
    setCoborrower_dividends(cleanValues.coborrower_dividends);
    setCoborrower_netRentalIncome(cleanValues.coborrower_netRentalIncome);
    setCoborrower_other(cleanValues.coborrower_other);
    setCoborrower_totalIncome(cleanValues.coborrower_totalIncome);
    setFamily_total(cleanValues.family_total);
    setTotal_baseIncome(cleanValues.total_baseIncome);
    setTotal_overtime(cleanValues.total_overtime);
    setTotal_bonus(cleanValues.total_bonus);
    setTotal_commissions(cleanValues.total_commissions);
    setTotal_dividends(cleanValues.total_dividends);
    setTotal_rentalIncome(cleanValues.total_rentalIncome);
    setTotal_otherIncome(cleanValues.total_otherIncome);
    setGrand_total_income(cleanValues.grand_total_income);
    setPresent_rent(cleanValues.present_rent);
    setPresent_mortgage1(cleanValues.present_mortgage1);
    setPresent_financing(cleanValues.present_financing);
    setProposed_mortgage1(cleanValues.proposed_mortgage1);
    setProposed_financing(cleanValues.proposed_financing);
    setPresent_hazard_insurance(cleanValues.present_hazard_insurance);
    setProposed_hazard_insurance(cleanValues.proposed_hazard_insurance);
    setPresent_real_estate_taxes(cleanValues.present_real_estate_taxes);
    setProposed_real_estate_taxes(cleanValues.proposed_real_estate_taxes);
    setPresent_mortgage_insurance(cleanValues.present_mortgage_insurance);
    setProposed_mortgage_insurance(cleanValues.proposed_mortgage_insurance);
    setPresent_hoa_dues(cleanValues.present_hoa_dues);
    setProposed_hoa_dues(cleanValues.proposed_hoa_dues);
    setPresent_other_expenses(cleanValues.present_other_expenses);
    setProposed_other_expenses(cleanValues.proposed_other_expenses);
    setTotal_combined_expenses(cleanValues.total_combined_expenses);
    setProposed_total_combined_expenses(
      cleanValues.proposed_total_combined_expenses
    );
    setErrors({});
  };
  const [incomeAndExpenseInfoRecord, setIncomeAndExpenseInfoRecord] =
    React.useState(incomeAndExpenseInfoModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getIncomeAndExpenseInfo.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getIncomeAndExpenseInfo
        : incomeAndExpenseInfoModelProp;
      setIncomeAndExpenseInfoRecord(record);
    };
    queryData();
  }, [idProp, incomeAndExpenseInfoModelProp]);
  React.useEffect(resetStateValues, [incomeAndExpenseInfoRecord]);
  const validations = {
    borrower_baseIncome: [],
    borrower_overtime: [],
    borrower_bonus: [],
    borrower_commissions: [],
    borrower_dividends: [],
    borrower_netRentalIncome: [],
    borrower_other: [],
    borrower_totalIncome: [],
    coborrower_baseIncome: [],
    coborrower_overtime: [],
    coborrower_bonus: [],
    coborrower_commissions: [],
    coborrower_dividends: [],
    coborrower_netRentalIncome: [],
    coborrower_other: [],
    coborrower_totalIncome: [],
    family_total: [],
    total_baseIncome: [],
    total_overtime: [],
    total_bonus: [],
    total_commissions: [],
    total_dividends: [],
    total_rentalIncome: [],
    total_otherIncome: [],
    grand_total_income: [],
    present_rent: [],
    present_mortgage1: [],
    present_financing: [],
    proposed_mortgage1: [],
    proposed_financing: [],
    present_hazard_insurance: [],
    proposed_hazard_insurance: [],
    present_real_estate_taxes: [],
    proposed_real_estate_taxes: [],
    present_mortgage_insurance: [],
    proposed_mortgage_insurance: [],
    present_hoa_dues: [],
    proposed_hoa_dues: [],
    present_other_expenses: [],
    proposed_other_expenses: [],
    total_combined_expenses: [],
    proposed_total_combined_expenses: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          borrower_baseIncome: borrower_baseIncome ?? null,
          borrower_overtime: borrower_overtime ?? null,
          borrower_bonus: borrower_bonus ?? null,
          borrower_commissions: borrower_commissions ?? null,
          borrower_dividends: borrower_dividends ?? null,
          borrower_netRentalIncome: borrower_netRentalIncome ?? null,
          borrower_other: borrower_other ?? null,
          borrower_totalIncome: borrower_totalIncome ?? null,
          coborrower_baseIncome: coborrower_baseIncome ?? null,
          coborrower_overtime: coborrower_overtime ?? null,
          coborrower_bonus: coborrower_bonus ?? null,
          coborrower_commissions: coborrower_commissions ?? null,
          coborrower_dividends: coborrower_dividends ?? null,
          coborrower_netRentalIncome: coborrower_netRentalIncome ?? null,
          coborrower_other: coborrower_other ?? null,
          coborrower_totalIncome: coborrower_totalIncome ?? null,
          family_total: family_total ?? null,
          total_baseIncome: total_baseIncome ?? null,
          total_overtime: total_overtime ?? null,
          total_bonus: total_bonus ?? null,
          total_commissions: total_commissions ?? null,
          total_dividends: total_dividends ?? null,
          total_rentalIncome: total_rentalIncome ?? null,
          total_otherIncome: total_otherIncome ?? null,
          grand_total_income: grand_total_income ?? null,
          present_rent: present_rent ?? null,
          present_mortgage1: present_mortgage1 ?? null,
          present_financing: present_financing ?? null,
          proposed_mortgage1: proposed_mortgage1 ?? null,
          proposed_financing: proposed_financing ?? null,
          present_hazard_insurance: present_hazard_insurance ?? null,
          proposed_hazard_insurance: proposed_hazard_insurance ?? null,
          present_real_estate_taxes: present_real_estate_taxes ?? null,
          proposed_real_estate_taxes: proposed_real_estate_taxes ?? null,
          present_mortgage_insurance: present_mortgage_insurance ?? null,
          proposed_mortgage_insurance: proposed_mortgage_insurance ?? null,
          present_hoa_dues: present_hoa_dues ?? null,
          proposed_hoa_dues: proposed_hoa_dues ?? null,
          present_other_expenses: present_other_expenses ?? null,
          proposed_other_expenses: proposed_other_expenses ?? null,
          total_combined_expenses: total_combined_expenses ?? null,
          proposed_total_combined_expenses:
            proposed_total_combined_expenses ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateIncomeAndExpenseInfo.replaceAll("__typename", ""),
            variables: {
              input: {
                id: incomeAndExpenseInfoRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "IncomeAndExpenseInfoUpdateForm")}
      {...rest}
    >
      <TextField
        label="Borrower base income"
        isRequired={false}
        isReadOnly={false}
        value={borrower_baseIncome}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome: value,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.borrower_baseIncome ?? value;
          }
          if (errors.borrower_baseIncome?.hasError) {
            runValidationTasks("borrower_baseIncome", value);
          }
          setBorrower_baseIncome(value);
        }}
        onBlur={() =>
          runValidationTasks("borrower_baseIncome", borrower_baseIncome)
        }
        errorMessage={errors.borrower_baseIncome?.errorMessage}
        hasError={errors.borrower_baseIncome?.hasError}
        {...getOverrideProps(overrides, "borrower_baseIncome")}
      ></TextField>
      <TextField
        label="Borrower overtime"
        isRequired={false}
        isReadOnly={false}
        value={borrower_overtime}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime: value,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.borrower_overtime ?? value;
          }
          if (errors.borrower_overtime?.hasError) {
            runValidationTasks("borrower_overtime", value);
          }
          setBorrower_overtime(value);
        }}
        onBlur={() =>
          runValidationTasks("borrower_overtime", borrower_overtime)
        }
        errorMessage={errors.borrower_overtime?.errorMessage}
        hasError={errors.borrower_overtime?.hasError}
        {...getOverrideProps(overrides, "borrower_overtime")}
      ></TextField>
      <TextField
        label="Borrower bonus"
        isRequired={false}
        isReadOnly={false}
        value={borrower_bonus}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus: value,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.borrower_bonus ?? value;
          }
          if (errors.borrower_bonus?.hasError) {
            runValidationTasks("borrower_bonus", value);
          }
          setBorrower_bonus(value);
        }}
        onBlur={() => runValidationTasks("borrower_bonus", borrower_bonus)}
        errorMessage={errors.borrower_bonus?.errorMessage}
        hasError={errors.borrower_bonus?.hasError}
        {...getOverrideProps(overrides, "borrower_bonus")}
      ></TextField>
      <TextField
        label="Borrower commissions"
        isRequired={false}
        isReadOnly={false}
        value={borrower_commissions}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions: value,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.borrower_commissions ?? value;
          }
          if (errors.borrower_commissions?.hasError) {
            runValidationTasks("borrower_commissions", value);
          }
          setBorrower_commissions(value);
        }}
        onBlur={() =>
          runValidationTasks("borrower_commissions", borrower_commissions)
        }
        errorMessage={errors.borrower_commissions?.errorMessage}
        hasError={errors.borrower_commissions?.hasError}
        {...getOverrideProps(overrides, "borrower_commissions")}
      ></TextField>
      <TextField
        label="Borrower dividends"
        isRequired={false}
        isReadOnly={false}
        value={borrower_dividends}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends: value,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.borrower_dividends ?? value;
          }
          if (errors.borrower_dividends?.hasError) {
            runValidationTasks("borrower_dividends", value);
          }
          setBorrower_dividends(value);
        }}
        onBlur={() =>
          runValidationTasks("borrower_dividends", borrower_dividends)
        }
        errorMessage={errors.borrower_dividends?.errorMessage}
        hasError={errors.borrower_dividends?.hasError}
        {...getOverrideProps(overrides, "borrower_dividends")}
      ></TextField>
      <TextField
        label="Borrower net rental income"
        isRequired={false}
        isReadOnly={false}
        value={borrower_netRentalIncome}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome: value,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.borrower_netRentalIncome ?? value;
          }
          if (errors.borrower_netRentalIncome?.hasError) {
            runValidationTasks("borrower_netRentalIncome", value);
          }
          setBorrower_netRentalIncome(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "borrower_netRentalIncome",
            borrower_netRentalIncome
          )
        }
        errorMessage={errors.borrower_netRentalIncome?.errorMessage}
        hasError={errors.borrower_netRentalIncome?.hasError}
        {...getOverrideProps(overrides, "borrower_netRentalIncome")}
      ></TextField>
      <TextField
        label="Borrower other"
        isRequired={false}
        isReadOnly={false}
        value={borrower_other}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other: value,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.borrower_other ?? value;
          }
          if (errors.borrower_other?.hasError) {
            runValidationTasks("borrower_other", value);
          }
          setBorrower_other(value);
        }}
        onBlur={() => runValidationTasks("borrower_other", borrower_other)}
        errorMessage={errors.borrower_other?.errorMessage}
        hasError={errors.borrower_other?.hasError}
        {...getOverrideProps(overrides, "borrower_other")}
      ></TextField>
      <TextField
        label="Borrower total income"
        isRequired={false}
        isReadOnly={false}
        value={borrower_totalIncome}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome: value,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.borrower_totalIncome ?? value;
          }
          if (errors.borrower_totalIncome?.hasError) {
            runValidationTasks("borrower_totalIncome", value);
          }
          setBorrower_totalIncome(value);
        }}
        onBlur={() =>
          runValidationTasks("borrower_totalIncome", borrower_totalIncome)
        }
        errorMessage={errors.borrower_totalIncome?.errorMessage}
        hasError={errors.borrower_totalIncome?.hasError}
        {...getOverrideProps(overrides, "borrower_totalIncome")}
      ></TextField>
      <TextField
        label="Coborrower base income"
        isRequired={false}
        isReadOnly={false}
        value={coborrower_baseIncome}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome: value,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.coborrower_baseIncome ?? value;
          }
          if (errors.coborrower_baseIncome?.hasError) {
            runValidationTasks("coborrower_baseIncome", value);
          }
          setCoborrower_baseIncome(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrower_baseIncome", coborrower_baseIncome)
        }
        errorMessage={errors.coborrower_baseIncome?.errorMessage}
        hasError={errors.coborrower_baseIncome?.hasError}
        {...getOverrideProps(overrides, "coborrower_baseIncome")}
      ></TextField>
      <TextField
        label="Coborrower overtime"
        isRequired={false}
        isReadOnly={false}
        value={coborrower_overtime}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime: value,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.coborrower_overtime ?? value;
          }
          if (errors.coborrower_overtime?.hasError) {
            runValidationTasks("coborrower_overtime", value);
          }
          setCoborrower_overtime(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrower_overtime", coborrower_overtime)
        }
        errorMessage={errors.coborrower_overtime?.errorMessage}
        hasError={errors.coborrower_overtime?.hasError}
        {...getOverrideProps(overrides, "coborrower_overtime")}
      ></TextField>
      <TextField
        label="Coborrower bonus"
        isRequired={false}
        isReadOnly={false}
        value={coborrower_bonus}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus: value,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.coborrower_bonus ?? value;
          }
          if (errors.coborrower_bonus?.hasError) {
            runValidationTasks("coborrower_bonus", value);
          }
          setCoborrower_bonus(value);
        }}
        onBlur={() => runValidationTasks("coborrower_bonus", coborrower_bonus)}
        errorMessage={errors.coborrower_bonus?.errorMessage}
        hasError={errors.coborrower_bonus?.hasError}
        {...getOverrideProps(overrides, "coborrower_bonus")}
      ></TextField>
      <TextField
        label="Coborrower commissions"
        isRequired={false}
        isReadOnly={false}
        value={coborrower_commissions}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions: value,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.coborrower_commissions ?? value;
          }
          if (errors.coborrower_commissions?.hasError) {
            runValidationTasks("coborrower_commissions", value);
          }
          setCoborrower_commissions(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrower_commissions", coborrower_commissions)
        }
        errorMessage={errors.coborrower_commissions?.errorMessage}
        hasError={errors.coborrower_commissions?.hasError}
        {...getOverrideProps(overrides, "coborrower_commissions")}
      ></TextField>
      <TextField
        label="Coborrower dividends"
        isRequired={false}
        isReadOnly={false}
        value={coborrower_dividends}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends: value,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.coborrower_dividends ?? value;
          }
          if (errors.coborrower_dividends?.hasError) {
            runValidationTasks("coborrower_dividends", value);
          }
          setCoborrower_dividends(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrower_dividends", coborrower_dividends)
        }
        errorMessage={errors.coborrower_dividends?.errorMessage}
        hasError={errors.coborrower_dividends?.hasError}
        {...getOverrideProps(overrides, "coborrower_dividends")}
      ></TextField>
      <TextField
        label="Coborrower net rental income"
        isRequired={false}
        isReadOnly={false}
        value={coborrower_netRentalIncome}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome: value,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.coborrower_netRentalIncome ?? value;
          }
          if (errors.coborrower_netRentalIncome?.hasError) {
            runValidationTasks("coborrower_netRentalIncome", value);
          }
          setCoborrower_netRentalIncome(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "coborrower_netRentalIncome",
            coborrower_netRentalIncome
          )
        }
        errorMessage={errors.coborrower_netRentalIncome?.errorMessage}
        hasError={errors.coborrower_netRentalIncome?.hasError}
        {...getOverrideProps(overrides, "coborrower_netRentalIncome")}
      ></TextField>
      <TextField
        label="Coborrower other"
        isRequired={false}
        isReadOnly={false}
        value={coborrower_other}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other: value,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.coborrower_other ?? value;
          }
          if (errors.coborrower_other?.hasError) {
            runValidationTasks("coborrower_other", value);
          }
          setCoborrower_other(value);
        }}
        onBlur={() => runValidationTasks("coborrower_other", coborrower_other)}
        errorMessage={errors.coborrower_other?.errorMessage}
        hasError={errors.coborrower_other?.hasError}
        {...getOverrideProps(overrides, "coborrower_other")}
      ></TextField>
      <TextField
        label="Coborrower total income"
        isRequired={false}
        isReadOnly={false}
        value={coborrower_totalIncome}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome: value,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.coborrower_totalIncome ?? value;
          }
          if (errors.coborrower_totalIncome?.hasError) {
            runValidationTasks("coborrower_totalIncome", value);
          }
          setCoborrower_totalIncome(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrower_totalIncome", coborrower_totalIncome)
        }
        errorMessage={errors.coborrower_totalIncome?.errorMessage}
        hasError={errors.coborrower_totalIncome?.hasError}
        {...getOverrideProps(overrides, "coborrower_totalIncome")}
      ></TextField>
      <TextField
        label="Family total"
        isRequired={false}
        isReadOnly={false}
        value={family_total}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total: value,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.family_total ?? value;
          }
          if (errors.family_total?.hasError) {
            runValidationTasks("family_total", value);
          }
          setFamily_total(value);
        }}
        onBlur={() => runValidationTasks("family_total", family_total)}
        errorMessage={errors.family_total?.errorMessage}
        hasError={errors.family_total?.hasError}
        {...getOverrideProps(overrides, "family_total")}
      ></TextField>
      <TextField
        label="Total base income"
        isRequired={false}
        isReadOnly={false}
        value={total_baseIncome}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome: value,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.total_baseIncome ?? value;
          }
          if (errors.total_baseIncome?.hasError) {
            runValidationTasks("total_baseIncome", value);
          }
          setTotal_baseIncome(value);
        }}
        onBlur={() => runValidationTasks("total_baseIncome", total_baseIncome)}
        errorMessage={errors.total_baseIncome?.errorMessage}
        hasError={errors.total_baseIncome?.hasError}
        {...getOverrideProps(overrides, "total_baseIncome")}
      ></TextField>
      <TextField
        label="Total overtime"
        isRequired={false}
        isReadOnly={false}
        value={total_overtime}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime: value,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.total_overtime ?? value;
          }
          if (errors.total_overtime?.hasError) {
            runValidationTasks("total_overtime", value);
          }
          setTotal_overtime(value);
        }}
        onBlur={() => runValidationTasks("total_overtime", total_overtime)}
        errorMessage={errors.total_overtime?.errorMessage}
        hasError={errors.total_overtime?.hasError}
        {...getOverrideProps(overrides, "total_overtime")}
      ></TextField>
      <TextField
        label="Total bonus"
        isRequired={false}
        isReadOnly={false}
        value={total_bonus}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus: value,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.total_bonus ?? value;
          }
          if (errors.total_bonus?.hasError) {
            runValidationTasks("total_bonus", value);
          }
          setTotal_bonus(value);
        }}
        onBlur={() => runValidationTasks("total_bonus", total_bonus)}
        errorMessage={errors.total_bonus?.errorMessage}
        hasError={errors.total_bonus?.hasError}
        {...getOverrideProps(overrides, "total_bonus")}
      ></TextField>
      <TextField
        label="Total commissions"
        isRequired={false}
        isReadOnly={false}
        value={total_commissions}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions: value,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.total_commissions ?? value;
          }
          if (errors.total_commissions?.hasError) {
            runValidationTasks("total_commissions", value);
          }
          setTotal_commissions(value);
        }}
        onBlur={() =>
          runValidationTasks("total_commissions", total_commissions)
        }
        errorMessage={errors.total_commissions?.errorMessage}
        hasError={errors.total_commissions?.hasError}
        {...getOverrideProps(overrides, "total_commissions")}
      ></TextField>
      <TextField
        label="Total dividends"
        isRequired={false}
        isReadOnly={false}
        value={total_dividends}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends: value,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.total_dividends ?? value;
          }
          if (errors.total_dividends?.hasError) {
            runValidationTasks("total_dividends", value);
          }
          setTotal_dividends(value);
        }}
        onBlur={() => runValidationTasks("total_dividends", total_dividends)}
        errorMessage={errors.total_dividends?.errorMessage}
        hasError={errors.total_dividends?.hasError}
        {...getOverrideProps(overrides, "total_dividends")}
      ></TextField>
      <TextField
        label="Total rental income"
        isRequired={false}
        isReadOnly={false}
        value={total_rentalIncome}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome: value,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.total_rentalIncome ?? value;
          }
          if (errors.total_rentalIncome?.hasError) {
            runValidationTasks("total_rentalIncome", value);
          }
          setTotal_rentalIncome(value);
        }}
        onBlur={() =>
          runValidationTasks("total_rentalIncome", total_rentalIncome)
        }
        errorMessage={errors.total_rentalIncome?.errorMessage}
        hasError={errors.total_rentalIncome?.hasError}
        {...getOverrideProps(overrides, "total_rentalIncome")}
      ></TextField>
      <TextField
        label="Total other income"
        isRequired={false}
        isReadOnly={false}
        value={total_otherIncome}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome: value,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.total_otherIncome ?? value;
          }
          if (errors.total_otherIncome?.hasError) {
            runValidationTasks("total_otherIncome", value);
          }
          setTotal_otherIncome(value);
        }}
        onBlur={() =>
          runValidationTasks("total_otherIncome", total_otherIncome)
        }
        errorMessage={errors.total_otherIncome?.errorMessage}
        hasError={errors.total_otherIncome?.hasError}
        {...getOverrideProps(overrides, "total_otherIncome")}
      ></TextField>
      <TextField
        label="Grand total income"
        isRequired={false}
        isReadOnly={false}
        value={grand_total_income}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income: value,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.grand_total_income ?? value;
          }
          if (errors.grand_total_income?.hasError) {
            runValidationTasks("grand_total_income", value);
          }
          setGrand_total_income(value);
        }}
        onBlur={() =>
          runValidationTasks("grand_total_income", grand_total_income)
        }
        errorMessage={errors.grand_total_income?.errorMessage}
        hasError={errors.grand_total_income?.hasError}
        {...getOverrideProps(overrides, "grand_total_income")}
      ></TextField>
      <TextField
        label="Present rent"
        isRequired={false}
        isReadOnly={false}
        value={present_rent}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent: value,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.present_rent ?? value;
          }
          if (errors.present_rent?.hasError) {
            runValidationTasks("present_rent", value);
          }
          setPresent_rent(value);
        }}
        onBlur={() => runValidationTasks("present_rent", present_rent)}
        errorMessage={errors.present_rent?.errorMessage}
        hasError={errors.present_rent?.hasError}
        {...getOverrideProps(overrides, "present_rent")}
      ></TextField>
      <TextField
        label="Present mortgage1"
        isRequired={false}
        isReadOnly={false}
        value={present_mortgage1}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1: value,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.present_mortgage1 ?? value;
          }
          if (errors.present_mortgage1?.hasError) {
            runValidationTasks("present_mortgage1", value);
          }
          setPresent_mortgage1(value);
        }}
        onBlur={() =>
          runValidationTasks("present_mortgage1", present_mortgage1)
        }
        errorMessage={errors.present_mortgage1?.errorMessage}
        hasError={errors.present_mortgage1?.hasError}
        {...getOverrideProps(overrides, "present_mortgage1")}
      ></TextField>
      <TextField
        label="Present financing"
        isRequired={false}
        isReadOnly={false}
        value={present_financing}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing: value,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.present_financing ?? value;
          }
          if (errors.present_financing?.hasError) {
            runValidationTasks("present_financing", value);
          }
          setPresent_financing(value);
        }}
        onBlur={() =>
          runValidationTasks("present_financing", present_financing)
        }
        errorMessage={errors.present_financing?.errorMessage}
        hasError={errors.present_financing?.hasError}
        {...getOverrideProps(overrides, "present_financing")}
      ></TextField>
      <TextField
        label="Proposed mortgage1"
        isRequired={false}
        isReadOnly={false}
        value={proposed_mortgage1}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1: value,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.proposed_mortgage1 ?? value;
          }
          if (errors.proposed_mortgage1?.hasError) {
            runValidationTasks("proposed_mortgage1", value);
          }
          setProposed_mortgage1(value);
        }}
        onBlur={() =>
          runValidationTasks("proposed_mortgage1", proposed_mortgage1)
        }
        errorMessage={errors.proposed_mortgage1?.errorMessage}
        hasError={errors.proposed_mortgage1?.hasError}
        {...getOverrideProps(overrides, "proposed_mortgage1")}
      ></TextField>
      <TextField
        label="Proposed financing"
        isRequired={false}
        isReadOnly={false}
        value={proposed_financing}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing: value,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.proposed_financing ?? value;
          }
          if (errors.proposed_financing?.hasError) {
            runValidationTasks("proposed_financing", value);
          }
          setProposed_financing(value);
        }}
        onBlur={() =>
          runValidationTasks("proposed_financing", proposed_financing)
        }
        errorMessage={errors.proposed_financing?.errorMessage}
        hasError={errors.proposed_financing?.hasError}
        {...getOverrideProps(overrides, "proposed_financing")}
      ></TextField>
      <TextField
        label="Present hazard insurance"
        isRequired={false}
        isReadOnly={false}
        value={present_hazard_insurance}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance: value,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.present_hazard_insurance ?? value;
          }
          if (errors.present_hazard_insurance?.hasError) {
            runValidationTasks("present_hazard_insurance", value);
          }
          setPresent_hazard_insurance(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "present_hazard_insurance",
            present_hazard_insurance
          )
        }
        errorMessage={errors.present_hazard_insurance?.errorMessage}
        hasError={errors.present_hazard_insurance?.hasError}
        {...getOverrideProps(overrides, "present_hazard_insurance")}
      ></TextField>
      <TextField
        label="Proposed hazard insurance"
        isRequired={false}
        isReadOnly={false}
        value={proposed_hazard_insurance}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance: value,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.proposed_hazard_insurance ?? value;
          }
          if (errors.proposed_hazard_insurance?.hasError) {
            runValidationTasks("proposed_hazard_insurance", value);
          }
          setProposed_hazard_insurance(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "proposed_hazard_insurance",
            proposed_hazard_insurance
          )
        }
        errorMessage={errors.proposed_hazard_insurance?.errorMessage}
        hasError={errors.proposed_hazard_insurance?.hasError}
        {...getOverrideProps(overrides, "proposed_hazard_insurance")}
      ></TextField>
      <TextField
        label="Present real estate taxes"
        isRequired={false}
        isReadOnly={false}
        value={present_real_estate_taxes}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes: value,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.present_real_estate_taxes ?? value;
          }
          if (errors.present_real_estate_taxes?.hasError) {
            runValidationTasks("present_real_estate_taxes", value);
          }
          setPresent_real_estate_taxes(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "present_real_estate_taxes",
            present_real_estate_taxes
          )
        }
        errorMessage={errors.present_real_estate_taxes?.errorMessage}
        hasError={errors.present_real_estate_taxes?.hasError}
        {...getOverrideProps(overrides, "present_real_estate_taxes")}
      ></TextField>
      <TextField
        label="Proposed real estate taxes"
        isRequired={false}
        isReadOnly={false}
        value={proposed_real_estate_taxes}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes: value,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.proposed_real_estate_taxes ?? value;
          }
          if (errors.proposed_real_estate_taxes?.hasError) {
            runValidationTasks("proposed_real_estate_taxes", value);
          }
          setProposed_real_estate_taxes(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "proposed_real_estate_taxes",
            proposed_real_estate_taxes
          )
        }
        errorMessage={errors.proposed_real_estate_taxes?.errorMessage}
        hasError={errors.proposed_real_estate_taxes?.hasError}
        {...getOverrideProps(overrides, "proposed_real_estate_taxes")}
      ></TextField>
      <TextField
        label="Present mortgage insurance"
        isRequired={false}
        isReadOnly={false}
        value={present_mortgage_insurance}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance: value,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.present_mortgage_insurance ?? value;
          }
          if (errors.present_mortgage_insurance?.hasError) {
            runValidationTasks("present_mortgage_insurance", value);
          }
          setPresent_mortgage_insurance(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "present_mortgage_insurance",
            present_mortgage_insurance
          )
        }
        errorMessage={errors.present_mortgage_insurance?.errorMessage}
        hasError={errors.present_mortgage_insurance?.hasError}
        {...getOverrideProps(overrides, "present_mortgage_insurance")}
      ></TextField>
      <TextField
        label="Proposed mortgage insurance"
        isRequired={false}
        isReadOnly={false}
        value={proposed_mortgage_insurance}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance: value,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.proposed_mortgage_insurance ?? value;
          }
          if (errors.proposed_mortgage_insurance?.hasError) {
            runValidationTasks("proposed_mortgage_insurance", value);
          }
          setProposed_mortgage_insurance(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "proposed_mortgage_insurance",
            proposed_mortgage_insurance
          )
        }
        errorMessage={errors.proposed_mortgage_insurance?.errorMessage}
        hasError={errors.proposed_mortgage_insurance?.hasError}
        {...getOverrideProps(overrides, "proposed_mortgage_insurance")}
      ></TextField>
      <TextField
        label="Present hoa dues"
        isRequired={false}
        isReadOnly={false}
        value={present_hoa_dues}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues: value,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.present_hoa_dues ?? value;
          }
          if (errors.present_hoa_dues?.hasError) {
            runValidationTasks("present_hoa_dues", value);
          }
          setPresent_hoa_dues(value);
        }}
        onBlur={() => runValidationTasks("present_hoa_dues", present_hoa_dues)}
        errorMessage={errors.present_hoa_dues?.errorMessage}
        hasError={errors.present_hoa_dues?.hasError}
        {...getOverrideProps(overrides, "present_hoa_dues")}
      ></TextField>
      <TextField
        label="Proposed hoa dues"
        isRequired={false}
        isReadOnly={false}
        value={proposed_hoa_dues}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues: value,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.proposed_hoa_dues ?? value;
          }
          if (errors.proposed_hoa_dues?.hasError) {
            runValidationTasks("proposed_hoa_dues", value);
          }
          setProposed_hoa_dues(value);
        }}
        onBlur={() =>
          runValidationTasks("proposed_hoa_dues", proposed_hoa_dues)
        }
        errorMessage={errors.proposed_hoa_dues?.errorMessage}
        hasError={errors.proposed_hoa_dues?.hasError}
        {...getOverrideProps(overrides, "proposed_hoa_dues")}
      ></TextField>
      <TextField
        label="Present other expenses"
        isRequired={false}
        isReadOnly={false}
        value={present_other_expenses}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses: value,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.present_other_expenses ?? value;
          }
          if (errors.present_other_expenses?.hasError) {
            runValidationTasks("present_other_expenses", value);
          }
          setPresent_other_expenses(value);
        }}
        onBlur={() =>
          runValidationTasks("present_other_expenses", present_other_expenses)
        }
        errorMessage={errors.present_other_expenses?.errorMessage}
        hasError={errors.present_other_expenses?.hasError}
        {...getOverrideProps(overrides, "present_other_expenses")}
      ></TextField>
      <TextField
        label="Proposed other expenses"
        isRequired={false}
        isReadOnly={false}
        value={proposed_other_expenses}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses: value,
              total_combined_expenses,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.proposed_other_expenses ?? value;
          }
          if (errors.proposed_other_expenses?.hasError) {
            runValidationTasks("proposed_other_expenses", value);
          }
          setProposed_other_expenses(value);
        }}
        onBlur={() =>
          runValidationTasks("proposed_other_expenses", proposed_other_expenses)
        }
        errorMessage={errors.proposed_other_expenses?.errorMessage}
        hasError={errors.proposed_other_expenses?.hasError}
        {...getOverrideProps(overrides, "proposed_other_expenses")}
      ></TextField>
      <TextField
        label="Total combined expenses"
        isRequired={false}
        isReadOnly={false}
        value={total_combined_expenses}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses: value,
              proposed_total_combined_expenses,
            };
            const result = onChange(modelFields);
            value = result?.total_combined_expenses ?? value;
          }
          if (errors.total_combined_expenses?.hasError) {
            runValidationTasks("total_combined_expenses", value);
          }
          setTotal_combined_expenses(value);
        }}
        onBlur={() =>
          runValidationTasks("total_combined_expenses", total_combined_expenses)
        }
        errorMessage={errors.total_combined_expenses?.errorMessage}
        hasError={errors.total_combined_expenses?.hasError}
        {...getOverrideProps(overrides, "total_combined_expenses")}
      ></TextField>
      <TextField
        label="Proposed total combined expenses"
        isRequired={false}
        isReadOnly={false}
        value={proposed_total_combined_expenses}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrower_baseIncome,
              borrower_overtime,
              borrower_bonus,
              borrower_commissions,
              borrower_dividends,
              borrower_netRentalIncome,
              borrower_other,
              borrower_totalIncome,
              coborrower_baseIncome,
              coborrower_overtime,
              coborrower_bonus,
              coborrower_commissions,
              coborrower_dividends,
              coborrower_netRentalIncome,
              coborrower_other,
              coborrower_totalIncome,
              family_total,
              total_baseIncome,
              total_overtime,
              total_bonus,
              total_commissions,
              total_dividends,
              total_rentalIncome,
              total_otherIncome,
              grand_total_income,
              present_rent,
              present_mortgage1,
              present_financing,
              proposed_mortgage1,
              proposed_financing,
              present_hazard_insurance,
              proposed_hazard_insurance,
              present_real_estate_taxes,
              proposed_real_estate_taxes,
              present_mortgage_insurance,
              proposed_mortgage_insurance,
              present_hoa_dues,
              proposed_hoa_dues,
              present_other_expenses,
              proposed_other_expenses,
              total_combined_expenses,
              proposed_total_combined_expenses: value,
            };
            const result = onChange(modelFields);
            value = result?.proposed_total_combined_expenses ?? value;
          }
          if (errors.proposed_total_combined_expenses?.hasError) {
            runValidationTasks("proposed_total_combined_expenses", value);
          }
          setProposed_total_combined_expenses(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "proposed_total_combined_expenses",
            proposed_total_combined_expenses
          )
        }
        errorMessage={errors.proposed_total_combined_expenses?.errorMessage}
        hasError={errors.proposed_total_combined_expenses?.hasError}
        {...getOverrideProps(overrides, "proposed_total_combined_expenses")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || incomeAndExpenseInfoModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || incomeAndExpenseInfoModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
