import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MortgageRates = ({ data }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Current Mortgage Rates',
      },
    },
  };

  const chartData = {
    labels: data.dates,
    datasets: [
      {
        label: '30-year fixed',
        data: data['30-year fixed'],
        borderColor: 'green',
        backgroundColor: 'rgba(0, 255, 0, 0.5)',
      },
      {
        label: '15-year fixed',
        data: data['15-year fixed'],
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
      },
      {
        label: 'FHA 30-year fixed',
        data: data['FHA 30-year fixed'],
        borderColor: 'orange',
        backgroundColor: 'rgba(255, 165, 0, 0.5)',
      },
      {
        label: 'VA 30-year fixed',
        data: data['VA 30-year fixed'],
        borderColor: 'blue',
        backgroundColor: 'rgba(0, 0, 255, 0.5)',
      },
    ],
  };

  return <Line options={options} data={chartData} />;
};

export default MortgageRates;
