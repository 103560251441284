import { React } from 'react';
import './App.css';
import AboutGobdu from './AboutGobdu';
import {  Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';


Amplify.configure(awsExports);

function App() {
  return (
    <div 
    className="App">
      <AboutGobdu />
    </div>
  );
}
export default App;