/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  CheckboxField,
  Divider,
  Flex,
  Grid,
  Heading,
  Radio,
  RadioGroupField,
  SelectField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createRaceInfo } from "../graphql/mutations";
const client = generateClient();
export default function RaceInfoCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    borrowerDoNotWish: false,
    borrowerHispanic: undefined,
    borrowerEthnicity: "",
    borrowerSex: "",
    coborrowerDoNotWish: false,
    coborrowerHispanic: undefined,
    coborrowerEthnicity: "",
    coborrowerSex: "",
  };
  const [borrowerDoNotWish, setBorrowerDoNotWish] = React.useState(
    initialValues.borrowerDoNotWish
  );
  const [borrowerHispanic, setBorrowerHispanic] = React.useState(
    initialValues.borrowerHispanic
  );
  const [borrowerEthnicity, setBorrowerEthnicity] = React.useState(
    initialValues.borrowerEthnicity
  );
  const [borrowerSex, setBorrowerSex] = React.useState(
    initialValues.borrowerSex
  );
  const [coborrowerDoNotWish, setCoborrowerDoNotWish] = React.useState(
    initialValues.coborrowerDoNotWish
  );
  const [coborrowerHispanic, setCoborrowerHispanic] = React.useState(
    initialValues.coborrowerHispanic
  );
  const [coborrowerEthnicity, setCoborrowerEthnicity] = React.useState(
    initialValues.coborrowerEthnicity
  );
  const [coborrowerSex, setCoborrowerSex] = React.useState(
    initialValues.coborrowerSex
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setBorrowerDoNotWish(initialValues.borrowerDoNotWish);
    setBorrowerHispanic(initialValues.borrowerHispanic);
    setBorrowerEthnicity(initialValues.borrowerEthnicity);
    setBorrowerSex(initialValues.borrowerSex);
    setCoborrowerDoNotWish(initialValues.coborrowerDoNotWish);
    setCoborrowerHispanic(initialValues.coborrowerHispanic);
    setCoborrowerEthnicity(initialValues.coborrowerEthnicity);
    setCoborrowerSex(initialValues.coborrowerSex);
    setErrors({});
  };
  const validations = {
    borrowerDoNotWish: [],
    borrowerHispanic: [],
    borrowerEthnicity: [],
    borrowerSex: [],
    coborrowerDoNotWish: [],
    coborrowerHispanic: [],
    coborrowerEthnicity: [],
    coborrowerSex: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          borrowerDoNotWish,
          borrowerHispanic,
          borrowerEthnicity,
          borrowerSex,
          coborrowerDoNotWish,
          coborrowerHispanic,
          coborrowerEthnicity,
          coborrowerSex,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createRaceInfo.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "RaceInfoCreateForm")}
      {...rest}
    >
      <Heading
        children="Borrower"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Heading>
      <CheckboxField
        label="I do not wish to furnish this information"
        name="borrowerDoNotWish"
        value="borrowerDoNotWish"
        isDisabled={false}
        checked={borrowerDoNotWish}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish: value,
              borrowerHispanic,
              borrowerEthnicity,
              borrowerSex,
              coborrowerDoNotWish,
              coborrowerHispanic,
              coborrowerEthnicity,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.borrowerDoNotWish ?? value;
          }
          if (errors.borrowerDoNotWish?.hasError) {
            runValidationTasks("borrowerDoNotWish", value);
          }
          setBorrowerDoNotWish(value);
        }}
        onBlur={() =>
          runValidationTasks("borrowerDoNotWish", borrowerDoNotWish)
        }
        errorMessage={errors.borrowerDoNotWish?.errorMessage}
        hasError={errors.borrowerDoNotWish?.hasError}
        {...getOverrideProps(overrides, "borrowerDoNotWish")}
      ></CheckboxField>
      <RadioGroupField
        label="Ethnicity"
        name="borrowerHispanic"
        isReadOnly={false}
        isRequired={false}
        onChange={(e) => {
          let value = e.target.value === "true";
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic: value,
              borrowerEthnicity,
              borrowerSex,
              coborrowerDoNotWish,
              coborrowerHispanic,
              coborrowerEthnicity,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.borrowerHispanic ?? value;
          }
          if (errors.borrowerHispanic?.hasError) {
            runValidationTasks("borrowerHispanic", value);
          }
          setBorrowerHispanic(value);
        }}
        onBlur={() => runValidationTasks("borrowerHispanic", borrowerHispanic)}
        errorMessage={errors.borrowerHispanic?.errorMessage}
        hasError={errors.borrowerHispanic?.hasError}
        {...getOverrideProps(overrides, "borrowerHispanic")}
      >
        <Radio
          children="Hispanic or Latino"
          value="true"
          {...getOverrideProps(overrides, "borrowerHispanicRadio0")}
        ></Radio>
        <Radio
          children="Not Hispanic or Latino"
          value="false"
          {...getOverrideProps(overrides, "borrowerHispanicRadio1")}
        ></Radio>
      </RadioGroupField>
      <SelectField
        label="Race"
        placeholder="Please select an option"
        isDisabled={false}
        value={borrowerEthnicity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic,
              borrowerEthnicity: value,
              borrowerSex,
              coborrowerDoNotWish,
              coborrowerHispanic,
              coborrowerEthnicity,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.borrowerEthnicity ?? value;
          }
          if (errors.borrowerEthnicity?.hasError) {
            runValidationTasks("borrowerEthnicity", value);
          }
          setBorrowerEthnicity(value);
        }}
        onBlur={() =>
          runValidationTasks("borrowerEthnicity", borrowerEthnicity)
        }
        errorMessage={errors.borrowerEthnicity?.errorMessage}
        hasError={errors.borrowerEthnicity?.hasError}
        {...getOverrideProps(overrides, "borrowerEthnicity")}
      >
        <option
          children="American Indian or Alaska Native"
          value="American Indian or Alaska Native"
          {...getOverrideProps(overrides, "borrowerEthnicityoption0")}
        ></option>
        <option
          children="Native Hawaiian or Other Pacific Islander"
          value="Native Hawaiian or Other Pacific Islander"
          {...getOverrideProps(overrides, "borrowerEthnicityoption1")}
        ></option>
        <option
          children="Asian"
          value="Asian"
          {...getOverrideProps(overrides, "borrowerEthnicityoption2")}
        ></option>
        <option
          children="White"
          value="White"
          {...getOverrideProps(overrides, "borrowerEthnicityoption3")}
        ></option>
        <option
          children="Black or African American"
          value="Black or African American"
          {...getOverrideProps(overrides, "borrowerEthnicityoption4")}
        ></option>
      </SelectField>
      <SelectField
        label="Sex"
        placeholder="Please select an option"
        isDisabled={false}
        value={borrowerSex}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic,
              borrowerEthnicity,
              borrowerSex: value,
              coborrowerDoNotWish,
              coborrowerHispanic,
              coborrowerEthnicity,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.borrowerSex ?? value;
          }
          if (errors.borrowerSex?.hasError) {
            runValidationTasks("borrowerSex", value);
          }
          setBorrowerSex(value);
        }}
        onBlur={() => runValidationTasks("borrowerSex", borrowerSex)}
        errorMessage={errors.borrowerSex?.errorMessage}
        hasError={errors.borrowerSex?.hasError}
        {...getOverrideProps(overrides, "borrowerSex")}
      >
        <option
          children="Female"
          value="Female"
          {...getOverrideProps(overrides, "borrowerSexoption0")}
        ></option>
        <option
          children="Male"
          value="Male"
          {...getOverrideProps(overrides, "borrowerSexoption1")}
        ></option>
      </SelectField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Heading
        children="Co-Borrower"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <CheckboxField
        label="I do not wish to furnish this information"
        name="coborrowerDoNotWish"
        value="coborrowerDoNotWish"
        isDisabled={false}
        checked={coborrowerDoNotWish}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic,
              borrowerEthnicity,
              borrowerSex,
              coborrowerDoNotWish: value,
              coborrowerHispanic,
              coborrowerEthnicity,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerDoNotWish ?? value;
          }
          if (errors.coborrowerDoNotWish?.hasError) {
            runValidationTasks("coborrowerDoNotWish", value);
          }
          setCoborrowerDoNotWish(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerDoNotWish", coborrowerDoNotWish)
        }
        errorMessage={errors.coborrowerDoNotWish?.errorMessage}
        hasError={errors.coborrowerDoNotWish?.hasError}
        {...getOverrideProps(overrides, "coborrowerDoNotWish")}
      ></CheckboxField>
      <RadioGroupField
        label="Ethnicity"
        name="coborrowerHispanic"
        isReadOnly={false}
        isRequired={false}
        onChange={(e) => {
          let value = e.target.value === "true";
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic,
              borrowerEthnicity,
              borrowerSex,
              coborrowerDoNotWish,
              coborrowerHispanic: value,
              coborrowerEthnicity,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerHispanic ?? value;
          }
          if (errors.coborrowerHispanic?.hasError) {
            runValidationTasks("coborrowerHispanic", value);
          }
          setCoborrowerHispanic(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerHispanic", coborrowerHispanic)
        }
        errorMessage={errors.coborrowerHispanic?.errorMessage}
        hasError={errors.coborrowerHispanic?.hasError}
        {...getOverrideProps(overrides, "coborrowerHispanic")}
      >
        <Radio
          children="Hispanic or Latino"
          value="true"
          {...getOverrideProps(overrides, "coborrowerHispanicRadio0")}
        ></Radio>
        <Radio
          children="Not Hispanic or Latino"
          value="false"
          {...getOverrideProps(overrides, "coborrowerHispanicRadio1")}
        ></Radio>
      </RadioGroupField>
      <SelectField
        label="Race"
        placeholder="Please select an option"
        isDisabled={false}
        value={coborrowerEthnicity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic,
              borrowerEthnicity,
              borrowerSex,
              coborrowerDoNotWish,
              coborrowerHispanic,
              coborrowerEthnicity: value,
              coborrowerSex,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerEthnicity ?? value;
          }
          if (errors.coborrowerEthnicity?.hasError) {
            runValidationTasks("coborrowerEthnicity", value);
          }
          setCoborrowerEthnicity(value);
        }}
        onBlur={() =>
          runValidationTasks("coborrowerEthnicity", coborrowerEthnicity)
        }
        errorMessage={errors.coborrowerEthnicity?.errorMessage}
        hasError={errors.coborrowerEthnicity?.hasError}
        {...getOverrideProps(overrides, "coborrowerEthnicity")}
      >
        <option
          children="American Indian or Alaska Native"
          value="American Indian or Alaska Native"
          {...getOverrideProps(overrides, "coborrowerEthnicityoption0")}
        ></option>
        <option
          children="Asian"
          value="Asian"
          {...getOverrideProps(overrides, "coborrowerEthnicityoption1")}
        ></option>
        <option
          children="Black or African American"
          value="Black or African American"
          {...getOverrideProps(overrides, "coborrowerEthnicityoption2")}
        ></option>
        <option
          children="Native Hawaiian or Other Pacific Islander"
          value="Native Hawaiian or Other Pacific Islander"
          {...getOverrideProps(overrides, "coborrowerEthnicityoption3")}
        ></option>
        <option
          children="White"
          value="White"
          {...getOverrideProps(overrides, "coborrowerEthnicityoption4")}
        ></option>
      </SelectField>
      <SelectField
        label="Sex"
        placeholder="Please select an option"
        isDisabled={false}
        value={coborrowerSex}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerDoNotWish,
              borrowerHispanic,
              borrowerEthnicity,
              borrowerSex,
              coborrowerDoNotWish,
              coborrowerHispanic,
              coborrowerEthnicity,
              coborrowerSex: value,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerSex ?? value;
          }
          if (errors.coborrowerSex?.hasError) {
            runValidationTasks("coborrowerSex", value);
          }
          setCoborrowerSex(value);
        }}
        onBlur={() => runValidationTasks("coborrowerSex", coborrowerSex)}
        errorMessage={errors.coborrowerSex?.errorMessage}
        hasError={errors.coborrowerSex?.hasError}
        {...getOverrideProps(overrides, "coborrowerSex")}
      >
        <option
          children="Female"
          value="Female"
          {...getOverrideProps(overrides, "coborrowerSexoption0")}
        ></option>
        <option
          children="Male"
          value="Male"
          {...getOverrideProps(overrides, "coborrowerSexoption1")}
        ></option>
      </SelectField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Save"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
