/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getLoanApplication } from "../graphql/queries";
import { updateLoanApplication } from "../graphql/mutations";
const client = generateClient();
export default function LoanApplicationUpdateForm(props) {
  const {
    id: idProp,
    loanApplication: loanApplicationModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    borrowerName: "",
    coborrowerName: "",
    mortgageType: "",
    amount: "",
    interestRate: "",
    numberOfMonths: "",
    amortizationType: "",
  };
  const [borrowerName, setBorrowerName] = React.useState(
    initialValues.borrowerName
  );
  const [coborrowerName, setCoborrowerName] = React.useState(
    initialValues.coborrowerName
  );
  const [mortgageType, setMortgageType] = React.useState(
    initialValues.mortgageType
  );
  const [amount, setAmount] = React.useState(initialValues.amount);
  const [interestRate, setInterestRate] = React.useState(
    initialValues.interestRate
  );
  const [numberOfMonths, setNumberOfMonths] = React.useState(
    initialValues.numberOfMonths
  );
  const [amortizationType, setAmortizationType] = React.useState(
    initialValues.amortizationType
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = loanApplicationRecord
      ? { ...initialValues, ...loanApplicationRecord }
      : initialValues;
    setBorrowerName(cleanValues.borrowerName);
    setCoborrowerName(cleanValues.coborrowerName);
    setMortgageType(cleanValues.mortgageType);
    setAmount(cleanValues.amount);
    setInterestRate(cleanValues.interestRate);
    setNumberOfMonths(cleanValues.numberOfMonths);
    setAmortizationType(cleanValues.amortizationType);
    setErrors({});
  };
  const [loanApplicationRecord, setLoanApplicationRecord] = React.useState(
    loanApplicationModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getLoanApplication.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getLoanApplication
        : loanApplicationModelProp;
      setLoanApplicationRecord(record);
    };
    queryData();
  }, [idProp, loanApplicationModelProp]);
  React.useEffect(resetStateValues, [loanApplicationRecord]);
  const validations = {
    borrowerName: [],
    coborrowerName: [],
    mortgageType: [],
    amount: [],
    interestRate: [],
    numberOfMonths: [],
    amortizationType: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          borrowerName: borrowerName ?? null,
          coborrowerName: coborrowerName ?? null,
          mortgageType: mortgageType ?? null,
          amount: amount ?? null,
          interestRate: interestRate ?? null,
          numberOfMonths: numberOfMonths ?? null,
          amortizationType: amortizationType ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateLoanApplication.replaceAll("__typename", ""),
            variables: {
              input: {
                id: loanApplicationRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "LoanApplicationUpdateForm")}
      {...rest}
    >
      <TextField
        label="Borrower name"
        isRequired={false}
        isReadOnly={false}
        value={borrowerName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName: value,
              coborrowerName,
              mortgageType,
              amount,
              interestRate,
              numberOfMonths,
              amortizationType,
            };
            const result = onChange(modelFields);
            value = result?.borrowerName ?? value;
          }
          if (errors.borrowerName?.hasError) {
            runValidationTasks("borrowerName", value);
          }
          setBorrowerName(value);
        }}
        onBlur={() => runValidationTasks("borrowerName", borrowerName)}
        errorMessage={errors.borrowerName?.errorMessage}
        hasError={errors.borrowerName?.hasError}
        {...getOverrideProps(overrides, "borrowerName")}
      ></TextField>
      <TextField
        label="Coborrower name"
        isRequired={false}
        isReadOnly={false}
        value={coborrowerName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName,
              coborrowerName: value,
              mortgageType,
              amount,
              interestRate,
              numberOfMonths,
              amortizationType,
            };
            const result = onChange(modelFields);
            value = result?.coborrowerName ?? value;
          }
          if (errors.coborrowerName?.hasError) {
            runValidationTasks("coborrowerName", value);
          }
          setCoborrowerName(value);
        }}
        onBlur={() => runValidationTasks("coborrowerName", coborrowerName)}
        errorMessage={errors.coborrowerName?.errorMessage}
        hasError={errors.coborrowerName?.hasError}
        {...getOverrideProps(overrides, "coborrowerName")}
      ></TextField>
      <TextField
        label="Mortgage type"
        isRequired={false}
        isReadOnly={false}
        value={mortgageType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName,
              coborrowerName,
              mortgageType: value,
              amount,
              interestRate,
              numberOfMonths,
              amortizationType,
            };
            const result = onChange(modelFields);
            value = result?.mortgageType ?? value;
          }
          if (errors.mortgageType?.hasError) {
            runValidationTasks("mortgageType", value);
          }
          setMortgageType(value);
        }}
        onBlur={() => runValidationTasks("mortgageType", mortgageType)}
        errorMessage={errors.mortgageType?.errorMessage}
        hasError={errors.mortgageType?.hasError}
        {...getOverrideProps(overrides, "mortgageType")}
      ></TextField>
      <TextField
        label="Amount"
        isRequired={false}
        isReadOnly={false}
        value={amount}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName,
              coborrowerName,
              mortgageType,
              amount: value,
              interestRate,
              numberOfMonths,
              amortizationType,
            };
            const result = onChange(modelFields);
            value = result?.amount ?? value;
          }
          if (errors.amount?.hasError) {
            runValidationTasks("amount", value);
          }
          setAmount(value);
        }}
        onBlur={() => runValidationTasks("amount", amount)}
        errorMessage={errors.amount?.errorMessage}
        hasError={errors.amount?.hasError}
        {...getOverrideProps(overrides, "amount")}
      ></TextField>
      <TextField
        label="Interest rate"
        isRequired={false}
        isReadOnly={false}
        value={interestRate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName,
              coborrowerName,
              mortgageType,
              amount,
              interestRate: value,
              numberOfMonths,
              amortizationType,
            };
            const result = onChange(modelFields);
            value = result?.interestRate ?? value;
          }
          if (errors.interestRate?.hasError) {
            runValidationTasks("interestRate", value);
          }
          setInterestRate(value);
        }}
        onBlur={() => runValidationTasks("interestRate", interestRate)}
        errorMessage={errors.interestRate?.errorMessage}
        hasError={errors.interestRate?.hasError}
        {...getOverrideProps(overrides, "interestRate")}
      ></TextField>
      <TextField
        label="Number of months"
        isRequired={false}
        isReadOnly={false}
        value={numberOfMonths}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName,
              coborrowerName,
              mortgageType,
              amount,
              interestRate,
              numberOfMonths: value,
              amortizationType,
            };
            const result = onChange(modelFields);
            value = result?.numberOfMonths ?? value;
          }
          if (errors.numberOfMonths?.hasError) {
            runValidationTasks("numberOfMonths", value);
          }
          setNumberOfMonths(value);
        }}
        onBlur={() => runValidationTasks("numberOfMonths", numberOfMonths)}
        errorMessage={errors.numberOfMonths?.errorMessage}
        hasError={errors.numberOfMonths?.hasError}
        {...getOverrideProps(overrides, "numberOfMonths")}
      ></TextField>
      <TextField
        label="Amortization type"
        isRequired={false}
        isReadOnly={false}
        value={amortizationType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              borrowerName,
              coborrowerName,
              mortgageType,
              amount,
              interestRate,
              numberOfMonths,
              amortizationType: value,
            };
            const result = onChange(modelFields);
            value = result?.amortizationType ?? value;
          }
          if (errors.amortizationType?.hasError) {
            runValidationTasks("amortizationType", value);
          }
          setAmortizationType(value);
        }}
        onBlur={() => runValidationTasks("amortizationType", amortizationType)}
        errorMessage={errors.amortizationType?.errorMessage}
        hasError={errors.amortizationType?.hasError}
        {...getOverrideProps(overrides, "amortizationType")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || loanApplicationModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || loanApplicationModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
