/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  SwitchField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createEmploymentInfo } from "../graphql/mutations";
const client = generateClient();
export default function EmploymentInfoCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    employer: "",
    selfEmployed: false,
    jobPosition: "",
    businessPhone: "",
    yearsInThisProf: "",
    yearsOnJob: "",
    employer2: "",
    selfEmployed2: false,
    jobPosition2: "",
    businessPhone2: "",
    employDates2: "",
    income2: "",
    employer3: "",
    selfEmployed3: false,
    jobPosition3: "",
    businessPhone3: "",
    employDates3: "",
    income3: "",
    completedJointly: false,
  };
  const [employer, setEmployer] = React.useState(initialValues.employer);
  const [selfEmployed, setSelfEmployed] = React.useState(
    initialValues.selfEmployed
  );
  const [jobPosition, setJobPosition] = React.useState(
    initialValues.jobPosition
  );
  const [businessPhone, setBusinessPhone] = React.useState(
    initialValues.businessPhone
  );
  const [yearsInThisProf, setYearsInThisProf] = React.useState(
    initialValues.yearsInThisProf
  );
  const [yearsOnJob, setYearsOnJob] = React.useState(initialValues.yearsOnJob);
  const [employer2, setEmployer2] = React.useState(initialValues.employer2);
  const [selfEmployed2, setSelfEmployed2] = React.useState(
    initialValues.selfEmployed2
  );
  const [jobPosition2, setJobPosition2] = React.useState(
    initialValues.jobPosition2
  );
  const [businessPhone2, setBusinessPhone2] = React.useState(
    initialValues.businessPhone2
  );
  const [employDates2, setEmployDates2] = React.useState(
    initialValues.employDates2
  );
  const [income2, setIncome2] = React.useState(initialValues.income2);
  const [employer3, setEmployer3] = React.useState(initialValues.employer3);
  const [selfEmployed3, setSelfEmployed3] = React.useState(
    initialValues.selfEmployed3
  );
  const [jobPosition3, setJobPosition3] = React.useState(
    initialValues.jobPosition3
  );
  const [businessPhone3, setBusinessPhone3] = React.useState(
    initialValues.businessPhone3
  );
  const [employDates3, setEmployDates3] = React.useState(
    initialValues.employDates3
  );
  const [income3, setIncome3] = React.useState(initialValues.income3);
  const [completedJointly, setCompletedJointly] = React.useState(
    initialValues.completedJointly
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setEmployer(initialValues.employer);
    setSelfEmployed(initialValues.selfEmployed);
    setJobPosition(initialValues.jobPosition);
    setBusinessPhone(initialValues.businessPhone);
    setYearsInThisProf(initialValues.yearsInThisProf);
    setYearsOnJob(initialValues.yearsOnJob);
    setEmployer2(initialValues.employer2);
    setSelfEmployed2(initialValues.selfEmployed2);
    setJobPosition2(initialValues.jobPosition2);
    setBusinessPhone2(initialValues.businessPhone2);
    setEmployDates2(initialValues.employDates2);
    setIncome2(initialValues.income2);
    setEmployer3(initialValues.employer3);
    setSelfEmployed3(initialValues.selfEmployed3);
    setJobPosition3(initialValues.jobPosition3);
    setBusinessPhone3(initialValues.businessPhone3);
    setEmployDates3(initialValues.employDates3);
    setIncome3(initialValues.income3);
    setCompletedJointly(initialValues.completedJointly);
    setErrors({});
  };
  const validations = {
    employer: [],
    selfEmployed: [],
    jobPosition: [],
    businessPhone: [{ type: "Phone" }],
    yearsInThisProf: [],
    yearsOnJob: [],
    employer2: [],
    selfEmployed2: [],
    jobPosition2: [],
    businessPhone2: [],
    employDates2: [],
    income2: [],
    employer3: [],
    selfEmployed3: [],
    jobPosition3: [],
    businessPhone3: [],
    employDates3: [],
    income3: [],
    completedJointly: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          employer,
          selfEmployed,
          jobPosition,
          businessPhone,
          yearsInThisProf,
          yearsOnJob,
          employer2,
          selfEmployed2,
          jobPosition2,
          businessPhone2,
          employDates2,
          income2,
          employer3,
          selfEmployed3,
          jobPosition3,
          businessPhone3,
          employDates3,
          income3,
          completedJointly,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createEmploymentInfo.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "EmploymentInfoCreateForm")}
      {...rest}
    >
      <TextField
        label="Employer Name and Address"
        isRequired={false}
        isReadOnly={false}
        value={employer}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer: value,
              selfEmployed,
              jobPosition,
              businessPhone,
              yearsInThisProf,
              yearsOnJob,
              employer2,
              selfEmployed2,
              jobPosition2,
              businessPhone2,
              employDates2,
              income2,
              employer3,
              selfEmployed3,
              jobPosition3,
              businessPhone3,
              employDates3,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.employer ?? value;
          }
          if (errors.employer?.hasError) {
            runValidationTasks("employer", value);
          }
          setEmployer(value);
        }}
        onBlur={() => runValidationTasks("employer", employer)}
        errorMessage={errors.employer?.errorMessage}
        hasError={errors.employer?.hasError}
        {...getOverrideProps(overrides, "employer")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(3, auto)"
        {...getOverrideProps(overrides, "RowGrid1")}
      >
        <SwitchField
          label="Self employed"
          defaultChecked={false}
          isDisabled={false}
          isChecked={selfEmployed}
          onChange={(e) => {
            let value = e.target.checked;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed: value,
                jobPosition,
                businessPhone,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2,
                employDates2,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.selfEmployed ?? value;
            }
            if (errors.selfEmployed?.hasError) {
              runValidationTasks("selfEmployed", value);
            }
            setSelfEmployed(value);
          }}
          onBlur={() => runValidationTasks("selfEmployed", selfEmployed)}
          errorMessage={errors.selfEmployed?.errorMessage}
          hasError={errors.selfEmployed?.hasError}
          {...getOverrideProps(overrides, "selfEmployed")}
        ></SwitchField>
        <TextField
          label="Job position / title"
          isRequired={false}
          isReadOnly={false}
          value={jobPosition}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition: value,
                businessPhone,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2,
                employDates2,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.jobPosition ?? value;
            }
            if (errors.jobPosition?.hasError) {
              runValidationTasks("jobPosition", value);
            }
            setJobPosition(value);
          }}
          onBlur={() => runValidationTasks("jobPosition", jobPosition)}
          errorMessage={errors.jobPosition?.errorMessage}
          hasError={errors.jobPosition?.hasError}
          {...getOverrideProps(overrides, "jobPosition")}
        ></TextField>
        <TextField
          label="Business phone"
          isRequired={false}
          isReadOnly={false}
          type="tel"
          value={businessPhone}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone: value,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2,
                employDates2,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.businessPhone ?? value;
            }
            if (errors.businessPhone?.hasError) {
              runValidationTasks("businessPhone", value);
            }
            setBusinessPhone(value);
          }}
          onBlur={() => runValidationTasks("businessPhone", businessPhone)}
          errorMessage={errors.businessPhone?.errorMessage}
          hasError={errors.businessPhone?.hasError}
          {...getOverrideProps(overrides, "businessPhone")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid2")}
      >
        <TextField
          label="Years in this profession"
          isRequired={false}
          isReadOnly={false}
          value={yearsInThisProf}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone,
                yearsInThisProf: value,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2,
                employDates2,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.yearsInThisProf ?? value;
            }
            if (errors.yearsInThisProf?.hasError) {
              runValidationTasks("yearsInThisProf", value);
            }
            setYearsInThisProf(value);
          }}
          onBlur={() => runValidationTasks("yearsInThisProf", yearsInThisProf)}
          errorMessage={errors.yearsInThisProf?.errorMessage}
          hasError={errors.yearsInThisProf?.hasError}
          {...getOverrideProps(overrides, "yearsInThisProf")}
        ></TextField>
        <TextField
          label="Years on job"
          isRequired={false}
          isReadOnly={false}
          value={yearsOnJob}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone,
                yearsInThisProf,
                yearsOnJob: value,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2,
                employDates2,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.yearsOnJob ?? value;
            }
            if (errors.yearsOnJob?.hasError) {
              runValidationTasks("yearsOnJob", value);
            }
            setYearsOnJob(value);
          }}
          onBlur={() => runValidationTasks("yearsOnJob", yearsOnJob)}
          errorMessage={errors.yearsOnJob?.errorMessage}
          hasError={errors.yearsOnJob?.hasError}
          {...getOverrideProps(overrides, "yearsOnJob")}
        ></TextField>
      </Grid>
      <Text
        children="If employed in current position for less than two years or if currently employed in more than one position, complete the following:"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Text>
      <TextField
        label="Employer name and address"
        isRequired={false}
        isReadOnly={false}
        value={employer2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              yearsInThisProf,
              yearsOnJob,
              employer2: value,
              selfEmployed2,
              jobPosition2,
              businessPhone2,
              employDates2,
              income2,
              employer3,
              selfEmployed3,
              jobPosition3,
              businessPhone3,
              employDates3,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.employer2 ?? value;
          }
          if (errors.employer2?.hasError) {
            runValidationTasks("employer2", value);
          }
          setEmployer2(value);
        }}
        onBlur={() => runValidationTasks("employer2", employer2)}
        errorMessage={errors.employer2?.errorMessage}
        hasError={errors.employer2?.hasError}
        {...getOverrideProps(overrides, "employer2")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(3, auto)"
        {...getOverrideProps(overrides, "RowGrid5")}
      >
        <SwitchField
          label="Self employed"
          defaultChecked={false}
          isDisabled={false}
          isChecked={selfEmployed2}
          onChange={(e) => {
            let value = e.target.checked;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2: value,
                jobPosition2,
                businessPhone2,
                employDates2,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.selfEmployed2 ?? value;
            }
            if (errors.selfEmployed2?.hasError) {
              runValidationTasks("selfEmployed2", value);
            }
            setSelfEmployed2(value);
          }}
          onBlur={() => runValidationTasks("selfEmployed2", selfEmployed2)}
          errorMessage={errors.selfEmployed2?.errorMessage}
          hasError={errors.selfEmployed2?.hasError}
          {...getOverrideProps(overrides, "selfEmployed2")}
        ></SwitchField>
        <TextField
          label="Job position / title"
          isRequired={false}
          isReadOnly={false}
          value={jobPosition2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2: value,
                businessPhone2,
                employDates2,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.jobPosition2 ?? value;
            }
            if (errors.jobPosition2?.hasError) {
              runValidationTasks("jobPosition2", value);
            }
            setJobPosition2(value);
          }}
          onBlur={() => runValidationTasks("jobPosition2", jobPosition2)}
          errorMessage={errors.jobPosition2?.errorMessage}
          hasError={errors.jobPosition2?.hasError}
          {...getOverrideProps(overrides, "jobPosition2")}
        ></TextField>
        <TextField
          label="Business phone"
          isRequired={false}
          isReadOnly={false}
          value={businessPhone2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2: value,
                employDates2,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.businessPhone2 ?? value;
            }
            if (errors.businessPhone2?.hasError) {
              runValidationTasks("businessPhone2", value);
            }
            setBusinessPhone2(value);
          }}
          onBlur={() => runValidationTasks("businessPhone2", businessPhone2)}
          errorMessage={errors.businessPhone2?.errorMessage}
          hasError={errors.businessPhone2?.hasError}
          {...getOverrideProps(overrides, "businessPhone2")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Employment dates"
          descriptiveText="(from - to)"
          isRequired={false}
          isReadOnly={false}
          placeholder="mm/dd/yyyy - mm/dd/yyyy"
          value={employDates2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2,
                employDates2: value,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.employDates2 ?? value;
            }
            if (errors.employDates2?.hasError) {
              runValidationTasks("employDates2", value);
            }
            setEmployDates2(value);
          }}
          onBlur={() => runValidationTasks("employDates2", employDates2)}
          errorMessage={errors.employDates2?.errorMessage}
          hasError={errors.employDates2?.hasError}
          {...getOverrideProps(overrides, "employDates2")}
        ></TextField>
        <TextField
          label="Monthly Income"
          descriptiveText="(at past position)"
          isRequired={false}
          isReadOnly={false}
          value={income2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2,
                employDates2,
                income2: value,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.income2 ?? value;
            }
            if (errors.income2?.hasError) {
              runValidationTasks("income2", value);
            }
            setIncome2(value);
          }}
          onBlur={() => runValidationTasks("income2", income2)}
          errorMessage={errors.income2?.errorMessage}
          hasError={errors.income2?.hasError}
          {...getOverrideProps(overrides, "income2")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Divider>
      <TextField
        label="Employer name and address"
        isRequired={false}
        isReadOnly={false}
        value={employer3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              yearsInThisProf,
              yearsOnJob,
              employer2,
              selfEmployed2,
              jobPosition2,
              businessPhone2,
              employDates2,
              income2,
              employer3: value,
              selfEmployed3,
              jobPosition3,
              businessPhone3,
              employDates3,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.employer3 ?? value;
          }
          if (errors.employer3?.hasError) {
            runValidationTasks("employer3", value);
          }
          setEmployer3(value);
        }}
        onBlur={() => runValidationTasks("employer3", employer3)}
        errorMessage={errors.employer3?.errorMessage}
        hasError={errors.employer3?.hasError}
        {...getOverrideProps(overrides, "employer3")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(3, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <SwitchField
          label="Self employed"
          defaultChecked={false}
          isDisabled={false}
          isChecked={selfEmployed3}
          onChange={(e) => {
            let value = e.target.checked;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2,
                employDates2,
                income2,
                employer3,
                selfEmployed3: value,
                jobPosition3,
                businessPhone3,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.selfEmployed3 ?? value;
            }
            if (errors.selfEmployed3?.hasError) {
              runValidationTasks("selfEmployed3", value);
            }
            setSelfEmployed3(value);
          }}
          onBlur={() => runValidationTasks("selfEmployed3", selfEmployed3)}
          errorMessage={errors.selfEmployed3?.errorMessage}
          hasError={errors.selfEmployed3?.hasError}
          {...getOverrideProps(overrides, "selfEmployed3")}
        ></SwitchField>
        <TextField
          label="Job position / title"
          isRequired={false}
          isReadOnly={false}
          value={jobPosition3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2,
                employDates2,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3: value,
                businessPhone3,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.jobPosition3 ?? value;
            }
            if (errors.jobPosition3?.hasError) {
              runValidationTasks("jobPosition3", value);
            }
            setJobPosition3(value);
          }}
          onBlur={() => runValidationTasks("jobPosition3", jobPosition3)}
          errorMessage={errors.jobPosition3?.errorMessage}
          hasError={errors.jobPosition3?.hasError}
          {...getOverrideProps(overrides, "jobPosition3")}
        ></TextField>
        <TextField
          label="Business phone"
          isRequired={false}
          isReadOnly={false}
          value={businessPhone3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2,
                employDates2,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3: value,
                employDates3,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.businessPhone3 ?? value;
            }
            if (errors.businessPhone3?.hasError) {
              runValidationTasks("businessPhone3", value);
            }
            setBusinessPhone3(value);
          }}
          onBlur={() => runValidationTasks("businessPhone3", businessPhone3)}
          errorMessage={errors.businessPhone3?.errorMessage}
          hasError={errors.businessPhone3?.hasError}
          {...getOverrideProps(overrides, "businessPhone3")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid10")}
      >
        <TextField
          label="Employment dates"
          descriptiveText="(from - to)"
          isRequired={false}
          isReadOnly={false}
          placeholder="mm/dd/yyyy - mm/dd/yyyy"
          value={employDates3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2,
                employDates2,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3,
                employDates3: value,
                income3,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.employDates3 ?? value;
            }
            if (errors.employDates3?.hasError) {
              runValidationTasks("employDates3", value);
            }
            setEmployDates3(value);
          }}
          onBlur={() => runValidationTasks("employDates3", employDates3)}
          errorMessage={errors.employDates3?.errorMessage}
          hasError={errors.employDates3?.hasError}
          {...getOverrideProps(overrides, "employDates3")}
        ></TextField>
        <TextField
          label="Monthly Income"
          descriptiveText="(at past position)"
          isRequired={false}
          isReadOnly={false}
          value={income3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                employer,
                selfEmployed,
                jobPosition,
                businessPhone,
                yearsInThisProf,
                yearsOnJob,
                employer2,
                selfEmployed2,
                jobPosition2,
                businessPhone2,
                employDates2,
                income2,
                employer3,
                selfEmployed3,
                jobPosition3,
                businessPhone3,
                employDates3,
                income3: value,
                completedJointly,
              };
              const result = onChange(modelFields);
              value = result?.income3 ?? value;
            }
            if (errors.income3?.hasError) {
              runValidationTasks("income3", value);
            }
            setIncome3(value);
          }}
          onBlur={() => runValidationTasks("income3", income3)}
          errorMessage={errors.income3?.errorMessage}
          hasError={errors.income3?.hasError}
          {...getOverrideProps(overrides, "income3")}
        ></TextField>
      </Grid>
      <SwitchField
        label="Completed jointly"
        defaultChecked={false}
        isDisabled={false}
        isChecked={completedJointly}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              yearsInThisProf,
              yearsOnJob,
              employer2,
              selfEmployed2,
              jobPosition2,
              businessPhone2,
              employDates2,
              income2,
              employer3,
              selfEmployed3,
              jobPosition3,
              businessPhone3,
              employDates3,
              income3,
              completedJointly: value,
            };
            const result = onChange(modelFields);
            value = result?.completedJointly ?? value;
          }
          if (errors.completedJointly?.hasError) {
            runValidationTasks("completedJointly", value);
          }
          setCompletedJointly(value);
        }}
        onBlur={() => runValidationTasks("completedJointly", completedJointly)}
        errorMessage={errors.completedJointly?.errorMessage}
        hasError={errors.completedJointly?.hasError}
        {...getOverrideProps(overrides, "completedJointly")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Save"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
