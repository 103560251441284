/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Radio,
  RadioGroupField,
  SelectField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createBorrowerInfo } from "../graphql/mutations";
const client = generateClient();
export default function BorrowerInfoCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    fullName: "",
    email: "",
    phone: "",
    ssn: "",
    address: "",
    presentAddressOwnership: undefined,
    noOfYrsAtPresentResidence: "",
    formerAddress: "",
    formerAddressOwnership: undefined,
    noOfYrsAtFormerResidence: "",
    mailingAddress: "",
    maritalStatus: "",
    numberOfDependents: "",
    agesOfDependents: "",
    birthdate: "",
    yrsInSchool: "",
  };
  const [fullName, setFullName] = React.useState(initialValues.fullName);
  const [email, setEmail] = React.useState(initialValues.email);
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [ssn, setSsn] = React.useState(initialValues.ssn);
  const [address, setAddress] = React.useState(initialValues.address);
  const [presentAddressOwnership, setPresentAddressOwnership] = React.useState(
    initialValues.presentAddressOwnership
  );
  const [noOfYrsAtPresentResidence, setNoOfYrsAtPresentResidence] =
    React.useState(initialValues.noOfYrsAtPresentResidence);
  const [formerAddress, setFormerAddress] = React.useState(
    initialValues.formerAddress
  );
  const [formerAddressOwnership, setFormerAddressOwnership] = React.useState(
    initialValues.formerAddressOwnership
  );
  const [noOfYrsAtFormerResidence, setNoOfYrsAtFormerResidence] =
    React.useState(initialValues.noOfYrsAtFormerResidence);
  const [mailingAddress, setMailingAddress] = React.useState(
    initialValues.mailingAddress
  );
  const [maritalStatus, setMaritalStatus] = React.useState(
    initialValues.maritalStatus
  );
  const [numberOfDependents, setNumberOfDependents] = React.useState(
    initialValues.numberOfDependents
  );
  const [agesOfDependents, setAgesOfDependents] = React.useState(
    initialValues.agesOfDependents
  );
  const [birthdate, setBirthdate] = React.useState(initialValues.birthdate);
  const [yrsInSchool, setYrsInSchool] = React.useState(
    initialValues.yrsInSchool
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setFullName(initialValues.fullName);
    setEmail(initialValues.email);
    setPhone(initialValues.phone);
    setSsn(initialValues.ssn);
    setAddress(initialValues.address);
    setPresentAddressOwnership(initialValues.presentAddressOwnership);
    setNoOfYrsAtPresentResidence(initialValues.noOfYrsAtPresentResidence);
    setFormerAddress(initialValues.formerAddress);
    setFormerAddressOwnership(initialValues.formerAddressOwnership);
    setNoOfYrsAtFormerResidence(initialValues.noOfYrsAtFormerResidence);
    setMailingAddress(initialValues.mailingAddress);
    setMaritalStatus(initialValues.maritalStatus);
    setNumberOfDependents(initialValues.numberOfDependents);
    setAgesOfDependents(initialValues.agesOfDependents);
    setBirthdate(initialValues.birthdate);
    setYrsInSchool(initialValues.yrsInSchool);
    setErrors({});
  };
  const validations = {
    fullName: [],
    email: [{ type: "Required" }],
    phone: [],
    ssn: [],
    address: [],
    presentAddressOwnership: [],
    noOfYrsAtPresentResidence: [],
    formerAddress: [],
    formerAddressOwnership: [],
    noOfYrsAtFormerResidence: [],
    mailingAddress: [],
    maritalStatus: [],
    numberOfDependents: [],
    agesOfDependents: [],
    birthdate: [],
    yrsInSchool: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          fullName,
          email,
          phone,
          ssn,
          address,
          presentAddressOwnership,
          noOfYrsAtPresentResidence,
          formerAddress,
          formerAddressOwnership,
          noOfYrsAtFormerResidence,
          mailingAddress,
          maritalStatus,
          numberOfDependents,
          agesOfDependents,
          birthdate,
          yrsInSchool,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createBorrowerInfo.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "BorrowerInfoCreateForm")}
      {...rest}
    >
      <Text
        children="We need some information from you..."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Text>
      <TextField
        label="Full name"
        isRequired={false}
        isReadOnly={false}
        value={fullName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName: value,
              email,
              phone,
              ssn,
              address,
              presentAddressOwnership,
              noOfYrsAtPresentResidence,
              formerAddress,
              formerAddressOwnership,
              noOfYrsAtFormerResidence,
              mailingAddress,
              maritalStatus,
              numberOfDependents,
              agesOfDependents,
              birthdate,
              yrsInSchool,
            };
            const result = onChange(modelFields);
            value = result?.fullName ?? value;
          }
          if (errors.fullName?.hasError) {
            runValidationTasks("fullName", value);
          }
          setFullName(value);
        }}
        onBlur={() => runValidationTasks("fullName", fullName)}
        errorMessage={errors.fullName?.errorMessage}
        hasError={errors.fullName?.hasError}
        {...getOverrideProps(overrides, "fullName")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(3, auto)"
        {...getOverrideProps(overrides, "RowGrid2")}
      >
        <TextField
          label="Email address"
          isRequired={true}
          isReadOnly={false}
          value={email}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                fullName,
                email: value,
                phone,
                ssn,
                address,
                presentAddressOwnership,
                noOfYrsAtPresentResidence,
                formerAddress,
                formerAddressOwnership,
                noOfYrsAtFormerResidence,
                mailingAddress,
                maritalStatus,
                numberOfDependents,
                agesOfDependents,
                birthdate,
                yrsInSchool,
              };
              const result = onChange(modelFields);
              value = result?.email ?? value;
            }
            if (errors.email?.hasError) {
              runValidationTasks("email", value);
            }
            setEmail(value);
          }}
          onBlur={() => runValidationTasks("email", email)}
          errorMessage={errors.email?.errorMessage}
          hasError={errors.email?.hasError}
          {...getOverrideProps(overrides, "email")}
        ></TextField>
        <TextField
          label="Phone Number (include area code)"
          descriptiveText=""
          isRequired={false}
          isReadOnly={false}
          value={phone}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                fullName,
                email,
                phone: value,
                ssn,
                address,
                presentAddressOwnership,
                noOfYrsAtPresentResidence,
                formerAddress,
                formerAddressOwnership,
                noOfYrsAtFormerResidence,
                mailingAddress,
                maritalStatus,
                numberOfDependents,
                agesOfDependents,
                birthdate,
                yrsInSchool,
              };
              const result = onChange(modelFields);
              value = result?.phone ?? value;
            }
            if (errors.phone?.hasError) {
              runValidationTasks("phone", value);
            }
            setPhone(value);
          }}
          onBlur={() => runValidationTasks("phone", phone)}
          errorMessage={errors.phone?.errorMessage}
          hasError={errors.phone?.hasError}
          {...getOverrideProps(overrides, "phone")}
        ></TextField>
        <TextField
          label="Social Security Number"
          descriptiveText=""
          isRequired={false}
          isReadOnly={false}
          placeholder="(xxx-xx-xxxx)"
          value={ssn}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                fullName,
                email,
                phone,
                ssn: value,
                address,
                presentAddressOwnership,
                noOfYrsAtPresentResidence,
                formerAddress,
                formerAddressOwnership,
                noOfYrsAtFormerResidence,
                mailingAddress,
                maritalStatus,
                numberOfDependents,
                agesOfDependents,
                birthdate,
                yrsInSchool,
              };
              const result = onChange(modelFields);
              value = result?.ssn ?? value;
            }
            if (errors.ssn?.hasError) {
              runValidationTasks("ssn", value);
            }
            setSsn(value);
          }}
          onBlur={() => runValidationTasks("ssn", ssn)}
          errorMessage={errors.ssn?.errorMessage}
          hasError={errors.ssn?.hasError}
          {...getOverrideProps(overrides, "ssn")}
        ></TextField>
      </Grid>
      <TextField
        label="Address"
        isRequired={false}
        isReadOnly={false}
        value={address}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              email,
              phone,
              ssn,
              address: value,
              presentAddressOwnership,
              noOfYrsAtPresentResidence,
              formerAddress,
              formerAddressOwnership,
              noOfYrsAtFormerResidence,
              mailingAddress,
              maritalStatus,
              numberOfDependents,
              agesOfDependents,
              birthdate,
              yrsInSchool,
            };
            const result = onChange(modelFields);
            value = result?.address ?? value;
          }
          if (errors.address?.hasError) {
            runValidationTasks("address", value);
          }
          setAddress(value);
        }}
        onBlur={() => runValidationTasks("address", address)}
        errorMessage={errors.address?.errorMessage}
        hasError={errors.address?.hasError}
        {...getOverrideProps(overrides, "address")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid4")}
      >
        <RadioGroupField
          label="Do you own or rent your current residence?"
          name="presentAddressOwnership"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                fullName,
                email,
                phone,
                ssn,
                address,
                presentAddressOwnership: value,
                noOfYrsAtPresentResidence,
                formerAddress,
                formerAddressOwnership,
                noOfYrsAtFormerResidence,
                mailingAddress,
                maritalStatus,
                numberOfDependents,
                agesOfDependents,
                birthdate,
                yrsInSchool,
              };
              const result = onChange(modelFields);
              value = result?.presentAddressOwnership ?? value;
            }
            if (errors.presentAddressOwnership?.hasError) {
              runValidationTasks("presentAddressOwnership", value);
            }
            setPresentAddressOwnership(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "presentAddressOwnership",
              presentAddressOwnership
            )
          }
          errorMessage={errors.presentAddressOwnership?.errorMessage}
          hasError={errors.presentAddressOwnership?.hasError}
          {...getOverrideProps(overrides, "presentAddressOwnership")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "presentAddressOwnershipRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "presentAddressOwnershipRadio1")}
          ></Radio>
        </RadioGroupField>
        <TextField
          label="No of yrs at present residence"
          isRequired={false}
          isReadOnly={false}
          value={noOfYrsAtPresentResidence}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                fullName,
                email,
                phone,
                ssn,
                address,
                presentAddressOwnership,
                noOfYrsAtPresentResidence: value,
                formerAddress,
                formerAddressOwnership,
                noOfYrsAtFormerResidence,
                mailingAddress,
                maritalStatus,
                numberOfDependents,
                agesOfDependents,
                birthdate,
                yrsInSchool,
              };
              const result = onChange(modelFields);
              value = result?.noOfYrsAtPresentResidence ?? value;
            }
            if (errors.noOfYrsAtPresentResidence?.hasError) {
              runValidationTasks("noOfYrsAtPresentResidence", value);
            }
            setNoOfYrsAtPresentResidence(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "noOfYrsAtPresentResidence",
              noOfYrsAtPresentResidence
            )
          }
          errorMessage={errors.noOfYrsAtPresentResidence?.errorMessage}
          hasError={errors.noOfYrsAtPresentResidence?.hasError}
          {...getOverrideProps(overrides, "noOfYrsAtPresentResidence")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Divider>
      <Text
        children="If residing at present address for less than two years, please complete the following:"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <TextField
        label="Former address"
        isRequired={false}
        isReadOnly={false}
        value={formerAddress}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              email,
              phone,
              ssn,
              address,
              presentAddressOwnership,
              noOfYrsAtPresentResidence,
              formerAddress: value,
              formerAddressOwnership,
              noOfYrsAtFormerResidence,
              mailingAddress,
              maritalStatus,
              numberOfDependents,
              agesOfDependents,
              birthdate,
              yrsInSchool,
            };
            const result = onChange(modelFields);
            value = result?.formerAddress ?? value;
          }
          if (errors.formerAddress?.hasError) {
            runValidationTasks("formerAddress", value);
          }
          setFormerAddress(value);
        }}
        onBlur={() => runValidationTasks("formerAddress", formerAddress)}
        errorMessage={errors.formerAddress?.errorMessage}
        hasError={errors.formerAddress?.hasError}
        {...getOverrideProps(overrides, "formerAddress")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <RadioGroupField
          label="Former address ownership"
          name="formerAddressOwnership"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                fullName,
                email,
                phone,
                ssn,
                address,
                presentAddressOwnership,
                noOfYrsAtPresentResidence,
                formerAddress,
                formerAddressOwnership: value,
                noOfYrsAtFormerResidence,
                mailingAddress,
                maritalStatus,
                numberOfDependents,
                agesOfDependents,
                birthdate,
                yrsInSchool,
              };
              const result = onChange(modelFields);
              value = result?.formerAddressOwnership ?? value;
            }
            if (errors.formerAddressOwnership?.hasError) {
              runValidationTasks("formerAddressOwnership", value);
            }
            setFormerAddressOwnership(value);
          }}
          onBlur={() =>
            runValidationTasks("formerAddressOwnership", formerAddressOwnership)
          }
          errorMessage={errors.formerAddressOwnership?.errorMessage}
          hasError={errors.formerAddressOwnership?.hasError}
          {...getOverrideProps(overrides, "formerAddressOwnership")}
        >
          <Radio
            children="Own"
            value="true"
            {...getOverrideProps(overrides, "formerAddressOwnershipRadio0")}
          ></Radio>
          <Radio
            children="Rent"
            value="false"
            {...getOverrideProps(overrides, "formerAddressOwnershipRadio1")}
          ></Radio>
        </RadioGroupField>
        <TextField
          label="No of yrs at former residence"
          isRequired={false}
          isReadOnly={false}
          value={noOfYrsAtFormerResidence}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                fullName,
                email,
                phone,
                ssn,
                address,
                presentAddressOwnership,
                noOfYrsAtPresentResidence,
                formerAddress,
                formerAddressOwnership,
                noOfYrsAtFormerResidence: value,
                mailingAddress,
                maritalStatus,
                numberOfDependents,
                agesOfDependents,
                birthdate,
                yrsInSchool,
              };
              const result = onChange(modelFields);
              value = result?.noOfYrsAtFormerResidence ?? value;
            }
            if (errors.noOfYrsAtFormerResidence?.hasError) {
              runValidationTasks("noOfYrsAtFormerResidence", value);
            }
            setNoOfYrsAtFormerResidence(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "noOfYrsAtFormerResidence",
              noOfYrsAtFormerResidence
            )
          }
          errorMessage={errors.noOfYrsAtFormerResidence?.errorMessage}
          hasError={errors.noOfYrsAtFormerResidence?.hasError}
          {...getOverrideProps(overrides, "noOfYrsAtFormerResidence")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <TextField
        label="Mailing address, if different from Present Address"
        isRequired={false}
        isReadOnly={false}
        value={mailingAddress}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              email,
              phone,
              ssn,
              address,
              presentAddressOwnership,
              noOfYrsAtPresentResidence,
              formerAddress,
              formerAddressOwnership,
              noOfYrsAtFormerResidence,
              mailingAddress: value,
              maritalStatus,
              numberOfDependents,
              agesOfDependents,
              birthdate,
              yrsInSchool,
            };
            const result = onChange(modelFields);
            value = result?.mailingAddress ?? value;
          }
          if (errors.mailingAddress?.hasError) {
            runValidationTasks("mailingAddress", value);
          }
          setMailingAddress(value);
        }}
        onBlur={() => runValidationTasks("mailingAddress", mailingAddress)}
        errorMessage={errors.mailingAddress?.errorMessage}
        hasError={errors.mailingAddress?.hasError}
        {...getOverrideProps(overrides, "mailingAddress")}
      ></TextField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Divider>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(3, auto)"
        {...getOverrideProps(overrides, "RowGrid12")}
      >
        <SelectField
          label="Marital status"
          placeholder="Please select an option"
          isDisabled={false}
          value={maritalStatus}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                fullName,
                email,
                phone,
                ssn,
                address,
                presentAddressOwnership,
                noOfYrsAtPresentResidence,
                formerAddress,
                formerAddressOwnership,
                noOfYrsAtFormerResidence,
                mailingAddress,
                maritalStatus: value,
                numberOfDependents,
                agesOfDependents,
                birthdate,
                yrsInSchool,
              };
              const result = onChange(modelFields);
              value = result?.maritalStatus ?? value;
            }
            if (errors.maritalStatus?.hasError) {
              runValidationTasks("maritalStatus", value);
            }
            setMaritalStatus(value);
          }}
          onBlur={() => runValidationTasks("maritalStatus", maritalStatus)}
          errorMessage={errors.maritalStatus?.errorMessage}
          hasError={errors.maritalStatus?.hasError}
          {...getOverrideProps(overrides, "maritalStatus")}
        >
          <option
            children="Married"
            value="Married"
            {...getOverrideProps(overrides, "maritalStatusoption0")}
          ></option>
          <option
            children="Unmarried (include single, divorced, widowed)"
            value="Unmarried (include single, divorced, widowed)"
            {...getOverrideProps(overrides, "maritalStatusoption1")}
          ></option>
          <option
            children="Separated"
            value="Separated"
            {...getOverrideProps(overrides, "maritalStatusoption2")}
          ></option>
        </SelectField>
        <TextField
          label="Number of dependents"
          descriptiveText="(not listed by Co-Borrower)"
          isRequired={false}
          isReadOnly={false}
          value={numberOfDependents}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                fullName,
                email,
                phone,
                ssn,
                address,
                presentAddressOwnership,
                noOfYrsAtPresentResidence,
                formerAddress,
                formerAddressOwnership,
                noOfYrsAtFormerResidence,
                mailingAddress,
                maritalStatus,
                numberOfDependents: value,
                agesOfDependents,
                birthdate,
                yrsInSchool,
              };
              const result = onChange(modelFields);
              value = result?.numberOfDependents ?? value;
            }
            if (errors.numberOfDependents?.hasError) {
              runValidationTasks("numberOfDependents", value);
            }
            setNumberOfDependents(value);
          }}
          onBlur={() =>
            runValidationTasks("numberOfDependents", numberOfDependents)
          }
          errorMessage={errors.numberOfDependents?.errorMessage}
          hasError={errors.numberOfDependents?.hasError}
          {...getOverrideProps(overrides, "numberOfDependents")}
        ></TextField>
        <TextField
          label="Ages of dependents"
          descriptiveText="(not listed by Co-Borrower)"
          isRequired={false}
          isReadOnly={false}
          value={agesOfDependents}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                fullName,
                email,
                phone,
                ssn,
                address,
                presentAddressOwnership,
                noOfYrsAtPresentResidence,
                formerAddress,
                formerAddressOwnership,
                noOfYrsAtFormerResidence,
                mailingAddress,
                maritalStatus,
                numberOfDependents,
                agesOfDependents: value,
                birthdate,
                yrsInSchool,
              };
              const result = onChange(modelFields);
              value = result?.agesOfDependents ?? value;
            }
            if (errors.agesOfDependents?.hasError) {
              runValidationTasks("agesOfDependents", value);
            }
            setAgesOfDependents(value);
          }}
          onBlur={() =>
            runValidationTasks("agesOfDependents", agesOfDependents)
          }
          errorMessage={errors.agesOfDependents?.errorMessage}
          hasError={errors.agesOfDependents?.hasError}
          {...getOverrideProps(overrides, "agesOfDependents")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid13")}
      >
        <TextField
          label="Date of Birth"
          isRequired={false}
          isReadOnly={false}
          placeholder="mm-dd-yyyy"
          value={birthdate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                fullName,
                email,
                phone,
                ssn,
                address,
                presentAddressOwnership,
                noOfYrsAtPresentResidence,
                formerAddress,
                formerAddressOwnership,
                noOfYrsAtFormerResidence,
                mailingAddress,
                maritalStatus,
                numberOfDependents,
                agesOfDependents,
                birthdate: value,
                yrsInSchool,
              };
              const result = onChange(modelFields);
              value = result?.birthdate ?? value;
            }
            if (errors.birthdate?.hasError) {
              runValidationTasks("birthdate", value);
            }
            setBirthdate(value);
          }}
          onBlur={() => runValidationTasks("birthdate", birthdate)}
          errorMessage={errors.birthdate?.errorMessage}
          hasError={errors.birthdate?.hasError}
          {...getOverrideProps(overrides, "birthdate")}
        ></TextField>
        <TextField
          label="Years in school"
          isRequired={false}
          isReadOnly={false}
          value={yrsInSchool}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                fullName,
                email,
                phone,
                ssn,
                address,
                presentAddressOwnership,
                noOfYrsAtPresentResidence,
                formerAddress,
                formerAddressOwnership,
                noOfYrsAtFormerResidence,
                mailingAddress,
                maritalStatus,
                numberOfDependents,
                agesOfDependents,
                birthdate,
                yrsInSchool: value,
              };
              const result = onChange(modelFields);
              value = result?.yrsInSchool ?? value;
            }
            if (errors.yrsInSchool?.hasError) {
              runValidationTasks("yrsInSchool", value);
            }
            setYrsInSchool(value);
          }}
          onBlur={() => runValidationTasks("yrsInSchool", yrsInSchool)}
          errorMessage={errors.yrsInSchool?.errorMessage}
          hasError={errors.yrsInSchool?.hasError}
          {...getOverrideProps(overrides, "yrsInSchool")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Save"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
