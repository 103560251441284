import React from 'react';
import { Grid, Typography, Paper, CardMedia } from '@mui/material';
import BasicHeader from './Header';
import Footer from './Footer';
import './Services.css';
import homebuyer_img from './iStock-2149890136.jpg';
import loan_officer_img from './iStock-1178856357.jpg';
import pricing_img from './iStock-2017790676.jpg';

const Services = () => {
    return (
        <>
            <BasicHeader />
            <div className="services-container">
                <Grid container spacing={4} direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="h4" className="services-title">
                            Services and Pricing
                        </Typography>
                        <br></br>
                        <Typography variant="body1" className="services-intro">
                            QuickApplyLoan is dedicated to making the journey to homeownership as smooth and hassle-free as possible. Our comprehensive services are designed to meet your needs at every step of the mortgage process. Below, you'll find a detailed description of our services and transparent pricing to help you make informed decisions.
                        </Typography>
                    </Grid>

                    {/* Section for Services Offered */}
                    <Grid container item xs={12} spacing={4} style={{ maxWidth: '800px', width: '100%' }}>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} className="plan-box">
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={homebuyer_img}
                                    alt="Standard Plan Image"
                                />
                                <Typography variant="h5" className="service-title">
                                    Standard Plan - for Individual Homebuyers
                                </Typography>
                                <Typography variant="body1" className="service-description">
                                    Our Standard Plan is designed for individual homebuyers seeking guidance and resources to navigate the home buying process.
                                </Typography>
                                <ul className="service-list">
                                    <li>Online Resources: Access a wealth of online resources, including articles, videos, and guides.</li>
                                    <li>Connect with real-estate agents and loan officers.</li>
                                    <li>Complete your loan application manually and obtain all necessary supporting documents.</li>
                                </ul>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} className="plan-box">
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={loan_officer_img}
                                    alt="Standard Plan Image"
                                />
                                <Typography variant="h5" className="service-title">
                                    Premium Plan - for Loan Officers, Banks, and Credit Unions
                                </Typography>
                                <Typography variant="body1" className="service-description">
                                    Comprehensive support, all Standard Plan features. Volume discounts for credit unions and loan officers.
                                </Typography>
                                <ul className="service-list">
                                    <li>Comprehensive support and resources.</li>
                                    <li>All features of the Standard Plan.</li>
                                    <li>Volume licensing available for credit unions and loan officers.</li>
                                </ul>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* Pricing Section */}
                    <Grid item xs={12} style={{ maxWidth: '800px', width: '100%' }}>
                        <Paper elevation={3} className="pricing-box">
                            <Typography variant="h5" className="pricing-title">
                                Pricing
                            </Typography>
                            <br></br>
                            <Typography variant="body1" className="pricing-description">
                                We believe in transparent and competitive pricing. Our goal is to provide exceptional value for our services without any hidden fees.
                            </Typography>
                            <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} md={4}>
                                    <Paper elevation={3} className="plan-box">
                                        <CardMedia
                                        component="img"
                                        height="240"
                                        image={pricing_img}
                                        alt="Pricing Image"
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Paper elevation={3} className="plan-box">
                                        <Typography variant="h6" className="plan-title">
                                            Standard Plan
                                        </Typography>
                                        <Typography variant="body2" className="plan-description">
                                            Complete your loan application manually and obtain all necessary supporting documents.
                                        </Typography>
                                        <Typography variant="h6" className="plan-price">
                                            $99 per application
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Paper elevation={3} className="plan-box">
                                        <Typography variant="h6" className="plan-title">
                                            Premium Plan
                                        </Typography>
                                        <Typography variant="body2" className="plan-description">
                                            Comprehensive support, all Standard Plan features. Volume discounts for credit unions and loan officers.
                                        </Typography>
                                        <Typography variant="h6" className="plan-price">
                                            Pricing varies by volume. Contact us for a quote.
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    );
}

export default Services;
