/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getEmploymentInfo } from "../graphql/queries";
import { updateEmploymentInfo } from "../graphql/mutations";
const client = generateClient();
export default function EmploymentInfoUpdateForm(props) {
  const {
    id: idProp,
    employmentInfo: employmentInfoModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    employer: "",
    selfEmployed: false,
    jobPosition: "",
    businessPhone: "",
    employer2: "",
    employer3: "",
    selfEmployed2: false,
    selfEmployed3: false,
    jobPosition2: "",
    jobPosition3: "",
    businessPhone2: "",
    businessPhone3: "",
    yearsOnJob: "",
    yearsInThisProf: "",
    employDates2: "",
    employDates3: "",
    income2: "",
    income3: "",
    completedJointly: false,
  };
  const [employer, setEmployer] = React.useState(initialValues.employer);
  const [selfEmployed, setSelfEmployed] = React.useState(
    initialValues.selfEmployed
  );
  const [jobPosition, setJobPosition] = React.useState(
    initialValues.jobPosition
  );
  const [businessPhone, setBusinessPhone] = React.useState(
    initialValues.businessPhone
  );
  const [employer2, setEmployer2] = React.useState(initialValues.employer2);
  const [employer3, setEmployer3] = React.useState(initialValues.employer3);
  const [selfEmployed2, setSelfEmployed2] = React.useState(
    initialValues.selfEmployed2
  );
  const [selfEmployed3, setSelfEmployed3] = React.useState(
    initialValues.selfEmployed3
  );
  const [jobPosition2, setJobPosition2] = React.useState(
    initialValues.jobPosition2
  );
  const [jobPosition3, setJobPosition3] = React.useState(
    initialValues.jobPosition3
  );
  const [businessPhone2, setBusinessPhone2] = React.useState(
    initialValues.businessPhone2
  );
  const [businessPhone3, setBusinessPhone3] = React.useState(
    initialValues.businessPhone3
  );
  const [yearsOnJob, setYearsOnJob] = React.useState(initialValues.yearsOnJob);
  const [yearsInThisProf, setYearsInThisProf] = React.useState(
    initialValues.yearsInThisProf
  );
  const [employDates2, setEmployDates2] = React.useState(
    initialValues.employDates2
  );
  const [employDates3, setEmployDates3] = React.useState(
    initialValues.employDates3
  );
  const [income2, setIncome2] = React.useState(initialValues.income2);
  const [income3, setIncome3] = React.useState(initialValues.income3);
  const [completedJointly, setCompletedJointly] = React.useState(
    initialValues.completedJointly
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = employmentInfoRecord
      ? { ...initialValues, ...employmentInfoRecord }
      : initialValues;
    setEmployer(cleanValues.employer);
    setSelfEmployed(cleanValues.selfEmployed);
    setJobPosition(cleanValues.jobPosition);
    setBusinessPhone(cleanValues.businessPhone);
    setEmployer2(cleanValues.employer2);
    setEmployer3(cleanValues.employer3);
    setSelfEmployed2(cleanValues.selfEmployed2);
    setSelfEmployed3(cleanValues.selfEmployed3);
    setJobPosition2(cleanValues.jobPosition2);
    setJobPosition3(cleanValues.jobPosition3);
    setBusinessPhone2(cleanValues.businessPhone2);
    setBusinessPhone3(cleanValues.businessPhone3);
    setYearsOnJob(cleanValues.yearsOnJob);
    setYearsInThisProf(cleanValues.yearsInThisProf);
    setEmployDates2(cleanValues.employDates2);
    setEmployDates3(cleanValues.employDates3);
    setIncome2(cleanValues.income2);
    setIncome3(cleanValues.income3);
    setCompletedJointly(cleanValues.completedJointly);
    setErrors({});
  };
  const [employmentInfoRecord, setEmploymentInfoRecord] = React.useState(
    employmentInfoModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getEmploymentInfo.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getEmploymentInfo
        : employmentInfoModelProp;
      setEmploymentInfoRecord(record);
    };
    queryData();
  }, [idProp, employmentInfoModelProp]);
  React.useEffect(resetStateValues, [employmentInfoRecord]);
  const validations = {
    employer: [],
    selfEmployed: [],
    jobPosition: [],
    businessPhone: [{ type: "Phone" }],
    employer2: [],
    employer3: [],
    selfEmployed2: [],
    selfEmployed3: [],
    jobPosition2: [],
    jobPosition3: [],
    businessPhone2: [],
    businessPhone3: [],
    yearsOnJob: [],
    yearsInThisProf: [],
    employDates2: [],
    employDates3: [],
    income2: [],
    income3: [],
    completedJointly: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          employer: employer ?? null,
          selfEmployed: selfEmployed ?? null,
          jobPosition: jobPosition ?? null,
          businessPhone: businessPhone ?? null,
          employer2: employer2 ?? null,
          employer3: employer3 ?? null,
          selfEmployed2: selfEmployed2 ?? null,
          selfEmployed3: selfEmployed3 ?? null,
          jobPosition2: jobPosition2 ?? null,
          jobPosition3: jobPosition3 ?? null,
          businessPhone2: businessPhone2 ?? null,
          businessPhone3: businessPhone3 ?? null,
          yearsOnJob: yearsOnJob ?? null,
          yearsInThisProf: yearsInThisProf ?? null,
          employDates2: employDates2 ?? null,
          employDates3: employDates3 ?? null,
          income2: income2 ?? null,
          income3: income3 ?? null,
          completedJointly: completedJointly ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateEmploymentInfo.replaceAll("__typename", ""),
            variables: {
              input: {
                id: employmentInfoRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "EmploymentInfoUpdateForm")}
      {...rest}
    >
      <TextField
        label="Employer"
        isRequired={false}
        isReadOnly={false}
        value={employer}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer: value,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.employer ?? value;
          }
          if (errors.employer?.hasError) {
            runValidationTasks("employer", value);
          }
          setEmployer(value);
        }}
        onBlur={() => runValidationTasks("employer", employer)}
        errorMessage={errors.employer?.errorMessage}
        hasError={errors.employer?.hasError}
        {...getOverrideProps(overrides, "employer")}
      ></TextField>
      <SwitchField
        label="Self employed"
        defaultChecked={false}
        isDisabled={false}
        isChecked={selfEmployed}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed: value,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.selfEmployed ?? value;
          }
          if (errors.selfEmployed?.hasError) {
            runValidationTasks("selfEmployed", value);
          }
          setSelfEmployed(value);
        }}
        onBlur={() => runValidationTasks("selfEmployed", selfEmployed)}
        errorMessage={errors.selfEmployed?.errorMessage}
        hasError={errors.selfEmployed?.hasError}
        {...getOverrideProps(overrides, "selfEmployed")}
      ></SwitchField>
      <TextField
        label="Job position"
        isRequired={false}
        isReadOnly={false}
        value={jobPosition}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition: value,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.jobPosition ?? value;
          }
          if (errors.jobPosition?.hasError) {
            runValidationTasks("jobPosition", value);
          }
          setJobPosition(value);
        }}
        onBlur={() => runValidationTasks("jobPosition", jobPosition)}
        errorMessage={errors.jobPosition?.errorMessage}
        hasError={errors.jobPosition?.hasError}
        {...getOverrideProps(overrides, "jobPosition")}
      ></TextField>
      <TextField
        label="Business phone"
        isRequired={false}
        isReadOnly={false}
        type="tel"
        value={businessPhone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone: value,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.businessPhone ?? value;
          }
          if (errors.businessPhone?.hasError) {
            runValidationTasks("businessPhone", value);
          }
          setBusinessPhone(value);
        }}
        onBlur={() => runValidationTasks("businessPhone", businessPhone)}
        errorMessage={errors.businessPhone?.errorMessage}
        hasError={errors.businessPhone?.hasError}
        {...getOverrideProps(overrides, "businessPhone")}
      ></TextField>
      <TextField
        label="Employer2"
        isRequired={false}
        isReadOnly={false}
        value={employer2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2: value,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.employer2 ?? value;
          }
          if (errors.employer2?.hasError) {
            runValidationTasks("employer2", value);
          }
          setEmployer2(value);
        }}
        onBlur={() => runValidationTasks("employer2", employer2)}
        errorMessage={errors.employer2?.errorMessage}
        hasError={errors.employer2?.hasError}
        {...getOverrideProps(overrides, "employer2")}
      ></TextField>
      <TextField
        label="Employer3"
        isRequired={false}
        isReadOnly={false}
        value={employer3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3: value,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.employer3 ?? value;
          }
          if (errors.employer3?.hasError) {
            runValidationTasks("employer3", value);
          }
          setEmployer3(value);
        }}
        onBlur={() => runValidationTasks("employer3", employer3)}
        errorMessage={errors.employer3?.errorMessage}
        hasError={errors.employer3?.hasError}
        {...getOverrideProps(overrides, "employer3")}
      ></TextField>
      <SwitchField
        label="Self employed2"
        defaultChecked={false}
        isDisabled={false}
        isChecked={selfEmployed2}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2: value,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.selfEmployed2 ?? value;
          }
          if (errors.selfEmployed2?.hasError) {
            runValidationTasks("selfEmployed2", value);
          }
          setSelfEmployed2(value);
        }}
        onBlur={() => runValidationTasks("selfEmployed2", selfEmployed2)}
        errorMessage={errors.selfEmployed2?.errorMessage}
        hasError={errors.selfEmployed2?.hasError}
        {...getOverrideProps(overrides, "selfEmployed2")}
      ></SwitchField>
      <SwitchField
        label="Self employed3"
        defaultChecked={false}
        isDisabled={false}
        isChecked={selfEmployed3}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3: value,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.selfEmployed3 ?? value;
          }
          if (errors.selfEmployed3?.hasError) {
            runValidationTasks("selfEmployed3", value);
          }
          setSelfEmployed3(value);
        }}
        onBlur={() => runValidationTasks("selfEmployed3", selfEmployed3)}
        errorMessage={errors.selfEmployed3?.errorMessage}
        hasError={errors.selfEmployed3?.hasError}
        {...getOverrideProps(overrides, "selfEmployed3")}
      ></SwitchField>
      <TextField
        label="Job position2"
        isRequired={false}
        isReadOnly={false}
        value={jobPosition2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2: value,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.jobPosition2 ?? value;
          }
          if (errors.jobPosition2?.hasError) {
            runValidationTasks("jobPosition2", value);
          }
          setJobPosition2(value);
        }}
        onBlur={() => runValidationTasks("jobPosition2", jobPosition2)}
        errorMessage={errors.jobPosition2?.errorMessage}
        hasError={errors.jobPosition2?.hasError}
        {...getOverrideProps(overrides, "jobPosition2")}
      ></TextField>
      <TextField
        label="Job position3"
        isRequired={false}
        isReadOnly={false}
        value={jobPosition3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3: value,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.jobPosition3 ?? value;
          }
          if (errors.jobPosition3?.hasError) {
            runValidationTasks("jobPosition3", value);
          }
          setJobPosition3(value);
        }}
        onBlur={() => runValidationTasks("jobPosition3", jobPosition3)}
        errorMessage={errors.jobPosition3?.errorMessage}
        hasError={errors.jobPosition3?.hasError}
        {...getOverrideProps(overrides, "jobPosition3")}
      ></TextField>
      <TextField
        label="Business phone2"
        isRequired={false}
        isReadOnly={false}
        value={businessPhone2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2: value,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.businessPhone2 ?? value;
          }
          if (errors.businessPhone2?.hasError) {
            runValidationTasks("businessPhone2", value);
          }
          setBusinessPhone2(value);
        }}
        onBlur={() => runValidationTasks("businessPhone2", businessPhone2)}
        errorMessage={errors.businessPhone2?.errorMessage}
        hasError={errors.businessPhone2?.hasError}
        {...getOverrideProps(overrides, "businessPhone2")}
      ></TextField>
      <TextField
        label="Business phone3"
        isRequired={false}
        isReadOnly={false}
        value={businessPhone3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3: value,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.businessPhone3 ?? value;
          }
          if (errors.businessPhone3?.hasError) {
            runValidationTasks("businessPhone3", value);
          }
          setBusinessPhone3(value);
        }}
        onBlur={() => runValidationTasks("businessPhone3", businessPhone3)}
        errorMessage={errors.businessPhone3?.errorMessage}
        hasError={errors.businessPhone3?.hasError}
        {...getOverrideProps(overrides, "businessPhone3")}
      ></TextField>
      <TextField
        label="Years on job"
        isRequired={false}
        isReadOnly={false}
        value={yearsOnJob}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob: value,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.yearsOnJob ?? value;
          }
          if (errors.yearsOnJob?.hasError) {
            runValidationTasks("yearsOnJob", value);
          }
          setYearsOnJob(value);
        }}
        onBlur={() => runValidationTasks("yearsOnJob", yearsOnJob)}
        errorMessage={errors.yearsOnJob?.errorMessage}
        hasError={errors.yearsOnJob?.hasError}
        {...getOverrideProps(overrides, "yearsOnJob")}
      ></TextField>
      <TextField
        label="Years in this prof"
        isRequired={false}
        isReadOnly={false}
        value={yearsInThisProf}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf: value,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.yearsInThisProf ?? value;
          }
          if (errors.yearsInThisProf?.hasError) {
            runValidationTasks("yearsInThisProf", value);
          }
          setYearsInThisProf(value);
        }}
        onBlur={() => runValidationTasks("yearsInThisProf", yearsInThisProf)}
        errorMessage={errors.yearsInThisProf?.errorMessage}
        hasError={errors.yearsInThisProf?.hasError}
        {...getOverrideProps(overrides, "yearsInThisProf")}
      ></TextField>
      <TextField
        label="Employ dates2"
        isRequired={false}
        isReadOnly={false}
        value={employDates2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2: value,
              employDates3,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.employDates2 ?? value;
          }
          if (errors.employDates2?.hasError) {
            runValidationTasks("employDates2", value);
          }
          setEmployDates2(value);
        }}
        onBlur={() => runValidationTasks("employDates2", employDates2)}
        errorMessage={errors.employDates2?.errorMessage}
        hasError={errors.employDates2?.hasError}
        {...getOverrideProps(overrides, "employDates2")}
      ></TextField>
      <TextField
        label="Employ dates3"
        isRequired={false}
        isReadOnly={false}
        value={employDates3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3: value,
              income2,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.employDates3 ?? value;
          }
          if (errors.employDates3?.hasError) {
            runValidationTasks("employDates3", value);
          }
          setEmployDates3(value);
        }}
        onBlur={() => runValidationTasks("employDates3", employDates3)}
        errorMessage={errors.employDates3?.errorMessage}
        hasError={errors.employDates3?.hasError}
        {...getOverrideProps(overrides, "employDates3")}
      ></TextField>
      <TextField
        label="Income2"
        isRequired={false}
        isReadOnly={false}
        value={income2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2: value,
              income3,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.income2 ?? value;
          }
          if (errors.income2?.hasError) {
            runValidationTasks("income2", value);
          }
          setIncome2(value);
        }}
        onBlur={() => runValidationTasks("income2", income2)}
        errorMessage={errors.income2?.errorMessage}
        hasError={errors.income2?.hasError}
        {...getOverrideProps(overrides, "income2")}
      ></TextField>
      <TextField
        label="Income3"
        isRequired={false}
        isReadOnly={false}
        value={income3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3: value,
              completedJointly,
            };
            const result = onChange(modelFields);
            value = result?.income3 ?? value;
          }
          if (errors.income3?.hasError) {
            runValidationTasks("income3", value);
          }
          setIncome3(value);
        }}
        onBlur={() => runValidationTasks("income3", income3)}
        errorMessage={errors.income3?.errorMessage}
        hasError={errors.income3?.hasError}
        {...getOverrideProps(overrides, "income3")}
      ></TextField>
      <SwitchField
        label="Completed jointly"
        defaultChecked={false}
        isDisabled={false}
        isChecked={completedJointly}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              employer,
              selfEmployed,
              jobPosition,
              businessPhone,
              employer2,
              employer3,
              selfEmployed2,
              selfEmployed3,
              jobPosition2,
              jobPosition3,
              businessPhone2,
              businessPhone3,
              yearsOnJob,
              yearsInThisProf,
              employDates2,
              employDates3,
              income2,
              income3,
              completedJointly: value,
            };
            const result = onChange(modelFields);
            value = result?.completedJointly ?? value;
          }
          if (errors.completedJointly?.hasError) {
            runValidationTasks("completedJointly", value);
          }
          setCompletedJointly(value);
        }}
        onBlur={() => runValidationTasks("completedJointly", completedJointly)}
        errorMessage={errors.completedJointly?.errorMessage}
        hasError={errors.completedJointly?.hasError}
        {...getOverrideProps(overrides, "completedJointly")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || employmentInfoModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || employmentInfoModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
