import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './index.css';

import reportWebVitals from './reportWebVitals';
import { Amplify } from "aws-amplify";

import App from './App'; // landing page

import UserForms from './UserForms';
import AboutGobdu from './AboutGobdu';
import OurTeam from './OurTeam';
import Services from './Services';
import ContactUs from './ContactUs';
import Terms from './Terms';
import Privacy from './Privacy';
import Disclaimer from './Disclaimer';
import LoginUser from './LoginUser';
import RenewLicense from './BuyLicense';

import awsconfig from './aws-exports';
import "@aws-amplify/ui-react/styles.css";


Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />         
                <Route path="/profile" element={<UserForms />} /> 
                <Route path="/aboutgobdu" element={<AboutGobdu />} />
                <Route path="/ourteam" element={<OurTeam />} />
                <Route path="/about" element={<OurTeam />} />
                <Route path="/logout" element={<AboutGobdu />} />
                <Route path="/services" element={<Services />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/terms-and-conditions" element={<Terms />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/login" element={<LoginUser />} />
                <Route path="/renewLicense" element={<RenewLicense />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
