/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Radio,
  RadioGroupField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createDeclarations } from "../graphql/mutations";
const client = generateClient();
export default function DeclarationsCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    borrowerOutstandingJudgments: undefined,
    coborrowerOutstandingJudgments: undefined,
    borrowerBankrupt: undefined,
    coborrowerBankrupt: undefined,
    borrowerForeclosed: undefined,
    coborrowerForeclosed: undefined,
    borrowerLawsuit: undefined,
    coborrowerLawsuit: undefined,
    borrowerObligatedLoan: undefined,
    coborrowerObligatedLoan: undefined,
    borrowerDelinquent: undefined,
    coborrowerDelinquent: undefined,
    borrowerObligatedMaintenance: undefined,
    coborrowerObligatedMaintenance: undefined,
    borrowerDownpaymentBorrowed: undefined,
    coborrowerDownpaymentBorrowed: undefined,
    borrowerNote: undefined,
    coborrowerNote: undefined,
    borrowerCitizen: undefined,
    coborrowerCitizen: undefined,
    borrowerResidentAlien: undefined,
    coborrowerResidentAlien: undefined,
    borrowerPrimaryResidence: undefined,
    coborrowerPrimaryResidence: undefined,
    borrowerOwnership: undefined,
    coborrowerOwnership: undefined,
    borrowerPropertyOwned: "",
    coborrowerPropertyOwned: "",
    borrowerTitleHeld: "",
    coborrowerTitleHeld: "",
  };
  const [borrowerOutstandingJudgments, setBorrowerOutstandingJudgments] =
    React.useState(initialValues.borrowerOutstandingJudgments);
  const [coborrowerOutstandingJudgments, setCoborrowerOutstandingJudgments] =
    React.useState(initialValues.coborrowerOutstandingJudgments);
  const [borrowerBankrupt, setBorrowerBankrupt] = React.useState(
    initialValues.borrowerBankrupt
  );
  const [coborrowerBankrupt, setCoborrowerBankrupt] = React.useState(
    initialValues.coborrowerBankrupt
  );
  const [borrowerForeclosed, setBorrowerForeclosed] = React.useState(
    initialValues.borrowerForeclosed
  );
  const [coborrowerForeclosed, setCoborrowerForeclosed] = React.useState(
    initialValues.coborrowerForeclosed
  );
  const [borrowerLawsuit, setBorrowerLawsuit] = React.useState(
    initialValues.borrowerLawsuit
  );
  const [coborrowerLawsuit, setCoborrowerLawsuit] = React.useState(
    initialValues.coborrowerLawsuit
  );
  const [borrowerObligatedLoan, setBorrowerObligatedLoan] = React.useState(
    initialValues.borrowerObligatedLoan
  );
  const [coborrowerObligatedLoan, setCoborrowerObligatedLoan] = React.useState(
    initialValues.coborrowerObligatedLoan
  );
  const [borrowerDelinquent, setBorrowerDelinquent] = React.useState(
    initialValues.borrowerDelinquent
  );
  const [coborrowerDelinquent, setCoborrowerDelinquent] = React.useState(
    initialValues.coborrowerDelinquent
  );
  const [borrowerObligatedMaintenance, setBorrowerObligatedMaintenance] =
    React.useState(initialValues.borrowerObligatedMaintenance);
  const [coborrowerObligatedMaintenance, setCoborrowerObligatedMaintenance] =
    React.useState(initialValues.coborrowerObligatedMaintenance);
  const [borrowerDownpaymentBorrowed, setBorrowerDownpaymentBorrowed] =
    React.useState(initialValues.borrowerDownpaymentBorrowed);
  const [coborrowerDownpaymentBorrowed, setCoborrowerDownpaymentBorrowed] =
    React.useState(initialValues.coborrowerDownpaymentBorrowed);
  const [borrowerNote, setBorrowerNote] = React.useState(
    initialValues.borrowerNote
  );
  const [coborrowerNote, setCoborrowerNote] = React.useState(
    initialValues.coborrowerNote
  );
  const [borrowerCitizen, setBorrowerCitizen] = React.useState(
    initialValues.borrowerCitizen
  );
  const [coborrowerCitizen, setCoborrowerCitizen] = React.useState(
    initialValues.coborrowerCitizen
  );
  const [borrowerResidentAlien, setBorrowerResidentAlien] = React.useState(
    initialValues.borrowerResidentAlien
  );
  const [coborrowerResidentAlien, setCoborrowerResidentAlien] = React.useState(
    initialValues.coborrowerResidentAlien
  );
  const [borrowerPrimaryResidence, setBorrowerPrimaryResidence] =
    React.useState(initialValues.borrowerPrimaryResidence);
  const [coborrowerPrimaryResidence, setCoborrowerPrimaryResidence] =
    React.useState(initialValues.coborrowerPrimaryResidence);
  const [borrowerOwnership, setBorrowerOwnership] = React.useState(
    initialValues.borrowerOwnership
  );
  const [coborrowerOwnership, setCoborrowerOwnership] = React.useState(
    initialValues.coborrowerOwnership
  );
  const [borrowerPropertyOwned, setBorrowerPropertyOwned] = React.useState(
    initialValues.borrowerPropertyOwned
  );
  const [coborrowerPropertyOwned, setCoborrowerPropertyOwned] = React.useState(
    initialValues.coborrowerPropertyOwned
  );
  const [borrowerTitleHeld, setBorrowerTitleHeld] = React.useState(
    initialValues.borrowerTitleHeld
  );
  const [coborrowerTitleHeld, setCoborrowerTitleHeld] = React.useState(
    initialValues.coborrowerTitleHeld
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setBorrowerOutstandingJudgments(initialValues.borrowerOutstandingJudgments);
    setCoborrowerOutstandingJudgments(
      initialValues.coborrowerOutstandingJudgments
    );
    setBorrowerBankrupt(initialValues.borrowerBankrupt);
    setCoborrowerBankrupt(initialValues.coborrowerBankrupt);
    setBorrowerForeclosed(initialValues.borrowerForeclosed);
    setCoborrowerForeclosed(initialValues.coborrowerForeclosed);
    setBorrowerLawsuit(initialValues.borrowerLawsuit);
    setCoborrowerLawsuit(initialValues.coborrowerLawsuit);
    setBorrowerObligatedLoan(initialValues.borrowerObligatedLoan);
    setCoborrowerObligatedLoan(initialValues.coborrowerObligatedLoan);
    setBorrowerDelinquent(initialValues.borrowerDelinquent);
    setCoborrowerDelinquent(initialValues.coborrowerDelinquent);
    setBorrowerObligatedMaintenance(initialValues.borrowerObligatedMaintenance);
    setCoborrowerObligatedMaintenance(
      initialValues.coborrowerObligatedMaintenance
    );
    setBorrowerDownpaymentBorrowed(initialValues.borrowerDownpaymentBorrowed);
    setCoborrowerDownpaymentBorrowed(
      initialValues.coborrowerDownpaymentBorrowed
    );
    setBorrowerNote(initialValues.borrowerNote);
    setCoborrowerNote(initialValues.coborrowerNote);
    setBorrowerCitizen(initialValues.borrowerCitizen);
    setCoborrowerCitizen(initialValues.coborrowerCitizen);
    setBorrowerResidentAlien(initialValues.borrowerResidentAlien);
    setCoborrowerResidentAlien(initialValues.coborrowerResidentAlien);
    setBorrowerPrimaryResidence(initialValues.borrowerPrimaryResidence);
    setCoborrowerPrimaryResidence(initialValues.coborrowerPrimaryResidence);
    setBorrowerOwnership(initialValues.borrowerOwnership);
    setCoborrowerOwnership(initialValues.coborrowerOwnership);
    setBorrowerPropertyOwned(initialValues.borrowerPropertyOwned);
    setCoborrowerPropertyOwned(initialValues.coborrowerPropertyOwned);
    setBorrowerTitleHeld(initialValues.borrowerTitleHeld);
    setCoborrowerTitleHeld(initialValues.coborrowerTitleHeld);
    setErrors({});
  };
  const validations = {
    borrowerOutstandingJudgments: [],
    coborrowerOutstandingJudgments: [],
    borrowerBankrupt: [],
    coborrowerBankrupt: [],
    borrowerForeclosed: [],
    coborrowerForeclosed: [],
    borrowerLawsuit: [],
    coborrowerLawsuit: [],
    borrowerObligatedLoan: [],
    coborrowerObligatedLoan: [],
    borrowerDelinquent: [],
    coborrowerDelinquent: [],
    borrowerObligatedMaintenance: [],
    coborrowerObligatedMaintenance: [],
    borrowerDownpaymentBorrowed: [],
    coborrowerDownpaymentBorrowed: [],
    borrowerNote: [],
    coborrowerNote: [],
    borrowerCitizen: [],
    coborrowerCitizen: [],
    borrowerResidentAlien: [],
    coborrowerResidentAlien: [],
    borrowerPrimaryResidence: [],
    coborrowerPrimaryResidence: [],
    borrowerOwnership: [],
    coborrowerOwnership: [],
    borrowerPropertyOwned: [],
    coborrowerPropertyOwned: [],
    borrowerTitleHeld: [],
    coborrowerTitleHeld: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          borrowerOutstandingJudgments,
          coborrowerOutstandingJudgments,
          borrowerBankrupt,
          coborrowerBankrupt,
          borrowerForeclosed,
          coborrowerForeclosed,
          borrowerLawsuit,
          coborrowerLawsuit,
          borrowerObligatedLoan,
          coborrowerObligatedLoan,
          borrowerDelinquent,
          coborrowerDelinquent,
          borrowerObligatedMaintenance,
          coborrowerObligatedMaintenance,
          borrowerDownpaymentBorrowed,
          coborrowerDownpaymentBorrowed,
          borrowerNote,
          coborrowerNote,
          borrowerCitizen,
          coborrowerCitizen,
          borrowerResidentAlien,
          coborrowerResidentAlien,
          borrowerPrimaryResidence,
          coborrowerPrimaryResidence,
          borrowerOwnership,
          coborrowerOwnership,
          borrowerPropertyOwned,
          coborrowerPropertyOwned,
          borrowerTitleHeld,
          coborrowerTitleHeld,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createDeclarations.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "DeclarationsCreateForm")}
      {...rest}
    >
      <Heading
        children="If you answer “Yes” to any question a through i, please use continuation sheet for explanation."
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Text
        children="a. Are there any outstanding judgments against you?"
        {...getOverrideProps(overrides, "SectionalElement13")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid2")}
      >
        <RadioGroupField
          label="Borrower"
          name="borrowerOutstandingJudgments"
          isReadOnly={false}
          descriptiveText="Borrower"
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments: value,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerOutstandingJudgments ?? value;
            }
            if (errors.borrowerOutstandingJudgments?.hasError) {
              runValidationTasks("borrowerOutstandingJudgments", value);
            }
            setBorrowerOutstandingJudgments(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "borrowerOutstandingJudgments",
              borrowerOutstandingJudgments
            )
          }
          errorMessage={errors.borrowerOutstandingJudgments?.errorMessage}
          hasError={errors.borrowerOutstandingJudgments?.hasError}
          {...getOverrideProps(overrides, "borrowerOutstandingJudgments")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(
              overrides,
              "borrowerOutstandingJudgmentsRadio0"
            )}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(
              overrides,
              "borrowerOutstandingJudgmentsRadio1"
            )}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower "
          name="coborrowerOutstandingJudgments"
          isReadOnly={false}
          descriptiveText="Co-Borrower"
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments: value,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerOutstandingJudgments ?? value;
            }
            if (errors.coborrowerOutstandingJudgments?.hasError) {
              runValidationTasks("coborrowerOutstandingJudgments", value);
            }
            setCoborrowerOutstandingJudgments(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "coborrowerOutstandingJudgments",
              coborrowerOutstandingJudgments
            )
          }
          errorMessage={errors.coborrowerOutstandingJudgments?.errorMessage}
          hasError={errors.coborrowerOutstandingJudgments?.hasError}
          {...getOverrideProps(overrides, "coborrowerOutstandingJudgments")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(
              overrides,
              "coborrowerOutstandingJudgmentsRadio0"
            )}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(
              overrides,
              "coborrowerOutstandingJudgmentsRadio1"
            )}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Text
        children="b. Have you been declared bankrupt within the past 7 years?"
        {...getOverrideProps(overrides, "SectionalElement14")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid4")}
      >
        <RadioGroupField
          label="Borrower "
          name="borrowerBankrupt"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt: value,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerBankrupt ?? value;
            }
            if (errors.borrowerBankrupt?.hasError) {
              runValidationTasks("borrowerBankrupt", value);
            }
            setBorrowerBankrupt(value);
          }}
          onBlur={() =>
            runValidationTasks("borrowerBankrupt", borrowerBankrupt)
          }
          errorMessage={errors.borrowerBankrupt?.errorMessage}
          hasError={errors.borrowerBankrupt?.hasError}
          {...getOverrideProps(overrides, "borrowerBankrupt")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "borrowerBankruptRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "borrowerBankruptRadio1")}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower "
          name="coborrowerBankrupt"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt: value,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerBankrupt ?? value;
            }
            if (errors.coborrowerBankrupt?.hasError) {
              runValidationTasks("coborrowerBankrupt", value);
            }
            setCoborrowerBankrupt(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrowerBankrupt", coborrowerBankrupt)
          }
          errorMessage={errors.coborrowerBankrupt?.errorMessage}
          hasError={errors.coborrowerBankrupt?.hasError}
          {...getOverrideProps(overrides, "coborrowerBankrupt")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "coborrowerBankruptRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "coborrowerBankruptRadio1")}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Text
        children="c. Have you had property foreclosed upon or given title or deed in lieu thereof in the last 7 years?"
        {...getOverrideProps(overrides, "SectionalElement15")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <RadioGroupField
          label="Borrower "
          name="borrowerForeclosed"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed: value,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerForeclosed ?? value;
            }
            if (errors.borrowerForeclosed?.hasError) {
              runValidationTasks("borrowerForeclosed", value);
            }
            setBorrowerForeclosed(value);
          }}
          onBlur={() =>
            runValidationTasks("borrowerForeclosed", borrowerForeclosed)
          }
          errorMessage={errors.borrowerForeclosed?.errorMessage}
          hasError={errors.borrowerForeclosed?.hasError}
          {...getOverrideProps(overrides, "borrowerForeclosed")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "borrowerForeclosedRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "borrowerForeclosedRadio1")}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower "
          name="coborrowerForeclosed"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed: value,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerForeclosed ?? value;
            }
            if (errors.coborrowerForeclosed?.hasError) {
              runValidationTasks("coborrowerForeclosed", value);
            }
            setCoborrowerForeclosed(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrowerForeclosed", coborrowerForeclosed)
          }
          errorMessage={errors.coborrowerForeclosed?.errorMessage}
          hasError={errors.coborrowerForeclosed?.hasError}
          {...getOverrideProps(overrides, "coborrowerForeclosed")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "coborrowerForeclosedRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "coborrowerForeclosedRadio1")}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Text
        children="d. Are you a party to a lawsuit?"
        {...getOverrideProps(overrides, "SectionalElement16")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <RadioGroupField
          label="Borrower "
          name="borrowerLawsuit"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit: value,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerLawsuit ?? value;
            }
            if (errors.borrowerLawsuit?.hasError) {
              runValidationTasks("borrowerLawsuit", value);
            }
            setBorrowerLawsuit(value);
          }}
          onBlur={() => runValidationTasks("borrowerLawsuit", borrowerLawsuit)}
          errorMessage={errors.borrowerLawsuit?.errorMessage}
          hasError={errors.borrowerLawsuit?.hasError}
          {...getOverrideProps(overrides, "borrowerLawsuit")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "borrowerLawsuitRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "borrowerLawsuitRadio1")}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower "
          name="coborrowerLawsuit"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit: value,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerLawsuit ?? value;
            }
            if (errors.coborrowerLawsuit?.hasError) {
              runValidationTasks("coborrowerLawsuit", value);
            }
            setCoborrowerLawsuit(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrowerLawsuit", coborrowerLawsuit)
          }
          errorMessage={errors.coborrowerLawsuit?.errorMessage}
          hasError={errors.coborrowerLawsuit?.hasError}
          {...getOverrideProps(overrides, "coborrowerLawsuit")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "coborrowerLawsuitRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "coborrowerLawsuitRadio1")}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Text
        children="e. Have you directly or indirectly been obligated on any loan which resulted in foreclosure, transfer of title in lieu of foreclosure, or judgment?"
        {...getOverrideProps(overrides, "SectionalElement17")}
      ></Text>
      <Text
        children="(This would include such loans as home mortgage loans, SBA loans, home improvement loans, educational loans, manufactured (mobile) home loans, any mortgage, financial obligation, bond, or loan guarantee. If “Yes,” provide details, including date, name, and address of Lender, FHA or VA case number, if any, and reasons for the action.)"
        {...getOverrideProps(overrides, "SectionalElement18")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid11")}
      >
        <RadioGroupField
          label="Borrower"
          name="borrowerObligatedLoan"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan: value,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerObligatedLoan ?? value;
            }
            if (errors.borrowerObligatedLoan?.hasError) {
              runValidationTasks("borrowerObligatedLoan", value);
            }
            setBorrowerObligatedLoan(value);
          }}
          onBlur={() =>
            runValidationTasks("borrowerObligatedLoan", borrowerObligatedLoan)
          }
          errorMessage={errors.borrowerObligatedLoan?.errorMessage}
          hasError={errors.borrowerObligatedLoan?.hasError}
          {...getOverrideProps(overrides, "borrowerObligatedLoan")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "borrowerObligatedLoanRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "borrowerObligatedLoanRadio1")}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower"
          name="coborrowerObligatedLoan"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan: value,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerObligatedLoan ?? value;
            }
            if (errors.coborrowerObligatedLoan?.hasError) {
              runValidationTasks("coborrowerObligatedLoan", value);
            }
            setCoborrowerObligatedLoan(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "coborrowerObligatedLoan",
              coborrowerObligatedLoan
            )
          }
          errorMessage={errors.coborrowerObligatedLoan?.errorMessage}
          hasError={errors.coborrowerObligatedLoan?.hasError}
          {...getOverrideProps(overrides, "coborrowerObligatedLoan")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "coborrowerObligatedLoanRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "coborrowerObligatedLoanRadio1")}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Text
        children="f. Are you presently delinquent or in default on any Federal debt or any other loan, mortgage, financial obligation, bond, or loan guarantee?"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid13")}
      >
        <RadioGroupField
          label="Borrower"
          name="borrowerDelinquent"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent: value,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerDelinquent ?? value;
            }
            if (errors.borrowerDelinquent?.hasError) {
              runValidationTasks("borrowerDelinquent", value);
            }
            setBorrowerDelinquent(value);
          }}
          onBlur={() =>
            runValidationTasks("borrowerDelinquent", borrowerDelinquent)
          }
          errorMessage={errors.borrowerDelinquent?.errorMessage}
          hasError={errors.borrowerDelinquent?.hasError}
          {...getOverrideProps(overrides, "borrowerDelinquent")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "borrowerDelinquentRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "borrowerDelinquentRadio1")}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower"
          name="coborrowerDelinquent"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent: value,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerDelinquent ?? value;
            }
            if (errors.coborrowerDelinquent?.hasError) {
              runValidationTasks("coborrowerDelinquent", value);
            }
            setCoborrowerDelinquent(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrowerDelinquent", coborrowerDelinquent)
          }
          errorMessage={errors.coborrowerDelinquent?.errorMessage}
          hasError={errors.coborrowerDelinquent?.hasError}
          {...getOverrideProps(overrides, "coborrowerDelinquent")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "coborrowerDelinquentRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "coborrowerDelinquentRadio1")}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Text
        children="g. Are you obligated to pay alimony, child support, or separate maintenance?"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid15")}
      >
        <RadioGroupField
          label="Borrower"
          name="borrowerObligatedMaintenance"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance: value,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerObligatedMaintenance ?? value;
            }
            if (errors.borrowerObligatedMaintenance?.hasError) {
              runValidationTasks("borrowerObligatedMaintenance", value);
            }
            setBorrowerObligatedMaintenance(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "borrowerObligatedMaintenance",
              borrowerObligatedMaintenance
            )
          }
          errorMessage={errors.borrowerObligatedMaintenance?.errorMessage}
          hasError={errors.borrowerObligatedMaintenance?.hasError}
          {...getOverrideProps(overrides, "borrowerObligatedMaintenance")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(
              overrides,
              "borrowerObligatedMaintenanceRadio0"
            )}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(
              overrides,
              "borrowerObligatedMaintenanceRadio1"
            )}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower"
          name="coborrowerObligatedMaintenance"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance: value,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerObligatedMaintenance ?? value;
            }
            if (errors.coborrowerObligatedMaintenance?.hasError) {
              runValidationTasks("coborrowerObligatedMaintenance", value);
            }
            setCoborrowerObligatedMaintenance(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "coborrowerObligatedMaintenance",
              coborrowerObligatedMaintenance
            )
          }
          errorMessage={errors.coborrowerObligatedMaintenance?.errorMessage}
          hasError={errors.coborrowerObligatedMaintenance?.hasError}
          {...getOverrideProps(overrides, "coborrowerObligatedMaintenance")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(
              overrides,
              "coborrowerObligatedMaintenanceRadio0"
            )}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(
              overrides,
              "coborrowerObligatedMaintenanceRadio1"
            )}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Text
        children="h. Is any part of the down payment borrowed?"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid17")}
      >
        <RadioGroupField
          label="Borrower"
          name="borrowerDownpaymentBorrowed"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed: value,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerDownpaymentBorrowed ?? value;
            }
            if (errors.borrowerDownpaymentBorrowed?.hasError) {
              runValidationTasks("borrowerDownpaymentBorrowed", value);
            }
            setBorrowerDownpaymentBorrowed(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "borrowerDownpaymentBorrowed",
              borrowerDownpaymentBorrowed
            )
          }
          errorMessage={errors.borrowerDownpaymentBorrowed?.errorMessage}
          hasError={errors.borrowerDownpaymentBorrowed?.hasError}
          {...getOverrideProps(overrides, "borrowerDownpaymentBorrowed")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(
              overrides,
              "borrowerDownpaymentBorrowedRadio0"
            )}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(
              overrides,
              "borrowerDownpaymentBorrowedRadio1"
            )}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower "
          name="coborrowerDownpaymentBorrowed"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed: value,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerDownpaymentBorrowed ?? value;
            }
            if (errors.coborrowerDownpaymentBorrowed?.hasError) {
              runValidationTasks("coborrowerDownpaymentBorrowed", value);
            }
            setCoborrowerDownpaymentBorrowed(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "coborrowerDownpaymentBorrowed",
              coborrowerDownpaymentBorrowed
            )
          }
          errorMessage={errors.coborrowerDownpaymentBorrowed?.errorMessage}
          hasError={errors.coborrowerDownpaymentBorrowed?.hasError}
          {...getOverrideProps(overrides, "coborrowerDownpaymentBorrowed")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(
              overrides,
              "coborrowerDownpaymentBorrowedRadio0"
            )}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(
              overrides,
              "coborrowerDownpaymentBorrowedRadio1"
            )}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Text
        children="i. Are you a co-maker or endorser on a note?"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid19")}
      >
        <RadioGroupField
          label="Borrower"
          name="borrowerNote"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote: value,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerNote ?? value;
            }
            if (errors.borrowerNote?.hasError) {
              runValidationTasks("borrowerNote", value);
            }
            setBorrowerNote(value);
          }}
          onBlur={() => runValidationTasks("borrowerNote", borrowerNote)}
          errorMessage={errors.borrowerNote?.errorMessage}
          hasError={errors.borrowerNote?.hasError}
          {...getOverrideProps(overrides, "borrowerNote")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "borrowerNoteRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "borrowerNoteRadio1")}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower"
          name="coborrowerNote"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote: value,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerNote ?? value;
            }
            if (errors.coborrowerNote?.hasError) {
              runValidationTasks("coborrowerNote", value);
            }
            setCoborrowerNote(value);
          }}
          onBlur={() => runValidationTasks("coborrowerNote", coborrowerNote)}
          errorMessage={errors.coborrowerNote?.errorMessage}
          hasError={errors.coborrowerNote?.hasError}
          {...getOverrideProps(overrides, "coborrowerNote")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "coborrowerNoteRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "coborrowerNoteRadio1")}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Divider>
      <Text
        children="j. Are you a U.S. citizen?"
        {...getOverrideProps(overrides, "SectionalElement6")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid22")}
      >
        <RadioGroupField
          label="Borrower "
          name="borrowerCitizen"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen: value,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerCitizen ?? value;
            }
            if (errors.borrowerCitizen?.hasError) {
              runValidationTasks("borrowerCitizen", value);
            }
            setBorrowerCitizen(value);
          }}
          onBlur={() => runValidationTasks("borrowerCitizen", borrowerCitizen)}
          errorMessage={errors.borrowerCitizen?.errorMessage}
          hasError={errors.borrowerCitizen?.hasError}
          {...getOverrideProps(overrides, "borrowerCitizen")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "borrowerCitizenRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "borrowerCitizenRadio1")}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower "
          name="coborrowerCitizen"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen: value,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerCitizen ?? value;
            }
            if (errors.coborrowerCitizen?.hasError) {
              runValidationTasks("coborrowerCitizen", value);
            }
            setCoborrowerCitizen(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrowerCitizen", coborrowerCitizen)
          }
          errorMessage={errors.coborrowerCitizen?.errorMessage}
          hasError={errors.coborrowerCitizen?.hasError}
          {...getOverrideProps(overrides, "coborrowerCitizen")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "coborrowerCitizenRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "coborrowerCitizenRadio1")}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Text
        children="k. Are you a permanent resident alien?"
        {...getOverrideProps(overrides, "SectionalElement7")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid24")}
      >
        <RadioGroupField
          label="Borrower "
          name="borrowerResidentAlien"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien: value,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerResidentAlien ?? value;
            }
            if (errors.borrowerResidentAlien?.hasError) {
              runValidationTasks("borrowerResidentAlien", value);
            }
            setBorrowerResidentAlien(value);
          }}
          onBlur={() =>
            runValidationTasks("borrowerResidentAlien", borrowerResidentAlien)
          }
          errorMessage={errors.borrowerResidentAlien?.errorMessage}
          hasError={errors.borrowerResidentAlien?.hasError}
          {...getOverrideProps(overrides, "borrowerResidentAlien")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "borrowerResidentAlienRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "borrowerResidentAlienRadio1")}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower "
          name="coborrowerResidentAlien"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien: value,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerResidentAlien ?? value;
            }
            if (errors.coborrowerResidentAlien?.hasError) {
              runValidationTasks("coborrowerResidentAlien", value);
            }
            setCoborrowerResidentAlien(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "coborrowerResidentAlien",
              coborrowerResidentAlien
            )
          }
          errorMessage={errors.coborrowerResidentAlien?.errorMessage}
          hasError={errors.coborrowerResidentAlien?.hasError}
          {...getOverrideProps(overrides, "coborrowerResidentAlien")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "coborrowerResidentAlienRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "coborrowerResidentAlienRadio1")}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Heading
        children="l. Do you intend to occupy the property as your primary residence?"
        {...getOverrideProps(overrides, "SectionalElement8")}
      ></Heading>
      <Text
        children='If "Yes”, complete question m below.'
        {...getOverrideProps(overrides, "SectionalElement9")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid27")}
      >
        <RadioGroupField
          label="Borrower "
          name="borrowerPrimaryResidence"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence: value,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerPrimaryResidence ?? value;
            }
            if (errors.borrowerPrimaryResidence?.hasError) {
              runValidationTasks("borrowerPrimaryResidence", value);
            }
            setBorrowerPrimaryResidence(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "borrowerPrimaryResidence",
              borrowerPrimaryResidence
            )
          }
          errorMessage={errors.borrowerPrimaryResidence?.errorMessage}
          hasError={errors.borrowerPrimaryResidence?.hasError}
          {...getOverrideProps(overrides, "borrowerPrimaryResidence")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "borrowerPrimaryResidenceRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "borrowerPrimaryResidenceRadio1")}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower "
          name="coborrowerPrimaryResidence"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence: value,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerPrimaryResidence ?? value;
            }
            if (errors.coborrowerPrimaryResidence?.hasError) {
              runValidationTasks("coborrowerPrimaryResidence", value);
            }
            setCoborrowerPrimaryResidence(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "coborrowerPrimaryResidence",
              coborrowerPrimaryResidence
            )
          }
          errorMessage={errors.coborrowerPrimaryResidence?.errorMessage}
          hasError={errors.coborrowerPrimaryResidence?.hasError}
          {...getOverrideProps(overrides, "coborrowerPrimaryResidence")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "coborrowerPrimaryResidenceRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "coborrowerPrimaryResidenceRadio1")}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Text
        children="m. Have you had an ownership interest in a property in the last three years?"
        {...getOverrideProps(overrides, "SectionalElement10")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid29")}
      >
        <RadioGroupField
          label="Borrower"
          name="borrowerOwnership"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership: value,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerOwnership ?? value;
            }
            if (errors.borrowerOwnership?.hasError) {
              runValidationTasks("borrowerOwnership", value);
            }
            setBorrowerOwnership(value);
          }}
          onBlur={() =>
            runValidationTasks("borrowerOwnership", borrowerOwnership)
          }
          errorMessage={errors.borrowerOwnership?.errorMessage}
          hasError={errors.borrowerOwnership?.hasError}
          {...getOverrideProps(overrides, "borrowerOwnership")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "borrowerOwnershipRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "borrowerOwnershipRadio1")}
          ></Radio>
        </RadioGroupField>
        <RadioGroupField
          label="Coborrower "
          name="coborrowerOwnership"
          isReadOnly={false}
          isRequired={false}
          onChange={(e) => {
            let value = e.target.value === "true";
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership: value,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerOwnership ?? value;
            }
            if (errors.coborrowerOwnership?.hasError) {
              runValidationTasks("coborrowerOwnership", value);
            }
            setCoborrowerOwnership(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrowerOwnership", coborrowerOwnership)
          }
          errorMessage={errors.coborrowerOwnership?.errorMessage}
          hasError={errors.coborrowerOwnership?.hasError}
          {...getOverrideProps(overrides, "coborrowerOwnership")}
        >
          <Radio
            children="Yes"
            value="true"
            {...getOverrideProps(overrides, "coborrowerOwnershipRadio0")}
          ></Radio>
          <Radio
            children="No"
            value="false"
            {...getOverrideProps(overrides, "coborrowerOwnershipRadio1")}
          ></Radio>
        </RadioGroupField>
      </Grid>
      <Text
        children="(1) What type of property did you own—principal residence (PR), second home (SH), or investment property (IP)?"
        {...getOverrideProps(overrides, "SectionalElement11")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid31")}
      >
        <TextField
          label="Borrower "
          isRequired={false}
          isReadOnly={false}
          value={borrowerPropertyOwned}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned: value,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerPropertyOwned ?? value;
            }
            if (errors.borrowerPropertyOwned?.hasError) {
              runValidationTasks("borrowerPropertyOwned", value);
            }
            setBorrowerPropertyOwned(value);
          }}
          onBlur={() =>
            runValidationTasks("borrowerPropertyOwned", borrowerPropertyOwned)
          }
          errorMessage={errors.borrowerPropertyOwned?.errorMessage}
          hasError={errors.borrowerPropertyOwned?.hasError}
          {...getOverrideProps(overrides, "borrowerPropertyOwned")}
        ></TextField>
        <TextField
          label="Coborrower "
          isRequired={false}
          isReadOnly={false}
          value={coborrowerPropertyOwned}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned: value,
                borrowerTitleHeld,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerPropertyOwned ?? value;
            }
            if (errors.coborrowerPropertyOwned?.hasError) {
              runValidationTasks("coborrowerPropertyOwned", value);
            }
            setCoborrowerPropertyOwned(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "coborrowerPropertyOwned",
              coborrowerPropertyOwned
            )
          }
          errorMessage={errors.coborrowerPropertyOwned?.errorMessage}
          hasError={errors.coborrowerPropertyOwned?.hasError}
          {...getOverrideProps(overrides, "coborrowerPropertyOwned")}
        ></TextField>
      </Grid>
      <Text
        children="(2) How did you hold title to the home— by yourself (S), jointly with your spouse (SP), or jointly with another person (O)?"
        {...getOverrideProps(overrides, "SectionalElement12")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid33")}
      >
        <TextField
          label="Borrower "
          isRequired={false}
          isReadOnly={false}
          value={borrowerTitleHeld}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld: value,
                coborrowerTitleHeld,
              };
              const result = onChange(modelFields);
              value = result?.borrowerTitleHeld ?? value;
            }
            if (errors.borrowerTitleHeld?.hasError) {
              runValidationTasks("borrowerTitleHeld", value);
            }
            setBorrowerTitleHeld(value);
          }}
          onBlur={() =>
            runValidationTasks("borrowerTitleHeld", borrowerTitleHeld)
          }
          errorMessage={errors.borrowerTitleHeld?.errorMessage}
          hasError={errors.borrowerTitleHeld?.hasError}
          {...getOverrideProps(overrides, "borrowerTitleHeld")}
        ></TextField>
        <TextField
          label="Coborrower "
          isRequired={false}
          isReadOnly={false}
          value={coborrowerTitleHeld}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                borrowerOutstandingJudgments,
                coborrowerOutstandingJudgments,
                borrowerBankrupt,
                coborrowerBankrupt,
                borrowerForeclosed,
                coborrowerForeclosed,
                borrowerLawsuit,
                coborrowerLawsuit,
                borrowerObligatedLoan,
                coborrowerObligatedLoan,
                borrowerDelinquent,
                coborrowerDelinquent,
                borrowerObligatedMaintenance,
                coborrowerObligatedMaintenance,
                borrowerDownpaymentBorrowed,
                coborrowerDownpaymentBorrowed,
                borrowerNote,
                coborrowerNote,
                borrowerCitizen,
                coborrowerCitizen,
                borrowerResidentAlien,
                coborrowerResidentAlien,
                borrowerPrimaryResidence,
                coborrowerPrimaryResidence,
                borrowerOwnership,
                coborrowerOwnership,
                borrowerPropertyOwned,
                coborrowerPropertyOwned,
                borrowerTitleHeld,
                coborrowerTitleHeld: value,
              };
              const result = onChange(modelFields);
              value = result?.coborrowerTitleHeld ?? value;
            }
            if (errors.coborrowerTitleHeld?.hasError) {
              runValidationTasks("coborrowerTitleHeld", value);
            }
            setCoborrowerTitleHeld(value);
          }}
          onBlur={() =>
            runValidationTasks("coborrowerTitleHeld", coborrowerTitleHeld)
          }
          errorMessage={errors.coborrowerTitleHeld?.errorMessage}
          hasError={errors.coborrowerTitleHeld?.hasError}
          {...getOverrideProps(overrides, "coborrowerTitleHeld")}
        ></TextField>
      </Grid>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Save"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
