import { Link, useNavigate } from "react-router-dom";
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Logo from './gobdu-logo.png';
import styles from './Header.module.css';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

function BasicHeader({ signOut, isAuthenticated }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate(); // Add this line

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut(); // Call the signOut function passed as a prop
    navigate('/AboutGobdu'); // Redirect to /AboutGobdu
  };

  return (
    <AppBar position="fixed" className={styles.header}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
            <Box
              component="img"
              sx={{ height: 60, marginRight: 2 }}
              alt="Your logo."
              src={Logo}
              className={styles.logo}
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', md: 'flex' }, color: 'white', fontSize: '3.0rem', fontWeight: 'bold', fontFamily: 'Caveat, cursive', ml: 2 }}
            >
              QuickApplyLoan
            </Typography>
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            <Button
              component={Link}
              to="/"
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Home
            </Button>
            <Button
              component={Link}
              to="/about"
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              About Us
            </Button>
            <Button
              component={Link}
              to="/services"
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Services & Pricing
            </Button>
            <Button
              component={Link}
              to="/contact"
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Contact
            </Button>
            {isAuthenticated ? (
              <>
                <Button
                  onClick={handleSignOut} // Change this line to use handleSignOut
                  className={styles.signInButton}
                  variant="contained"
                  sx={{ ml: 2 }}
                >
                  Sign Out
                </Button>
              </>
            ) : (
              <>
                <Button
                  id="basic-button"
                  aria-controls={anchorEl ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={anchorEl ? 'true' : undefined}
                  onClick={handleOpenUserMenu}
                  className={styles.signInButton}
                  variant="contained"
                  sx={{ ml: 2 }}
                >
                  SIGN IN
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseUserMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                      My account
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Link to="/logout" style={{ textDecoration: 'none', color: 'inherit' }}>
                      Logout
                    </Link>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Button
              aria-controls={anchorElNav ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: 'white' }}
            >
              Menu
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                  Home
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
                  About Us
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/services" style={{ textDecoration: 'none', color: 'inherit' }}>
                  Services & Pricing
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>
                  Contact
                </Link>
              </MenuItem>
              {isAuthenticated ? (
                <>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link to="/profile" style={{ textDecoration: 'none', color: 'inherit' }}>
                      My account
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link to="/logout" style={{ textDecoration: 'none', color: 'inherit' }}>
                      Logout
                    </Link>
                  </MenuItem>
                </>
              ) : (
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                    Sign In
                  </Link>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default BasicHeader;
