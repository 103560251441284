import config from './config.json';

export const loadConfig = async () => {
    try {
        // Ensure the config is loaded
        if (!config) {
            throw new Error('Config file could not be loaded.');
        }

        // Determine the environment
        const hostname = window.location.hostname;

        let environment;

        if (hostname === 'localhost') {
            environment = 'localhost'; // Map localhost to a specific config
        } else if (hostname.includes('dev')) {
            environment = 'dev'; // Handle dev environments
        } else {
            environment = 'prod'; // Default to production
        }

        // Retrieve the configuration for the determined environment
        if (config[environment]) {
            return config[environment];
        } else {
            throw new Error(`Environment configuration not found for '${environment}'`);
        }
    } catch (err) {
        console.error('Error loading configuration:', err);
        throw err;
    }
};
