/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getBorrowerInfo } from "../graphql/queries";
import { updateBorrowerInfo } from "../graphql/mutations";
const client = generateClient();
export default function BorrowerInfoUpdateForm(props) {
  const {
    id: idProp,
    borrowerInfo: borrowerInfoModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    fullName: "",
    address: "",
    maritalStatus: "",
    numberOfDependents: "",
    birthdate: "",
    ssn: "",
    formerAddress: "",
    phone: "",
    presentAddressOwnership: false,
    formerAddressOwnership: false,
    mailingAddress: "",
    agesOfDependents: "",
    yrsInSchool: "",
    noOfYrsAtPresentResidence: "",
    noOfYrsAtFormerResidence: "",
    email: "",
    userToken: "",
    clientUserId: "",
  };
  const [fullName, setFullName] = React.useState(initialValues.fullName);
  const [address, setAddress] = React.useState(initialValues.address);
  const [maritalStatus, setMaritalStatus] = React.useState(
    initialValues.maritalStatus
  );
  const [numberOfDependents, setNumberOfDependents] = React.useState(
    initialValues.numberOfDependents
  );
  const [birthdate, setBirthdate] = React.useState(initialValues.birthdate);
  const [ssn, setSsn] = React.useState(initialValues.ssn);
  const [formerAddress, setFormerAddress] = React.useState(
    initialValues.formerAddress
  );
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [presentAddressOwnership, setPresentAddressOwnership] = React.useState(
    initialValues.presentAddressOwnership
  );
  const [formerAddressOwnership, setFormerAddressOwnership] = React.useState(
    initialValues.formerAddressOwnership
  );
  const [mailingAddress, setMailingAddress] = React.useState(
    initialValues.mailingAddress
  );
  const [agesOfDependents, setAgesOfDependents] = React.useState(
    initialValues.agesOfDependents
  );
  const [yrsInSchool, setYrsInSchool] = React.useState(
    initialValues.yrsInSchool
  );
  const [noOfYrsAtPresentResidence, setNoOfYrsAtPresentResidence] =
    React.useState(initialValues.noOfYrsAtPresentResidence);
  const [noOfYrsAtFormerResidence, setNoOfYrsAtFormerResidence] =
    React.useState(initialValues.noOfYrsAtFormerResidence);
  const [email, setEmail] = React.useState(initialValues.email);
  const [userToken, setUserToken] = React.useState(initialValues.userToken);
  const [clientUserId, setClientUserId] = React.useState(
    initialValues.clientUserId
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = borrowerInfoRecord
      ? { ...initialValues, ...borrowerInfoRecord }
      : initialValues;
    setFullName(cleanValues.fullName);
    setAddress(cleanValues.address);
    setMaritalStatus(cleanValues.maritalStatus);
    setNumberOfDependents(cleanValues.numberOfDependents);
    setBirthdate(cleanValues.birthdate);
    setSsn(cleanValues.ssn);
    setFormerAddress(cleanValues.formerAddress);
    setPhone(cleanValues.phone);
    setPresentAddressOwnership(cleanValues.presentAddressOwnership);
    setFormerAddressOwnership(cleanValues.formerAddressOwnership);
    setMailingAddress(cleanValues.mailingAddress);
    setAgesOfDependents(cleanValues.agesOfDependents);
    setYrsInSchool(cleanValues.yrsInSchool);
    setNoOfYrsAtPresentResidence(cleanValues.noOfYrsAtPresentResidence);
    setNoOfYrsAtFormerResidence(cleanValues.noOfYrsAtFormerResidence);
    setEmail(cleanValues.email);
    setUserToken(cleanValues.userToken);
    setClientUserId(cleanValues.clientUserId);
    setErrors({});
  };
  const [borrowerInfoRecord, setBorrowerInfoRecord] = React.useState(
    borrowerInfoModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getBorrowerInfo.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getBorrowerInfo
        : borrowerInfoModelProp;
      setBorrowerInfoRecord(record);
    };
    queryData();
  }, [idProp, borrowerInfoModelProp]);
  React.useEffect(resetStateValues, [borrowerInfoRecord]);
  const validations = {
    fullName: [],
    address: [],
    maritalStatus: [],
    numberOfDependents: [],
    birthdate: [],
    ssn: [],
    formerAddress: [],
    phone: [],
    presentAddressOwnership: [],
    formerAddressOwnership: [],
    mailingAddress: [],
    agesOfDependents: [],
    yrsInSchool: [],
    noOfYrsAtPresentResidence: [],
    noOfYrsAtFormerResidence: [],
    email: [],
    userToken: [],
    clientUserId: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          fullName: fullName ?? null,
          address: address ?? null,
          maritalStatus: maritalStatus ?? null,
          numberOfDependents: numberOfDependents ?? null,
          birthdate: birthdate ?? null,
          ssn: ssn ?? null,
          formerAddress: formerAddress ?? null,
          phone: phone ?? null,
          presentAddressOwnership: presentAddressOwnership ?? null,
          formerAddressOwnership: formerAddressOwnership ?? null,
          mailingAddress: mailingAddress ?? null,
          agesOfDependents: agesOfDependents ?? null,
          yrsInSchool: yrsInSchool ?? null,
          noOfYrsAtPresentResidence: noOfYrsAtPresentResidence ?? null,
          noOfYrsAtFormerResidence: noOfYrsAtFormerResidence ?? null,
          email: email ?? null,
          userToken: userToken ?? null,
          clientUserId: clientUserId ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateBorrowerInfo.replaceAll("__typename", ""),
            variables: {
              input: {
                id: borrowerInfoRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "BorrowerInfoUpdateForm")}
      {...rest}
    >
      <TextField
        label="Full name"
        isRequired={false}
        isReadOnly={false}
        value={fullName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName: value,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.fullName ?? value;
          }
          if (errors.fullName?.hasError) {
            runValidationTasks("fullName", value);
          }
          setFullName(value);
        }}
        onBlur={() => runValidationTasks("fullName", fullName)}
        errorMessage={errors.fullName?.errorMessage}
        hasError={errors.fullName?.hasError}
        {...getOverrideProps(overrides, "fullName")}
      ></TextField>
      <TextField
        label="Address"
        isRequired={false}
        isReadOnly={false}
        value={address}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address: value,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.address ?? value;
          }
          if (errors.address?.hasError) {
            runValidationTasks("address", value);
          }
          setAddress(value);
        }}
        onBlur={() => runValidationTasks("address", address)}
        errorMessage={errors.address?.errorMessage}
        hasError={errors.address?.hasError}
        {...getOverrideProps(overrides, "address")}
      ></TextField>
      <TextField
        label="Marital status"
        isRequired={false}
        isReadOnly={false}
        value={maritalStatus}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus: value,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.maritalStatus ?? value;
          }
          if (errors.maritalStatus?.hasError) {
            runValidationTasks("maritalStatus", value);
          }
          setMaritalStatus(value);
        }}
        onBlur={() => runValidationTasks("maritalStatus", maritalStatus)}
        errorMessage={errors.maritalStatus?.errorMessage}
        hasError={errors.maritalStatus?.hasError}
        {...getOverrideProps(overrides, "maritalStatus")}
      ></TextField>
      <TextField
        label="Number of dependents"
        isRequired={false}
        isReadOnly={false}
        value={numberOfDependents}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents: value,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.numberOfDependents ?? value;
          }
          if (errors.numberOfDependents?.hasError) {
            runValidationTasks("numberOfDependents", value);
          }
          setNumberOfDependents(value);
        }}
        onBlur={() =>
          runValidationTasks("numberOfDependents", numberOfDependents)
        }
        errorMessage={errors.numberOfDependents?.errorMessage}
        hasError={errors.numberOfDependents?.hasError}
        {...getOverrideProps(overrides, "numberOfDependents")}
      ></TextField>
      <TextField
        label="Birthdate"
        isRequired={false}
        isReadOnly={false}
        value={birthdate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate: value,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.birthdate ?? value;
          }
          if (errors.birthdate?.hasError) {
            runValidationTasks("birthdate", value);
          }
          setBirthdate(value);
        }}
        onBlur={() => runValidationTasks("birthdate", birthdate)}
        errorMessage={errors.birthdate?.errorMessage}
        hasError={errors.birthdate?.hasError}
        {...getOverrideProps(overrides, "birthdate")}
      ></TextField>
      <TextField
        label="Ssn"
        isRequired={false}
        isReadOnly={false}
        value={ssn}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn: value,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.ssn ?? value;
          }
          if (errors.ssn?.hasError) {
            runValidationTasks("ssn", value);
          }
          setSsn(value);
        }}
        onBlur={() => runValidationTasks("ssn", ssn)}
        errorMessage={errors.ssn?.errorMessage}
        hasError={errors.ssn?.hasError}
        {...getOverrideProps(overrides, "ssn")}
      ></TextField>
      <TextField
        label="Former address"
        isRequired={false}
        isReadOnly={false}
        value={formerAddress}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress: value,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.formerAddress ?? value;
          }
          if (errors.formerAddress?.hasError) {
            runValidationTasks("formerAddress", value);
          }
          setFormerAddress(value);
        }}
        onBlur={() => runValidationTasks("formerAddress", formerAddress)}
        errorMessage={errors.formerAddress?.errorMessage}
        hasError={errors.formerAddress?.hasError}
        {...getOverrideProps(overrides, "formerAddress")}
      ></TextField>
      <TextField
        label="Phone"
        isRequired={false}
        isReadOnly={false}
        value={phone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone: value,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></TextField>
      <SwitchField
        label="Present address ownership"
        defaultChecked={false}
        isDisabled={false}
        isChecked={presentAddressOwnership}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership: value,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.presentAddressOwnership ?? value;
          }
          if (errors.presentAddressOwnership?.hasError) {
            runValidationTasks("presentAddressOwnership", value);
          }
          setPresentAddressOwnership(value);
        }}
        onBlur={() =>
          runValidationTasks("presentAddressOwnership", presentAddressOwnership)
        }
        errorMessage={errors.presentAddressOwnership?.errorMessage}
        hasError={errors.presentAddressOwnership?.hasError}
        {...getOverrideProps(overrides, "presentAddressOwnership")}
      ></SwitchField>
      <SwitchField
        label="Former address ownership"
        defaultChecked={false}
        isDisabled={false}
        isChecked={formerAddressOwnership}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership: value,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.formerAddressOwnership ?? value;
          }
          if (errors.formerAddressOwnership?.hasError) {
            runValidationTasks("formerAddressOwnership", value);
          }
          setFormerAddressOwnership(value);
        }}
        onBlur={() =>
          runValidationTasks("formerAddressOwnership", formerAddressOwnership)
        }
        errorMessage={errors.formerAddressOwnership?.errorMessage}
        hasError={errors.formerAddressOwnership?.hasError}
        {...getOverrideProps(overrides, "formerAddressOwnership")}
      ></SwitchField>
      <TextField
        label="Mailing address"
        isRequired={false}
        isReadOnly={false}
        value={mailingAddress}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress: value,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.mailingAddress ?? value;
          }
          if (errors.mailingAddress?.hasError) {
            runValidationTasks("mailingAddress", value);
          }
          setMailingAddress(value);
        }}
        onBlur={() => runValidationTasks("mailingAddress", mailingAddress)}
        errorMessage={errors.mailingAddress?.errorMessage}
        hasError={errors.mailingAddress?.hasError}
        {...getOverrideProps(overrides, "mailingAddress")}
      ></TextField>
      <TextField
        label="Ages of dependents"
        isRequired={false}
        isReadOnly={false}
        value={agesOfDependents}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents: value,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.agesOfDependents ?? value;
          }
          if (errors.agesOfDependents?.hasError) {
            runValidationTasks("agesOfDependents", value);
          }
          setAgesOfDependents(value);
        }}
        onBlur={() => runValidationTasks("agesOfDependents", agesOfDependents)}
        errorMessage={errors.agesOfDependents?.errorMessage}
        hasError={errors.agesOfDependents?.hasError}
        {...getOverrideProps(overrides, "agesOfDependents")}
      ></TextField>
      <TextField
        label="Yrs in school"
        isRequired={false}
        isReadOnly={false}
        value={yrsInSchool}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool: value,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.yrsInSchool ?? value;
          }
          if (errors.yrsInSchool?.hasError) {
            runValidationTasks("yrsInSchool", value);
          }
          setYrsInSchool(value);
        }}
        onBlur={() => runValidationTasks("yrsInSchool", yrsInSchool)}
        errorMessage={errors.yrsInSchool?.errorMessage}
        hasError={errors.yrsInSchool?.hasError}
        {...getOverrideProps(overrides, "yrsInSchool")}
      ></TextField>
      <TextField
        label="No of yrs at present residence"
        isRequired={false}
        isReadOnly={false}
        value={noOfYrsAtPresentResidence}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence: value,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.noOfYrsAtPresentResidence ?? value;
          }
          if (errors.noOfYrsAtPresentResidence?.hasError) {
            runValidationTasks("noOfYrsAtPresentResidence", value);
          }
          setNoOfYrsAtPresentResidence(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "noOfYrsAtPresentResidence",
            noOfYrsAtPresentResidence
          )
        }
        errorMessage={errors.noOfYrsAtPresentResidence?.errorMessage}
        hasError={errors.noOfYrsAtPresentResidence?.hasError}
        {...getOverrideProps(overrides, "noOfYrsAtPresentResidence")}
      ></TextField>
      <TextField
        label="No of yrs at former residence"
        isRequired={false}
        isReadOnly={false}
        value={noOfYrsAtFormerResidence}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence: value,
              email,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.noOfYrsAtFormerResidence ?? value;
          }
          if (errors.noOfYrsAtFormerResidence?.hasError) {
            runValidationTasks("noOfYrsAtFormerResidence", value);
          }
          setNoOfYrsAtFormerResidence(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "noOfYrsAtFormerResidence",
            noOfYrsAtFormerResidence
          )
        }
        errorMessage={errors.noOfYrsAtFormerResidence?.errorMessage}
        hasError={errors.noOfYrsAtFormerResidence?.hasError}
        {...getOverrideProps(overrides, "noOfYrsAtFormerResidence")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email: value,
              userToken,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="User token"
        isRequired={false}
        isReadOnly={false}
        value={userToken}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken: value,
              clientUserId,
            };
            const result = onChange(modelFields);
            value = result?.userToken ?? value;
          }
          if (errors.userToken?.hasError) {
            runValidationTasks("userToken", value);
          }
          setUserToken(value);
        }}
        onBlur={() => runValidationTasks("userToken", userToken)}
        errorMessage={errors.userToken?.errorMessage}
        hasError={errors.userToken?.hasError}
        {...getOverrideProps(overrides, "userToken")}
      ></TextField>
      <TextField
        label="Client user id"
        isRequired={false}
        isReadOnly={false}
        value={clientUserId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              fullName,
              address,
              maritalStatus,
              numberOfDependents,
              birthdate,
              ssn,
              formerAddress,
              phone,
              presentAddressOwnership,
              formerAddressOwnership,
              mailingAddress,
              agesOfDependents,
              yrsInSchool,
              noOfYrsAtPresentResidence,
              noOfYrsAtFormerResidence,
              email,
              userToken,
              clientUserId: value,
            };
            const result = onChange(modelFields);
            value = result?.clientUserId ?? value;
          }
          if (errors.clientUserId?.hasError) {
            runValidationTasks("clientUserId", value);
          }
          setClientUserId(value);
        }}
        onBlur={() => runValidationTasks("clientUserId", clientUserId)}
        errorMessage={errors.clientUserId?.errorMessage}
        hasError={errors.clientUserId?.hasError}
        {...getOverrideProps(overrides, "clientUserId")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || borrowerInfoModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || borrowerInfoModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
