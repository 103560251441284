/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getProperty } from "../graphql/queries";
import { updateProperty } from "../graphql/mutations";
const client = generateClient();
export default function PropertyUpdateForm(props) {
  const {
    id: idProp,
    property: propertyModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    subjectPropertyAddress: "",
    subjectPropertyAddress2: "",
    subjectPropertyCity: "",
    subjectPropertyState: "",
    subjectPropertyZip: "",
    numberOfUnits: "",
    legalDescription: "",
    yearBuilt: "",
    purposeOfProperty: "",
    purposeOfLoan: "",
    cons_yearLotAcquired: "",
    cons_originalCost: "",
    cons_AmountExistingLiens: "",
    cons_presentVal: "",
    cons_costOfImprovements: "",
    cons_total: "",
    refinance_yearAcquired: "",
    refinance_originalCost: "",
    refinance_amountExistingLiens: "",
    refinance_purpose: "",
    refinance_describeImprovements: "",
    refinance_cost: "",
    refinance_improvementsMade: false,
    titleName: "",
    titleManner: "",
    sourceOfFinancing: "",
    estateToBeHeld: "",
  };
  const [subjectPropertyAddress, setSubjectPropertyAddress] = React.useState(
    initialValues.subjectPropertyAddress
  );
  const [subjectPropertyAddress2, setSubjectPropertyAddress2] = React.useState(
    initialValues.subjectPropertyAddress2
  );
  const [subjectPropertyCity, setSubjectPropertyCity] = React.useState(
    initialValues.subjectPropertyCity
  );
  const [subjectPropertyState, setSubjectPropertyState] = React.useState(
    initialValues.subjectPropertyState
  );
  const [subjectPropertyZip, setSubjectPropertyZip] = React.useState(
    initialValues.subjectPropertyZip
  );
  const [numberOfUnits, setNumberOfUnits] = React.useState(
    initialValues.numberOfUnits
  );
  const [legalDescription, setLegalDescription] = React.useState(
    initialValues.legalDescription
  );
  const [yearBuilt, setYearBuilt] = React.useState(initialValues.yearBuilt);
  const [purposeOfProperty, setPurposeOfProperty] = React.useState(
    initialValues.purposeOfProperty
  );
  const [purposeOfLoan, setPurposeOfLoan] = React.useState(
    initialValues.purposeOfLoan
  );
  const [cons_yearLotAcquired, setCons_yearLotAcquired] = React.useState(
    initialValues.cons_yearLotAcquired
  );
  const [cons_originalCost, setCons_originalCost] = React.useState(
    initialValues.cons_originalCost
  );
  const [cons_AmountExistingLiens, setCons_AmountExistingLiens] =
    React.useState(initialValues.cons_AmountExistingLiens);
  const [cons_presentVal, setCons_presentVal] = React.useState(
    initialValues.cons_presentVal
  );
  const [cons_costOfImprovements, setCons_costOfImprovements] = React.useState(
    initialValues.cons_costOfImprovements
  );
  const [cons_total, setCons_total] = React.useState(initialValues.cons_total);
  const [refinance_yearAcquired, setRefinance_yearAcquired] = React.useState(
    initialValues.refinance_yearAcquired
  );
  const [refinance_originalCost, setRefinance_originalCost] = React.useState(
    initialValues.refinance_originalCost
  );
  const [refinance_amountExistingLiens, setRefinance_amountExistingLiens] =
    React.useState(initialValues.refinance_amountExistingLiens);
  const [refinance_purpose, setRefinance_purpose] = React.useState(
    initialValues.refinance_purpose
  );
  const [refinance_describeImprovements, setRefinance_describeImprovements] =
    React.useState(initialValues.refinance_describeImprovements);
  const [refinance_cost, setRefinance_cost] = React.useState(
    initialValues.refinance_cost
  );
  const [refinance_improvementsMade, setRefinance_improvementsMade] =
    React.useState(initialValues.refinance_improvementsMade);
  const [titleName, setTitleName] = React.useState(initialValues.titleName);
  const [titleManner, setTitleManner] = React.useState(
    initialValues.titleManner
  );
  const [sourceOfFinancing, setSourceOfFinancing] = React.useState(
    initialValues.sourceOfFinancing
  );
  const [estateToBeHeld, setEstateToBeHeld] = React.useState(
    initialValues.estateToBeHeld
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = propertyRecord
      ? { ...initialValues, ...propertyRecord }
      : initialValues;
    setSubjectPropertyAddress(cleanValues.subjectPropertyAddress);
    setSubjectPropertyAddress2(cleanValues.subjectPropertyAddress2);
    setSubjectPropertyCity(cleanValues.subjectPropertyCity);
    setSubjectPropertyState(cleanValues.subjectPropertyState);
    setSubjectPropertyZip(cleanValues.subjectPropertyZip);
    setNumberOfUnits(cleanValues.numberOfUnits);
    setLegalDescription(cleanValues.legalDescription);
    setYearBuilt(cleanValues.yearBuilt);
    setPurposeOfProperty(cleanValues.purposeOfProperty);
    setPurposeOfLoan(cleanValues.purposeOfLoan);
    setCons_yearLotAcquired(cleanValues.cons_yearLotAcquired);
    setCons_originalCost(cleanValues.cons_originalCost);
    setCons_AmountExistingLiens(cleanValues.cons_AmountExistingLiens);
    setCons_presentVal(cleanValues.cons_presentVal);
    setCons_costOfImprovements(cleanValues.cons_costOfImprovements);
    setCons_total(cleanValues.cons_total);
    setRefinance_yearAcquired(cleanValues.refinance_yearAcquired);
    setRefinance_originalCost(cleanValues.refinance_originalCost);
    setRefinance_amountExistingLiens(cleanValues.refinance_amountExistingLiens);
    setRefinance_purpose(cleanValues.refinance_purpose);
    setRefinance_describeImprovements(
      cleanValues.refinance_describeImprovements
    );
    setRefinance_cost(cleanValues.refinance_cost);
    setRefinance_improvementsMade(cleanValues.refinance_improvementsMade);
    setTitleName(cleanValues.titleName);
    setTitleManner(cleanValues.titleManner);
    setSourceOfFinancing(cleanValues.sourceOfFinancing);
    setEstateToBeHeld(cleanValues.estateToBeHeld);
    setErrors({});
  };
  const [propertyRecord, setPropertyRecord] = React.useState(propertyModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getProperty.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getProperty
        : propertyModelProp;
      setPropertyRecord(record);
    };
    queryData();
  }, [idProp, propertyModelProp]);
  React.useEffect(resetStateValues, [propertyRecord]);
  const validations = {
    subjectPropertyAddress: [],
    subjectPropertyAddress2: [],
    subjectPropertyCity: [],
    subjectPropertyState: [],
    subjectPropertyZip: [],
    numberOfUnits: [],
    legalDescription: [],
    yearBuilt: [],
    purposeOfProperty: [],
    purposeOfLoan: [],
    cons_yearLotAcquired: [],
    cons_originalCost: [],
    cons_AmountExistingLiens: [],
    cons_presentVal: [],
    cons_costOfImprovements: [],
    cons_total: [],
    refinance_yearAcquired: [],
    refinance_originalCost: [],
    refinance_amountExistingLiens: [],
    refinance_purpose: [],
    refinance_describeImprovements: [],
    refinance_cost: [],
    refinance_improvementsMade: [],
    titleName: [],
    titleManner: [],
    sourceOfFinancing: [],
    estateToBeHeld: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          subjectPropertyAddress: subjectPropertyAddress ?? null,
          subjectPropertyAddress2: subjectPropertyAddress2 ?? null,
          subjectPropertyCity: subjectPropertyCity ?? null,
          subjectPropertyState: subjectPropertyState ?? null,
          subjectPropertyZip: subjectPropertyZip ?? null,
          numberOfUnits: numberOfUnits ?? null,
          legalDescription: legalDescription ?? null,
          yearBuilt: yearBuilt ?? null,
          purposeOfProperty: purposeOfProperty ?? null,
          purposeOfLoan: purposeOfLoan ?? null,
          cons_yearLotAcquired: cons_yearLotAcquired ?? null,
          cons_originalCost: cons_originalCost ?? null,
          cons_AmountExistingLiens: cons_AmountExistingLiens ?? null,
          cons_presentVal: cons_presentVal ?? null,
          cons_costOfImprovements: cons_costOfImprovements ?? null,
          cons_total: cons_total ?? null,
          refinance_yearAcquired: refinance_yearAcquired ?? null,
          refinance_originalCost: refinance_originalCost ?? null,
          refinance_amountExistingLiens: refinance_amountExistingLiens ?? null,
          refinance_purpose: refinance_purpose ?? null,
          refinance_describeImprovements:
            refinance_describeImprovements ?? null,
          refinance_cost: refinance_cost ?? null,
          refinance_improvementsMade: refinance_improvementsMade ?? null,
          titleName: titleName ?? null,
          titleManner: titleManner ?? null,
          sourceOfFinancing: sourceOfFinancing ?? null,
          estateToBeHeld: estateToBeHeld ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateProperty.replaceAll("__typename", ""),
            variables: {
              input: {
                id: propertyRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "PropertyUpdateForm")}
      {...rest}
    >
      <TextField
        label="Subject property address"
        isRequired={false}
        isReadOnly={false}
        value={subjectPropertyAddress}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress: value,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.subjectPropertyAddress ?? value;
          }
          if (errors.subjectPropertyAddress?.hasError) {
            runValidationTasks("subjectPropertyAddress", value);
          }
          setSubjectPropertyAddress(value);
        }}
        onBlur={() =>
          runValidationTasks("subjectPropertyAddress", subjectPropertyAddress)
        }
        errorMessage={errors.subjectPropertyAddress?.errorMessage}
        hasError={errors.subjectPropertyAddress?.hasError}
        {...getOverrideProps(overrides, "subjectPropertyAddress")}
      ></TextField>
      <TextField
        label="Subject property address2"
        isRequired={false}
        isReadOnly={false}
        value={subjectPropertyAddress2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2: value,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.subjectPropertyAddress2 ?? value;
          }
          if (errors.subjectPropertyAddress2?.hasError) {
            runValidationTasks("subjectPropertyAddress2", value);
          }
          setSubjectPropertyAddress2(value);
        }}
        onBlur={() =>
          runValidationTasks("subjectPropertyAddress2", subjectPropertyAddress2)
        }
        errorMessage={errors.subjectPropertyAddress2?.errorMessage}
        hasError={errors.subjectPropertyAddress2?.hasError}
        {...getOverrideProps(overrides, "subjectPropertyAddress2")}
      ></TextField>
      <TextField
        label="Subject property city"
        isRequired={false}
        isReadOnly={false}
        value={subjectPropertyCity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity: value,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.subjectPropertyCity ?? value;
          }
          if (errors.subjectPropertyCity?.hasError) {
            runValidationTasks("subjectPropertyCity", value);
          }
          setSubjectPropertyCity(value);
        }}
        onBlur={() =>
          runValidationTasks("subjectPropertyCity", subjectPropertyCity)
        }
        errorMessage={errors.subjectPropertyCity?.errorMessage}
        hasError={errors.subjectPropertyCity?.hasError}
        {...getOverrideProps(overrides, "subjectPropertyCity")}
      ></TextField>
      <TextField
        label="Subject property state"
        isRequired={false}
        isReadOnly={false}
        value={subjectPropertyState}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState: value,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.subjectPropertyState ?? value;
          }
          if (errors.subjectPropertyState?.hasError) {
            runValidationTasks("subjectPropertyState", value);
          }
          setSubjectPropertyState(value);
        }}
        onBlur={() =>
          runValidationTasks("subjectPropertyState", subjectPropertyState)
        }
        errorMessage={errors.subjectPropertyState?.errorMessage}
        hasError={errors.subjectPropertyState?.hasError}
        {...getOverrideProps(overrides, "subjectPropertyState")}
      ></TextField>
      <TextField
        label="Subject property zip"
        isRequired={false}
        isReadOnly={false}
        value={subjectPropertyZip}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip: value,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.subjectPropertyZip ?? value;
          }
          if (errors.subjectPropertyZip?.hasError) {
            runValidationTasks("subjectPropertyZip", value);
          }
          setSubjectPropertyZip(value);
        }}
        onBlur={() =>
          runValidationTasks("subjectPropertyZip", subjectPropertyZip)
        }
        errorMessage={errors.subjectPropertyZip?.errorMessage}
        hasError={errors.subjectPropertyZip?.hasError}
        {...getOverrideProps(overrides, "subjectPropertyZip")}
      ></TextField>
      <TextField
        label="Number of units"
        isRequired={false}
        isReadOnly={false}
        value={numberOfUnits}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits: value,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.numberOfUnits ?? value;
          }
          if (errors.numberOfUnits?.hasError) {
            runValidationTasks("numberOfUnits", value);
          }
          setNumberOfUnits(value);
        }}
        onBlur={() => runValidationTasks("numberOfUnits", numberOfUnits)}
        errorMessage={errors.numberOfUnits?.errorMessage}
        hasError={errors.numberOfUnits?.hasError}
        {...getOverrideProps(overrides, "numberOfUnits")}
      ></TextField>
      <TextField
        label="Legal description"
        isRequired={false}
        isReadOnly={false}
        value={legalDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription: value,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.legalDescription ?? value;
          }
          if (errors.legalDescription?.hasError) {
            runValidationTasks("legalDescription", value);
          }
          setLegalDescription(value);
        }}
        onBlur={() => runValidationTasks("legalDescription", legalDescription)}
        errorMessage={errors.legalDescription?.errorMessage}
        hasError={errors.legalDescription?.hasError}
        {...getOverrideProps(overrides, "legalDescription")}
      ></TextField>
      <TextField
        label="Year built"
        isRequired={false}
        isReadOnly={false}
        value={yearBuilt}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt: value,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.yearBuilt ?? value;
          }
          if (errors.yearBuilt?.hasError) {
            runValidationTasks("yearBuilt", value);
          }
          setYearBuilt(value);
        }}
        onBlur={() => runValidationTasks("yearBuilt", yearBuilt)}
        errorMessage={errors.yearBuilt?.errorMessage}
        hasError={errors.yearBuilt?.hasError}
        {...getOverrideProps(overrides, "yearBuilt")}
      ></TextField>
      <TextField
        label="Purpose of property"
        isRequired={false}
        isReadOnly={false}
        value={purposeOfProperty}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty: value,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.purposeOfProperty ?? value;
          }
          if (errors.purposeOfProperty?.hasError) {
            runValidationTasks("purposeOfProperty", value);
          }
          setPurposeOfProperty(value);
        }}
        onBlur={() =>
          runValidationTasks("purposeOfProperty", purposeOfProperty)
        }
        errorMessage={errors.purposeOfProperty?.errorMessage}
        hasError={errors.purposeOfProperty?.hasError}
        {...getOverrideProps(overrides, "purposeOfProperty")}
      ></TextField>
      <TextField
        label="Purpose of loan"
        isRequired={false}
        isReadOnly={false}
        value={purposeOfLoan}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan: value,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.purposeOfLoan ?? value;
          }
          if (errors.purposeOfLoan?.hasError) {
            runValidationTasks("purposeOfLoan", value);
          }
          setPurposeOfLoan(value);
        }}
        onBlur={() => runValidationTasks("purposeOfLoan", purposeOfLoan)}
        errorMessage={errors.purposeOfLoan?.errorMessage}
        hasError={errors.purposeOfLoan?.hasError}
        {...getOverrideProps(overrides, "purposeOfLoan")}
      ></TextField>
      <TextField
        label="Cons year lot acquired"
        isRequired={false}
        isReadOnly={false}
        value={cons_yearLotAcquired}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired: value,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.cons_yearLotAcquired ?? value;
          }
          if (errors.cons_yearLotAcquired?.hasError) {
            runValidationTasks("cons_yearLotAcquired", value);
          }
          setCons_yearLotAcquired(value);
        }}
        onBlur={() =>
          runValidationTasks("cons_yearLotAcquired", cons_yearLotAcquired)
        }
        errorMessage={errors.cons_yearLotAcquired?.errorMessage}
        hasError={errors.cons_yearLotAcquired?.hasError}
        {...getOverrideProps(overrides, "cons_yearLotAcquired")}
      ></TextField>
      <TextField
        label="Cons original cost"
        isRequired={false}
        isReadOnly={false}
        value={cons_originalCost}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost: value,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.cons_originalCost ?? value;
          }
          if (errors.cons_originalCost?.hasError) {
            runValidationTasks("cons_originalCost", value);
          }
          setCons_originalCost(value);
        }}
        onBlur={() =>
          runValidationTasks("cons_originalCost", cons_originalCost)
        }
        errorMessage={errors.cons_originalCost?.errorMessage}
        hasError={errors.cons_originalCost?.hasError}
        {...getOverrideProps(overrides, "cons_originalCost")}
      ></TextField>
      <TextField
        label="Cons amount existing liens"
        isRequired={false}
        isReadOnly={false}
        value={cons_AmountExistingLiens}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens: value,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.cons_AmountExistingLiens ?? value;
          }
          if (errors.cons_AmountExistingLiens?.hasError) {
            runValidationTasks("cons_AmountExistingLiens", value);
          }
          setCons_AmountExistingLiens(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "cons_AmountExistingLiens",
            cons_AmountExistingLiens
          )
        }
        errorMessage={errors.cons_AmountExistingLiens?.errorMessage}
        hasError={errors.cons_AmountExistingLiens?.hasError}
        {...getOverrideProps(overrides, "cons_AmountExistingLiens")}
      ></TextField>
      <TextField
        label="Cons present val"
        isRequired={false}
        isReadOnly={false}
        value={cons_presentVal}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal: value,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.cons_presentVal ?? value;
          }
          if (errors.cons_presentVal?.hasError) {
            runValidationTasks("cons_presentVal", value);
          }
          setCons_presentVal(value);
        }}
        onBlur={() => runValidationTasks("cons_presentVal", cons_presentVal)}
        errorMessage={errors.cons_presentVal?.errorMessage}
        hasError={errors.cons_presentVal?.hasError}
        {...getOverrideProps(overrides, "cons_presentVal")}
      ></TextField>
      <TextField
        label="Cons cost of improvements"
        isRequired={false}
        isReadOnly={false}
        value={cons_costOfImprovements}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements: value,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.cons_costOfImprovements ?? value;
          }
          if (errors.cons_costOfImprovements?.hasError) {
            runValidationTasks("cons_costOfImprovements", value);
          }
          setCons_costOfImprovements(value);
        }}
        onBlur={() =>
          runValidationTasks("cons_costOfImprovements", cons_costOfImprovements)
        }
        errorMessage={errors.cons_costOfImprovements?.errorMessage}
        hasError={errors.cons_costOfImprovements?.hasError}
        {...getOverrideProps(overrides, "cons_costOfImprovements")}
      ></TextField>
      <TextField
        label="Cons total"
        isRequired={false}
        isReadOnly={false}
        value={cons_total}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total: value,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.cons_total ?? value;
          }
          if (errors.cons_total?.hasError) {
            runValidationTasks("cons_total", value);
          }
          setCons_total(value);
        }}
        onBlur={() => runValidationTasks("cons_total", cons_total)}
        errorMessage={errors.cons_total?.errorMessage}
        hasError={errors.cons_total?.hasError}
        {...getOverrideProps(overrides, "cons_total")}
      ></TextField>
      <TextField
        label="Refinance year acquired"
        isRequired={false}
        isReadOnly={false}
        value={refinance_yearAcquired}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired: value,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.refinance_yearAcquired ?? value;
          }
          if (errors.refinance_yearAcquired?.hasError) {
            runValidationTasks("refinance_yearAcquired", value);
          }
          setRefinance_yearAcquired(value);
        }}
        onBlur={() =>
          runValidationTasks("refinance_yearAcquired", refinance_yearAcquired)
        }
        errorMessage={errors.refinance_yearAcquired?.errorMessage}
        hasError={errors.refinance_yearAcquired?.hasError}
        {...getOverrideProps(overrides, "refinance_yearAcquired")}
      ></TextField>
      <TextField
        label="Refinance original cost"
        isRequired={false}
        isReadOnly={false}
        value={refinance_originalCost}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost: value,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.refinance_originalCost ?? value;
          }
          if (errors.refinance_originalCost?.hasError) {
            runValidationTasks("refinance_originalCost", value);
          }
          setRefinance_originalCost(value);
        }}
        onBlur={() =>
          runValidationTasks("refinance_originalCost", refinance_originalCost)
        }
        errorMessage={errors.refinance_originalCost?.errorMessage}
        hasError={errors.refinance_originalCost?.hasError}
        {...getOverrideProps(overrides, "refinance_originalCost")}
      ></TextField>
      <TextField
        label="Refinance amount existing liens"
        isRequired={false}
        isReadOnly={false}
        value={refinance_amountExistingLiens}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens: value,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.refinance_amountExistingLiens ?? value;
          }
          if (errors.refinance_amountExistingLiens?.hasError) {
            runValidationTasks("refinance_amountExistingLiens", value);
          }
          setRefinance_amountExistingLiens(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "refinance_amountExistingLiens",
            refinance_amountExistingLiens
          )
        }
        errorMessage={errors.refinance_amountExistingLiens?.errorMessage}
        hasError={errors.refinance_amountExistingLiens?.hasError}
        {...getOverrideProps(overrides, "refinance_amountExistingLiens")}
      ></TextField>
      <TextField
        label="Refinance purpose"
        isRequired={false}
        isReadOnly={false}
        value={refinance_purpose}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose: value,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.refinance_purpose ?? value;
          }
          if (errors.refinance_purpose?.hasError) {
            runValidationTasks("refinance_purpose", value);
          }
          setRefinance_purpose(value);
        }}
        onBlur={() =>
          runValidationTasks("refinance_purpose", refinance_purpose)
        }
        errorMessage={errors.refinance_purpose?.errorMessage}
        hasError={errors.refinance_purpose?.hasError}
        {...getOverrideProps(overrides, "refinance_purpose")}
      ></TextField>
      <TextField
        label="Refinance describe improvements"
        isRequired={false}
        isReadOnly={false}
        value={refinance_describeImprovements}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements: value,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.refinance_describeImprovements ?? value;
          }
          if (errors.refinance_describeImprovements?.hasError) {
            runValidationTasks("refinance_describeImprovements", value);
          }
          setRefinance_describeImprovements(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "refinance_describeImprovements",
            refinance_describeImprovements
          )
        }
        errorMessage={errors.refinance_describeImprovements?.errorMessage}
        hasError={errors.refinance_describeImprovements?.hasError}
        {...getOverrideProps(overrides, "refinance_describeImprovements")}
      ></TextField>
      <TextField
        label="Refinance cost"
        isRequired={false}
        isReadOnly={false}
        value={refinance_cost}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost: value,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.refinance_cost ?? value;
          }
          if (errors.refinance_cost?.hasError) {
            runValidationTasks("refinance_cost", value);
          }
          setRefinance_cost(value);
        }}
        onBlur={() => runValidationTasks("refinance_cost", refinance_cost)}
        errorMessage={errors.refinance_cost?.errorMessage}
        hasError={errors.refinance_cost?.hasError}
        {...getOverrideProps(overrides, "refinance_cost")}
      ></TextField>
      <SwitchField
        label="Refinance improvements made"
        defaultChecked={false}
        isDisabled={false}
        isChecked={refinance_improvementsMade}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade: value,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.refinance_improvementsMade ?? value;
          }
          if (errors.refinance_improvementsMade?.hasError) {
            runValidationTasks("refinance_improvementsMade", value);
          }
          setRefinance_improvementsMade(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "refinance_improvementsMade",
            refinance_improvementsMade
          )
        }
        errorMessage={errors.refinance_improvementsMade?.errorMessage}
        hasError={errors.refinance_improvementsMade?.hasError}
        {...getOverrideProps(overrides, "refinance_improvementsMade")}
      ></SwitchField>
      <TextField
        label="Title name"
        isRequired={false}
        isReadOnly={false}
        value={titleName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName: value,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.titleName ?? value;
          }
          if (errors.titleName?.hasError) {
            runValidationTasks("titleName", value);
          }
          setTitleName(value);
        }}
        onBlur={() => runValidationTasks("titleName", titleName)}
        errorMessage={errors.titleName?.errorMessage}
        hasError={errors.titleName?.hasError}
        {...getOverrideProps(overrides, "titleName")}
      ></TextField>
      <TextField
        label="Title manner"
        isRequired={false}
        isReadOnly={false}
        value={titleManner}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner: value,
              sourceOfFinancing,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.titleManner ?? value;
          }
          if (errors.titleManner?.hasError) {
            runValidationTasks("titleManner", value);
          }
          setTitleManner(value);
        }}
        onBlur={() => runValidationTasks("titleManner", titleManner)}
        errorMessage={errors.titleManner?.errorMessage}
        hasError={errors.titleManner?.hasError}
        {...getOverrideProps(overrides, "titleManner")}
      ></TextField>
      <TextField
        label="Source of financing"
        isRequired={false}
        isReadOnly={false}
        value={sourceOfFinancing}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing: value,
              estateToBeHeld,
            };
            const result = onChange(modelFields);
            value = result?.sourceOfFinancing ?? value;
          }
          if (errors.sourceOfFinancing?.hasError) {
            runValidationTasks("sourceOfFinancing", value);
          }
          setSourceOfFinancing(value);
        }}
        onBlur={() =>
          runValidationTasks("sourceOfFinancing", sourceOfFinancing)
        }
        errorMessage={errors.sourceOfFinancing?.errorMessage}
        hasError={errors.sourceOfFinancing?.hasError}
        {...getOverrideProps(overrides, "sourceOfFinancing")}
      ></TextField>
      <TextField
        label="Estate to be held"
        isRequired={false}
        isReadOnly={false}
        value={estateToBeHeld}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              subjectPropertyAddress,
              subjectPropertyAddress2,
              subjectPropertyCity,
              subjectPropertyState,
              subjectPropertyZip,
              numberOfUnits,
              legalDescription,
              yearBuilt,
              purposeOfProperty,
              purposeOfLoan,
              cons_yearLotAcquired,
              cons_originalCost,
              cons_AmountExistingLiens,
              cons_presentVal,
              cons_costOfImprovements,
              cons_total,
              refinance_yearAcquired,
              refinance_originalCost,
              refinance_amountExistingLiens,
              refinance_purpose,
              refinance_describeImprovements,
              refinance_cost,
              refinance_improvementsMade,
              titleName,
              titleManner,
              sourceOfFinancing,
              estateToBeHeld: value,
            };
            const result = onChange(modelFields);
            value = result?.estateToBeHeld ?? value;
          }
          if (errors.estateToBeHeld?.hasError) {
            runValidationTasks("estateToBeHeld", value);
          }
          setEstateToBeHeld(value);
        }}
        onBlur={() => runValidationTasks("estateToBeHeld", estateToBeHeld)}
        errorMessage={errors.estateToBeHeld?.errorMessage}
        hasError={errors.estateToBeHeld?.hasError}
        {...getOverrideProps(overrides, "estateToBeHeld")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || propertyModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || propertyModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
