/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getLiabilitiesInfo } from "../graphql/queries";
import { updateLiabilitiesInfo } from "../graphql/mutations";
const client = generateClient();
export default function LiabilitiesInfoUpdateForm(props) {
  const {
    id: idProp,
    liabilitiesInfo: liabilitiesInfoModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    nameAndAddressOfCompany: "",
    accountNo: "",
    payments: "",
    unpaidBalance: "",
    nameAndAddressOfCompany2: "",
    accountNo2: "",
    payments2: "",
    unpaidBalance2: "",
    nameAndAddressOfCompany3: "",
    accountNo3: "",
    payments3: "",
    unpaidBalance3: "",
    nameAndAddressOfCompany4: "",
    accountNo4: "",
    payments4: "",
    unpaidBalance4: "",
    nameAndAddressOfCompany5: "",
    accountNo5: "",
    payments5: "",
    unpaidBalance5: "",
    nameAndAddressOfCompany6: "",
    accountNo6: "",
    payments6: "",
    unpaidBalance6: "",
    maintenancePaymentsOwed: "",
    maintenancePaymentsAmount: "",
    jobRelatedExpenses: "",
    jobRelatedExpensesAmount: "",
    totalMonthlyPayments: "",
    totalLiabilities: "",
    netWorth: "",
  };
  const [nameAndAddressOfCompany, setNameAndAddressOfCompany] = React.useState(
    initialValues.nameAndAddressOfCompany
  );
  const [accountNo, setAccountNo] = React.useState(initialValues.accountNo);
  const [payments, setPayments] = React.useState(initialValues.payments);
  const [unpaidBalance, setUnpaidBalance] = React.useState(
    initialValues.unpaidBalance
  );
  const [nameAndAddressOfCompany2, setNameAndAddressOfCompany2] =
    React.useState(initialValues.nameAndAddressOfCompany2);
  const [accountNo2, setAccountNo2] = React.useState(initialValues.accountNo2);
  const [payments2, setPayments2] = React.useState(initialValues.payments2);
  const [unpaidBalance2, setUnpaidBalance2] = React.useState(
    initialValues.unpaidBalance2
  );
  const [nameAndAddressOfCompany3, setNameAndAddressOfCompany3] =
    React.useState(initialValues.nameAndAddressOfCompany3);
  const [accountNo3, setAccountNo3] = React.useState(initialValues.accountNo3);
  const [payments3, setPayments3] = React.useState(initialValues.payments3);
  const [unpaidBalance3, setUnpaidBalance3] = React.useState(
    initialValues.unpaidBalance3
  );
  const [nameAndAddressOfCompany4, setNameAndAddressOfCompany4] =
    React.useState(initialValues.nameAndAddressOfCompany4);
  const [accountNo4, setAccountNo4] = React.useState(initialValues.accountNo4);
  const [payments4, setPayments4] = React.useState(initialValues.payments4);
  const [unpaidBalance4, setUnpaidBalance4] = React.useState(
    initialValues.unpaidBalance4
  );
  const [nameAndAddressOfCompany5, setNameAndAddressOfCompany5] =
    React.useState(initialValues.nameAndAddressOfCompany5);
  const [accountNo5, setAccountNo5] = React.useState(initialValues.accountNo5);
  const [payments5, setPayments5] = React.useState(initialValues.payments5);
  const [unpaidBalance5, setUnpaidBalance5] = React.useState(
    initialValues.unpaidBalance5
  );
  const [nameAndAddressOfCompany6, setNameAndAddressOfCompany6] =
    React.useState(initialValues.nameAndAddressOfCompany6);
  const [accountNo6, setAccountNo6] = React.useState(initialValues.accountNo6);
  const [payments6, setPayments6] = React.useState(initialValues.payments6);
  const [unpaidBalance6, setUnpaidBalance6] = React.useState(
    initialValues.unpaidBalance6
  );
  const [maintenancePaymentsOwed, setMaintenancePaymentsOwed] = React.useState(
    initialValues.maintenancePaymentsOwed
  );
  const [maintenancePaymentsAmount, setMaintenancePaymentsAmount] =
    React.useState(initialValues.maintenancePaymentsAmount);
  const [jobRelatedExpenses, setJobRelatedExpenses] = React.useState(
    initialValues.jobRelatedExpenses
  );
  const [jobRelatedExpensesAmount, setJobRelatedExpensesAmount] =
    React.useState(initialValues.jobRelatedExpensesAmount);
  const [totalMonthlyPayments, setTotalMonthlyPayments] = React.useState(
    initialValues.totalMonthlyPayments
  );
  const [totalLiabilities, setTotalLiabilities] = React.useState(
    initialValues.totalLiabilities
  );
  const [netWorth, setNetWorth] = React.useState(initialValues.netWorth);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = liabilitiesInfoRecord
      ? { ...initialValues, ...liabilitiesInfoRecord }
      : initialValues;
    setNameAndAddressOfCompany(cleanValues.nameAndAddressOfCompany);
    setAccountNo(cleanValues.accountNo);
    setPayments(cleanValues.payments);
    setUnpaidBalance(cleanValues.unpaidBalance);
    setNameAndAddressOfCompany2(cleanValues.nameAndAddressOfCompany2);
    setAccountNo2(cleanValues.accountNo2);
    setPayments2(cleanValues.payments2);
    setUnpaidBalance2(cleanValues.unpaidBalance2);
    setNameAndAddressOfCompany3(cleanValues.nameAndAddressOfCompany3);
    setAccountNo3(cleanValues.accountNo3);
    setPayments3(cleanValues.payments3);
    setUnpaidBalance3(cleanValues.unpaidBalance3);
    setNameAndAddressOfCompany4(cleanValues.nameAndAddressOfCompany4);
    setAccountNo4(cleanValues.accountNo4);
    setPayments4(cleanValues.payments4);
    setUnpaidBalance4(cleanValues.unpaidBalance4);
    setNameAndAddressOfCompany5(cleanValues.nameAndAddressOfCompany5);
    setAccountNo5(cleanValues.accountNo5);
    setPayments5(cleanValues.payments5);
    setUnpaidBalance5(cleanValues.unpaidBalance5);
    setNameAndAddressOfCompany6(cleanValues.nameAndAddressOfCompany6);
    setAccountNo6(cleanValues.accountNo6);
    setPayments6(cleanValues.payments6);
    setUnpaidBalance6(cleanValues.unpaidBalance6);
    setMaintenancePaymentsOwed(cleanValues.maintenancePaymentsOwed);
    setMaintenancePaymentsAmount(cleanValues.maintenancePaymentsAmount);
    setJobRelatedExpenses(cleanValues.jobRelatedExpenses);
    setJobRelatedExpensesAmount(cleanValues.jobRelatedExpensesAmount);
    setTotalMonthlyPayments(cleanValues.totalMonthlyPayments);
    setTotalLiabilities(cleanValues.totalLiabilities);
    setNetWorth(cleanValues.netWorth);
    setErrors({});
  };
  const [liabilitiesInfoRecord, setLiabilitiesInfoRecord] = React.useState(
    liabilitiesInfoModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getLiabilitiesInfo.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getLiabilitiesInfo
        : liabilitiesInfoModelProp;
      setLiabilitiesInfoRecord(record);
    };
    queryData();
  }, [idProp, liabilitiesInfoModelProp]);
  React.useEffect(resetStateValues, [liabilitiesInfoRecord]);
  const validations = {
    nameAndAddressOfCompany: [],
    accountNo: [],
    payments: [],
    unpaidBalance: [],
    nameAndAddressOfCompany2: [],
    accountNo2: [],
    payments2: [],
    unpaidBalance2: [],
    nameAndAddressOfCompany3: [],
    accountNo3: [],
    payments3: [],
    unpaidBalance3: [],
    nameAndAddressOfCompany4: [],
    accountNo4: [],
    payments4: [],
    unpaidBalance4: [],
    nameAndAddressOfCompany5: [],
    accountNo5: [],
    payments5: [],
    unpaidBalance5: [],
    nameAndAddressOfCompany6: [],
    accountNo6: [],
    payments6: [],
    unpaidBalance6: [],
    maintenancePaymentsOwed: [],
    maintenancePaymentsAmount: [],
    jobRelatedExpenses: [],
    jobRelatedExpensesAmount: [],
    totalMonthlyPayments: [],
    totalLiabilities: [],
    netWorth: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          nameAndAddressOfCompany: nameAndAddressOfCompany ?? null,
          accountNo: accountNo ?? null,
          payments: payments ?? null,
          unpaidBalance: unpaidBalance ?? null,
          nameAndAddressOfCompany2: nameAndAddressOfCompany2 ?? null,
          accountNo2: accountNo2 ?? null,
          payments2: payments2 ?? null,
          unpaidBalance2: unpaidBalance2 ?? null,
          nameAndAddressOfCompany3: nameAndAddressOfCompany3 ?? null,
          accountNo3: accountNo3 ?? null,
          payments3: payments3 ?? null,
          unpaidBalance3: unpaidBalance3 ?? null,
          nameAndAddressOfCompany4: nameAndAddressOfCompany4 ?? null,
          accountNo4: accountNo4 ?? null,
          payments4: payments4 ?? null,
          unpaidBalance4: unpaidBalance4 ?? null,
          nameAndAddressOfCompany5: nameAndAddressOfCompany5 ?? null,
          accountNo5: accountNo5 ?? null,
          payments5: payments5 ?? null,
          unpaidBalance5: unpaidBalance5 ?? null,
          nameAndAddressOfCompany6: nameAndAddressOfCompany6 ?? null,
          accountNo6: accountNo6 ?? null,
          payments6: payments6 ?? null,
          unpaidBalance6: unpaidBalance6 ?? null,
          maintenancePaymentsOwed: maintenancePaymentsOwed ?? null,
          maintenancePaymentsAmount: maintenancePaymentsAmount ?? null,
          jobRelatedExpenses: jobRelatedExpenses ?? null,
          jobRelatedExpensesAmount: jobRelatedExpensesAmount ?? null,
          totalMonthlyPayments: totalMonthlyPayments ?? null,
          totalLiabilities: totalLiabilities ?? null,
          netWorth: netWorth ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateLiabilitiesInfo.replaceAll("__typename", ""),
            variables: {
              input: {
                id: liabilitiesInfoRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "LiabilitiesInfoUpdateForm")}
      {...rest}
    >
      <TextField
        label="Name and address of company"
        isRequired={false}
        isReadOnly={false}
        value={nameAndAddressOfCompany}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany: value,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.nameAndAddressOfCompany ?? value;
          }
          if (errors.nameAndAddressOfCompany?.hasError) {
            runValidationTasks("nameAndAddressOfCompany", value);
          }
          setNameAndAddressOfCompany(value);
        }}
        onBlur={() =>
          runValidationTasks("nameAndAddressOfCompany", nameAndAddressOfCompany)
        }
        errorMessage={errors.nameAndAddressOfCompany?.errorMessage}
        hasError={errors.nameAndAddressOfCompany?.hasError}
        {...getOverrideProps(overrides, "nameAndAddressOfCompany")}
      ></TextField>
      <TextField
        label="Account no"
        isRequired={false}
        isReadOnly={false}
        value={accountNo}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo: value,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.accountNo ?? value;
          }
          if (errors.accountNo?.hasError) {
            runValidationTasks("accountNo", value);
          }
          setAccountNo(value);
        }}
        onBlur={() => runValidationTasks("accountNo", accountNo)}
        errorMessage={errors.accountNo?.errorMessage}
        hasError={errors.accountNo?.hasError}
        {...getOverrideProps(overrides, "accountNo")}
      ></TextField>
      <TextField
        label="Payments"
        isRequired={false}
        isReadOnly={false}
        value={payments}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments: value,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.payments ?? value;
          }
          if (errors.payments?.hasError) {
            runValidationTasks("payments", value);
          }
          setPayments(value);
        }}
        onBlur={() => runValidationTasks("payments", payments)}
        errorMessage={errors.payments?.errorMessage}
        hasError={errors.payments?.hasError}
        {...getOverrideProps(overrides, "payments")}
      ></TextField>
      <TextField
        label="Unpaid balance"
        isRequired={false}
        isReadOnly={false}
        value={unpaidBalance}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance: value,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.unpaidBalance ?? value;
          }
          if (errors.unpaidBalance?.hasError) {
            runValidationTasks("unpaidBalance", value);
          }
          setUnpaidBalance(value);
        }}
        onBlur={() => runValidationTasks("unpaidBalance", unpaidBalance)}
        errorMessage={errors.unpaidBalance?.errorMessage}
        hasError={errors.unpaidBalance?.hasError}
        {...getOverrideProps(overrides, "unpaidBalance")}
      ></TextField>
      <TextField
        label="Name and address of company2"
        isRequired={false}
        isReadOnly={false}
        value={nameAndAddressOfCompany2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2: value,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.nameAndAddressOfCompany2 ?? value;
          }
          if (errors.nameAndAddressOfCompany2?.hasError) {
            runValidationTasks("nameAndAddressOfCompany2", value);
          }
          setNameAndAddressOfCompany2(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "nameAndAddressOfCompany2",
            nameAndAddressOfCompany2
          )
        }
        errorMessage={errors.nameAndAddressOfCompany2?.errorMessage}
        hasError={errors.nameAndAddressOfCompany2?.hasError}
        {...getOverrideProps(overrides, "nameAndAddressOfCompany2")}
      ></TextField>
      <TextField
        label="Account no2"
        isRequired={false}
        isReadOnly={false}
        value={accountNo2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2: value,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.accountNo2 ?? value;
          }
          if (errors.accountNo2?.hasError) {
            runValidationTasks("accountNo2", value);
          }
          setAccountNo2(value);
        }}
        onBlur={() => runValidationTasks("accountNo2", accountNo2)}
        errorMessage={errors.accountNo2?.errorMessage}
        hasError={errors.accountNo2?.hasError}
        {...getOverrideProps(overrides, "accountNo2")}
      ></TextField>
      <TextField
        label="Payments2"
        isRequired={false}
        isReadOnly={false}
        value={payments2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2: value,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.payments2 ?? value;
          }
          if (errors.payments2?.hasError) {
            runValidationTasks("payments2", value);
          }
          setPayments2(value);
        }}
        onBlur={() => runValidationTasks("payments2", payments2)}
        errorMessage={errors.payments2?.errorMessage}
        hasError={errors.payments2?.hasError}
        {...getOverrideProps(overrides, "payments2")}
      ></TextField>
      <TextField
        label="Unpaid balance2"
        isRequired={false}
        isReadOnly={false}
        value={unpaidBalance2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2: value,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.unpaidBalance2 ?? value;
          }
          if (errors.unpaidBalance2?.hasError) {
            runValidationTasks("unpaidBalance2", value);
          }
          setUnpaidBalance2(value);
        }}
        onBlur={() => runValidationTasks("unpaidBalance2", unpaidBalance2)}
        errorMessage={errors.unpaidBalance2?.errorMessage}
        hasError={errors.unpaidBalance2?.hasError}
        {...getOverrideProps(overrides, "unpaidBalance2")}
      ></TextField>
      <TextField
        label="Name and address of company3"
        isRequired={false}
        isReadOnly={false}
        value={nameAndAddressOfCompany3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3: value,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.nameAndAddressOfCompany3 ?? value;
          }
          if (errors.nameAndAddressOfCompany3?.hasError) {
            runValidationTasks("nameAndAddressOfCompany3", value);
          }
          setNameAndAddressOfCompany3(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "nameAndAddressOfCompany3",
            nameAndAddressOfCompany3
          )
        }
        errorMessage={errors.nameAndAddressOfCompany3?.errorMessage}
        hasError={errors.nameAndAddressOfCompany3?.hasError}
        {...getOverrideProps(overrides, "nameAndAddressOfCompany3")}
      ></TextField>
      <TextField
        label="Account no3"
        isRequired={false}
        isReadOnly={false}
        value={accountNo3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3: value,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.accountNo3 ?? value;
          }
          if (errors.accountNo3?.hasError) {
            runValidationTasks("accountNo3", value);
          }
          setAccountNo3(value);
        }}
        onBlur={() => runValidationTasks("accountNo3", accountNo3)}
        errorMessage={errors.accountNo3?.errorMessage}
        hasError={errors.accountNo3?.hasError}
        {...getOverrideProps(overrides, "accountNo3")}
      ></TextField>
      <TextField
        label="Payments3"
        isRequired={false}
        isReadOnly={false}
        value={payments3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3: value,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.payments3 ?? value;
          }
          if (errors.payments3?.hasError) {
            runValidationTasks("payments3", value);
          }
          setPayments3(value);
        }}
        onBlur={() => runValidationTasks("payments3", payments3)}
        errorMessage={errors.payments3?.errorMessage}
        hasError={errors.payments3?.hasError}
        {...getOverrideProps(overrides, "payments3")}
      ></TextField>
      <TextField
        label="Unpaid balance3"
        isRequired={false}
        isReadOnly={false}
        value={unpaidBalance3}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3: value,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.unpaidBalance3 ?? value;
          }
          if (errors.unpaidBalance3?.hasError) {
            runValidationTasks("unpaidBalance3", value);
          }
          setUnpaidBalance3(value);
        }}
        onBlur={() => runValidationTasks("unpaidBalance3", unpaidBalance3)}
        errorMessage={errors.unpaidBalance3?.errorMessage}
        hasError={errors.unpaidBalance3?.hasError}
        {...getOverrideProps(overrides, "unpaidBalance3")}
      ></TextField>
      <TextField
        label="Name and address of company4"
        isRequired={false}
        isReadOnly={false}
        value={nameAndAddressOfCompany4}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4: value,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.nameAndAddressOfCompany4 ?? value;
          }
          if (errors.nameAndAddressOfCompany4?.hasError) {
            runValidationTasks("nameAndAddressOfCompany4", value);
          }
          setNameAndAddressOfCompany4(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "nameAndAddressOfCompany4",
            nameAndAddressOfCompany4
          )
        }
        errorMessage={errors.nameAndAddressOfCompany4?.errorMessage}
        hasError={errors.nameAndAddressOfCompany4?.hasError}
        {...getOverrideProps(overrides, "nameAndAddressOfCompany4")}
      ></TextField>
      <TextField
        label="Account no4"
        isRequired={false}
        isReadOnly={false}
        value={accountNo4}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4: value,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.accountNo4 ?? value;
          }
          if (errors.accountNo4?.hasError) {
            runValidationTasks("accountNo4", value);
          }
          setAccountNo4(value);
        }}
        onBlur={() => runValidationTasks("accountNo4", accountNo4)}
        errorMessage={errors.accountNo4?.errorMessage}
        hasError={errors.accountNo4?.hasError}
        {...getOverrideProps(overrides, "accountNo4")}
      ></TextField>
      <TextField
        label="Payments4"
        isRequired={false}
        isReadOnly={false}
        value={payments4}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4: value,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.payments4 ?? value;
          }
          if (errors.payments4?.hasError) {
            runValidationTasks("payments4", value);
          }
          setPayments4(value);
        }}
        onBlur={() => runValidationTasks("payments4", payments4)}
        errorMessage={errors.payments4?.errorMessage}
        hasError={errors.payments4?.hasError}
        {...getOverrideProps(overrides, "payments4")}
      ></TextField>
      <TextField
        label="Unpaid balance4"
        isRequired={false}
        isReadOnly={false}
        value={unpaidBalance4}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4: value,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.unpaidBalance4 ?? value;
          }
          if (errors.unpaidBalance4?.hasError) {
            runValidationTasks("unpaidBalance4", value);
          }
          setUnpaidBalance4(value);
        }}
        onBlur={() => runValidationTasks("unpaidBalance4", unpaidBalance4)}
        errorMessage={errors.unpaidBalance4?.errorMessage}
        hasError={errors.unpaidBalance4?.hasError}
        {...getOverrideProps(overrides, "unpaidBalance4")}
      ></TextField>
      <TextField
        label="Name and address of company5"
        isRequired={false}
        isReadOnly={false}
        value={nameAndAddressOfCompany5}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5: value,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.nameAndAddressOfCompany5 ?? value;
          }
          if (errors.nameAndAddressOfCompany5?.hasError) {
            runValidationTasks("nameAndAddressOfCompany5", value);
          }
          setNameAndAddressOfCompany5(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "nameAndAddressOfCompany5",
            nameAndAddressOfCompany5
          )
        }
        errorMessage={errors.nameAndAddressOfCompany5?.errorMessage}
        hasError={errors.nameAndAddressOfCompany5?.hasError}
        {...getOverrideProps(overrides, "nameAndAddressOfCompany5")}
      ></TextField>
      <TextField
        label="Account no5"
        isRequired={false}
        isReadOnly={false}
        value={accountNo5}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5: value,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.accountNo5 ?? value;
          }
          if (errors.accountNo5?.hasError) {
            runValidationTasks("accountNo5", value);
          }
          setAccountNo5(value);
        }}
        onBlur={() => runValidationTasks("accountNo5", accountNo5)}
        errorMessage={errors.accountNo5?.errorMessage}
        hasError={errors.accountNo5?.hasError}
        {...getOverrideProps(overrides, "accountNo5")}
      ></TextField>
      <TextField
        label="Payments5"
        isRequired={false}
        isReadOnly={false}
        value={payments5}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5: value,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.payments5 ?? value;
          }
          if (errors.payments5?.hasError) {
            runValidationTasks("payments5", value);
          }
          setPayments5(value);
        }}
        onBlur={() => runValidationTasks("payments5", payments5)}
        errorMessage={errors.payments5?.errorMessage}
        hasError={errors.payments5?.hasError}
        {...getOverrideProps(overrides, "payments5")}
      ></TextField>
      <TextField
        label="Unpaid balance5"
        isRequired={false}
        isReadOnly={false}
        value={unpaidBalance5}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5: value,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.unpaidBalance5 ?? value;
          }
          if (errors.unpaidBalance5?.hasError) {
            runValidationTasks("unpaidBalance5", value);
          }
          setUnpaidBalance5(value);
        }}
        onBlur={() => runValidationTasks("unpaidBalance5", unpaidBalance5)}
        errorMessage={errors.unpaidBalance5?.errorMessage}
        hasError={errors.unpaidBalance5?.hasError}
        {...getOverrideProps(overrides, "unpaidBalance5")}
      ></TextField>
      <TextField
        label="Name and address of company6"
        isRequired={false}
        isReadOnly={false}
        value={nameAndAddressOfCompany6}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6: value,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.nameAndAddressOfCompany6 ?? value;
          }
          if (errors.nameAndAddressOfCompany6?.hasError) {
            runValidationTasks("nameAndAddressOfCompany6", value);
          }
          setNameAndAddressOfCompany6(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "nameAndAddressOfCompany6",
            nameAndAddressOfCompany6
          )
        }
        errorMessage={errors.nameAndAddressOfCompany6?.errorMessage}
        hasError={errors.nameAndAddressOfCompany6?.hasError}
        {...getOverrideProps(overrides, "nameAndAddressOfCompany6")}
      ></TextField>
      <TextField
        label="Account no6"
        isRequired={false}
        isReadOnly={false}
        value={accountNo6}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6: value,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.accountNo6 ?? value;
          }
          if (errors.accountNo6?.hasError) {
            runValidationTasks("accountNo6", value);
          }
          setAccountNo6(value);
        }}
        onBlur={() => runValidationTasks("accountNo6", accountNo6)}
        errorMessage={errors.accountNo6?.errorMessage}
        hasError={errors.accountNo6?.hasError}
        {...getOverrideProps(overrides, "accountNo6")}
      ></TextField>
      <TextField
        label="Payments6"
        isRequired={false}
        isReadOnly={false}
        value={payments6}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6: value,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.payments6 ?? value;
          }
          if (errors.payments6?.hasError) {
            runValidationTasks("payments6", value);
          }
          setPayments6(value);
        }}
        onBlur={() => runValidationTasks("payments6", payments6)}
        errorMessage={errors.payments6?.errorMessage}
        hasError={errors.payments6?.hasError}
        {...getOverrideProps(overrides, "payments6")}
      ></TextField>
      <TextField
        label="Unpaid balance6"
        isRequired={false}
        isReadOnly={false}
        value={unpaidBalance6}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6: value,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.unpaidBalance6 ?? value;
          }
          if (errors.unpaidBalance6?.hasError) {
            runValidationTasks("unpaidBalance6", value);
          }
          setUnpaidBalance6(value);
        }}
        onBlur={() => runValidationTasks("unpaidBalance6", unpaidBalance6)}
        errorMessage={errors.unpaidBalance6?.errorMessage}
        hasError={errors.unpaidBalance6?.hasError}
        {...getOverrideProps(overrides, "unpaidBalance6")}
      ></TextField>
      <TextField
        label="Maintenance payments owed"
        isRequired={false}
        isReadOnly={false}
        value={maintenancePaymentsOwed}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed: value,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.maintenancePaymentsOwed ?? value;
          }
          if (errors.maintenancePaymentsOwed?.hasError) {
            runValidationTasks("maintenancePaymentsOwed", value);
          }
          setMaintenancePaymentsOwed(value);
        }}
        onBlur={() =>
          runValidationTasks("maintenancePaymentsOwed", maintenancePaymentsOwed)
        }
        errorMessage={errors.maintenancePaymentsOwed?.errorMessage}
        hasError={errors.maintenancePaymentsOwed?.hasError}
        {...getOverrideProps(overrides, "maintenancePaymentsOwed")}
      ></TextField>
      <TextField
        label="Maintenance payments amount"
        isRequired={false}
        isReadOnly={false}
        value={maintenancePaymentsAmount}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount: value,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.maintenancePaymentsAmount ?? value;
          }
          if (errors.maintenancePaymentsAmount?.hasError) {
            runValidationTasks("maintenancePaymentsAmount", value);
          }
          setMaintenancePaymentsAmount(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "maintenancePaymentsAmount",
            maintenancePaymentsAmount
          )
        }
        errorMessage={errors.maintenancePaymentsAmount?.errorMessage}
        hasError={errors.maintenancePaymentsAmount?.hasError}
        {...getOverrideProps(overrides, "maintenancePaymentsAmount")}
      ></TextField>
      <TextField
        label="Job related expenses"
        isRequired={false}
        isReadOnly={false}
        value={jobRelatedExpenses}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses: value,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.jobRelatedExpenses ?? value;
          }
          if (errors.jobRelatedExpenses?.hasError) {
            runValidationTasks("jobRelatedExpenses", value);
          }
          setJobRelatedExpenses(value);
        }}
        onBlur={() =>
          runValidationTasks("jobRelatedExpenses", jobRelatedExpenses)
        }
        errorMessage={errors.jobRelatedExpenses?.errorMessage}
        hasError={errors.jobRelatedExpenses?.hasError}
        {...getOverrideProps(overrides, "jobRelatedExpenses")}
      ></TextField>
      <TextField
        label="Job related expenses amount"
        isRequired={false}
        isReadOnly={false}
        value={jobRelatedExpensesAmount}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount: value,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.jobRelatedExpensesAmount ?? value;
          }
          if (errors.jobRelatedExpensesAmount?.hasError) {
            runValidationTasks("jobRelatedExpensesAmount", value);
          }
          setJobRelatedExpensesAmount(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "jobRelatedExpensesAmount",
            jobRelatedExpensesAmount
          )
        }
        errorMessage={errors.jobRelatedExpensesAmount?.errorMessage}
        hasError={errors.jobRelatedExpensesAmount?.hasError}
        {...getOverrideProps(overrides, "jobRelatedExpensesAmount")}
      ></TextField>
      <TextField
        label="Total monthly payments"
        isRequired={false}
        isReadOnly={false}
        value={totalMonthlyPayments}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments: value,
              totalLiabilities,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.totalMonthlyPayments ?? value;
          }
          if (errors.totalMonthlyPayments?.hasError) {
            runValidationTasks("totalMonthlyPayments", value);
          }
          setTotalMonthlyPayments(value);
        }}
        onBlur={() =>
          runValidationTasks("totalMonthlyPayments", totalMonthlyPayments)
        }
        errorMessage={errors.totalMonthlyPayments?.errorMessage}
        hasError={errors.totalMonthlyPayments?.hasError}
        {...getOverrideProps(overrides, "totalMonthlyPayments")}
      ></TextField>
      <TextField
        label="Total liabilities"
        isRequired={false}
        isReadOnly={false}
        value={totalLiabilities}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities: value,
              netWorth,
            };
            const result = onChange(modelFields);
            value = result?.totalLiabilities ?? value;
          }
          if (errors.totalLiabilities?.hasError) {
            runValidationTasks("totalLiabilities", value);
          }
          setTotalLiabilities(value);
        }}
        onBlur={() => runValidationTasks("totalLiabilities", totalLiabilities)}
        errorMessage={errors.totalLiabilities?.errorMessage}
        hasError={errors.totalLiabilities?.hasError}
        {...getOverrideProps(overrides, "totalLiabilities")}
      ></TextField>
      <TextField
        label="Net worth"
        isRequired={false}
        isReadOnly={false}
        value={netWorth}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nameAndAddressOfCompany,
              accountNo,
              payments,
              unpaidBalance,
              nameAndAddressOfCompany2,
              accountNo2,
              payments2,
              unpaidBalance2,
              nameAndAddressOfCompany3,
              accountNo3,
              payments3,
              unpaidBalance3,
              nameAndAddressOfCompany4,
              accountNo4,
              payments4,
              unpaidBalance4,
              nameAndAddressOfCompany5,
              accountNo5,
              payments5,
              unpaidBalance5,
              nameAndAddressOfCompany6,
              accountNo6,
              payments6,
              unpaidBalance6,
              maintenancePaymentsOwed,
              maintenancePaymentsAmount,
              jobRelatedExpenses,
              jobRelatedExpensesAmount,
              totalMonthlyPayments,
              totalLiabilities,
              netWorth: value,
            };
            const result = onChange(modelFields);
            value = result?.netWorth ?? value;
          }
          if (errors.netWorth?.hasError) {
            runValidationTasks("netWorth", value);
          }
          setNetWorth(value);
        }}
        onBlur={() => runValidationTasks("netWorth", netWorth)}
        errorMessage={errors.netWorth?.errorMessage}
        hasError={errors.netWorth?.hasError}
        {...getOverrideProps(overrides, "netWorth")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || liabilitiesInfoModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || liabilitiesInfoModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
