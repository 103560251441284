/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createDetailsOfTransaction } from "../graphql/mutations";
const client = generateClient();
export default function DetailsOfTransactionCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    purchasePrice: "",
    repairs: "",
    land: "",
    refinance: "",
    estPrepaid: "",
    estClosing: "",
    fundingFee: "",
    discounts: "",
    totalCosts: "",
    subordinateFinancing: "",
    borrowersClosing: "",
    otherCredits: "",
    otherCreditsAmount: "",
    loanAmount: "",
    fundingFeeFinanced: "",
    loanAmountAdd: "",
    cash: "",
  };
  const [purchasePrice, setPurchasePrice] = React.useState(
    initialValues.purchasePrice
  );
  const [repairs, setRepairs] = React.useState(initialValues.repairs);
  const [land, setLand] = React.useState(initialValues.land);
  const [refinance, setRefinance] = React.useState(initialValues.refinance);
  const [estPrepaid, setEstPrepaid] = React.useState(initialValues.estPrepaid);
  const [estClosing, setEstClosing] = React.useState(initialValues.estClosing);
  const [fundingFee, setFundingFee] = React.useState(initialValues.fundingFee);
  const [discounts, setDiscounts] = React.useState(initialValues.discounts);
  const [totalCosts, setTotalCosts] = React.useState(initialValues.totalCosts);
  const [subordinateFinancing, setSubordinateFinancing] = React.useState(
    initialValues.subordinateFinancing
  );
  const [borrowersClosing, setBorrowersClosing] = React.useState(
    initialValues.borrowersClosing
  );
  const [otherCredits, setOtherCredits] = React.useState(
    initialValues.otherCredits
  );
  const [otherCreditsAmount, setOtherCreditsAmount] = React.useState(
    initialValues.otherCreditsAmount
  );
  const [loanAmount, setLoanAmount] = React.useState(initialValues.loanAmount);
  const [fundingFeeFinanced, setFundingFeeFinanced] = React.useState(
    initialValues.fundingFeeFinanced
  );
  const [loanAmountAdd, setLoanAmountAdd] = React.useState(
    initialValues.loanAmountAdd
  );
  const [cash, setCash] = React.useState(initialValues.cash);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setPurchasePrice(initialValues.purchasePrice);
    setRepairs(initialValues.repairs);
    setLand(initialValues.land);
    setRefinance(initialValues.refinance);
    setEstPrepaid(initialValues.estPrepaid);
    setEstClosing(initialValues.estClosing);
    setFundingFee(initialValues.fundingFee);
    setDiscounts(initialValues.discounts);
    setTotalCosts(initialValues.totalCosts);
    setSubordinateFinancing(initialValues.subordinateFinancing);
    setBorrowersClosing(initialValues.borrowersClosing);
    setOtherCredits(initialValues.otherCredits);
    setOtherCreditsAmount(initialValues.otherCreditsAmount);
    setLoanAmount(initialValues.loanAmount);
    setFundingFeeFinanced(initialValues.fundingFeeFinanced);
    setLoanAmountAdd(initialValues.loanAmountAdd);
    setCash(initialValues.cash);
    setErrors({});
  };
  const validations = {
    purchasePrice: [],
    repairs: [],
    land: [],
    refinance: [],
    estPrepaid: [],
    estClosing: [],
    fundingFee: [],
    discounts: [],
    totalCosts: [],
    subordinateFinancing: [],
    borrowersClosing: [],
    otherCredits: [],
    otherCreditsAmount: [],
    loanAmount: [],
    fundingFeeFinanced: [],
    loanAmountAdd: [],
    cash: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          purchasePrice,
          repairs,
          land,
          refinance,
          estPrepaid,
          estClosing,
          fundingFee,
          discounts,
          totalCosts,
          subordinateFinancing,
          borrowersClosing,
          otherCredits,
          otherCreditsAmount,
          loanAmount,
          fundingFeeFinanced,
          loanAmountAdd,
          cash,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createDetailsOfTransaction.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "DetailsOfTransactionCreateForm")}
      {...rest}
    >
      <TextField
        label="a. Purchase price"
        isRequired={false}
        isReadOnly={false}
        value={purchasePrice}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice: value,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.purchasePrice ?? value;
          }
          if (errors.purchasePrice?.hasError) {
            runValidationTasks("purchasePrice", value);
          }
          setPurchasePrice(value);
        }}
        onBlur={() => runValidationTasks("purchasePrice", purchasePrice)}
        errorMessage={errors.purchasePrice?.errorMessage}
        hasError={errors.purchasePrice?.hasError}
        {...getOverrideProps(overrides, "purchasePrice")}
      ></TextField>
      <TextField
        label="b. Alterations, improvements, repairs"
        isRequired={false}
        isReadOnly={false}
        value={repairs}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs: value,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.repairs ?? value;
          }
          if (errors.repairs?.hasError) {
            runValidationTasks("repairs", value);
          }
          setRepairs(value);
        }}
        onBlur={() => runValidationTasks("repairs", repairs)}
        errorMessage={errors.repairs?.errorMessage}
        hasError={errors.repairs?.hasError}
        {...getOverrideProps(overrides, "repairs")}
      ></TextField>
      <TextField
        label="c. Land (if acquired separately)"
        isRequired={false}
        isReadOnly={false}
        value={land}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land: value,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.land ?? value;
          }
          if (errors.land?.hasError) {
            runValidationTasks("land", value);
          }
          setLand(value);
        }}
        onBlur={() => runValidationTasks("land", land)}
        errorMessage={errors.land?.errorMessage}
        hasError={errors.land?.hasError}
        {...getOverrideProps(overrides, "land")}
      ></TextField>
      <TextField
        label="d. Refinance (incl. debts to be paid off)"
        isRequired={false}
        isReadOnly={false}
        value={refinance}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance: value,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.refinance ?? value;
          }
          if (errors.refinance?.hasError) {
            runValidationTasks("refinance", value);
          }
          setRefinance(value);
        }}
        onBlur={() => runValidationTasks("refinance", refinance)}
        errorMessage={errors.refinance?.errorMessage}
        hasError={errors.refinance?.hasError}
        {...getOverrideProps(overrides, "refinance")}
      ></TextField>
      <TextField
        label="e. Estimated prepaid items"
        isRequired={false}
        isReadOnly={false}
        value={estPrepaid}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid: value,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.estPrepaid ?? value;
          }
          if (errors.estPrepaid?.hasError) {
            runValidationTasks("estPrepaid", value);
          }
          setEstPrepaid(value);
        }}
        onBlur={() => runValidationTasks("estPrepaid", estPrepaid)}
        errorMessage={errors.estPrepaid?.errorMessage}
        hasError={errors.estPrepaid?.hasError}
        {...getOverrideProps(overrides, "estPrepaid")}
      ></TextField>
      <TextField
        label="f. Estimated closing costs"
        isRequired={false}
        isReadOnly={false}
        value={estClosing}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing: value,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.estClosing ?? value;
          }
          if (errors.estClosing?.hasError) {
            runValidationTasks("estClosing", value);
          }
          setEstClosing(value);
        }}
        onBlur={() => runValidationTasks("estClosing", estClosing)}
        errorMessage={errors.estClosing?.errorMessage}
        hasError={errors.estClosing?.hasError}
        {...getOverrideProps(overrides, "estClosing")}
      ></TextField>
      <TextField
        label="g. PMI, MIP, Funding Fee"
        isRequired={false}
        isReadOnly={false}
        value={fundingFee}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee: value,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.fundingFee ?? value;
          }
          if (errors.fundingFee?.hasError) {
            runValidationTasks("fundingFee", value);
          }
          setFundingFee(value);
        }}
        onBlur={() => runValidationTasks("fundingFee", fundingFee)}
        errorMessage={errors.fundingFee?.errorMessage}
        hasError={errors.fundingFee?.hasError}
        {...getOverrideProps(overrides, "fundingFee")}
      ></TextField>
      <TextField
        label="h. Discount (if Borrower will pay)"
        isRequired={false}
        isReadOnly={false}
        value={discounts}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts: value,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.discounts ?? value;
          }
          if (errors.discounts?.hasError) {
            runValidationTasks("discounts", value);
          }
          setDiscounts(value);
        }}
        onBlur={() => runValidationTasks("discounts", discounts)}
        errorMessage={errors.discounts?.errorMessage}
        hasError={errors.discounts?.hasError}
        {...getOverrideProps(overrides, "discounts")}
      ></TextField>
      <TextField
        label="i. Total costs (add items a through h)"
        isRequired={false}
        isReadOnly={false}
        placeholder="$0.00"
        value={totalCosts}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts: value,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.totalCosts ?? value;
          }
          if (errors.totalCosts?.hasError) {
            runValidationTasks("totalCosts", value);
          }
          setTotalCosts(value);
        }}
        onBlur={() => runValidationTasks("totalCosts", totalCosts)}
        errorMessage={errors.totalCosts?.errorMessage}
        hasError={errors.totalCosts?.hasError}
        {...getOverrideProps(overrides, "totalCosts")}
      ></TextField>
      <TextField
        label="j. Subordinate financing"
        isRequired={false}
        isReadOnly={false}
        value={subordinateFinancing}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing: value,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.subordinateFinancing ?? value;
          }
          if (errors.subordinateFinancing?.hasError) {
            runValidationTasks("subordinateFinancing", value);
          }
          setSubordinateFinancing(value);
        }}
        onBlur={() =>
          runValidationTasks("subordinateFinancing", subordinateFinancing)
        }
        errorMessage={errors.subordinateFinancing?.errorMessage}
        hasError={errors.subordinateFinancing?.hasError}
        {...getOverrideProps(overrides, "subordinateFinancing")}
      ></TextField>
      <TextField
        label="k. Borrower's closing costs paid by Seller"
        isRequired={false}
        isReadOnly={false}
        value={borrowersClosing}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing: value,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.borrowersClosing ?? value;
          }
          if (errors.borrowersClosing?.hasError) {
            runValidationTasks("borrowersClosing", value);
          }
          setBorrowersClosing(value);
        }}
        onBlur={() => runValidationTasks("borrowersClosing", borrowersClosing)}
        errorMessage={errors.borrowersClosing?.errorMessage}
        hasError={errors.borrowersClosing?.hasError}
        {...getOverrideProps(overrides, "borrowersClosing")}
      ></TextField>
      <TextField
        label="l. Other credits (explain)"
        isRequired={false}
        isReadOnly={false}
        value={otherCredits}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits: value,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.otherCredits ?? value;
          }
          if (errors.otherCredits?.hasError) {
            runValidationTasks("otherCredits", value);
          }
          setOtherCredits(value);
        }}
        onBlur={() => runValidationTasks("otherCredits", otherCredits)}
        errorMessage={errors.otherCredits?.errorMessage}
        hasError={errors.otherCredits?.hasError}
        {...getOverrideProps(overrides, "otherCredits")}
      ></TextField>
      <TextField
        label="Other credits amount"
        isRequired={false}
        isReadOnly={false}
        value={otherCreditsAmount}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount: value,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.otherCreditsAmount ?? value;
          }
          if (errors.otherCreditsAmount?.hasError) {
            runValidationTasks("otherCreditsAmount", value);
          }
          setOtherCreditsAmount(value);
        }}
        onBlur={() =>
          runValidationTasks("otherCreditsAmount", otherCreditsAmount)
        }
        errorMessage={errors.otherCreditsAmount?.errorMessage}
        hasError={errors.otherCreditsAmount?.hasError}
        {...getOverrideProps(overrides, "otherCreditsAmount")}
      ></TextField>
      <TextField
        label="m. Loan amount (exclude PMI, MIP, Funding Fee financed)"
        isRequired={false}
        isReadOnly={false}
        value={loanAmount}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount: value,
              fundingFeeFinanced,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.loanAmount ?? value;
          }
          if (errors.loanAmount?.hasError) {
            runValidationTasks("loanAmount", value);
          }
          setLoanAmount(value);
        }}
        onBlur={() => runValidationTasks("loanAmount", loanAmount)}
        errorMessage={errors.loanAmount?.errorMessage}
        hasError={errors.loanAmount?.hasError}
        {...getOverrideProps(overrides, "loanAmount")}
      ></TextField>
      <TextField
        label="n. PMI, MIP, Funding fee financed"
        isRequired={false}
        isReadOnly={false}
        value={fundingFeeFinanced}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced: value,
              loanAmountAdd,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.fundingFeeFinanced ?? value;
          }
          if (errors.fundingFeeFinanced?.hasError) {
            runValidationTasks("fundingFeeFinanced", value);
          }
          setFundingFeeFinanced(value);
        }}
        onBlur={() =>
          runValidationTasks("fundingFeeFinanced", fundingFeeFinanced)
        }
        errorMessage={errors.fundingFeeFinanced?.errorMessage}
        hasError={errors.fundingFeeFinanced?.hasError}
        {...getOverrideProps(overrides, "fundingFeeFinanced")}
      ></TextField>
      <TextField
        label="o. Loan amount (add m & n)"
        isRequired={false}
        isReadOnly={false}
        placeholder="$0.00"
        value={loanAmountAdd}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd: value,
              cash,
            };
            const result = onChange(modelFields);
            value = result?.loanAmountAdd ?? value;
          }
          if (errors.loanAmountAdd?.hasError) {
            runValidationTasks("loanAmountAdd", value);
          }
          setLoanAmountAdd(value);
        }}
        onBlur={() => runValidationTasks("loanAmountAdd", loanAmountAdd)}
        errorMessage={errors.loanAmountAdd?.errorMessage}
        hasError={errors.loanAmountAdd?.hasError}
        {...getOverrideProps(overrides, "loanAmountAdd")}
      ></TextField>
      <TextField
        label="p. Cash from/to Borrower (subtract j, k, l & o from i)"
        isRequired={false}
        isReadOnly={false}
        value={cash}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              purchasePrice,
              repairs,
              land,
              refinance,
              estPrepaid,
              estClosing,
              fundingFee,
              discounts,
              totalCosts,
              subordinateFinancing,
              borrowersClosing,
              otherCredits,
              otherCreditsAmount,
              loanAmount,
              fundingFeeFinanced,
              loanAmountAdd,
              cash: value,
            };
            const result = onChange(modelFields);
            value = result?.cash ?? value;
          }
          if (errors.cash?.hasError) {
            runValidationTasks("cash", value);
          }
          setCash(value);
        }}
        onBlur={() => runValidationTasks("cash", cash)}
        errorMessage={errors.cash?.errorMessage}
        hasError={errors.cash?.hasError}
        {...getOverrideProps(overrides, "cash")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Save"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
