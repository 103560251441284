import React from "react";
import { Box, Grid, Typography, Button, Card, CardContent } from '@mui/material';
// import StepperComponent from './StepperComponent.js';
import BasicHeader from './Header.js';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";

import { Authenticator } from '@aws-amplify/ui-react';
import { handleBuyLicense } from './CommonFunctions';

function BuyLicensePage({ user }) {
    let navigate = useNavigate();

    return (
        <Authenticator>
            {({ signOut, user }) => (
                <>
                    <BasicHeader signOut={signOut} isAuthenticated={!!user} />
                    <Box sx={{ flexGrow: 1, padding: 2, backgroundColor: '#f9f9f9', minHeight: '100vh', marginTop: '80px' }}>
                        <Grid container spacing={3} justifyContent="center">
                            {/* <Grid item xs={12} sm={3}>
                                <StepperComponent />
                            </Grid> */}
                            <Grid item xs={12} sm={6}>
                                <Card sx={{ boxShadow: 3 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            Buy a New License
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            The cost of a new license is:
                                        </Typography>
                                        <Box sx={{ mt: 2, display: 'inline-flex', alignItems: 'center', p: 1, border: '1px solid #ccc', borderRadius: '8px' }}>
                                            <Typography variant="h5" sx={{ pr: 1 }}>$50.00</Typography>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleBuyLicense(user)}
                                            sx={{ mt: 3, display: 'block', mx: 'auto' }}
                                        >
                                            Buy License
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </Authenticator>
    );
}

export default withAuthenticator(BuyLicensePage);