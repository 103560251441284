
import { loadStripe } from '@stripe/stripe-js';
import { loadConfig } from './ConfigLoader';

let stripePromise;

const getStripePublicKey = async () => {
    try {
        // Load configuration
        const config = await loadConfig();
        const stripePublicKeyApiUrl = config.REACT_APP_STRIPE_PUBLIC_KEY_URL; // Ensure this is in your config.json

        console.log('Fetching Stripe public key from:', stripePublicKeyApiUrl);

        // Use API Gateway to fetch the Stripe public key
        const response = await fetch(stripePublicKeyApiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch Stripe public key from API Gateway');
        }

        const result = await response.json();
        const data = JSON.parse(result.body);
        const stripePublicKey = data.stripePublicKey;
        console.log("Using Stripe Public Key from API Gateway:", stripePublicKey);
        stripePromise = loadStripe(stripePublicKey);
    } catch (err) {
        console.error('Error fetching public key:', err);
    }
};

// Fetch the public key once when the module is loaded
getStripePublicKey();

export const handleBuyLicense = async (user) => {
    try {
        // Fixed amount for the license
        const amount = 99.99;

        // Load configuration to get the API URL
        const config = await loadConfig();
        const buyLicenseApiUrl = config.REACT_APP_BUY_LICENSE_API_URL;

        // Call the backend to create a Stripe session
        const response = await fetch(buyLicenseApiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: user.username, amount }),
        });

        const result = await response.json();

        // Retrieve session ID and redirect to Stripe Checkout
        if (result && result.sessionId) {
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({ sessionId: result.sessionId });

            if (error) {
                console.error('Stripe Checkout error:', error);
            }
        } else {
            alert('Error initiating payment.');
        }
    } catch (error) {
        console.error('Error buying license:', error);
    }
};

export const navigateToRenewLicense = (navigate) => {
    navigate('/renewLicense');
};