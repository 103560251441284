import React from 'react';
import "./App.css";
import Footer from './Footer.js';
import { CoborrowerEmploymentInfoCreateForm } from './ui-components';
import { Amplify } from 'aws-amplify';
import { Paper, Typography, Box } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports.js';
import { withAuthenticator } from '@aws-amplify/ui-react';

Amplify.configure(awsExports);

const styles = {
    mainContent: {
        backgroundColor: '#f4f4f4',
        padding: '20px',
        marginTop: '100px',
        display: 'flex',
        justifyContent: 'center'
    },
    paperContent: {
        width: '100%',
        maxWidth: '800px',
        padding: '20px',
        backgroundColor: '#ffffff',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        margin: '20px 0'
    },
    formContainer: {
        backgroundColor: '#f9f9f9',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        marginBottom: '40px'
    },
    h3: {
        color: '#333',
        fontSize: '2rem',
        marginBottom: '20px',
        textAlign: 'center'
    },
    p: {
        fontSize: '1.2rem',
        color: '#555',
        textAlign: 'center',
        marginBottom: '20px'
    },
    hr: {
        border: '1px solid #ddd',
        margin: '20px 0'
    },
    button: {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '1rem',
        margin: '10px 0',
        display: 'block',
        width: '100%'
    },
    buttonHover: {
        backgroundColor: '#45a049'
    },
    input: {
        width: '100%',
        padding: '12px 20px',
        margin: '8px 0',
        display: 'inline-block',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box'
    },
    label: {
        fontSize: '1rem',
        color: '#333',
        marginBottom: '8px',
        display: 'block'
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.9rem',
        marginBottom: '20px'
    }
};

function CoborrowerEmploymentInfo({ user }) {
    var staticData = {
        id: user.username,
    };

    return (
        <>
        <div style={styles.mainContent}>
            <Paper elevation={3} style={styles.paperContent}>
                <Typography variant="h3" gutterBottom style={styles.h3}>
                    Co-Borrower Employment Information
                </Typography>
                <hr style={styles.hr} />
                <Box style={styles.formContainer}>
                    <CoborrowerEmploymentInfoCreateForm
                        onSubmit={(fields) => {
                            const payload = {
                                ...fields,
                                ...staticData,
                            };
                            return payload;
                        }}
                    />
                </Box>
            </Paper>
            
        </div>
        <Footer />
        </>
    );
}

export default withAuthenticator(CoborrowerEmploymentInfo);
